import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Layout from "../Common/Layout";
import LayoutDesktop, {
  LayoutLeftContent,
  LayoutRightContent,
} from "../Common/LayoutDesktop";

import { Button, LoadingButton } from "../../core/buttons";

import { useStudent } from "../../redux/Student/hooks";
import { updateStudentLocal } from "../../redux/Student/actions";

import Api from "../../services/Api";

function EmailUpdate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateId } = useParams();

  const { student } = useStudent();

  const [error, setError] = useState();
  const [emailChange, setEmailChange] = useState();
  const [isLoadingMutation, setIsLoadingMutation] = useState(false);

  const skipOnClick = () => navigate("/");

  const acceptOnClick = async () => {
    setIsLoadingMutation(true);
    try {
      const { newEmail } = await Api.confirmEmailChange({ updateId });
      dispatch(updateStudentLocal({ ...student, email: newEmail }));
      navigate("/");
    } catch (error) {
      setError(error);
    } finally {
      setIsLoadingMutation(false);
    }
  };

  useEffect(() => {
    const fetchEmailChange = async () => {
      try {
        setEmailChange(await Api.emailChangeRequest({ updateId }));
      } catch (error) {
        setError(error);
      } finally {
        setIsLoadingMutation(false);
      }
    };
    fetchEmailChange();
  }, [updateId]);

  const title = "Are you sure you want to change your email?";
  const content = (
    <>
      <p>
        From: <span className="font-semibold">{emailChange?.oldEmail} </span>
      </p>
      <p>
        to: <span className="font-semibold">{emailChange?.newEmail}</span>
      </p>
    </>
  );

  return (
    <Layout logoWhite={true} headerBGColor="text-white bg-primary-green">
      <LayoutDesktop className="bg-primary-green min-h-screen-footer-md">
        <LayoutLeftContent>
          <p className="text-3xl font-semibold laptop:text-4xl desktop:text-5xl">
            Confirm your profile update
          </p>
        </LayoutLeftContent>
        <LayoutRightContent>
          <div
            className="
              py-4
              px-6
              flex
              border
              flex-col
              shadow-lg
              bg-white
              items-center
              border-black
            "
          >
            <div className="flex flex-1 flex-col">
              <div className="my-12">
                <div className="text-4xl font-extrabold text-primary-green">
                  {error ? error?.errors[0]?.message : title}
                </div>
                <div
                  className={`
                    mb-4
                    my-12
                    text-2xl
                    text-black
                    ${error && "hidden"}
                  `}
                >
                  {content}
                </div>
              </div>
              <div
                className={`
                  flex
                  flex-1
                  flex-row
                  space-x-4
                  ${error && "hidden"}
                `}
              >
                <div className="w-1/2">
                  <Button
                    onClick={() => skipOnClick()}
                    className="
                      mb-5
                      w-full
                      bg-white
                      text-primary-green
                      border-primary-green
                      hover:text-white
                      active:text-white
                      hover:bg-primary-green
                      active:bg-primary-green
                    "
                  >
                    Skip
                  </Button>
                </div>
                <div className="w-1/2">
                  <LoadingButton
                    isLoading={isLoadingMutation}
                    onClick={() => acceptOnClick()}
                    className="
                      mb-5
                      w-full
                      text-white
                      bg-primary-green
                      border-primary-green
                      hover:bg-white
                      active:bg-white
                      hover:text-primary-green
                      active:text-primary-green
                    "
                  >
                    Accept
                  </LoadingButton>
                </div>
              </div>
              <div className={`flex flex-row flex-1 ${!error && "hidden"}`}>
                <div className="w-full">
                  <Button
                    onClick={() => skipOnClick()}
                    className="
                      mb-5
                      w-full
                      bg-white
                      text-primary-green
                      border-primary-green
                      hover:text-white
                      active:text-white
                      hover:bg-primary-green
                      active:bg-primary-green
                    "
                  >
                    Return
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </LayoutRightContent>
      </LayoutDesktop>
    </Layout>
  );
}

export default EmailUpdate;
