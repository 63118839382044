import PropTypes from "prop-types";

import { Panel, CampusEnvironment } from "../../../core/compose";
import { getTheme } from "../../../core/utils";

function CollegeCampusEnvironment({
  isDesktop,
  chosenSchool,
  handlerOnClickTooltip,
}) {
  const { award, school } = chosenSchool;
  const { size, control, areaType } = school;

  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability,
  });

  return (
    <Panel
      tooltip={true}
      isDesktop={isDesktop}
      title="campus environment"
      tooltipCustomToggle={handlerOnClickTooltip}
      className="
        p-4
        mt-4
        mx-4
        bg-white
        shadow-lg
        tablet:mx-0
        tablet:mt-4
        laptop:mx-0
        laptop:mt-4
        desktop:mx-0
        desktop:mt-4
      "
    >
      <CampusEnvironment
        bodySize={size}
        college={control}
        color={theme.color}
        locationType={areaType}
      />
    </Panel>
  );
}

CollegeCampusEnvironment.propTypes = {
  isDesktop: PropTypes.bool,
  chosenSchool: PropTypes.shape(),
  handlerOnClickTooltip: PropTypes.func,
};

export default CollegeCampusEnvironment;
