import PuffLoader from "react-spinners/PuffLoader";
import PropTypes from "prop-types";

import SearchResults from "./SearchResults";

import { MagnifyGlass } from "../../icons";
import { Colors, getColors } from "../../utils";

function SearchTextDropdownBtn({
  id,
  color = Colors.DEFAULT,
  search,
  isLoading,
  placeholder,
  collegeResults,
  handlerOnClick,
  handlerOnChange,
  handlerOnKeyDown,
  handlerOnSelectCollege
}) {
  const getComposePlaceholder = (text) => {
    switch (text) {
      case "text-primary-red":
        return "placeholder-primary-red";
      case "text-primary-blue":
        return "placeholder-primary-blue";
      case "text-primary-green":
        return "placeholder-primary-green";
      case "text-primary-orange":
        return "placeholder-primary-orange";

      default:
        return "placeholder-gray-500";
    }
  };

  const colors = getColors(color);
  const composePlaceholder = getComposePlaceholder(colors.primary.text);

  return (
    <div className="flex flex-1 flex-row">
      <div className="w-full relative">
        <div
          className="
            p-3
            flex
            flex-1
            border
            flex-row
            bg-white
            items-center
            border-black
            focus-within:shadow-black
          "
        >
          <input
            id={id}
            type="text"
            value={search}
            placeholder={placeholder}
            onChange={handlerOnChange}
            onKeyDown={handlerOnKeyDown}
            aria-label="searchTextDropdownBtn"
            className={`
              flex
              flex-1
              text-black
              outline-none
              ${composePlaceholder}
            `}
          />
          {isLoading && <PuffLoader className="flex justify-end" size={24} />}
        </div>
        {collegeResults && (
          <SearchResults
            colors={colors}
            colleges={collegeResults}
            onSelect={handlerOnSelectCollege}
          />
        )}
      </div>
      <div
        onClick={handlerOnClick}
        className="
          w-12
          flex
          ml-0.5
          items-center
          justify-center
          cursor-pointer
        "
      >
        <MagnifyGlass size={30} color="fill-current" />
      </div>
    </div>
  );
}

SearchTextDropdownBtn.propTypes = {
  id: PropTypes.string,
  search: PropTypes.string,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  handlerOnClick: PropTypes.func,
  collegeResults: PropTypes.array,
  handlerOnChange: PropTypes.func,
  handlerOnKeyDown: PropTypes.func,
  handlerOnSelectCollege: PropTypes.func,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default SearchTextDropdownBtn;
