import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import PopularMajors from "./PopularMajors";

import { Panel, YourMajors } from "../../../../core/compose";
import { getTheme } from "../../../../core/utils";

import { addMajor, removeMajor } from "../../../../redux/Student/actions";

import Api from "../../../../services/Api";
import {
  MajorsPlusCP,
  MajorsRemoveCP,
  MajorsSearchCP,
} from "../../../../utils/DataLayers";

function CollegeMajorsBox({ chosenSchool }) {
  const dispatch = useDispatch();

  const { award, school, pairedId, selectedMajors } = chosenSchool;

  const [selected, setSelected] = useState(
    selectedMajors.map((major) => major.code)
  );

  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability,
  });

  const hasSelectedMajors = selectedMajors.length > 0;

  const fetchMajorsCollege = async (search) => {
    if (!search) return [];

    const majors = await Api.searchMajorsCollege({
      pairedId,
      name: search,
    });

    return majors
      .filter((item) => !selected.includes(item.code))
      .map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.code,
        };
      });
  };

  const selectMajor = (major) => {
    MajorsPlusCP({ ipeds: school.ipedsId });
    if (!selected.includes(major.code)) dispatch(addMajor({ major, pairedId }));
  };

  const deleteSelectedMajor = (major) => {
    MajorsRemoveCP({ ipeds: school.ipedsId });
    dispatch(removeMajor({ major, pairedId }));
  };

  const withSearchDefault = selectedMajors.length < 4;

  useEffect(() => {
    setSelected(selectedMajors.map((major) => major.code));
  }, [selectedMajors]);

  return (
    <Panel
      title="Select a Major"
      titleClassName="normal-case font-medium"
      className="
        p-4
        mt-2
        mx-4
        shadow-lg
        bg-white
        mobile:mt-4
        mobile-sm:mt-4
        tablet-sm:mt-4
      "
    >
      {!hasSelectedMajors ? (
        <PopularMajors
          color={theme.color}
          schoolName={school.name}
          popularMajors={school.popularMajors}
          selectMajor={(major) => selectMajor(major)}
          searchMajorsResults={(search) => fetchMajorsCollege(search)}
        />
      ) : (
        <YourMajors
          color={theme.color}
          schoolName={school.name}
          majorsSelected={selectedMajors}
          withSearchDefault={withSearchDefault}
          gaOnClick={() => MajorsSearchCP({ ipeds: school.ipedsId })}
          selectMajor={(major) => selectMajor(major)}
          deleteSelectedMajor={(major) => deleteSelectedMajor(major)}
          searchMajorsResults={(search) => fetchMajorsCollege(search)}
        />
      )}
    </Panel>
  );
}

CollegeMajorsBox.propTypes = {
  chosenSchool: PropTypes.shape(),
};

export default CollegeMajorsBox;
