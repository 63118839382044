import { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";

import { Button } from "../../../core/buttons";
import { PillList, MultiSelector, SearchFloatBox } from "../../../core/compose";

const getDataReligious = (data, selected) => {
  if (!data) return [];

  return data.filter((item) => !selected.includes(item.code));
};

function FilterSection({ label, children }) {
  return (
    <div className="flex flex-col">
      <label
        className="
          font-bold
          mobile:text-sm
          mobile-sm:text-sm
          tablet-sm:text-sm
        "
      >
        {label}
      </label>
      {children}
    </div>
  );
}

FilterSection.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
};

function FilterByType({ isModal, filters, onSubmit, religiousTypes }) {
  const [results, setResults] = useState([]);
  const [localFilters, setLocalFilters] = useState({});

  useEffect(() => setLocalFilters(filters), [filters]);

  const handlerFilters = (name, filter) => {
    setLocalFilters((filters) => {
      return { ...filters, [name]: { ...filters[name], ...filter } };
    });
  };

  const campusSizeOptions = useMemo(
    () => [
      {
        label: "small",
        id: "button_small",
        active: localFilters?.size?.SMALL,
        handler: () =>
          handlerFilters("size", { SMALL: !localFilters?.size?.SMALL }),
      },
      {
        label: "medium",
        id: "button_medium",
        active: localFilters?.size?.MEDIUM,
        handler: () =>
          handlerFilters("size", { MEDIUM: !localFilters?.size?.MEDIUM }),
      },
      {
        label: "large",
        id: "button_large",
        active: localFilters?.size?.LARGE,
        handler: () =>
          handlerFilters("size", { LARGE: !localFilters?.size?.LARGE }),
      },
    ],
    [localFilters]
  );

  const campusTypeOptions = useMemo(
    () => [
      {
        label: "public",
        id: "public-button",
        active: localFilters?.control?.PUBLIC,
        handler: () =>
          handlerFilters("control", { PUBLIC: !localFilters?.control?.PUBLIC }),
      },
      {
        label: "private",
        id: "private-button",
        active: localFilters?.control?.PRIVATE,
        handler: () =>
          handlerFilters("control", {
            PRIVATE: !localFilters?.control?.PRIVATE,
            FOR_PROFIT: !localFilters?.control?.FOR_PROFIT,
          }),
      },
    ],
    [localFilters]
  );

  const campusYearOptions = useMemo(
    () => [
      {
        label: "4 year",
        id: "4-year-button",
        active: localFilters?.year?.FOUR_YEARS,
        handler: () =>
          handlerFilters("year", {
            FOUR_YEARS: !localFilters?.year?.FOUR_YEARS,
          }),
      },
      {
        label: "2 year",
        id: "2-year-button",
        active: localFilters?.year?.TWO_YEARS,
        handler: () =>
          handlerFilters("year", { TWO_YEARS: !localFilters?.year?.TWO_YEARS }),
      },
    ],
    [localFilters]
  );

  const campusPopulationOptions = useMemo(
    () => [
      {
        id: "historically-black-checkbox",
        label: "Historically Black Colleges and Universities",
        active: localFilters?.missionType?.HBCU,
        handler: () =>
          handlerFilters("missionType", {
            HBCU: !localFilters?.missionType?.HBCU,
          }),
      },
      {
        id: "hispanic-serving-checkbox",
        label: "Hispanic Serving Institutions (HSI's)",
        active: localFilters?.missionType?.HSI,
        handler: () =>
          handlerFilters("missionType", {
            HSI: !localFilters?.missionType?.HSI,
          }),
      },
    ],
    [localFilters]
  );

  const handlerOnSearchDebounce = useMemo(
    () =>
      debounce((search) => {
        if (search) {
          const regex = new RegExp(`^.*${search}.*`, "gi");
          setResults(
            religiousTypes.filter((religion) => regex.test(religion?.name))
          );
        } else setResults([]);
      }, 500),
    [religiousTypes]
  );

  const handlerOnSearch = useCallback(handlerOnSearchDebounce, [
    handlerOnSearchDebounce,
  ]);

  const handlerOnSelect = (religious) => {
    setResults([]);
    setLocalFilters((localFilters) => ({
      ...localFilters,
      religiousAffiliation: [
        ...(localFilters?.religiousAffiliation || []),
        { ...religious },
      ],
    }));
  };

  const handlerOnRemove = (religious) => {
    setLocalFilters((localFilters) => ({
      ...localFilters,
      religiousAffiliation: localFilters?.religiousAffiliation?.filter(
        (relSelected) => relSelected.code !== religious.code
      ),
    }));
  };

  const handlerOnSubmit = () => {
    let filters = {};

    const size =
      localFilters.size &&
      Object.keys(localFilters.size).some((key) => localFilters.size[key])
        ? localFilters.size
        : {};
    const year =
      localFilters.year &&
      Object.keys(localFilters.year).some((key) => localFilters.year[key])
        ? localFilters.year
        : {};
    const control =
      localFilters.control &&
      Object.keys(localFilters.control).some((key) => localFilters.control[key])
        ? localFilters.control
        : {};
    const missionType =
      localFilters.missionType &&
      Object.keys(localFilters.missionType).some(
        (key) => localFilters.missionType[key]
      )
        ? localFilters.missionType
        : {};
    const religiousAffiliation = localFilters.religiousAffiliation || [];

    if (Object.keys(size).length > 0) filters.size = size;
    if (Object.keys(year).length > 0) filters.year = year;
    if (Object.keys(control).length > 0) filters.control = control;
    if (Object.keys(missionType).length > 0) filters.missionType = missionType;
    if (religiousAffiliation.length > 0)
      filters.religiousAffiliation = religiousAffiliation;

    onSubmit({ type: "type", filters });
  };

  return (
    <div
      className="
        pb-2
        flex
        flex-1
        flex-col
        space-y-5
        mobile:pb-0
        mobile-sm:pb-0
        tablet-sm:pb-0
        overflow-y-auto
        mobile:space-y-1
        mobile-sm:space-y-1
        tablet-sm:space-y-1
      "
    >
      <div className="flex flex-1 flex-col">
        <FilterSection label="What type of campus are you looking for?">
          <span
            className="
              text-sm
              font-light
              mobile:hidden
              mobile-sm:hidden
              tablet-sm:hidden
            "
          >
            Campus type can vary
          </span>
          <MultiSelector data={campusSizeOptions} selectorType="Button" />
        </FilterSection>
        <FilterSection label="What about Public vs Private?">
          <span
            className="
              text-sm
              font-light
              mobile:hidden
              mobile-sm:hidden
              tablet-sm:hidden
            "
          >
            We suggest clicking both as you can see all your options
          </span>
          <span
            className="
              hidden
              text-xs
              font-light
              mobile:block
              tablet-sm:block
            "
          >
            We suggest clicking both
          </span>
          <MultiSelector data={campusTypeOptions} selectorType="Button" />
        </FilterSection>
        <FilterSection label="What type of school are you interested in?">
          <span
            className="
              text-sm
              font-light
              mobile:hidden
              mobile-sm:hidden
              tablet-sm:hidden
            "
          >
            4 year schools offer Bachelor degrees while 2 year schools offer
            Associates degrees or certificates
          </span>
          <MultiSelector data={campusYearOptions} selectorType="Button" />
        </FilterSection>
        <FilterSection label="If you are interested in searching for schools with a specific mission, check that below:">
          <div className="flex flex-row">
            <div className="flex flex-col w-1/2">
              <span
                className="
                  text-sm
                  font-light
                  mobile:hidden
                  mobile-sm:hidden
                  tablet-sm:hidden
                "
              >
                Some colleges serve specific populations
              </span>
              <MultiSelector
                data={campusPopulationOptions}
                selectorType="CheckBox"
              />
              <SearchFloatBox
                searchResults={getDataReligious(
                  results,
                  localFilters?.religiousAffiliation?.map(
                    (religious) => religious.code
                  ) || []
                )}
                className="-mt-2 px-1 pb-3"
                searchEndpoint={handlerOnSearch}
                onSelectResult={handlerOnSelect}
                label="Religiously Affiliated Universities"
              />
            </div>
            <div className="flex flex-col w-1/2">
              {localFilters?.religiousAffiliation?.length > 0 && (
                <span
                  className="
                    text-sm
                    font-light
                    mobile:hidden
                    mobile-sm:hidden
                    tablet-sm:hidden
                  "
                >
                  Selected Affiliated Universities:
                </span>
              )}
              <div className="overflow-auto h-10rem">
                <PillList
                  data={
                    localFilters?.religiousAffiliation?.map((religious) => ({
                      label: religious.name,
                      code: religious.code,
                    })) || []
                  }
                  onRemovePill={handlerOnRemove}
                  pillWidthFull={true}
                />
              </div>
            </div>
          </div>
        </FilterSection>
      </div>
      <div
        className={`
          flex
          flex-row
          ${isModal ? "items-end justify-end" : "justify-center"}
        `}
      >
        <Button
          onClick={handlerOnSubmit}
          id="show-updated-results-button"
          className={`
            py-2
            text-white
            bg-primary-green
            border-primary-green
            hover:bg-white
            active:bg-white
            hover:text-primary-green 
            active:text-primary-green
            mobile:text-sm
            mobile-sm:text-sm
            ${
              isModal
                ? "w-1/3 mobile:w-1/2 mobile-sm:w-1/2"
                : "w-1/2 mobile:w-full mobile-sm:w-full"
            }
          `}
        >
          {isModal ? "continue" : "show updated results"}
        </Button>
      </div>
    </div>
  );
}

FilterByType.propTypes = {
  isModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  filters: PropTypes.shape(),
  religiousTypes: PropTypes.arrayOf(PropTypes.shape()),
};

export default FilterByType;
