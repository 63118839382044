import { useCallback, useEffect, useMemo, useState } from "react";
import { keyBy, merge, values, debounce } from "lodash";
import PropTypes from "prop-types";

import { Button } from "../../../core/buttons";
import { MapChart } from "../../../core/charts";
import { PillList } from "../../../core/compose";
import { SearchTextModal } from "../../../core/inputs";

import { Colors } from "../../../core/utils";
import { useIsDesktopMQL } from "../../../core/hooks";

import { useAllMapStates } from "../../Common/utils";

function getDataStates(data) {
  if (!data) return null;

  return data
    .filter((item) => item.label)
    .map((item) => ({ ...item, name: item.label }));
}

function checkItemExist(arr, prop, itemCheck) {
  let itemExist = false;
  if (arr.some((item) => item[prop] === itemCheck)) {
    itemExist = true;
  } else {
    itemExist = false;
  }
  return itemExist;
}

function FilterByLocation({ isModal, filters, onSubmit }) {
  const isDesktop = useIsDesktopMQL();
  const allStates = useAllMapStates();

  const [results, setResults] = useState(null);
  const [mapShow, setMapShow] = useState(true);
  const [localFilters, setLocalFilters] = useState([]);
  const [mapStates, setMapStates] = useState(allStates);
  const [searchLocation, setSearchLocation] = useState("");

  useEffect(() => {
    setLocalFilters(filters);
    setTimeout(() => setMapShow(false), 1000);
    setMapStates((mapStates) =>
      values(merge(keyBy(mapStates, "id"), keyBy(filters, "id")))
    );
  }, [filters]);

  const handlerOnClear = () => {
    setResults(null);
    setLocalFilters([]);
    setSearchLocation("");
    setMapStates((mapStates) =>
      mapStates.map((state) => ({ ...state, isActive: false }))
    );
  };

  const handlerOnRemove = (location) => {
    setLocalFilters((localFilters) => {
      setMapStates((mapStates) =>
        mapStates.map((state) =>
          state.id === location.id ? { ...state, isActive: false } : state
        )
      );
      return localFilters.filter((filter) => filter.id !== location.id);
    });
  };

  const handlerOnSelect = (location) => {
    setResults(null);
    setSearchLocation("");
    if (!checkItemExist(localFilters, "id", location.id)) {
      handlerOnSelectState(location);
    }
  };

  const handlerOnSelectState = (location) => {
    if (!checkItemExist(localFilters, "id", location.id)) {
      setLocalFilters((localFilters) => {
        setMapStates((mapStates) =>
          mapStates.map((state) =>
            state.id === location.id ? { ...state, isActive: true } : state
          )
        );

        return [...localFilters, { ...location, isActive: true }];
      });
    } else {
      handlerOnRemove(location);
    }
  };

  const handlerOnSearchDebounce = useMemo(
    () =>
      debounce((search) => {
        if (search) {
          setSearchLocation(search);
          const regex = new RegExp(`^.*${search}.*`, "i");
          setResults(mapStates.filter((state) => regex.test(state?.label)));
        } else setResults(null);
      }, 500),
    [mapStates]
  );

  const handlerOnSearch = useCallback(handlerOnSearchDebounce, [
    handlerOnSearchDebounce
  ]);

  const calculateHeightPills = () => {
    const refHeight = 80;
    const dynamicHeight =
      Math.ceil(localFilters.length / (isDesktop ? 4 : 2)) * 40;

    return refHeight > dynamicHeight ? dynamicHeight : refHeight;
  };

  return (
    <div
      className="
        pb-2
        flex
        flex-1
        flex-col
        space-y-5
        mobile:pb-0
        mobile-sm:pb-0
        tablet-sm:pb-0
        overflow-hidden
        mobile:space-y-1
        mobile-sm:space-y-1
        tablet-sm:space-y-1
      "
    >
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="flex flex-row justify-between">
          <p
            className="
              font-bold
              mobile:text-sm
              mobile-sm:text-sm
              tablet-sm:text-sm
            "
          >
            Search for states you would like to add
          </p>
          <p
            onClick={handlerOnClear}
            className="
              text-sm
              font-bold
              cursor-pointer
              text-primary-green
            "
          >
            Clear
          </p>
        </div>
        <div
          className="pt-1 overflow-auto"
          style={{
            height: `${calculateHeightPills()}px`,
            minHeight: `${calculateHeightPills()}px`
          }}
        >
          <PillList
            data={localFilters}
            color={Colors.GREEN}
            onRemovePill={handlerOnRemove}
          />
        </div>
        <div className="pt-2">
          <SearchTextModal
            isFloat={true}
            isLoading={false}
            value={searchLocation}
            searchEndpoint={handlerOnSearch}
            onSelectResult={handlerOnSelect}
            placeholder="Start typing the state here"
            searchResults={getDataStates(
              results,
              localFilters.map((filter) => filter.id)
            )}
          />
        </div>
        <div className="flex flex-1 flex-row relative">
          {mapShow && (
            <div
              style={{ top: "25%" }}
              className="z-0 left-0 right-0 absolute text-center"
            >
              <span className="font-bold">Loading Map...</span>
            </div>
          )}
          <MapChart
            color={Colors.GREEN}
            isDesktop={isDesktop}
            mapStates={mapStates}
            className="place-self-center"
            onSelectState={handlerOnSelectState}
          />
        </div>
      </div>
      <div
        className={`
          flex
          flex-row
          ${isModal ? "items-end justify-end" : "justify-center"}
        `}
      >
        <Button
          onClick={() => {
            const filters =
              localFilters.length > 0 ? { selected: localFilters } : {};
            onSubmit({ type: "location", filters });
          }}
          className={`
            py-2
            text-md
            text-white
            bg-primary-green
            border-primary-green
            hover:bg-white
            active:bg-white
            hover:text-primary-green 
            active:text-primary-green
            mobile:text-sm
            mobile-sm:text-sm
            ${
              isModal
                ? "w-1/3 mobile:w-1/2 mobile-sm:w-1/2"
                : "w-1/2 mobile:w-full mobile-sm:w-full"
            }
          `}
        >
          {isModal ? "continue" : "show updated results"}
        </Button>
      </div>
    </div>
  );
}

FilterByLocation.propTypes = {
  isModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.shape())
};

export default FilterByLocation;
