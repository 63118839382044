import PropTypes from "prop-types";

import { OkayHand } from "../../core/icons";

function DefaultScreen({ title, children }) {
  return (
    <div
      className="
        p-4
        flex
        flex-1
        h-full
        w-full
        flex-col
        min-h-screen
        items-center
        overflow-auto
        justify-center
        bg-secondary-green
      "
    >
      <OkayHand size={250} />
      <div
        className="
          mb-5
          text-6xl
          capitalize
          text-center
          max-w-25rem
          leading-none
          font-extrabold
          text-primary-green
        "
      >
        {title}
      </div>
      {children}
    </div>
  );
}

DefaultScreen.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};

export default DefaultScreen;
