import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Upload from "./Upload";
import LivingChoice from "./LivingChoice";
import Layout from "../../../Common/Layout";
import LayoutCollege from "../../LayoutCollege";

import { AwardStatus } from "../../../../core/utils";
import { waitForTimeout } from "../../../../core/hooks";

import Api from "../../../../services/Api";
import { useSpinner } from "../../../Common/Hooks";
import {
  joinChosenSchool,
  fetchStudent,
} from "../../../../redux/Student/actions";
import { PageViewDataLayer } from "../../../../utils/DataLayers";

function UploadImages({ student, openModal, modalResponse, selectedSchool }) {
  const [currentView, setCurrentView] = useState("upload");
  const [archiveToUpload, setArchiveToUpload] = useState(null);

  const navigate = useNavigate();
  const { ipedsId } = useParams();
  const dispatch = useDispatch();

  const handleOnDoneUpload = (archive) => {
    setArchiveToUpload(archive);
    setCurrentView("living-choice");
  };

  useEffect(() => {
    if (student) {
      PageViewDataLayer({
        ipeds: ipedsId,
        userId: student.userId,
        screenName: "Upload Images Screen",
        organizationId: student.organizations.map((org) => org.organizationId),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSelectLivingChoice = useSpinner(async (livingChoice) => {
    const pairedId = selectedSchool?.pairedId;
    const sortOrder = selectedSchool?.sortOrder;
    try {
      const chosenSchool = await Api.uploadAward({
        pairedId,
        sortOrder,
        livingChoice,
        file: archiveToUpload,
      });

      await dispatch(joinChosenSchool(chosenSchool));

      const status = await Api.waitForAward({
        pairedId,
        retryInterval: 10 * 1000,
        maxRetries: 4,
      });

      await waitForTimeout(10 * 1000);
      await dispatch(fetchStudent({}));

      if (status === AwardStatus.READY) {
        await navigate(`/college/add-your-money/${ipedsId}/success`);
      } else {
        await navigate(`/college/add-your-money/${ipedsId}/hmm`);
      }
    } catch (err) {
      await navigate(`/college/add-your-money/${ipedsId}/hmm`);
    }
  });

  return (
    <Layout
      student={student}
      openModal={openModal}
      modalResponse={modalResponse}
    >
      <LayoutCollege>
        <div
          className="
            min-h-screen-footer-md
            mobile:min-h-screen-footer
            mobile-sm:min-h-screen-footer
            tablet-sm:min-h-screen-footer
          "
        >
          {currentView === "upload" && (
            <Upload
              onDone={handleOnDoneUpload}
              onBack={() => navigate(-1)}
              pairedId={selectedSchool?.pairedId}
              universityName={selectedSchool?.school.name || ""}
            />
          )}

          {currentView === "living-choice" && (
            <LivingChoice
              pairedId={selectedSchool?.pairedId}
              onSelect={handleOnSelectLivingChoice}
              onBack={() => setCurrentView("upload")}
              universityName={selectedSchool?.school.name || ""}
              hasOnCampusHousing={selectedSchool?.school.hasOnCampusHousing}
            />
          )}
        </div>
      </LayoutCollege>
    </Layout>
  );
}

UploadImages.propTypes = {
  openModal: PropTypes.func,
  student: PropTypes.shape(),
  modalResponse: PropTypes.string,
  selectedSchool: PropTypes.shape(),
};

export default UploadImages;
