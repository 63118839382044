import PropTypes from "prop-types";

import Item from "./Item";
import Category from "./Category";

function BoxAmounts({
  grants,
  financialAids,
  removeFinancialAids,
  handlerOnEditFinancialAid,
  handlerOnRemoveFinancialAid,
}) {
  const excludedGrants = removeFinancialAids.concat(
    financialAids.map((aid) => aid.id)
  );

  return (
    <>
      <div className="bg-white border border-black">
        <Category name="Grants & Scholarships" border={grants.length > 0} />
        <div className="px-2 divide-y divide-black">
          {grants
            .filter((aid) => !excludedGrants.includes(aid.id))
            .map((aid) => (
              <div key={`${aid.id}-${aid.name}`}>
                <Item name={aid.name} value={aid.value} isNew={aid.isNew} />
              </div>
            ))}
        </div>
      </div>
      {financialAids.length > 0 && (
        <div className="bg-white border-x border-b border-primary-green">
          <div className="px-2 divide-y divide-primary-green">
            {financialAids.map((aid) => (
              <div key={`${aid.id}-${aid.name}`}>
                <Item
                  name={aid.name}
                  edit={aid.edit}
                  added={aid.added}
                  value={aid.amount}
                  handlerOnRemove={() => handlerOnRemoveFinancialAid(aid.id)}
                  handlerOnEdit={(value) =>
                    handlerOnEditFinancialAid({ value, editId: aid.id })
                  }
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

BoxAmounts.propTypes = {
  grants: PropTypes.array,
  handlerOnEditFinancialAid: PropTypes.func,
  handlerOnRemoveFinancialAid: PropTypes.func,
  financialAids: PropTypes.arrayOf(PropTypes.shape()),
  removeFinancialAids: PropTypes.arrayOf(PropTypes.string),
};

export default BoxAmounts;
