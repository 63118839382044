import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useStudent } from "../../redux/Student/hooks";

function Home() {
  const navigate = useNavigate();

  const { isLoading } = useStudent();

  useEffect(() => {
    if (!isLoading) navigate("/college/my-path");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return null;
}

export default Home;
