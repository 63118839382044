import PropTypes from "prop-types";

import { Button } from "../../../../core/buttons";

function Buttons({ onAdd, showReuploadButton, onError, onReupload }) {
  return (
    <>
      {showReuploadButton ? (
        <div className="flex flex-row w-full justify-end items-end">
          <Button
            onClick={onReupload}
            id="award-report-error-button"
            className="
            w-1/2
            bg-white
            normal-case
            text-primary-green
            border-primary-green
            hover:text-white
            active:text-white
            hover:bg-primary-green
            active:bg-primary-green
            mobile:w-full
            mobile-sm:w-full
            tablet-sm:w-full
          "
          >
            Reupload Award Letter
          </Button>
        </div>
      ) : (
        <>
          <Button
            onClick={onAdd}
            id="award-looks-correct-button"
            className="
              w-1/2 
              text-white
              bg-primary-green
              border-primary-green
              hover:bg-white
              active:bg-white
              hover:text-primary-green
              active:text-primary-green
              mobile:w-full
              mobile-sm:w-full
              tablet-sm:w-full
            "
          >
            add additional financial aid
          </Button>
          <Button
            onClick={onError}
            id="award-report-error-button"
            className="
              w-1/2 
              bg-white
              normal-case
              text-primary-green
              border-primary-green
              hover:text-white
              active:text-white
              hover:bg-primary-green
              active:bg-primary-green
              mobile:w-full
              mobile-sm:w-full
              tablet-sm:w-full
            "
          >
            Report an Error
          </Button>
        </>
      )}
    </>
  );
}

Buttons.propTypes = {
  onAdd: PropTypes.func,
  showReportErrorButton: PropTypes.bool,
  onError: PropTypes.func,
  onReupload: PropTypes.func
};

export default Buttons;
