import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Form, FloatBox } from "../../../core";
import { Button } from "../../../core/buttons";
import { Colors, retryTimeout, GradeYearTypes } from "../../../core/utils";

import { CloseMini } from "../../../core/icons";

import { useSpinner } from "../../Common/Hooks";

import Api from "../../../services/Api";
import { Conversion } from "../../../utils/DataLayers";

function CreateAccountForm({ redirectUri, defaultValues }) {
  const formID = "create-account-form";

  const navigate = useNavigate();

  const divRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);

  const handleOutsideClick = (state) => setShowDropdown(state);

  const { setError, handleSubmit, control, formState } = useForm({
    mode: "onChange",
    defaultValues
  });

  const disabledSubmitButton =
    !formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

  const onSubmitWithSpinner = retryTimeout(
    useSpinner(async (data) => {
      setSomethingWentWrong(false);
      try {
        const response = await Api.signUp({
          firstName: data.firstName,
          lastName: data.lastName,
          gradeYear: data.gradeYear.value,
          gpa: data.gpa,
          zip: data.zip,
          emailReceive: data.emailReceive
        });

        if (response.ok) {
          const { id } = await response.json();
          Conversion({ user_id: `${id}` });
          return navigate(redirectUri);
        } else {
          const { first_name, last_name, grade_year, gpa, zip, ...errors } =
            await response.json();

          if (first_name) {
            setError("firstName", {
              message: "Please enter a valid first name"
            });
          }

          if (last_name) {
            setError("lastName", {
              message: "Please enter a valid last name"
            });
          }

          if (grade_year) {
            setError("gradeYear", {
              message: "Please enter a valid option"
            });
          }

          if (gpa) {
            setError("gpa", {
              message: "Please enter a valid GPA between 0 and 4"
            });
          }

          if (zip) {
            setError("zip", {
              message: "Please enter a valid US zip code"
            });
          }

          if (Object.keys(errors).length) {
            setSomethingWentWrong(true);
          }
        }
      } catch (e) {
        console.error(e);
        setSomethingWentWrong(true);
      }
    })
  );

  const fetchGradeYearTypes = (search, callback) => {
    const options = GradeYearTypes;

    callback(() =>
      options.filter((option) =>
        option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  return (
    <Form id={formID} onSubmit={handleSubmit(onSubmitWithSpinner)}>
      <Form.Input
        required={true}
        name="firstName"
        control={control}
        label="first name"
        color={Colors.GREEN}
        errors={formState.errors}
        rules={{
          maxLength: {
            value: 255,
            message: "First Name is too long."
          },
          required: {
            value: true,
            message: "First Name is required."
          }
        }}
      />
      <Form.ErrorMessage errors={formState.errors} name="firstName" />

      <Form.Input
        required={true}
        name="lastName"
        label="last name"
        control={control}
        color={Colors.GREEN}
        errors={formState.errors}
        rules={{
          maxLength: {
            value: 255,
            message: "Last Name is too long."
          },
          required: {
            value: true,
            message: "Last Name is required."
          }
        }}
      />
      <Form.ErrorMessage errors={formState.errors} name="lastName" />

      <Form.Select
        id="grade-year-select"
        name="gradeYear"
        label="What type of student are you?"
        required={true}
        control={control}
        color={Colors.GREEN}
        loadOptions={fetchGradeYearTypes}
        rules={{
          required: {
            value: true,
            message: "This field is required."
          }
        }}
      />
      <Form.ErrorMessage errors={formState.errors} name="gradeYear" />

      <Form.Input
        required={true}
        name="gpa"
        type="number"
        label="GPA"
        control={control}
        color={Colors.GREEN}
        errors={formState.errors}
        rules={{
          required: {
            value: true,
            message: "GPA is required."
          },
          min: {
            value: 0,
            message: "GPA must be greater than or equal to 0."
          },
          max: {
            value: 4,
            message: "GPA must be less than or equal to 4."
          }
        }}
      />
      <Form.ErrorMessage errors={formState.errors} name="gpa" />

      <div className="mt-6 relative">
        <Form.Input
          name="zip"
          maxLength="5"
          label="zip code"
          control={control}
          color={Colors.GREEN}
          errors={formState.errors}
          rules={{
            pattern: {
              value: /^[0-9]{5}$/,
              message: "Zip Code must be a 5-digit number."
            }
          }}
        />
        <div
          className="
            pt-2
            top-0
            right-0
            text-sm
            absolute
            font-medium
            text-primary-green
          "
        >
          <div ref={divRef} className="relative">
            <p
              role="button"
              className="hover:underline text-primary-green"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              Why do we ask this?
            </p>
            <FloatBox
              open={showDropdown}
              color={Colors.GREEN}
              containerRef={divRef}
              handleOnClickOutside={handleOutsideClick}
              className="mt-1 p-4 flex right-0 flex-col font-bold bg-white"
            >
              <div
                role="button"
                className="place-self-end"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <CloseMini size={20} />
              </div>
              Entering your zip code will allow you to see travel distance and
              see if you qualify for in state vs out of state tuition.
            </FloatBox>
          </div>
        </div>
      </div>
      <Form.ErrorMessage errors={formState.errors} name="zip" />

      <Form.Checkbox
        control={control}
        name="emailReceive"
        color={Colors.GREEN}
        label="I agree to receive product updates and email from DecidED."
        className="ml-2 text-sm font-medium text-primary-green"
      />

      <Button
        type="submit"
        form={formID}
        value="submit"
        id="create-account-button"
        disabled={!formState.isValid}
        className={`
          mt-6
          text-white
          bg-primary-green
          border-primary-green
          hover:bg-white
          active:bg-white
          hover:text-primary-green
          active:text-primary-green
          ${disabledSubmitButton}
        `}
      >
        Continue
      </Button>

      <div className="mt-1">
        {somethingWentWrong && (
          <p className="text-sm font-semibold text-primary-red">
            Something went wrong, please try again later!
          </p>
        )}
      </div>
    </Form>
  );
}

CreateAccountForm.propTypes = {
  redirectUri: PropTypes.string,
  defaultValues: PropTypes.shape()
};

export default CreateAccountForm;
