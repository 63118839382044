import { AwardStatus } from "../core/utils";
import { forceUserToSignIn } from "../utils/auth";
import { apiVersionErrorToast } from "../utils/unexpectedErrorToast";

const ResponseStatusCodes = {
  ok: 200,
  forbidden: 403,
  notFound: 404,
  serverRefuse: 418
};

const ErrorTypes = {
  authError: "AuthError",
  apiVersionError: "ApiVersionError",
  graphQLError: "GraphQLError",
  apiRestError: "apiRestError"
};

const awardFields = `
  id
  status
  downloadUrl
  filePurged
  aidAmounts {
    id
    value
    isNew
    category
    description
    subCategory
    categorySource
  }
  postAwardSchoolCosts {
    totalGrant
    totalOther
    totalScholarship
    totalAward
    coverage
    affordability
    lifeBudget
    schoolCosts
  }
  pendingError {
    errorType
  }
`;

const chosenSchoolFields = `
  isNew
  pairedId
  sortOrder
  isFavorite
  livingChoice
  enrollmentStatus
  estimatedAids {
    id
    value
    isNew
    description
    category
    categorySource
  }
  estimatedPostAwardCosts {
    totalGrant
    totalOther
    totalScholarship
    totalAward
    coverage
    affordability
    lifeBudget
    schoolCosts
  }
  school {
    name
    size
    city
    logo
    state
    control
    ipedsId
    areaType
    earnings
    latitude
    longitude
    commonAppUrl
    programLength
    stateYearlySalary
    percentLowIncome
    hasOnCampusHousing
    admissionsOfficeWebsiteUrl
    onlineApplicationWebsiteUrl
    financialAidOfficeWebsiteUrl
    popularMajors{
      name
      code
    }
  }
  selectedMajors{
    code
    name
  }
  schoolCosts {
    tuitionAndFeesYear
    tuitionAndFees
    housingAndMealsYear
    housingAndMeals
    booksAndSuppliesYear
    booksAndSupplies
    transportationYear
    transportation
    personalExpensesYear
    personalExpenses
    graduationRate
    studentDiversityPercentage
    diversityPercentage
    ethnicityGroup
    totalCollegeCosts
    lifeBudget
    totalCost
  }
  award {
    ${awardFields}
  }
`;

const organizationField = `
  name
  organizationId
`;

const studentFields = `
  lat
  lon
  gpa
  zip
  email
  state
  isPell
  userId
  actScore
  satScore
  gpaGuess
  username
  lastName
  firstName
  gradeYear
  studentId
  ethnicity
  phoneNumber
  satActGuess
  emailReceive
  onboardingSignUp
  onboardingLetter
  onboardingSearch
  emailNotifications
  onboardingChosenSchool
  emailChangeRequestSent
  onboardingFirstSchoolTooltip
  onboardingAutomaticAidEstimation
  banner {
    desktopContent
    mobileContent
  }
  organizations {
    ${organizationField}
  }
  chosenSchools(
    filter: $filter,
    orderBy: $orderBy
  ) {
    ${chosenSchoolFields}
  }
`;

const notificationsFields = `
  newItemsCount
  itemsCount
  pageCount
  items {
    id
    type
    status
    content
    insertTime
  }
`;

class DecidedApi {
  constructor({ token, baseUrl = null, clientId = null } = {}) {
    this.configure({ token, baseUrl, clientId });
  }

  configure({ token, baseUrl, clientId }) {
    this.token = token;
    this.baseUrl = baseUrl || window.SERVER_DATA.REACT_APP_BACKEND_URL;
    this.clientId = clientId || window.SERVER_DATA.REACT_APP_AUTH0_CLIENT_ID;
    this.graphqlUrl = `${this.baseUrl}/students/graphql/`;
    this.signinUrl = `${this.baseUrl}/students/api/signin`;
    this.signupUrl = `${this.baseUrl}/students/api/signup`;
    this.newVerifyLinkUrl = `${this.baseUrl}/students/api/send-email-verification-link`;
  }

  async fetchInvitation({ invitationId }) {
    const url = `${this.baseUrl}/students/api/invitations/${invitationId}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Revision": window.SERVER_DATA.REACT_APP_REVISION
      }
    });

    if (response.status === ResponseStatusCodes.serverRefuse) {
      apiVersionErrorToast();
      throw Object.assign(new Error(), { type: ErrorTypes.apiVersionError });
    } else if (response.status === ResponseStatusCodes.notFound) {
      throw Object.assign(new Error(), {
        type: ErrorTypes.apiRestError,
        errors: { message: "Invitation not found!" }
      });
    }

    const responseBody = await response.json();
    return responseBody;
  }

  async fetch(url, body, options) {
    const getToken = async () => {
      try {
        return await this.token(options?.refetch);
      } catch (e) {
        forceUserToSignIn();
        throw e;
      }
    };

    const token = await getToken();

    const response = await fetch(url, {
      ...options,
      headers: {
        ...options?.headers,
        Authorization: `JWT ${token}`,
        "Api-Revision": window.SERVER_DATA.REACT_APP_REVISION
      },
      body: body && JSON.stringify(body)
    });

    if (response.status === ResponseStatusCodes.serverRefuse) {
      apiVersionErrorToast();
      throw Object.assign(new Error(), { type: ErrorTypes.apiVersionError });
    }

    return response;
  }

  async gqlQuery(body, refetch) {
    const response = await this.fetch(this.graphqlUrl, body, {
      refetch,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (response.status === ResponseStatusCodes.forbidden) {
      forceUserToSignIn();
      throw Object.assign(new Error(), { type: ErrorTypes.authError });
    }

    const responseBody = await response.json();
    if (responseBody?.errors) {
      throw Object.assign(new Error(), {
        type: ErrorTypes.graphQLError,
        errors: responseBody.errors
      });
    }
    return responseBody;
  }

  async checkUserExists() {
    const response = await this.fetch(this.signinUrl, undefined, {
      method: "POST"
    });
    return response.ok;
  }

  async newVerifyLink(resultUrl) {
    const response = await this.fetch(
      this.newVerifyLinkUrl,
      { result_url: resultUrl },
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return response.ok;
  }

  async signUp({
    firstName,
    lastName,
    gradeYear,
    phoneNumber,
    gpa,
    gpaGuess,
    sat,
    act,
    satactGuess,
    zip,
    ethnicity,
    program,
    emailReceive
  }) {
    const body = {
      first_name: firstName,
      last_name: lastName,
      grade_year: gradeYear,
      phone_number: phoneNumber,
      gpa: gpa,
      gpa_guess: gpaGuess,
      sat_score: sat,
      act_score: act,
      sat_act_guess: satactGuess,
      zip: zip,
      ethnicity: ethnicity,
      program: program,
      email_receive: emailReceive
    };
    const response = await this.fetch(this.signupUrl, body, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response;
  }

  async fetchGuides() {
    const { data } = await this.gqlQuery({
      query: `
        query StudentFetchGuides {
          guides {
            url
            image
            title
            subtitle
            category
            sortOrder
            enrollmentStatuses
            awardLetterStatuses
          }
          __schema {
            types {
              name
              enumValues {
                name
                description
              }
            }
          }
        }
      `,
      variables: {}
    });

    return data;
  }

  async fetchStudent({ filter, orderBy, refetch }) {
    const { data } = await this.gqlQuery(
      {
        query: `
        query StudentFetchStudent(
          $filter: ChosenSchoolFilter,
          $orderBy: ChosenSchoolOrderBy
        ) {
          student {
            ${studentFields}
          }
        }
      `,
        variables: {
          filter,
          orderBy
        },
        fetchPolicy: "cache-and-network"
      },
      refetch
    );

    return data.student;
  }

  async fetchStudentChosenSchools({ filter, orderBy }) {
    const { data } = await this.gqlQuery({
      query: `
        query StudentFetchStudentChosenSchools(
          $filter: ChosenSchoolFilter,
          $orderBy: ChosenSchoolOrderBy
        ) {
          student {
            chosenSchools(
              filter: $filter,
              orderBy: $orderBy
            ) {
              isNew
              sortOrder
              isFavorite
              enrollmentStatus
              schoolCosts {
                graduationRate
              }
              school {
                name
                size
                city
                logo
                state
                control
                ipedsId
                areaType
                programLength
                religiousAffiliation
              }
              award {
                postAwardSchoolCosts {
                  affordability
                }
              }
            }
          }
        }
      `,
      variables: {
        filter,
        orderBy
      },
      fetchPolicy: "cache-and-network"
    });

    return data.student.chosenSchools;
  }

  async chooseSchool({ schoolId }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation StudentChooseSchool($input: ChosenSchoolInput!) {
          chooseSchool(input: $input) {
            ${chosenSchoolFields}
          }
        }
      `,
      variables: {
        input: {
          universityId: schoolId
        }
      }
    });

    return data.chooseSchool;
  }

  async deleteChosenSchool({ pairedId }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation StudentDeleteChosenSchool($input: ID!) {
          deleteChosenSchool(pairedId: $input) {
            pairedId
          }
        }
      `,
      variables: {
        input: pairedId
      }
    });

    return data.deleteChosenSchool;
  }

  async updateStudentFlags({
    onboardingFirstSchoolTooltip,
    onboardingAutomaticAidEstimation
  }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation StudentUpdateStudent(
          $input: UpdateStudentInput!
        ) {
          updateStudent(input: $input) {
            onboardingFirstSchoolTooltip
          }
        }
      `,
      variables: {
        input: {
          onboardingFirstSchoolTooltip,
          onboardingAutomaticAidEstimation
        }
      }
    });

    return data.updateStudent;
  }

  async updateStudent({
    zip,
    gpa,
    email,
    filter,
    orderBy,
    actScore,
    satScore,
    gpaGuess,
    lastName,
    firstName,
    gradeYear,
    ethnicity,
    phoneNumber,
    satActGuess,
    emailReceive,
    onboardingSignUp,
    onboardingLetter,
    onboardingSearch,
    emailNotifications,
    onboardingChosenSchool,
    onboardingFirstSchoolTooltip
  }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation StudentUpdateStudent(
          $input: UpdateStudentInput!,
          $filter: ChosenSchoolFilter,
          $orderBy: ChosenSchoolOrderBy
        ) {
          updateStudent(input: $input) {
            ${studentFields}
          }
        }
      `,
      variables: {
        filter,
        orderBy,
        input: {
          gpa,
          zip,
          email,
          actScore,
          satScore,
          gpaGuess,
          lastName,
          firstName,
          gradeYear,
          ethnicity,
          phoneNumber,
          satActGuess,
          emailReceive,
          onboardingSignUp,
          onboardingLetter,
          onboardingSearch,
          emailNotifications,
          onboardingChosenSchool,
          onboardingFirstSchoolTooltip
        }
      }
    });

    data.updateStudent.chosenSchools.sort((a, b) =>
      a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0
    );

    return data.updateStudent;
  }

  async editStudentOrganizations({
    filter,
    orderBy,
    organizationIdsAdd,
    organizationIdsRemove
  }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation StudentEditOrganizations (
          $organizationIdsAdd: [ID!]!,
          $filter: ChosenSchoolFilter,
          $orderBy: ChosenSchoolOrderBy,
          $organizationIdsRemove: [ID!]!,
        ) {
          linkOrganization(organizationIds: $organizationIdsAdd,) {
            addedOrgs {
              name
              organizationId
            }
            alreadyInOrgs {
              name
              organizationId
            }
          }
          studentLeaveOrganization(organizationIds: $organizationIdsRemove) {
            ${studentFields}
          }
        }
      `,
      variables: {
        filter,
        orderBy,
        organizationIdsAdd,
        organizationIdsRemove
      }
    });

    const { linkOrganization, studentLeaveOrganization } = data;
    const returnData = {
      editStudentOrgs: linkOrganization,
      student: studentLeaveOrganization
    };

    return returnData;
  }

  async leaveStudentOrganization({ filter, orderBy, organizationIds }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation StudentLeaveOrganization (
          $organizationIds: [ID!]!,
          $filter: ChosenSchoolFilter,
          $orderBy: ChosenSchoolOrderBy,
        ) {
          studentLeaveOrganization(organizationIds: $organizationIds) {
            ${studentFields}
          }
        }
      `,
      variables: {
        filter,
        orderBy,
        organizationIds
      }
    });

    return data.studentLeaveOrganization;
  }

  async fetchStudentOrganization({ code }) {
    const { data } = await this.gqlQuery({
      query: `
        query FetchOrganization ($code: ID) {
          organization(code: $code) {
            organizationId
            name
          }
        }
      `,
      variables: {
        code
      }
    });

    return data.organization;
  }

  async searchSchool({
    input,
    orderBy,
    page = { pageNumber: 1, pageSize: 30 }
  }) {
    const { data } = await this.gqlQuery({
      query: `
        query StudentSearchSchools(
           $input: SearchSchoolInput!,
           $orderBy: SchoolOrderBy,
           $page: Page
        ) {
          searchSchool(
            input: $input,
            orderBy: $orderBy,
            page: $page
          ) {
            itemsCount
            page
            pageSize
            pageCount
            items {
              ipedsId
              name
              city
              size
              logo
              state
              control
              commonAppUrl
              programLength
              graduationRate
              religiousAffiliation
            }
          }
        }
      `,
      variables: {
        page,
        input,
        orderBy
      }
    });

    return data.searchSchool;
  }

  uploadFile(uploadUrl, file) {
    // TODO: Add load, progress, error, etc. event listeners.
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("put", uploadUrl);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject(xhr.statusText);
        }
      };
      xhr.onerror = () => reject(xhr.statusText);
      xhr.send(file);
    });
  }

  async uploadAward({ file, pairedId, sortOrder, livingChoice }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation StudentUploadAward(
          $pairedId: ID!,
          $sortOrder: Int!,
          $input: UploadAwardInput!,
          $livingChoice: LivingChoice!
        ) {
          uploadAward(input: $input) {
            url
          }
          updateChosenSchool(
            pairedId: $pairedId,
            sortOrder: $sortOrder,
            livingChoice: $livingChoice
          ) {
            ${chosenSchoolFields}
          }
        }
      `,
      variables: {
        pairedId,
        sortOrder,
        livingChoice,
        input: {
          pairedId,
          filename: file.name
        }
      }
    });

    await this.uploadFile(data.uploadAward.url, file);

    return data.updateChosenSchool;
  }

  waitForAward({ pairedId, retryInterval, maxRetries }) {
    const fetchAwardStatus = async ({ pairedId }) => {
      const { data } = await this.gqlQuery({
        query: `
          query StudentWaitForAward {
            student {
              chosenSchools {
                pairedId
                award {
                  status
                }
              }
            }
          }
        `
      });

      const chosenSchool = (data.student?.chosenSchools || []).filter(
        (x) => x.pairedId === pairedId
      )[0];

      return chosenSchool?.award?.status;
    };

    return new Promise((resolve, reject) => {
      let count = 0;
      const id = setInterval(() => {
        console.log(`Checking AwardLetter status... (${count})`);
        if (count <= maxRetries) {
          count += 1;

          fetchAwardStatus({ pairedId })
            .then((status) => {
              switch (status) {
                case AwardStatus.READY:
                case AwardStatus.MANUAL_REVIEW:
                  clearInterval(id);
                  resolve(status);
                  break;
                default:
                  break;
              }
            })
            .catch((error) => {
              clearInterval(id);
              reject(error);
            });
        } else {
          clearInterval(id);
          reject(
            new Error({
              type: "Timeout"
            })
          );
        }
      }, retryInterval);
    });
  }

  async updateChosenSchool({
    isNew,
    pairedId,
    sortOrder,
    isFavorite,
    livingChoice,
    enrollmentStatus
  }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation StudentUpdateChosenSchool(
          $pairedId: ID!,
          $isNew: Boolean,
          $sortOrder: Int,
          $isFavorite: Boolean,
          $livingChoice: LivingChoice!
          $enrollmentStatus: _EnrollmentStatus
        ) {
          updateChosenSchool(
            isNew: $isNew,
            pairedId: $pairedId,
            sortOrder: $sortOrder,
            isFavorite: $isFavorite,
            livingChoice: $livingChoice
            enrollmentStatus: $enrollmentStatus
          ) {
            ${chosenSchoolFields}
          }
        }
      `,
      variables: {
        isNew,
        pairedId,
        sortOrder,
        isFavorite,
        livingChoice,
        enrollmentStatus
      }
    });

    return data.updateChosenSchool;
  }

  async readAwardUpdate({ chosenSchoolId }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation StudentReadAwardUpdate ($chosenSchoolId: ID!) {
          readAwardUpdate (chosenSchoolId: $chosenSchoolId) {
            ok
          }
        }
      `,
      variables: {
        chosenSchoolId
      }
    });

    return data.readAwardUpdate;
  }

  async createAwardLetterErrorReport({ awardId, errorType, description }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation CreateAwardLetterErrorReport (
          $input: CreateAwardLetterErrorReportInput!
        ) {
          createAwardLetterErrorReport(input: $input) {
            status
            description
          }
        }
      `,
      variables: {
        input: {
          awardId,
          errorType,
          description
        }
      }
    });

    return data.createAwardLetterErrorReport;
  }

  async acceptInvitation({ invitationId }) {
    const mutation = `
      mutation AcceptInvitation($invitationId: ID!){
        acceptInvitation(invitationId: $invitationId) {
          status
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        invitationId
      }
    });

    return data?.acceptInvitation;
  }

  async fetchNotifications({ page, refetch }) {
    const query = `
      query FetchNotifications(
        $page: Page
      ) {
        notifications(page: $page) {
          ${notificationsFields}
        }
      }
    `;

    const { data } = await this.gqlQuery(
      {
        query: query,
        variables: {
          page
        },
        fetchPolicy: "network-only"
      },
      refetch
    );

    return data.notifications;
  }

  async readNotifications({ notificationIds }) {
    const mutation = `
      mutation ReadNotifications(
        $notificationIds: [ID!]
      ){
        readNotification(
          notificationIds: $notificationIds
        ){
          readCount
        }
      }`;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        notificationIds
      },
      fetchPolicy: "network-only"
    });
    return data?.readNotification;
  }

  async emailChangeRequest({ updateId }) {
    const query = `
      query EmailChangeRequest(
        $authorizationCode: ID!
      ) {
        emailChangeRequest(
          authorizationCode: $authorizationCode
        ) {
          oldEmail
          newEmail
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        authorizationCode: updateId
      }
    });

    return data?.emailChangeRequest;
  }

  async confirmEmailChange({ updateId }) {
    const mutation = `
      mutation ConfirmEmailChange(
        $authorizationCode: ID!
      ) {
        confirmEmailChange(
          authorizationCode: $authorizationCode
        ) {
          newEmail
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        authorizationCode: updateId
      }
    });

    return data?.confirmEmailChange;
  }

  async deleteStudent() {
    const mutation = `
      mutation DeleteStudent {
        deleteStudent {
          ok,
          url
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation
    });

    return data?.deleteStudent;
  }

  async selectMajor({ major, pairedId }) {
    const mutation = `
      mutation SelectMajor(
        $majorCode: ID!,
        $pairedId: ID!
      ) {
        selectMajor(
          majorCode: $majorCode,
          pairedId: $pairedId
        ){
          code
          name
        }
      }
    `;
    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        majorCode: major.code,
        pairedId
      }
    });
    return data?.selectMajor;
  }

  async deleteSelectedMajor({ major, pairedId }) {
    const mutation = `
      mutation DeleteSelectedMajor(
        $majorCode: ID!,
        $pairedId: ID!
      ) {
        deleteSelectedMajor(
          majorCode: $majorCode,
          pairedId: $pairedId
        ){
          ok
        }
      }
    `;
    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        majorCode: major.code,
        pairedId
      }
    });
    return data?.deleteSelectedMajor;
  }

  async searchMajorsCollege({ name, pairedId }) {
    const { data } = await this.gqlQuery({
      query: `
        query StudentFetchMajorsCollege(
          $pairedId: ID,
          $name: String
        ) {
          student {
            chosenSchools (filter: {pairedId: $pairedId}) {
              pairedId
              school {
                ipedsId
                majors (name: $name) {
                  name
                  code
                }
              }
            }
          }
        }
      `,
      variables: {
        name,
        pairedId
      }
    });

    return data.student.chosenSchools[0].school.majors;
  }

  async searchMajors({ name }) {
    const { data } = await this.gqlQuery({
      query: `
        query SearchMajors(
          $input: SearchMajorInput,
        ) {
          searchMajor(input: $input) {
            items {
              code
              name
            }
          }
        }
      `,
      variables: {
        input: {
          name
        }
      }
    });

    return data?.searchMajor?.items;
  }

  async editAwardLetterResults({ letterId, addAidAmounts, removeAidAmounts }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation StudentEditAwardLetterResult (
          $letterId: ID!,
          $removeAidAmounts: [ID!],
          $addAidAmounts: [RawAidAmountType!],
        ) {
          editAwardLetterResult(
            letterId: $letterId,
            addAidAmounts: $addAidAmounts,
            removeAidAmounts: $removeAidAmounts,
          ) {
            ${awardFields}
          }
        }
      `,
      variables: {
        letterId,
        addAidAmounts,
        removeAidAmounts
      }
    });
    return data?.editAwardLetterResult;
  }

  async editEstimatedAids({
    chosenSchoolId,
    addAidAmounts,
    removeAidAmounts,
    livingChoice
  }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation EditEstimatedAids (
          $chosenSchoolId: ID!,
          $removeAidAmounts: [ID!],
          $addAidAmounts: [RawAidAmountType!],
          $livingChoice: LivingChoice!
          $pairedId: ID!,
        ) {
          editEstimatedAids(
            chosenSchoolId: $chosenSchoolId,
            addAidAmounts: $addAidAmounts,
            removeAidAmounts: $removeAidAmounts,
          ) {
            estimatedAids {
              id
            }
          }
          updateChosenSchool(
            pairedId: $pairedId,
            livingChoice: $livingChoice
          ) {
            ${chosenSchoolFields}
          }
        }
      `,
      variables: {
        chosenSchoolId,
        addAidAmounts,
        removeAidAmounts,
        livingChoice,
        pairedId: chosenSchoolId
      }
    });
    return data?.updateChosenSchool;
  }
}

const Api = new DecidedApi();

export default Api;

export { DecidedApi, ErrorTypes };
