import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import StatusPill from "./StatusPill";
import FilterResults from "./FilterResults";

import { ItemsCounter } from "../../../../core";
import { Filter } from "../../../../core/icons";
import { Button } from "../../../../core/buttons";

function CollegesFilter({
  status,
  filters,
  isDesktop,
  collegesCount,
  handlerOnSubmitFilters,
}) {
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const [localStatus, setLocalStatus] = useState([]);
  const [showFilterResults, setShowFilterResults] = useState(false);

  useEffect(() => {
    const statusFilter = filters?.status || [];
    setLocalStatus(
      status
        .filter((status) => statusFilter.includes(status.id))
        .map((status) => ({ ...status, active: true })),
    );
  }, [status, filters]);

  const toggleFilterResults = () => setShowFilterResults(!showFilterResults);

  return (
    <div className="mt-4 w-full bg-white">
      <div className="p-4 flex flex-1 flex-col">
        {isDesktop ? (
          <div
            id="filters-container"
            className="flex flex-1 flex-row items-center justify-between"
          >
            <p className="text-2xl font-semibold">
              Colleges you are interested in
            </p>
            <ItemsCounter
              color="text-black"
              numberItems={collegesCount}
              pluralDescriptionItems="colleges found"
              singularDescriptionItems="college found"
            />
          </div>
        ) : (
          <div id="filters-container" className="flex flex-col">
            <p className="text-2xl font-semibold">
              Colleges you are interested in
            </p>
            <ItemsCounter
              color="text-black"
              numberItems={collegesCount}
              pluralDescriptionItems="colleges found"
              singularDescriptionItems="college found"
            />
          </div>
        )}
        <div className="flex flex-1 flex-row">
          <p>Below you can find colleges that you added to your list</p>
        </div>
        <div
          className="
            mt-4
            flex
            flex-1
            flex-row
            mobile:flex-col
            mobile-sm:flex-col
            tablet-sm:flex-col
          "
        >
          <div
            className="
              flex
              flex-1
              flex-row
              space-x-2
              mobile:w-full
              mobile:flex-col
              mobile:space-x-0
              mobile-sm:w-full
              mobile-sm:flex-col
              mobile-sm:space-x-0
              tablet-sm:w-full
              tablet-sm:flex-col
              tablet-sm:space-x-0
            "
          >
            <div ref={containerRef} className="relative">
              <Button
                id="my-path-filters-button"
                onClick={toggleFilterResults}
                className="
                  flex
                  flex-1
                  w-full
                  flex-row
                  space-x-2
                  bg-white
                  items-center
                  border
                  border-primary-green
                  focus:outline-none
                  text-primary-green
                  hover:bg-primary-green
                  hover:text-white
                  mobile:ml-1
                  mobile:w-fit
                  mobile:text-white
                  mobile:bg-primary-green
                  mobile:border-primary-green
                  mobile-sm:ml-1
                  mobile-sm:w-fit
                  mobile-sm:text-white
                  mobile-sm:bg-primary-green
                  mobile-sm:border-primary-green
                "
              >
                <div>
                  <Filter size={20} color="fill-current" />
                </div>
                <span className="font-bold capitalize">filter</span>
              </Button>
              <FilterResults
                status={status}
                filters={filters}
                open={showFilterResults}
                containerRef={containerRef}
                toggle={toggleFilterResults}
                handleOnSubmit={(value) =>
                  handlerOnSubmitFilters({ type: "status", value })
                }
              />
            </div>
            <div
              className="
                flex
                flex-1
                flex-row
                flex-wrap
                items-center
              "
            >
              {localStatus.map((status) => (
                <StatusPill
                  id={status.id}
                  key={status.id}
                  name={status.name}
                  disabled={status.disabled}
                  handlerOnSubmitFilters={handlerOnSubmitFilters}
                />
              ))}
            </div>
            <div className="flex">
              <div
                role="button"
                onClick={() => navigate("/college/search")}
                className="
                  py-1
                  px-4
                  w-fit
                  h-fit
                  border
                  text-sm
                  capitalize
                  bg-white
                  self-center
                  text-center
                  font-extrabold
                  text-primary-green
                  border-primary-green
                  hover:text-white
                  hover:bg-primary-green
                  mobile:mx-1
                  mobile:mt-2
                  mobile:w-full
                  tablet-sm:mx-1
                  tablet-sm:mt-2
                  tablet-sm:w-full
                  mobile-sm:mx-1
                  mobile-sm:mt-2
                  mobile-sm:w-full
                "
              >
                Add More Schools
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CollegesFilter.propTypes = {
  isDesktop: PropTypes.bool,
  filters: PropTypes.shape(),
  collegesCount: PropTypes.number,
  handlerOnSubmitFilters: PropTypes.func,
  status: PropTypes.arrayOf(PropTypes.shape()),
};

export default CollegesFilter;
