import PropTypes from "prop-types";

import { CloseMini } from "../../../../core/icons";

function StatusPill({ id, name, disabled, handlerOnSubmitFilters }) {
  return (
    <div className="p-1 min-w-10rem mobile:w-1/2 mobile-sm:w-1/2 tablet-sm:w-1/2">
      <div
        className={`
          py-1
          px-5
          flex
          border
          flex-row
          text-white
          items-center
          justify-between
          bg-primary-green
          border-primary-green
          mobile:justify-between
          mobile-sm:justify-between
          tablet-sm:justify-between
          ${disabled ? "opacity-40 cursor-not-allowed pointer-events-none" : ""}
        `}
      >
        <div
          className="
            px-1
            flex
            text-sm
            flex-row
            font-bold
            capitalize
            mobile:text-xs
            mobile-sm:text-xs
            tablet-sm:text-xs
          "
        >
          {name}
        </div>
        <div
          role="button"
          className="w-4"
          onClick={() => handlerOnSubmitFilters({ type: "status", value: id })}
        >
          <CloseMini
            size={15}
            strokeColor="stroke-white"
            fillColor="fill-primary-green"
          />
        </div>
      </div>
    </div>
  );
}

StatusPill.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  handlerOnSelectFilters: PropTypes.func,
};

export default StatusPill;
