import PropTypes from "prop-types";

function IconButton({ icon, label, disabled, className = "", ...restProps }) {
  return (
    <button
      tabIndex="0"
      className={`
        p-2
        flex
        border
        flex-col
        space-y-2
        capitalize
        items-center
        justify-center
        font-extrabold
        ${className}
        ${disabled ? "opacity-40 cursor-not-allowed pointer-events-none" : ""}
      `}
      disabled={disabled}
      {...restProps}
    >
      {icon}
      {label}
    </button>
  );
}

IconButton.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default IconButton;
