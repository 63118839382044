import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router";
import { useDispatch } from "react-redux";

import Layout from "../../Common/Layout";
import SearchResults from "./SearchResults";
import LayoutCollege from "../LayoutCollege";
import SearchDashboard from "./SearchDashboard";

import Api from "../../../services/Api";
import { ModalTypes } from "../../Common/utils";
import { GetIpedsId, useSpinner } from "../../Common/Hooks";
import { updateStudent } from "../../../redux/Student/actions";
import {
  SortFields,
  SortOptions
} from "./SearchResults/SearchResultsHeader/SortByButton";
import { useStudent } from "../../../redux/Student/hooks";
import {
  PageViewDataLayer,
  SchoolSearchLocation,
  SchoolSearchMajor,
  SchoolSearchType
} from "../../../utils/DataLayers";

function getDataColleges(data, chosenSchools) {
  if (!data) return null;

  const selected = chosenSchools.map((school) => school.school.ipedsId);

  return data.map((item) => ({
    ...item,
    add: !selected.includes(item.ipedsId),
    graduationRate: `${Math.round(item.graduationRate * 100)}%`,
    award: selected.includes(item.ipedsId)
      ? chosenSchools.find((school) => school.school.ipedsId === item.ipedsId)
          ?.award
      : null
  }));
}

function Search({ student, openModal, modalResponse }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const ipedsId = GetIpedsId();

  const { schemaTypes } = useStudent();

  const [filters, setFilters] = useState({});
  const [doFetch, setDoFetch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [religiousTypes, setReligiousTypes] = useState([]);
  const [showOnboardingTooltip, setShowOnboardingTooltip] = useState(false);
  const [sortBy, setSortBy] = useState({
    attr: SortFields.NAME,
    order: SortOptions.ASC
  });
  const [searchResults, setSearchResults] = useState({
    items: [],
    pageCount: 0,
    itemsCount: 0,
    isLoading: false
  });

  useEffect(() => {
    if (student) {
      PageViewDataLayer({
        ipeds: ipedsId,
        userId: student.userId,
        screenName: "Search Page",
        organizationId: student.organizations.map((org) => org.organizationId)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchApi = useSpinner(async ({ input, orderBy, currentPage }) => {
    try {
      return await Api.searchSchool({
        input,
        orderBy,
        page: { pageNumber: currentPage, pageSize: 30 }
      });
    } catch (error) {
      console.log(error);
    }

    return {};
  });

  const putFiltersUrl = async (filters) => {
    let search = "";
    const { filter, orderBy } = filters;

    const verifyHasSearch = (search) => (search.length === 0 ? "?" : "&");

    if (filter.name)
      search += `${verifyHasSearch(search)}name=${encodeURIComponent(
        filter.name.search
      )}`;
    if (filter.hasCommonApp)
      search += `${verifyHasSearch(search)}hasCommonApp=${encodeURIComponent(
        filter.hasCommonApp.value
      )}`;
    if (filter.location)
      search += `${verifyHasSearch(search)}location=${encodeURIComponent(
        `${filter.location.selected
          .map((lc) => `${lc.id}:${lc.val}:${lc.label}`)
          .join(",")}`
      )}`;
    if (filter.major)
      search += `${verifyHasSearch(search)}major=${encodeURIComponent(
        `${filter.major.selected
          .map((mj) => `${mj.val}:${mj.label}`)
          .join(",")}`
      )}`;
    if (filter.type) {
      if (filter.type.size)
        search += `${verifyHasSearch(search)}size=${Object.keys(
          filter.type.size
        )
          .filter((key) => filter.type.size[key])
          .join(",")}`;
      if (filter.type.year)
        search += `${verifyHasSearch(search)}year=${Object.keys(
          filter.type.year
        )
          .filter((key) => filter.type.year[key])
          .join(",")}`;
      if (filter.type.control)
        search += `${verifyHasSearch(search)}control=${Object.keys(
          filter.type.control
        )
          .filter((key) => filter.type.control[key])
          .join(",")}`;
      if (filter.type.missionType)
        search += `${verifyHasSearch(search)}missionType=${Object.keys(
          filter.type.missionType
        )
          .filter((key) => filter.type.missionType[key])
          .join(",")}`;
      if (filter.type.religiousAffiliation)
        search += `${verifyHasSearch(
          search
        )}religiousAffiliation=${encodeURIComponent(
          `${filter.type.religiousAffiliation
            .map((mj) => `${mj.code}:${mj.name}`)
            .join(",")}`
        )}`;
    }
    if (orderBy)
      search += `${verifyHasSearch(search)}orderBy=${orderBy.attr}:${
        orderBy.order
      }`;

    navigate(`${location.pathname}${search}`);
  };

  const locationModalHandler = () => {
    SchoolSearchLocation({ ipeds: ipedsId });
    openModal({
      type: ModalTypes.LOCATION_FILTER,
      className: "w-40rem overflow-hidden",
      data: { filters: filters?.location, onSubmit: handlerOnSubmitFilters }
    });
  };

  const majorModalHandler = () => {
    SchoolSearchMajor({ ipeds: ipedsId });
    openModal({
      type: ModalTypes.MAJOR_FILTER,
      className: "w-40rem overflow-hidden",
      data: { filters: filters?.major, onSubmit: handlerOnSubmitFilters }
    });
  };

  const typeModalHandler = () => {
    SchoolSearchType({ ipeds: ipedsId });
    openModal({
      type: ModalTypes.TYPE_FILTER,
      className: "w-40rem overflow-hidden",
      data: {
        religiousTypes,
        filters: filters?.type,
        onSubmit: handlerOnSubmitFilters
      }
    });
  };

  const handlerOnSubmitSortBy = ({ attr, order }) => {
    setCurrentPage(1);
    setSortBy({ attr, order });
    setDoFetch(true);
  };

  const handlerOnSubmitFilters = ({ type, filters }) => {
    if (Object.keys(filters).length > 0) {
      setFilters((stateFilters) => {
        setCurrentPage(1);
        setDoFetch(true);

        setSearchResults((searchResults) => ({
          ...searchResults,
          isLoading: true
        }));

        return { ...stateFilters, [type]: { ...filters } };
      });
    } else {
      handlerOnClearFilters(type);
    }
  };

  const handlerOnClearFilters = (filter) => {
    let localHasFilters = false;
    if (filter) {
      setFilters((filters) => {
        delete filters[filter];

        if (Object.keys(filters).length === 0) {
          setSearchResults((searchResults) => ({
            ...searchResults,
            items: []
          }));
          navigate(location.pathname);
          setSortBy({ attr: SortFields.NAME, order: SortOptions.ASC });
          return {};
        } else {
          localHasFilters = true;
        }

        return { ...filters };
      });

      setCurrentPage(1);
      if (localHasFilters) setDoFetch(true);
    } else {
      setFilters({});
      navigate(location.pathname);
      setSortBy({ attr: SortFields.NAME, order: SortOptions.ASC });
      setSearchResults((searchResults) => ({ ...searchResults, items: [] }));
    }
  };

  const handlerPagination = (isIntersecting) => {
    if (
      !doFetch &&
      isIntersecting &&
      !searchResults.isLoading &&
      currentPage <= searchResults.pageCount + 1 &&
      searchResults.items.length < searchResults.itemsCount
    ) {
      setDoFetch(() => {
        setCurrentPage(currentPage + 1);
        return true;
      });
    }
  };

  const markOnboardingSearchDone = () => {
    setShowOnboardingTooltip(false);
  };

  useEffect(() => {
    const fetchCollegeSearch = async () => {
      const size = filters?.type?.size || {};
      const sizeFilters = Object.keys(size);

      const year = filters?.type?.year || {};
      const yearFilters = Object.keys(year);

      const control = filters?.type?.control || {};
      const controlFilters = Object.keys(control);

      const missionType = filters?.type?.missionType || {};
      const missionTypeFilters = Object.keys(missionType);

      const religiousAffiliationType =
        filters?.type?.religiousAffiliation || [];
      const religiousAffiliationTypeFilters = religiousAffiliationType.map(
        (religious) => religious.code
      );

      const fetchApiParams = {
        input: {
          name: filters?.name?.search || null,
          hasCommonApp:
            filters?.hasCommonApp?.value === "true"
              ? true
              : filters?.hasCommonApp?.value === "false"
              ? false
              : Boolean(filters?.hasCommonApp?.value) || null,
          state: filters?.location?.selected.map((state) => state.val) || null,
          size:
            sizeFilters.length > 0
              ? sizeFilters.filter((key) => size[key])
              : null,
          programLength:
            yearFilters.length > 0
              ? yearFilters.filter((key) => year[key])
              : null,
          control:
            controlFilters.length > 0
              ? controlFilters.filter((key) => control[key])
              : null,
          missionType:
            missionTypeFilters.length > 0
              ? missionTypeFilters.filter((key) => missionType[key])
              : null,
          religiousAffiliation:
            religiousAffiliationTypeFilters.length > 0
              ? religiousAffiliationTypeFilters
              : null,
          majors: filters?.major?.selected.map((major) => major.val) || null
        },
        currentPage
      };
      fetchApiParams.orderBy = sortBy;

      const { items, pageCount, itemsCount } = await fetchApi(fetchApiParams);

      if (currentPage === 1) {
        putFiltersUrl({
          filter: filters,
          orderBy: fetchApiParams?.orderBy
        });

        setSearchResults((searchResults) => ({
          ...searchResults,
          items: getDataColleges(items, student?.chosenSchools || []),
          pageCount,
          itemsCount
        }));
      } else {
        setSearchResults((searchResults) => ({
          ...searchResults,
          items: searchResults.items.concat(
            getDataColleges(items, student?.chosenSchools || [])
          )
        }));
      }

      setDoFetch(false);
      setSearchResults((searchResults) => ({
        ...searchResults,
        isLoading: false
      }));
    };

    if (doFetch) fetchCollegeSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doFetch]);

  useEffect(() => {
    let filters = {};
    const { search } = location;

    const params = new URLSearchParams(search);

    if (params.get("name")) filters = { name: { search: params.get("name") } };
    if (params.get("hasCommonApp"))
      filters = { hasCommonApp: { value: params.get("hasCommonApp") } };
    if (params.get("location"))
      filters = {
        ...filters,
        location: {
          selected: params
            .get("location")
            .split(",")
            .map((lc) => {
              const lcObj = lc.split(":");
              return {
                id: lcObj[0],
                val: lcObj[1],
                label: lcObj[2],
                isActive: true
              };
            })
        }
      };
    if (params.get("major"))
      filters = {
        ...filters,
        major: {
          selected: params
            .get("major")
            .split(",")
            .map((mj) => {
              const mjObj = mj.split(":");
              return { val: mjObj[0], label: mjObj[1] };
            })
        }
      };
    if (params.get("size"))
      filters = {
        ...filters,
        type: {
          size: params
            .get("size")
            .split(",")
            .reduce((object, size) => ({ ...object, [size]: true }), {})
        }
      };
    if (params.get("year"))
      filters = {
        ...filters,
        type: {
          ...(filters.type || {}),
          year: params
            .get("year")
            .split(",")
            .reduce((object, year) => ({ ...object, [year]: true }), {})
        }
      };
    if (params.get("control"))
      filters = {
        ...filters,
        type: {
          ...(filters.type || {}),
          control: params
            .get("control")
            .split(",")
            .reduce((object, control) => ({ ...object, [control]: true }), {})
        }
      };
    if (params.get("missionType"))
      filters = {
        ...filters,
        type: {
          ...(filters.type || {}),
          missionType: params
            .get("missionType")
            .split(",")
            .reduce(
              (object, missionType) => ({ ...object, [missionType]: true }),
              {}
            )
        }
      };
    if (params.get("religiousAffiliation"))
      filters = {
        ...filters,
        type: {
          ...(filters.type || {}),
          religiousAffiliation: params
            .get("religiousAffiliation")
            .split(",")
            .map((ra) => {
              const raObj = ra.split(":");
              return { code: raObj[0], name: raObj[1] };
            })
        }
      };

    if (Object.keys(filters).length > 0) {
      if (params.get("orderBy")) {
        const orderBy = params.get("orderBy").split(":");
        setSortBy({ attr: orderBy[0], order: orderBy[1] });
      }

      setFilters(filters);
      setDoFetch(true);
      setSearchResults((searchResults) => ({
        ...searchResults,
        isLoading: true
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchTypes = async () => {
      const religionsType =
        schemaTypes?.find(
          (type) => type.name === "SchoolsSchoolReligiousAffiliationChoices"
        )?.enumValues || [];
      setReligiousTypes(
        religionsType.map((religion) => ({
          code: religion.name,
          name: religion.description
        }))
      );
    };
    fetchTypes();
  }, [schemaTypes]);

  useEffect(() => {
    if (
      student.onboardingSignUp &&
      !student.onboardingSearch &&
      !student.chosenSchools.length > 0
    ) {
      setShowOnboardingTooltip(true);
      dispatch(updateStudent({ onboardingSearch: true }));
    }
  }, [
    student.chosenSchools.length,
    student.onboardingSignUp,
    student.onboardingSearch,
    dispatch
  ]);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      setCurrentPage(1);
      setDoFetch(true);
    }
  }, [filters]);

  return (
    <Layout
      student={student}
      openModal={openModal}
      modalResponse={modalResponse}
    >
      <LayoutCollege isCentered={Object.keys(filters).length > 0}>
        <div className="min-h-screen">
          {!Object.keys(filters).length > 0 ? (
            <SearchDashboard
              typeModalHandler={typeModalHandler}
              chosenSchools={student.chosenSchools}
              majorModalHandler={majorModalHandler}
              locationModalHandler={locationModalHandler}
              showOnboardingTooltip={showOnboardingTooltip}
              handlerOnSubmitFilters={handlerOnSubmitFilters}
              markOnboardingSearchDone={markOnboardingSearchDone}
            />
          ) : (
            <SearchResults
              sortBy={sortBy}
              filters={filters}
              religiousTypes={religiousTypes}
              resultSchools={searchResults.items}
              isLoading={searchResults.isLoading}
              chosenSchools={student.chosenSchools}
              handlerPagination={handlerPagination}
              collegesCount={searchResults.itemsCount}
              handlerOnSubmitSortBy={handlerOnSubmitSortBy}
              handlerOnClearFilters={handlerOnClearFilters}
              handlerOnSubmitFilters={handlerOnSubmitFilters}
            />
          )}
        </div>
      </LayoutCollege>
    </Layout>
  );
}

Search.propTypes = {
  student: PropTypes.object,
  openModal: PropTypes.func,
  modalResponse: PropTypes.string
};

export default Search;
