import PropTypes from "prop-types";

function FilterLocation({ size, color }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 20 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M8.91043 18.0856H6.83909C6.72535 18.0856 6.61627 18.1275 6.53584 18.202C6.45542 18.2766 6.41024 18.3777 6.41024 18.4831C6.41024 18.5885 6.45542 18.6896 6.53584 18.7642C6.61627 18.8387 6.72535 18.8806 6.83909 18.8806H13.1603C13.2741 18.8806 13.3832 18.8387 13.4636 18.7642C13.544 18.6896 13.5892 18.5885 13.5892 18.4831C13.5892 18.3777 13.544 18.2766 13.4636 18.202C13.3832 18.1275 13.2741 18.0856 13.1603 18.0856H11.089C13.1003 16.2969 18.1479 11.4476 18.1479 7.94973C18.1479 3.57738 14.4941 0 9.99971 0C5.50536 0 1.85156 3.57738 1.85156 7.94973C1.85156 11.4476 6.89913 16.2969 8.91043 18.0856ZM9.99971 0.794973C14.0223 0.794973 17.2902 3.99871 17.2902 7.94973C17.2902 11.4436 11.3763 16.766 9.99971 17.9584C8.61881 16.766 2.70926 11.4436 2.70926 7.94973C2.70926 3.99871 5.9771 0.794973 9.99971 0.794973Z"
      />
      <path
        className={color}
        d="M13.704 7.38033C13.7018 6.51604 13.3291 5.68774 12.6675 5.0766C12.006 4.46545 11.1093 4.1212 10.1736 4.11914C5.496 4.28278 5.50022 10.4779 10.1736 10.6415C11.1093 10.6395 12.006 10.2952 12.6675 9.68407C13.3291 9.07293 13.7018 8.24462 13.704 7.38033ZM7.48685 7.38033C7.48796 6.7224 7.77139 6.09171 8.27502 5.62648C8.77866 5.16125 9.46141 4.89943 10.1736 4.8984C13.7293 5.01529 13.7293 9.74538 10.1736 9.86227C9.46141 9.86124 8.77866 9.59942 8.27502 9.13419C7.77139 8.66896 7.48796 8.03827 7.48685 7.38033Z"
      />
      <path
        className={color}
        d="M17.8847 14.4781C17.7937 14.4256 17.6843 14.4065 17.5786 14.4246C17.4728 14.4427 17.3784 14.4966 17.3142 14.5756C17.2499 14.6545 17.2207 14.7528 17.2322 14.8506C17.2437 14.9485 17.2952 15.0387 17.3763 15.1034C22.1444 18.5778 16.3295 22.3101 9.98484 22.2163C3.64017 22.3101 -2.1747 18.5778 2.59341 15.1034C2.67452 15.0387 2.72598 14.9485 2.73749 14.8506C2.74901 14.7528 2.71973 14.6545 2.65552 14.5756C2.59131 14.4966 2.49689 14.4427 2.39112 14.4246C2.28535 14.4065 2.17602 14.4256 2.08498 14.4781C0.717781 15.4004 0 16.5221 0 17.718C0 20.6804 4.38359 22.998 9.98484 22.998C17.1712 23.1113 23.409 18.5973 17.8847 14.4781Z"
      />
    </svg>
  );
}

FilterLocation.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default FilterLocation;
