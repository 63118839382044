import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchNotifications } from "../Notifications/actions";
import { fetchStudent, updateChosenSchoolYearType } from "./actions";
import { useRefreshFlag } from "../../core/hooks";
import {
  AwardStatus,
  SchoolStatus,
  getGrantsAndScholarships
} from "../../core/utils";

export function useStudent() {
  return useSelector((state) => state.student);
}

export function useRefetchStudent({ refreshTimeout = 90 * 1000 }) {
  const dispatch = useDispatch();
  const { refreshFlag } = useRefreshFlag({ refreshTimeout });

  useEffect(() => {
    (async () => {
      await dispatch(fetchStudent({ refetch: true }));
      await dispatch(fetchNotifications({ refetch: true }));
    })();
  }, [dispatch, refreshFlag]);
}

function useGetFirstChosenSchoolLocal() {
  return useSelector((state) => {
    const programLength = state.student.student.chosenSchoolsYearType;
    const allChosenSchools = state.student?.student?.chosenSchools || [];
    const chosenSchools = programLength
      ? allChosenSchools.filter(
          (chosenSchool) => chosenSchool.school?.programLength === programLength
        )
      : allChosenSchools;
    return chosenSchools[0];
  });
}

export function useGetFirstChosenSchool() {
  const dispatch = useDispatch();
  const college = useGetFirstChosenSchoolLocal();
  const student = useSelector((state) => state.student.student);

  useEffect(() => {
    if (college && !student.chosenSchoolsYearType)
      dispatch(
        updateChosenSchoolYearType({
          chosenSchoolsYearType: college?.school?.programLength
        })
      );
  }, [dispatch, college, student.chosenSchoolsYearType]);

  return !student.chosenSchoolsYearType
    ? college
    : student.chosenSchoolsYearType === college?.school?.programLength
    ? college
    : null;
}

function useGetChosenSchoolLocal({ ipedsId = null, pairedId = null }) {
  return useSelector((state) => {
    const chosenSchools = state.student?.student?.chosenSchools || [];
    if (ipedsId) {
      return chosenSchools.find((school) => school.school.ipedsId === ipedsId);
    } else if (pairedId) {
      return chosenSchools.find((school) => school.pairedId === pairedId);
    }
  });
}

function determineSchoolStatus(college) {
  if (!college) return null;

  const { award, estimatedAids, estimatedPostAwardCosts } = college;
  const ready = award?.status === AwardStatus.READY;
  const inReview =
    award?.status === AwardStatus.PENDING ||
    award?.status === AwardStatus.MANUAL_REVIEW;
  const invalid = !ready && !inReview && typeof award?.status !== "undefined";
  const grantsReviewed =
    ready &&
    getGrantsAndScholarships(award?.aidAmounts).reduce(
      (accumulator, aidAmount) => accumulator + aidAmount.value,
      0
    ) === 0;

  if (!award && estimatedPostAwardCosts && estimatedAids.length > 0) {
    return SchoolStatus.ESTIMATED;
  }
  if (ready && !grantsReviewed) {
    return SchoolStatus.READY;
  }
  if (invalid) {
    return SchoolStatus.INVALID;
  }
  if (inReview) {
    return SchoolStatus.IN_REVIEW;
  }
  if (grantsReviewed) {
    return SchoolStatus.GRANTS_REVIEWED;
  }
  return SchoolStatus.NO_AID;
}

export function useGetChosenSchool({ ipedsId = null, pairedId = null }) {
  const dispatch = useDispatch();
  const college = useGetChosenSchoolLocal({ ipedsId, pairedId });

  useEffect(() => {
    if (college) {
      dispatch(
        updateChosenSchoolYearType({
          chosenSchoolsYearType: college?.school?.programLength
        })
      );
    }
  }, [dispatch, college]);

  const schoolStatus = useMemo(() => determineSchoolStatus(college), [college]);

  return { ...college, schoolStatus };
}

export function useGetShowUploadTutorial() {
  return useSelector((state) => {
    const chosenSchools = state.student?.student?.chosenSchools || [];
    return !chosenSchools.some((school) => school.award);
  });
}
