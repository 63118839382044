import PropTypes from "prop-types";

import {
  Budget,
  AffordabilityThumbs
} from "../../../../../core/compose/affordability";
import { SchoolStatus } from "../../../../../core/utils";
import ChartMobile from "../../../../../core/charts/AffordableChart/Mobile";

import { GetIpedsId } from "../../../../Common/Hooks";
import EstimateYourAidButton from "../../../Common/EstimateYourAidButton";
import FirstYearBreakdownButton from "../../../Common/FirstYearBreakdownButton";

import AffordabilityLabel from "../AffordabilityLabel";
import CollegeStatusDropdown from "../CollegeStatusDropdown";
import { getChartComponent } from "./AffordabilityChartConfig";

function AffordabilityChartMobile(props) {
  const {
    theme,
    award,
    isDesktop,
    affordability,
    chosenSchool,
    addYourMoneyPath,
    firstYearBreakdownPath,
    openModal
  } = props;

  const show = !isDesktop;

  if (!show) return null;

  const ipedsId = GetIpedsId();
  const estimateYourAidPath = `/college/id/${ipedsId}/estimate-financial-aid`;

  const chartConfig = getChartComponent(chosenSchool?.schoolStatus, props);

  let coverage =
    chosenSchool.schoolStatus === SchoolStatus.ESTIMATED ||
    chosenSchool.schoolStatus === SchoolStatus.INVALID
      ? chosenSchool?.estimatedPostAwardCosts?.coverage
      : chosenSchool?.award?.postAwardSchoolCosts?.coverage || 0;

  let collegeCosts;
  if (
    chosenSchool?.schoolStatus === SchoolStatus.ESTIMATED ||
    chosenSchool.schoolStatus === SchoolStatus.INVALID
  ) {
    collegeCosts =
      chosenSchool.estimatedPostAwardCosts.schoolCosts ||
      chosenSchool.schoolCosts.totalCollegeCosts;
  } else {
    collegeCosts = award?.postAwardSchoolCosts
      ? award.postAwardSchoolCosts.schoolCosts
      : chosenSchool.schoolCosts.totalCollegeCosts;
  }

  let lifeBudget;
  if (
    chosenSchool.schoolStatus === SchoolStatus.ESTIMATED ||
    chosenSchool.schoolStatus === SchoolStatus.INVALID
  ) {
    lifeBudget =
      chosenSchool.estimatedPostAwardCosts.lifeBudget ||
      chosenSchool.schoolCosts.lifeBudget;
  } else {
    lifeBudget = award?.postAwardSchoolCosts
      ? award.postAwardSchoolCosts.lifeBudget
      : chosenSchool.schoolCosts.lifeBudget;
  }

  return (
    <div
      className="
        p-4
        mx-4
        flex
        flex-1
        flex-col
        shadow-lg
        bg-white
      "
    >
      <AffordabilityLabel show={true} />
      {(chosenSchool?.schoolStatus === SchoolStatus.READY ||
        chosenSchool?.schoolStatus === SchoolStatus.ESTIMATED ||
        chosenSchool?.schoolStatus === SchoolStatus.INVALID ||
        chosenSchool?.schoolStatus === SchoolStatus.GRANTS_REVIEWED) && (
        <>
          {chosenSchool?.schoolStatus === SchoolStatus.READY && (
            <div className="-mt-5 mobile-sm:-mt-4">
              <AffordabilityThumbs
                theme={theme}
                className="flex flex-1 flex-row text-xl mobile-sm:text-lg"
              />
            </div>
          )}
          <div className="-my-10 px-10 mobile-sm:-my-6">
            <ChartMobile percent={coverage} affordability={affordability} />
          </div>
        </>
      )}
      {chartConfig}
      <Budget
        color={theme.color}
        lifeBudget={lifeBudget}
        collegeCosts={collegeCosts}
        classNameCost="font-medium"
        classNameLink="text-xs font-bold"
      />
      {chosenSchool?.schoolStatus === undefined && (
        <EstimateYourAidButton path={estimateYourAidPath} />
      )}
      <FirstYearBreakdownButton
        className="my-4"
        color={theme.color}
        path={firstYearBreakdownPath}
      />
      <CollegeStatusDropdown
        color={theme.color}
        openModal={openModal}
        chosenSchool={chosenSchool}
        className="w-full text-base"
        addYourMoneyPath={addYourMoneyPath}
        enrollmentStatus={chosenSchool.enrollmentStatus}
      />
    </div>
  );
}

AffordabilityChartMobile.propTypes = {
  show: PropTypes.bool,
  theme: PropTypes.shape(),
  award: PropTypes.shape(),
  openModal: PropTypes.func,
  coverage: PropTypes.number,
  totalCost: PropTypes.number,
  lifeBudget: PropTypes.number,
  schoolBill: PropTypes.number,
  affordability: PropTypes.string,
  chosenSchool: PropTypes.shape(),
  addYourMoneyPath: PropTypes.string,
  schoolStatus: PropTypes.string,
  onClickViewAwardLetter: PropTypes.func,
  firstYearBreakdownPath: PropTypes.string,
  onClickReuploadAwardLetter: PropTypes.func
};

export default AffordabilityChartMobile;
