import PropTypes from "prop-types";

import { Loader } from "../../";

function StudentListGroup({ data, isLoading }) {
  return (
    <>
      {data.map((item) => (
        <div
          key={item.id}
          className="
            p-4
            border-b
            mobile:p-2
            mobile-sm:p-2
            tablet-sm:p-2
            border-light-gray
          "
        >
          <div>
            <p className="text-sm font-semibold">{item.name}</p>
            <p className="text-xs font-semibold text-gray-500">{item.email}</p>
          </div>
        </div>
      ))}
      {data.length === 0 && !isLoading && (
        <div className="p-8 w-full text-sm text-center font-semibold">
          <p>No Students Yet!!</p>
        </div>
      )}
      {isLoading && (
        <div className="py-8">
          <Loader show={isLoading} />
        </div>
      )}
    </>
  );
}

StudentListGroup.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default StudentListGroup;
