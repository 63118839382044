const SET_ERROR = "STUDENT/SET_ERROR";
const SET_GUIDES = "STUDENT/SET_GUIDES";
const SET_STUDENT = "STUDENT/SET_STUDENT";
const SET_IS_LOADING = "STUDENT/SET_IS_LOADING";
const SET_SCHEMA_TYPES = "STUDENT/SET_SCHEMA_TYPES";
const SET_IS_LOGGED_IN = "STUDENT/SET_IS_LOGGED_IN";
const ADD_CHOSEN_SCHOOL = "STUDENT/ADD_CHOSEN_SCHOOL";
const REMOVE_CHOSEN_SCHOOL = "STUDENT/REMOVE_CHOSEN_SCHOOL";
const RESET_CHOSEN_SCHOOLS = "STUDENT/RESET_CHOSEN_SCHOOLS";
const UPDATE_CHOSEN_SCHOOL = "STUDENT/UPDATE_CHOSEN_SCHOOL";
const UPDATE_CHOSEN_SCHOOLS = "STUDENT/UPDATE_CHOSEN_SCHOOLS";
const ADD_CHOSEN_SCHOOL_MAJOR = "STUDENT/ADD_CHOSEN_SCHOOL_MAJOR";
const REMOVE_CHOSEN_SCHOOL_MAJOR = "STUDENT/REMOVE_CHOSEN_SCHOOL_MAJOR";
const UPDATE_CHOSEN_SCHOOL_YEAR_TYPE = "STUDENT/UPDATE_CHOSEN_SCHOOL_YEAR_TYPE";

const Types = {
  SET_ERROR,
  SET_GUIDES,
  SET_STUDENT,
  SET_IS_LOADING,
  SET_SCHEMA_TYPES,
  SET_IS_LOGGED_IN,
  ADD_CHOSEN_SCHOOL,
  REMOVE_CHOSEN_SCHOOL,
  RESET_CHOSEN_SCHOOLS,
  UPDATE_CHOSEN_SCHOOL,
  UPDATE_CHOSEN_SCHOOLS,
  ADD_CHOSEN_SCHOOL_MAJOR,
  REMOVE_CHOSEN_SCHOOL_MAJOR,
  UPDATE_CHOSEN_SCHOOL_YEAR_TYPE,
};

export default Types;
