import PropTypes from "prop-types";
import { useState, useRef } from "react";

import ZoomControlButtons from "../College/ViewAwardLetter/Viewer/ZoomControlButtons";

export default function ViewAwardLetterModal({ image }) {
  const [imgDimensions, setImgDimensions] = useState({
    height: null,
    width: null,
  });
  const [defaultImgDimensions, setDefaultImgDimensions] = useState({});
  const [currentZoomPercentage, setCurrentZoomPercentage] = useState(100);
  const [zoomStepsCounter, setZoomStepsCounter] = useState(1);

  const ZOOM_STEP_SIZE = 0.1;
  const ZOOM_MIN_LIMIT = ZOOM_STEP_SIZE;
  const ZOOM_MAX_LIMIT = 2;

  const imgRef = useRef();

  const handleOnLoad = () => {
    setImgDimensions({
      height: imgRef.current?.clientHeight,
      width: imgRef.current?.clientWidth,
    });
    setDefaultImgDimensions({
      height: imgRef.current?.clientHeight,
      width: imgRef.current?.clientWidth,
    });
  };

  const handleZoomIn = () => {
    if (zoomStepsCounter < ZOOM_MAX_LIMIT) {
      setZoomStepsCounter(zoomStepsCounter + ZOOM_STEP_SIZE);
      setImgDimensions({
        height: imgDimensions.height + imgDimensions.height * ZOOM_STEP_SIZE,
        width: imgDimensions.width + imgDimensions.width * ZOOM_STEP_SIZE,
      });
      setCurrentZoomPercentage(currentZoomPercentage + ZOOM_STEP_SIZE * 100);
    }
  };

  const handleZoomOut = () => {
    if (zoomStepsCounter > ZOOM_MIN_LIMIT) {
      setZoomStepsCounter(zoomStepsCounter - ZOOM_STEP_SIZE);
      setImgDimensions({
        height: imgDimensions.height - imgDimensions.height * ZOOM_STEP_SIZE,
        width: imgDimensions.width - imgDimensions.width * ZOOM_STEP_SIZE,
      });
      setCurrentZoomPercentage(currentZoomPercentage - ZOOM_STEP_SIZE * 100);
    }
  };

  const handleResetZoom = () => {
    setZoomStepsCounter(1);
    setImgDimensions({
      height: defaultImgDimensions.height,
      width: defaultImgDimensions.width,
    });
    setCurrentZoomPercentage(100);
  };

  return (
    <div className="pb-20 flex flex-col h-full w-full relative justify-center items-center bg-black">
      <div
        className="
                flex
                h-full
                w-full
                flex-col
                bg-white
                items-center
                justify-center
                relative
              "
      >
        <div className="overflow-scroll absolute h-full w-full">
          <img
            src={image}
            ref={imgRef}
            alt="Award Letter Preview"
            onLoad={handleOnLoad}
            style={{
              ...imgDimensions,
              position: "absolute",
              overflow: "scroll",
              maxWidth: "none",
            }}
          />
        </div>

        <ZoomControlButtons
          zoomIn={handleZoomIn}
          zoomOut={handleZoomOut}
          resetTransform={handleResetZoom}
          currentZoomPercentage={currentZoomPercentage}
        />
      </div>
    </div>
  );
}

ViewAwardLetterModal.propTypes = {
  image: PropTypes.string.isRequired,
};
