import PropTypes from "prop-types";

import { Check } from "../../../../../core/icons";
import { ButtonDots } from "../../../../../core/compose";

import { Colors } from "../../../../../core/utils";

function DotsButton({ id, add, handlerAddCollege, handlerRemoveCollege }) {
  const buttonStyle = add
    ? "bg-primary-green text-white"
    : "bg-white text-primary-green";

  return (
    <ButtonDots
      size={20}
      width={130}
      color={Colors.GREEN}
      customId={`${id}-menu-button`}
      className={`p-2 ${buttonStyle}`}
    >
      {add ? (
        <div
          role="button"
          onClick={handlerAddCollege}
          className="text-sm text-center"
          id={`${id}-add-to-my-list-button`}
        >
          <p className="text-xsm font-bold">Add to My List</p>
        </div>
      ) : (
        <div
          role="button"
          onClick={handlerRemoveCollege}
          id={`${id}-on-my-list-button`}
          className="flex flex-row items-center justify-center"
        >
          <div className="w-5 mr-1">
            <Check size={20} color="fill-current" />
          </div>
          <p className="text-sm font-bold capitalize">On my list</p>
        </div>
      )}
    </ButtonDots>
  );
}

DotsButton.propTypes = {
  add: PropTypes.bool,
  id: PropTypes.string,
  handlerAddCollege: PropTypes.func,
  handlerRemoveCollege: PropTypes.func,
};

export default DotsButton;
