import PropTypes from "prop-types";

function NewLabel({ show }) {
  if (!show) return null;

  return (
    <div
      id="new-tag"
      className="
        px-1
        -top-1
        -right-0
        text-xs
        absolute
        font-bold
        text-black
        bg-alternative-yellow
      "
    >
      New!
    </div>
  );
}

NewLabel.propTypes = {
  show: PropTypes.bool,
};

export default NewLabel;
