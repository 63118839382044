import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Dropdown, SmallModal } from "../../../../core";
import { Button } from "../../../../core/buttons";
import { Colors, Enrollment, getEnrollmentIcon } from "../../../../core/utils";

import { ModalTypes } from "../../../Common/utils";

import { useStudent } from "../../../../redux/Student/hooks";
import { updateEnrollmentStatus } from "../../../../redux/Student/actions";
import { ProgressTrackerDropdown } from "../../../../utils/DataLayers";

function CollegeStatusDropdown({
  color,
  iconSize = 20,
  className = "",
  openModal,
  chosenSchool,
  labelClassName = "",
  enrollmentStatus,
  addYourMoneyPath
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { schemaTypes, student } = useStudent();

  const [statusReady, setStatusReady] = useState(false);
  const [currentStatusOption, setCurrentStatusOption] = useState(null);
  const [enrollmentStatusOptions, setEnrollmentStatusOptions] = useState(null);
  const [showEnrollmentStatusModal, setShowEnrollmentStatusModal] =
    useState(false);
  const [previousChosenSchoolEnrolled, setPreviousChosenSchoolEnrolled] =
    useState({});
  const [areMultipleSchoolsEnrolled, setAreMultipleSchoolsEnrolled] =
    useState(false);

  useEffect(() => {
    const enrollmentStatus =
      schemaTypes?.find((type) => type.name === "_EnrollmentStatus")
        ?.enumValues || [];

    const enrollmentStatusOptions = enrollmentStatus.map((status) => ({
      enabled: true,
      id: status.name,
      value: status.name,
      label: (
        <div
          className="
            flex
            flex-1
            flex-row
            font-bold
            space-x-2
            items-center
          "
        >
          <div>
            {getEnrollmentIcon({
              size: iconSize,
              color: "fill-current",
              enrollment: status.name
            })}
          </div>
          <span className={labelClassName}>{status.description}</span>
        </div>
      )
    }));

    setEnrollmentStatusOptions(enrollmentStatusOptions);
    setStatusReady(true);
  }, [schemaTypes, iconSize, labelClassName]);

  useEffect(() => {
    if (statusReady) {
      const currentOption = enrollmentStatusOptions.find((option) => {
        return option.value === enrollmentStatus;
      });

      setCurrentStatusOption(currentOption);
      if (chosenSchool?.award?.postAwardSchoolCosts) {
        setEnrollmentStatusOptions((enrollmentStatusOptions) =>
          enrollmentStatusOptions.map((enrollmentStatusOption) => {
            if (
              enrollmentStatusOption.id <
              Enrollment.ENROLLMENT_4_UPLOADED_LETTER
            )
              return { ...enrollmentStatusOption, enabled: false };

            return enrollmentStatusOption;
          })
        );
      } else {
        setEnrollmentStatusOptions((prevOptions) =>
          prevOptions.map((option) => {
            if (option.id < Enrollment.ENROLLMENT_4_UPLOADED_LETTER) {
              return { ...option, enabled: true };
            }
            return option;
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusReady, chosenSchool, enrollmentStatus]);

  const getChosenSchoolsEnrolled = useCallback(() => {
    return student.chosenSchools.filter(
      (school) => school.enrollmentStatus === Enrollment.ENROLLMENT_5_ENROLLED
    );
  }, [student.chosenSchools]);

  useEffect(() => {
    const chosenSchoolsEnrolled = getChosenSchoolsEnrolled();
    if (chosenSchoolsEnrolled.length > 1) {
      setAreMultipleSchoolsEnrolled(true);
    } else {
      setAreMultipleSchoolsEnrolled(false);
      setPreviousChosenSchoolEnrolled(chosenSchoolsEnrolled[0]);
    }
  }, [getChosenSchoolsEnrolled]);

  const updateEnrollment = useCallback(
    (school, enrollmentStatus) => {
      dispatch(
        updateEnrollmentStatus({
          chosenSchool: school,
          enrollmentStatus: enrollmentStatus
        })
      );
    },
    [dispatch]
  );

  const onSelectEnrolled = () => {
    updateEnrollment(chosenSchool, Enrollment.ENROLLMENT_5_ENROLLED);
    openModal({
      type: ModalTypes.ENROLLED_STATUS,
      data: { schoolEnrolled: chosenSchool.school }
    });
    setShowEnrollmentStatusModal(false);
    setAreMultipleSchoolsEnrolled(false);
    setPreviousChosenSchoolEnrolled(null);
  };

  const onSelect = (item) => {
    ProgressTrackerDropdown({
      affordability_state:
        chosenSchool?.award?.postAwardSchoolCosts?.affordability,
      ipeds: chosenSchool?.school.ipedsId
    });
    const dispatchOption = () => {
      setCurrentStatusOption(item);
      dispatch(
        updateEnrollmentStatus({
          chosenSchool,
          enrollmentStatus: item.value
        })
      );
    };
    if (item.id === Enrollment.ENROLLMENT_4_UPLOADED_LETTER) {
      if (chosenSchool?.award?.postAwardSchoolCosts) dispatchOption();
      else navigate(addYourMoneyPath);
    } else if (
      item.id === Enrollment.ENROLLMENT_5_ENROLLED &&
      (areMultipleSchoolsEnrolled || previousChosenSchoolEnrolled)
    ) {
      setShowEnrollmentStatusModal(true);
    } else {
      dispatchOption();
    }
  };

  return (
    <div className={className}>
      <Dropdown
        color={color}
        handleOnSelect={onSelect}
        options={enrollmentStatusOptions}
        currentOption={currentStatusOption}
      />
      {showEnrollmentStatusModal && (
        <SmallModal open={showEnrollmentStatusModal}>
          <>
            <p className="text-xl font-bold text-primary-green">
              You already marked{" "}
              {areMultipleSchoolsEnrolled
                ? "multiple schools "
                : `${previousChosenSchoolEnrolled?.school?.name} `}
              as enrolled.
            </p>
            <p className="my-8">
              Do you want to switch your enrollment to{" "}
              {chosenSchool?.school?.name} instead?"
            </p>

            <div className="flex flex-row justify-center space-x-2">
              <Button
                onClick={() => {
                  setShowEnrollmentStatusModal(false);
                }}
                className="
                    w-1/3
                    text-primary-green
                    bg-white
                    border-primary-green
                    hover:bg-primary-green
                    active:bg-primary-green
                    hover:text-white
                    active:text-white
                  "
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onSelectEnrolled();
                }}
                className="
                    w-1/3
                    text-white
                    bg-primary-green
                    border-primary-green
                    hover:bg-white
                    active:bg-white
                    hover:text-primary-green
                    active:text-primary-green
                  "
              >
                Switch
              </Button>
            </div>
          </>
        </SmallModal>
      )}
    </div>
  );
}

CollegeStatusDropdown.propTypes = {
  openModal: PropTypes.func,
  iconSize: PropTypes.number,
  className: PropTypes.string,
  chosenSchool: PropTypes.shape(),
  labelClassName: PropTypes.string,
  addYourMoneyPath: PropTypes.string,
  enrollmentStatus: PropTypes.string,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default CollegeStatusDropdown;
