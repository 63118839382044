import PropTypes from "prop-types";

function FinancialAid({ size = 25, color = "fill-black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 29 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666016 7.29814V38.5613H28.5189V0.484375H7.19612L0.666016 7.29814ZM6.39652 4.62607V6.49652H4.53077L6.39652 4.62607ZM26.1201 36.29H2.93156V8.90138H8.66207V2.88923H26.1201V36.29Z"
        fill={color}
      />
      <path
        d="M23.7215 6.49658H11.1943V8.76784H23.7215V6.49658Z"
        fill={color}
      />
      <path d="M23.7215 11.707H5.46387V13.9783H23.7215V11.707Z" fill={color} />
      <path
        d="M23.7214 16.7839H5.19727V19.0552H23.7214V16.7839Z"
        fill={color}
      />
      <path
        d="M23.7218 21.9946H16.5254V24.2659H23.7218V21.9946Z"
        fill={color}
      />
      <path
        d="M23.7218 27.2051H16.5254V29.4763H23.7218V27.2051Z"
        fill={color}
      />
      <path d="M23.7218 32.282H16.5254V34.5532H23.7218V32.282Z" fill={color} />
      <path
        d="M10.1281 25.6019C10.3946 25.6019 10.9277 25.6019 10.9277 26.4035H12.1271H13.3265C13.3265 25.3347 12.7935 23.9987 11.3275 23.4643V21.9946H8.9287V23.4643C7.72929 23.8651 6.92969 24.9339 6.92969 26.2699C6.92969 28.274 8.5289 28.942 9.59504 29.2092C10.7944 29.61 10.9277 29.7436 10.9277 30.4116C10.9277 30.6788 10.9277 30.9461 10.1281 30.9461C9.46177 30.9461 9.19524 30.8124 9.19524 30.0108H6.92969C6.92969 31.6141 7.72929 32.6829 8.9287 33.0837V34.5533H11.1942V33.2173C12.3937 32.8165 13.1933 31.8813 13.1933 30.5452C13.1933 28.1404 11.3275 27.606 10.2614 27.2052C9.19524 26.8043 9.19524 26.6707 9.19524 26.4035C9.19524 25.8691 9.3285 25.6019 10.1281 25.6019Z"
        fill={color}
      />
    </svg>
  );
}

FinancialAid.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default FinancialAid;
