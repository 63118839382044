import PropTypes from "prop-types";

import FilterByType from "../College/Search/FilterByType";

export default function TypeFilterModal({ filters, onClose, religiousTypes }) {
  return (
    <div
      className="
        py-5
        flex
        px-10
        flex-1
        flex-col
        mobile:px-5
        tablet-sm:px-5
        overflow-hidden
      "
    >
      <FilterByType
        isModal={true}
        filters={filters || {}}
        religiousTypes={religiousTypes}
        onSubmit={({ type, filters }) => onClose({ type, filters })}
      />
    </div>
  );
}

TypeFilterModal.propTypes = {
  onClose: PropTypes.func,
  filters: PropTypes.shape(),
  religiousTypes: PropTypes.arrayOf(PropTypes.shape()),
};
