import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import CollegeStatusDropdown from "../../Detail/AffordabilityBox/CollegeStatusDropdown";

import { updateStudentFlags } from "../../../../redux/Student/actions";
import { useGetChosenSchool } from "../../../../redux/Student/hooks";

import { CoveredOutOf } from "../../../../core/compose/affordability";
import { SmallStar, Question } from "../../../../core/icons";

import { ButtonTooltip } from "../../../../core/compose/";
import { Directions } from "../../../../core/Tooltip";

import {
  getTheme,
  getColors,
  Ethnicity,
  CollegeType,
  ethnicityDisplay,
  SchoolStatus
} from "../../../../core/utils";

const collegeMapDictionary = {
  SMALL: "Small",
  MEDIUM: "Medium",
  LARGE: "Large",
  PUBLIC: "Public",
  PRIVATE: "Private",
  FOR_PROFIT: "For-Profit"
};

function CollegeItem({
  id,
  student,
  college,
  distance,
  openModal,
  showTooltip,
  tooltipText,
  showUploadTutorial
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const chosenSchool = useGetChosenSchool({ ipedsId: college.ipedsId });

  const [tooltipOptions, setTooltipOptions] = useState(null);

  const affordability = college.affordability;
  const theme = getTheme({ size: 25, strokeColor: true, affordability });
  const colors = getColors(theme.color);

  const addYourMoneyPath = `/college/add-your-money/${college?.ipedsId}${
    !showUploadTutorial ? "/upload-images" : ""
  }`;

  useEffect(() => {
    if (id === "college-item-0") {
      setTooltipOptions({
        text: "Click on any college card to open the detailed college view and upload an award letter. You can, also, update your status for this school here.",
        open: !student.onboardingFirstSchoolTooltip,
        onClose:
          student.onboardingFirstSchoolTooltip === false
            ? () => {
                dispatch(
                  updateStudentFlags({
                    ...student,
                    onboardingFirstSchoolTooltip: true
                  })
                );
              }
            : null
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student, id]);

  if (!chosenSchool) return null;

  const { schoolCosts, schoolStatus } = chosenSchool;

  const lifeBudget = schoolCosts?.lifeBudget || 0;
  const totalCollegeCosts = schoolCosts?.totalCollegeCosts || 0;
  const totalCost = totalCollegeCosts + lifeBudget;

  const totalAward =
    chosenSchool.schoolStatus === SchoolStatus.ESTIMATED
      ? chosenSchool?.estimatedPostAwardCosts?.totalAward
      : chosenSchool?.award?.postAwardSchoolCosts?.totalAward || 0;

  const ethnicity = student.ethnicity;
  const diversityText =
    !ethnicity || ethnicity === Ethnicity.WHITE
      ? `of students identify as students of color.`
      : `of students identify as students of color—of
          which ${
            schoolCosts.studentDiversityPercentage
          }% of students identify as
          ${ethnicityDisplay(ethnicity)}.
        `;

  const handleCardOnClick = () => navigate(`/college/id/${college.ipedsId}`);

  return (
    <div
      className="
        p-2
        w-1/3
        mobile:w-full
        mobile-sm:w-full
        tablet-sm:w-full
        tablet:w-1/2
      "
    >
      <div
        role="button"
        id={`${id}-card`}
        className="flex flex-col p-4 bg-white"
      >
        <div className="flex flex-row space-x-2 items-center">
          <div id={`${id}-logo`} onClick={() => handleCardOnClick()}>
            <div
              className={`
                flex
                border
                items-center
                border-black
                justify-center
                ${!college.logo ? "bg-white" : ""}
              `}
              style={{
                width: 100,
                height: 100,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${college.logo})`
              }}
            >
              {!college.logo && (
                <span
                  className="
                    text-5xl
                    text-black
                    font-semibold
                    mobile:text-3xl
                    mobile-sm:text-2xl
                    tablet-sm:text-4xl
                  "
                >
                  {college.name[0]}
                </span>
              )}
            </div>
          </div>
          <div className="h-28 flex flex-1 flex-col justify-between">
            <div
              id={`${id}-info`}
              className="flex flex-col"
              onClick={() => handleCardOnClick()}
            >
              <div className="flex flex-row space-x-2 items-center">
                <p className="text-lg leading-none font-bold line-clamp-2">
                  {college.name}
                </p>
                {college.isFavorite && (
                  <SmallStar size={18} fillColor="fill-alternative-yellow" />
                )}
                {college.isNew && (
                  <div
                    className="
                      px-1
                      text-xs
                      font-bold
                      text-black
                      bg-alternative-yellow
                    "
                  >
                    New!
                  </div>
                )}
              </div>
              <p className="text-sm">
                {college?.city && `${college.city},`} {college.state}
              </p>
            </div>
            <div className="flex flex-row my-2">
              <CollegeStatusDropdown
                iconSize={18}
                color={theme.color}
                openModal={openModal}
                chosenSchool={chosenSchool}
                className="w-max-auto"
                addYourMoneyPath={addYourMoneyPath}
                labelClassName="text-xs laptop:text-2xs"
                enrollmentStatus={college.enrollmentStatus}
              />
              {showTooltip && (
                <div className="flex items-center justify-center">
                  <ButtonTooltip
                    tooltipColor={theme.color}
                    tooltipCloseButton={true}
                    tooltipCloseOnClickOutside={true}
                    text={tooltipText}
                    tooltipOptions={tooltipOptions}
                    tooltipDirection={Directions.TOP_RIGHT}
                    tooltipClassName="right-0 top-16"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          id={`${id}-type`}
          onClick={() => handleCardOnClick()}
          className="my-2 flex flow-row divide-x  divide-black"
        >
          <div className="px-2 font-bold">
            {collegeMapDictionary[college.control]}
          </div>
          <div className="px-2 font-bold">
            {collegeMapDictionary[college.size]}
          </div>
          <div className="px-2 font-bold">{college.areaType}</div>
          <div className="px-2 font-bold">
            {`${
              college.programLength === CollegeType.FOUR_YEARS ? "4" : "2"
            }yr`}
          </div>
        </div>
        <div
          id={`${id}-detail`}
          onClick={() => handleCardOnClick()}
          className="flex flex-1 flex-col space-y-1 justify-end"
        >
          <div
            className={`
              mt-2
              mb-4
              flex
              flex-row
              font-bold
              items-center
              laptop:text-sm
              mobile-sm:text-sm
              ${colors.primary.text}
            `}
          >
            {schoolStatus === SchoolStatus.READY ||
            schoolStatus === SchoolStatus.GRANTS_REVIEWED ? (
              <>
                <div>{theme.icon}</div>
                <div className="ml-2">Affordability:</div>
                <div className="ml-2">{theme.label}</div>
              </>
            ) : (
              <>
                <div>
                  <Question
                    size={25}
                    iconColor="fill-white"
                    fillColor="fill-primary-blue"
                    strokeColor="stroke-primary-blue"
                  />
                </div>
                <div className="ml-2">Information Needed</div>
              </>
            )}
          </div>
          {schoolStatus === SchoolStatus.READY ||
          schoolStatus === SchoolStatus.GRANTS_REVIEWED ? (
            <CoveredOutOf
              tooltip={false}
              color={theme.color}
              award={chosenSchool?.award}
              totalSchoolCosts={totalCost}
              totalAward={totalAward}
            />
          ) : (
            <CoveredOutOf
              tooltip={false}
              color={theme.color}
              totalSchoolCosts={totalCost}
              totalAward={totalAward}
              award={{ postAwardSchoolCosts: { totalAward: 0 } }}
            />
          )}
          <div>
            <span className="font-bold">
              {`${schoolCosts.diversityPercentage}% `}
            </span>
            {diversityText}
          </div>
          <div>{distance}</div>
        </div>
      </div>
    </div>
  );
}

CollegeItem.propTypes = {
  id: PropTypes.string.isRequired,
  student: PropTypes.object.isRequired,
  college: PropTypes.object.isRequired,
  distance: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  showTooltip: PropTypes.bool.isRequired,
  tooltipText: PropTypes.string.isRequired,
  showUploadTutorial: PropTypes.bool.isRequired
};

export default CollegeItem;
