import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";
import composeRefs from "@seznam/compose-react-refs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CollegeCard from "./CollegeCard";

import { ItemsCounter } from "../../../core";
import { AffordabilitySquare } from "../../../core/compose/affordability";

import { ArrowNav, SmallStar } from "../../../core/icons";

import { SortOrderColleges } from "../../../utils/SortOrderColleges";
import {
  CompareSchoolArrow,
  CompareSchoolsSquare,
  CompareSchoolsStar
} from "../../../utils/DataLayers";
import { GetIpedsId } from "../../Common/Hooks";

function CollegeContainer({
  schools,
  heights,
  favorite,
  ethnicity,
  className,
  studentState,
  sectionsState,
  selectAsFavorite,
  setGlobalHeights,
  unselectAsFavorite,
  showUploadTutorial,
  setGlobalSectionState,
  setNewSortOrderColleges
}) {
  const scroll = useRef(null);
  const [offsetIndex, setOffsetIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [schoolsLocal, setSchoolsLocal] = useState([]);
  const [favoriteLocal, setFavoriteLocal] = useState(null);

  const ipedsId = GetIpedsId();

  useEffect(() => {
    setSchoolsLocal(schools);
    setFavoriteLocal(favorite);
  }, [schools, favorite]);

  useEffect(() => {
    if (scroll.current) {
      const scrollableWidth =
        (scroll.current.getBoundingClientRect().width - 24) / 408;

      setOffsetIndex(scrollableWidth);
    }
  }, [schoolsLocal]);

  useEffect(() => {
    if (scroll.current) {
      const scrollOffset = currentIndex * 408;
      scroll.current.scrollTo({ left: scrollOffset, behavior: "smooth" });
    }
  }, [scroll, currentIndex]);

  const selectAsFavoriteLocal = (college) => {
    CompareSchoolsStar({ ipeds: ipedsId });
    const newSchools = SortOrderColleges({
      college,
      isFavorite: true,
      newSortOrder: college.sortOrder,
      colleges: favoriteLocal ? [favoriteLocal, ...schoolsLocal] : schoolsLocal
    });
    setFavoriteLocal(newSchools.find((school) => school.isFavorite));
    setSchoolsLocal(newSchools.filter((school) => !school.isFavorite));
    selectAsFavorite(college);
  };

  const unselectAsFavoriteLocal = () => {
    const newSchools = SortOrderColleges({
      isFavorite: false,
      college: favoriteLocal,
      newSortOrder: favoriteLocal.sortOrder,
      colleges: [favoriteLocal, ...schoolsLocal]
    });
    setFavoriteLocal(null);
    setSchoolsLocal(newSchools);
    unselectAsFavorite(favoriteLocal);
  };

  const next = () => {
    CompareSchoolArrow({ ipeds: ipedsId });
    setCurrentIndex((index) => {
      return index < schoolsLocal.length - offsetIndex ? index + 1 : index;
    });
  };

  const previous = () => {
    CompareSchoolArrow({ ipeds: ipedsId });
    setCurrentIndex((index) => {
      return index !== 0 ? index - 1 : index;
    });
  };

  const navigateTo = (index) => {
    CompareSchoolsSquare({ ipeds: ipedsId });
    setCurrentIndex(
      index < schoolsLocal.length - offsetIndex
        ? index
        : schoolsLocal.length - offsetIndex
    );
  };

  const onDragEnd = (res) => {
    const { source, destination } = res;
    if (
      !destination ||
      source.index === destination.index ||
      source.droppableId !== destination.droppableId
    )
      return;

    const startIndex = source.index;
    const endIndex = destination.index;
    const newSchools = SortOrderColleges({
      sort: false,
      colleges: schoolsLocal,
      college: schoolsLocal[startIndex],
      newSortOrder: schoolsLocal[endIndex].sortOrder
    });
    const newSchoolsLocal = Array.from(newSchools);
    const [removed] = newSchoolsLocal.splice(startIndex, 1);
    newSchoolsLocal.splice(endIndex, 0, removed);
    setSchoolsLocal(newSchoolsLocal);
    setCurrentIndex(endIndex);
    setNewSortOrderColleges(
      schoolsLocal[startIndex],
      schoolsLocal[endIndex].sortOrder
    );
  };

  if (schoolsLocal.length === 0 && !favoriteLocal) return null;

  return (
    <div className={className}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="py-2">
          <div className="flex flex-1 flex-row">
            {favoriteLocal && (
              <div
                id="compare-schools-favorite-container"
                className="mx-4 w-24rem min-w-24rem"
              >
                <h2 className="text-2xl font-bold">Compare Schools</h2>
                <p className="text-sm font-bold capitalize">your favorite:</p>
              </div>
            )}
            <div className="flex flex-1 flex-col overflow-hidden">
              <div
                id="compare-schools-container"
                className="flex flex-row items-center"
              >
                {!favoriteLocal && (
                  <h2 className="mr-5 text-2xl font-bold">Compare Schools</h2>
                )}
                <button
                  onClick={previous}
                  className="my-1 py-1 px-2"
                  aria-label="arrow-left-nav"
                  id="compare-left-arrow-button"
                >
                  <ArrowNav size={20} />
                </button>
                <button
                  onClick={next}
                  aria-label="arrow-right-nav"
                  id="compare-right-arrow-button"
                  className="my-1 py-1 px-2 transform rotate-180"
                >
                  <ArrowNav size={20} />
                </button>
                <div className="ml-5 mr-2">
                  <ItemsCounter
                    pluralDescriptionItems="Schools"
                    singularDescriptionItems="School"
                    numberItems={
                      favoriteLocal
                        ? schoolsLocal.length + 1
                        : schoolsLocal.length
                    }
                  />
                </div>
                <div
                  className="
                    flex
                    flex-row
                    divide-x-2
                    items-center
                    divide-black
                    overflow-hidden
                  "
                >
                  {favoriteLocal && (
                    <div id="school-favorite-icon">
                      <AffordabilitySquare
                        className="mr-2"
                        data-tooltip-id="college-thumbnail-squares"
                        data-tooltip-content={favoriteLocal?.school?.name}
                        coverage={
                          favoriteLocal.award?.postAwardSchoolCosts?.coverage
                        }
                        affordability={
                          favoriteLocal.award?.postAwardSchoolCosts
                            ?.affordability
                        }
                      />
                    </div>
                  )}
                  <div id="compare-schools-icons" className="overflow-hidden">
                    <Droppable
                      direction="horizontal"
                      droppableId="colleges-squares"
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={`
                            flex
                            flex-row
                            items-center
                            overflow-x-auto
                            ${favoriteLocal && "pl-2"}
                          `}
                        >
                          {schoolsLocal.map((school, index) => (
                            <Draggable
                              index={index}
                              key={`square-${school.school.ipedsId}`}
                              draggableId={`square-${school.school.ipedsId}`}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <AffordabilitySquare
                                    key={index}
                                    id="college-item-square"
                                    className="cursor-pointer"
                                    data-tooltip-content={school?.school?.name}
                                    data-tooltip-id="college-thumbnail-squares"
                                    coverage={
                                      school.award?.postAwardSchoolCosts
                                        ?.coverage
                                    }
                                    affordability={
                                      school.award?.postAwardSchoolCosts
                                        ?.affordability
                                    }
                                    onClick={() => navigateTo(index)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </div>
              </div>
              <p className="text-sm font-bold normal-case">
                Select and move to reorder your list or star{" "}
                <span className="inline-block">
                  <SmallStar size={15} fillColor="fill-alternative-yellow" />
                </span>{" "}
                your favorite
              </p>
            </div>
          </div>
        </div>
        <div
          className="
            flex
            flex-1
            flex-row
            divide-x-2
            divide-black
            overflow-hidden
          "
        >
          {favoriteLocal && (
            <div className="py-3">
              <CollegeCard
                heights={heights}
                marginRight="mr-4"
                ethnicity={ethnicity}
                school={favoriteLocal}
                studentState={studentState}
                setHeights={setGlobalHeights}
                sectionsState={sectionsState}
                id="college-item-card-favorite"
                showUploadTutorial={showUploadTutorial}
                setSectionsState={setGlobalSectionState}
                selectAsFavorite={() => unselectAsFavoriteLocal()}
              />
            </div>
          )}
          <Droppable droppableId="colleges" direction="horizontal">
            {(provided) => (
              <div
                id="compare-schools-items"
                className={`
                  py-3
                  flex
                  flex-row
                  box-border
                  align-center
                  justify-start
                  overflow-x-auto
                  ${favoriteLocal && "pl-4"}
                `}
                ref={composeRefs(provided.innerRef, scroll)}
                {...provided.droppableProps}
              >
                {schoolsLocal.map((school, index) => {
                  return (
                    <Draggable
                      index={index}
                      key={school.school.ipedsId}
                      draggableId={school.school.ipedsId}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          className="outline-none"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          id={`college-item-card-${index}`}
                        >
                          <CollegeCard
                            school={school}
                            heights={heights}
                            ethnicity={ethnicity}
                            studentState={studentState}
                            setHeights={setGlobalHeights}
                            sectionsState={sectionsState}
                            id={`college-item-card-${index}`}
                            setSectionsState={setGlobalSectionState}
                            showUploadTutorial={showUploadTutorial}
                            selectAsFavorite={(college) =>
                              selectAsFavoriteLocal(college)
                            }
                            marginRight={
                              index === schoolsLocal.length - 1 ? "mr-0" : null
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <Tooltip
        place="bottom"
        effect="solid"
        delayShow={500}
        id="college-thumbnail-squares"
        getContent={(schoolName) => {
          return (
            <div className="text-center">
              <p>{schoolName}</p>
              {schoolName !== favoriteLocal?.school?.name && (
                <p className="text-xs text-gray-500">(Drag to reorder)</p>
              )}
            </div>
          );
        }}
      />
      <Tooltip
        place="bottom"
        effect="solid"
        delayShow={500}
        id="enrollment-icon-label"
        getContent={(statusName) => {
          return (
            <div className="text-center">
              <p className="text-xsm font-bold capitalize">{statusName}</p>
            </div>
          );
        }}
      />
    </div>
  );
}

CollegeContainer.propTypes = {
  schools: PropTypes.array.isRequired,
  heights: PropTypes.object.isRequired,
  favorite: PropTypes.object,
  ethnicity: PropTypes.string,
  className: PropTypes.string.isRequired,
  studentState: PropTypes.string.isRequired,
  sectionsState: PropTypes.object.isRequired,
  selectAsFavorite: PropTypes.func.isRequired,
  setGlobalHeights: PropTypes.func.isRequired,
  unselectAsFavorite: PropTypes.func.isRequired,
  showUploadTutorial: PropTypes.bool.isRequired,
  setGlobalSectionState: PropTypes.func.isRequired,
  setNewSortOrderColleges: PropTypes.func.isRequired
};

export default CollegeContainer;
