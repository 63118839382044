import PropTypes from "prop-types";

import { Check } from "../../../../../core/icons";

function AddToMyListButton({
  id,
  add,
  handlerAddCollege,
  handlerRemoveCollege,
}) {
  return (
    <div
      className="
        w-40
        flex
        flex-col
        items-center
        justify-center
      "
    >
      {add ? (
        <div
          role="button"
          onClick={handlerAddCollege}
          id={`${id}-add-to-my-list-button`}
          className="
            py-3
            w-full
            border
            text-center
            text-white
            bg-primary-green
            border-primary-green
            hover:bg-white
            hover:text-primary-green
            hover:border-primary-green
          "
        >
          <p className="text-sm font-bold">Add to My List</p>
        </div>
      ) : (
        <div
          role="button"
          onClick={handlerRemoveCollege}
          id={`${id}-on-my-list-button`}
          className="
            py-3
            px-2
            flex
            w-full
            border
            flex-row
            items-center
            justify-center
            text-primary-green
            border-primary-green
          "
        >
          <div className="w-5 mr-1">
            <Check size={20} color="fill-current" />
          </div>
          <p className="text-sm font-bold capitalize">On my list</p>
        </div>
      )}
    </div>
  );
}

AddToMyListButton.propTypes = {
  add: PropTypes.bool,
  id: PropTypes.string,
  handlerAddCollege: PropTypes.func,
  handlerRemoveCollege: PropTypes.func,
};

export default AddToMyListButton;
