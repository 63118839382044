import PropTypes from "prop-types";

function CollegeItem({
  id,
  college,
  isDesktop,
  selectedSchool,
  handleOnCollegeSelect,
}) {
  function addCollegeClasses(college, selectedSchool) {
    return college.ipedsId === selectedSchool?.school?.ipedsId
      ? "shadow-black border-4 border-primary-green "
      : "border-1 border-black hover:shadow-black";
  }

  const styleLogo = college.logo
    ? {
        backgroundImage: `url('${college.logo}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }
    : {};

  return (
    <div
      id={id}
      role="button"
      data-tooltip-content={college.name}
      onClick={handleOnCollegeSelect}
      data-tooltip-id="college-thumbnail-selector"
      className={`
        mx-1
        flex
        border
        flex-none
        items-center
        overflow-auto
        justify-center
        ${!college.logo && "bg-white"}
        ${addCollegeClasses(college, selectedSchool)}
      `}
      style={{
        ...styleLogo,
        width: isDesktop ? 105 : 70,
        height: isDesktop ? 105 : 70,
      }}
    >
      {!college.logo && (
        <span
          className="
            text-6xl
            font-semibold
            mobile:text-4xl
            tablet:text-4xl
            mobile-sm:text-4xl
            tablet-sm:text-4xl
          "
        >
          {college.name[0]}
        </span>
      )}
    </div>
  );
}

CollegeItem.propTypes = {
  id: PropTypes.string,
  isDesktop: PropTypes.bool,
  college: PropTypes.shape(),
  selectedSchool: PropTypes.shape(),
  handleOnCollegeSelect: PropTypes.func,
};

export default CollegeItem;
