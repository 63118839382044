import PropTypes from "prop-types";

import { InfiniteScroll } from "../../";

import OrgsListModal from "./OrgsListModal";
import UsersListModal from "./UsersListModal";
import GroupsListModal from "./GroupsListModal";
import StudentListGroup from "./StudentListGroup";
import { ListTypes } from "./utils";

function ItemsList({
  type,
  data,
  scrollId,
  className = "",
  isLoading = false,
  handlePagination = () => {},
  ...restProps
}) {
  const getList = (type, data, isLoading) => {
    switch (type) {
      case ListTypes.ADVISOR_STUDENT_LIST_GROUP:
        return <StudentListGroup data={data} isLoading={isLoading} />;
      case ListTypes.ADVISOR_GROUPS_LIST_MODAL:
        return (
          <GroupsListModal data={data} isLoading={isLoading} {...restProps} />
        );
      case ListTypes.ADVISOR_STUDENTS_LIST_MODAL:
        return (
          <UsersListModal
            data={data}
            isLoading={isLoading}
            type={ListTypes.ADVISOR_STUDENTS_LIST_MODAL}
            {...restProps}
          />
        );
      case ListTypes.ADVISOR_ADVISORS_LIST_MODAL:
        return (
          <UsersListModal
            data={data}
            isLoading={isLoading}
            type={ListTypes.ADVISOR_ADVISORS_LIST_MODAL}
            {...restProps}
          />
        );
      case ListTypes.STUDENT_ORGANIZATIONS_LIST_MODAL:
        return (
          <OrgsListModal data={data} isLoading={isLoading} {...restProps} />
        );
      default:
        return <div>Item List</div>;
    }
  };

  return (
    <>
      <InfiniteScroll
        className={className}
        handlePagination={handlePagination}
        querySelector={`screenViewportItemsList-${scrollId}`}
      >
        {getList(type, data, isLoading)}
      </InfiniteScroll>
    </>
  );
}

ItemsList.propTypes = {
  isLoading: PropTypes.bool,
  scrollId: PropTypes.string,
  className: PropTypes.string,
  handlePagination: PropTypes.func,
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired
};

export default ItemsList;
