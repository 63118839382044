import PropTypes from "prop-types";

function Option({ icon, text, onClick, selected }) {
  const selectedStyle = selected 
  ? "bg-primary-green text-white" 
  : "bg-white text-primary-green border-primary-green";

  return (
    <div
      className={`
        p-4
        flex
        border
        flex-row
        items-center
        hover:bg-primary-green
        hover:text-white
        ${selectedStyle}
      `}
      onClick={onClick}
    >
      <div>{icon}</div>
      <p className="ml-2 font-semibold">{text}</p>
    </div>
  );
}

Option.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default Option;
