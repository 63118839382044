import { useEffect } from "react";
import PropTypes from "prop-types";

import SearchResultsBody from "./SearchResultsBody";
import SearchResultsHeader from "./SearchResultsHeader";
import DoubleCollegeSelector from "./DoubleCollegeSelector";

import { useStudent } from "../../../../redux/Student/hooks";
import { PageViewDataLayer } from "../../../../utils/DataLayers";
import { GetIpedsId } from "../../../Common/Hooks";

function SearchResults({
  sortBy,
  filters,
  isLoading,
  collegesCount,
  chosenSchools,
  resultSchools,
  religiousTypes,
  handlerPagination,
  handlerOnSubmitSortBy,
  handlerOnClearFilters,
  handlerOnSubmitFilters,
}) {
  const { student } = useStudent();
  const ipedsId = GetIpedsId();

  useEffect(() => {
    if (student) {
      PageViewDataLayer({
        ipeds: ipedsId,
        userId: student.userId,
        screenName: "Explore Colleges",
        countOfSearchResults: collegesCount,
        searchType: filters.hasOwnProperty("type"),
        searchMajor: filters.hasOwnProperty("major"),
        searchState: filters.hasOwnProperty("location"),
        organizationId: student.organizations.map((org) => org.organizationId),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-8 flex flex-1 flex-col">
      {chosenSchools.length > 0 && (
        <DoubleCollegeSelector
          colleges={chosenSchools.map((x) => ({
            ...x.school,
            isNew: x.isNew,
            sortOrder: x.sortOrder,
            isFavorite: x.isFavorite,
          }))}
        />
      )}
      <SearchResultsHeader
        sortBy={sortBy}
        filters={filters}
        collegesCount={collegesCount}
        religiousTypes={religiousTypes}
        handlerOnSubmitSortBy={handlerOnSubmitSortBy}
        handlerOnClearFilters={handlerOnClearFilters}
        handlerOnSubmitFilters={handlerOnSubmitFilters}
      />
      <SearchResultsBody
        isLoading={isLoading}
        colleges={resultSchools}
        religiousTypes={religiousTypes}
        handlerPagination={handlerPagination}
        handlerOnClearFilters={handlerOnClearFilters}
        selectedColleges={chosenSchools.map((x) => ({
          award: x.award,
          pairedId: x.pairedId,
          ipedsId: x.school.ipedsId,
        }))}
      />
    </div>
  );
}

SearchResults.propTypes = {
  sortBy: PropTypes.shape(),
  isLoading: PropTypes.bool,
  filters: PropTypes.shape(),
  collegesCount: PropTypes.number,
  handlerPagination: PropTypes.func,
  handlerOnClearFilters: PropTypes.func,
  handlerOnSubmitSortBy: PropTypes.func,
  handlerOnSubmitFilters: PropTypes.func,
  chosenSchools: PropTypes.arrayOf(PropTypes.shape()),
  resultSchools: PropTypes.arrayOf(PropTypes.shape()),
  religiousTypes: PropTypes.arrayOf(PropTypes.shape()),
};

export default SearchResults;
