import PropTypes from "prop-types";

function MapChart({
  size = 25,
  iconColor = "fill-black",
  fillColor = "fill-white",
  strokeColor = "stroke-black"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="0.8"
        className={`${fillColor} ${strokeColor}`}
        d="M11.0966 0.485824L16.9848 1.61821L16.9848 16.2529L11.3406 15.1241L11.0966 0.485824Z"
      />
      <path
        strokeWidth="0.8"
        className={`${fillColor} ${strokeColor}`}
        d="M0.400208 0.487921L6.03896 1.61567L6.03896 16.3408L5.839 16.3408L0.400207 15.1321L0.400208 0.487921Z"
      />
      <path
        strokeWidth="0.8"
        className={`${fillColor} ${strokeColor}`}
        d="M6.38959 16.3408L11.1885 15.1411L11.1267 0.567751L10.675 0.417183L6.19496 1.59618L6.19496 16.3408L6.38959 16.3408Z"
      />
      <path
        className={iconColor}
        d="M14.9641 2.56921L14.9641 8.42085L15.4531 8.52262L15.4531 2.67098L14.9641 2.56921Z"
      />
      <path
        className={iconColor}
        d="M13.6538 2.39136L13.6538 4.93555L14.1428 5.03732L14.1428 2.49312L13.6538 2.39136Z"
      />
      <path
        className={iconColor}
        d="M9.6582 13.5355L9.6582 2.08667L9.16918 2.163L9.16918 13.6118L9.6582 13.5355Z"
      />
      <path
        className={iconColor}
        d="M8.43433 2.44226L7.94531 2.51859L7.94531 13.2042L8.43433 13.1279L8.43433 2.44226Z"
      />
      <path
        className={iconColor}
        d="M3.86328 8.8536L3.86328 2.49312L3.37426 2.39136L3.37426 8.75183L3.86328 8.8536Z"
      />
      <path
        className={iconColor}
        d="M2.41699 4.83426L2.41699 2.29007L1.92797 2.21375L1.92797 4.75794L2.41699 4.83426Z"
      />
    </svg>
  );
}

MapChart.propTypes = {
  size: PropTypes.number,
  iconColor: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string
};

export default MapChart;
