import PropTypes from "prop-types";

import { GraduationRateCard as CommonGraduationRateCard } from "../../../../core/compose/cards";

function GraduationRateCard({
  id,
  open,
  chosenSchool,
  externalToggle,
  callbackToggle,
  ethnicityStudent,
}) {
  return (
    <CommonGraduationRateCard
      id={id}
      open={open}
      chosenSchool={chosenSchool}
      externalToggle={externalToggle}
      callbackToggle={callbackToggle}
      ethnicityStudent={ethnicityStudent}
    />
  );
}

GraduationRateCard.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  externalToggle: PropTypes.bool,
  callbackToggle: PropTypes.func,
  chosenSchool: PropTypes.shape(),
  ethnicityStudent: PropTypes.string,
};

export default GraduationRateCard;
