import PropTypes from "prop-types";

function ActionRow({ id = "", text, onClick, actionText = "Leave" }) {
  return (
    <div
      id={`title-row-${id}`}
      className="p-2 flex flex-1 flex-row items-center text-black"
    >
      <p className="flex flex-1 text-sm font-bold">{text}</p>
      <div
        tabIndex={1}
        role="button"
        onClick={onClick}
        id={`action-row-onclick-${id}`}
        className="flex flex-row items-center hover:underline"
      >
        <p className="ml-1 text-xs font-semibold">{actionText}</p>
      </div>
    </div>
  );
}

ActionRow.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  actionText: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default ActionRow;
