import PropTypes from "prop-types";

import { Button } from "../../../../../core/buttons";
import { UnhappyFace } from "../../../../../core/icons";
import { StarsPattern } from "../../../../../core/icons/patterns";

import { decidedRouteSupport } from "../../../../../core/utils";

function Description({ text, children }) {
  return (
    <div className="flex flex-1 flex-row items-center justify-center text-center">
      <p className="text-2xl mobile:text-xl mobile-sm:text-xl tablet-sm:text-xl">
        {text}
      </p>
      {children}
    </div>
  );
}

Description.propTypes = {
  text: PropTypes.string
};

function SearchResultsNoFound({ handlerOnClearFilters }) {
  return (
    <div
      className="
        flex
        py-12
        px-12
        flex-1
        flex-row
        relative
        bg-white
        items-center
        justify-center
        mobile:px-4
        mobile:py-24
        mobile-sm:px-4
        mobile-sm:py-24
        tablet-sm:px-4
        tablet-sm:py-24
      "
    >
      <div className="top-0 left-5 absolute">
        <StarsPattern className="transform -rotate-90" />
      </div>
      <div className="right-5 bottom-0 absolute">
        <StarsPattern className="transform -rotate-90" />
      </div>
      <div
        className="
          w-1/2
          flex
          flex-col
          font-bold
          mobile:w-full
          mobile-sm:mt-10
          mobile-sm:w-full
          tablet-sm:w-full
        "
      >
        <Description text="We're sorry.">
          <div className="mx-2">
            <UnhappyFace size={30} color="fill-primary-green" />
          </div>
        </Description>
        <Description text="No results match the current filter criteria." />
        <Description text="We suggest refining your search for more results." />
        <div className="mt-10 flex flow-row justify-center">
          <Button
            onClick={() => handlerOnClearFilters()}
            className="
              w-48
              normal-case
              text-white
              bg-primary-green
              border-primary-green
              hover:bg-white
              active:bg-white
              hover:text-primary-green
              active:text-primary-green
              mobile:text-sm
              mobile-sm:text-sm
              tablet-sm:text-sm
            "
          >
            Clear All Filters
          </Button>
        </div>
        <p className="mt-10 font-normal text-center">
          Why can't I find a particular school in DecidED?
        </p>
        <div className="mt-5 flex flow-row justify-center">
          <Button
            onClick={() =>
              window.open(
                decidedRouteSupport(
                  "/articles/8131482600468-Why-can-t-I-find-a-particular-school-in-DecidED-"
                ),
                "_blank"
              )
            }
            className="
              w-48
              bg-white
              normal-case
              text-primary-green
              border-primary-green
              hover:text-white
              active:text-white
              hover:bg-primary-green
              active:bg-primary-green
              mobile:text-sm
              mobile-sm:mb-10
              mobile-sm:text-sm
              tablet-sm:text-sm
            "
          >
            Read Our FAQ
          </Button>
        </div>
      </div>
    </div>
  );
}

SearchResultsNoFound.propTypes = {
  handlerOnClearAllFilters: PropTypes.func
};

export default SearchResultsNoFound;
