import { createContext, useState } from "react";

import Modal from "../components/Common/Modal";

const ModalContext = createContext({
  show: false,
  modalResponse: "",
  modalResponseData: {},
  openModalHandler: () => {},
  clearModalHandler: () => {},
  closeModalHandler: () => {},
  modalData: { type: "", data: {}, className: "" }
});

export default ModalContext;

export function ModalContextProvider({ children }) {
  const clearModalHandler = () =>
    setModalContext((state) => ({
      ...state,
      modalResponse: "",
      modalResponseData: {}
    }));

  const openModalHandler = ({ data, type, className }) => {
    setModalContext((state) => ({
      ...state,
      show: true,
      modalData: { data, type, className }
    }));
  };

  const closeModalHandler = (modalType, modalData) => {
    setModalContext((state) => ({
      ...state,
      show: false,
      modalResponse: modalType,
      modalResponseData: modalData,
      modalData: { type: "", data: {}, className: "" }
    }));
  };

  const [modalContext, setModalContext] = useState({
    show: false,
    openModalHandler,
    clearModalHandler,
    closeModalHandler,
    modalResponse: "",
    modalResponseData: {},
    modalData: { type: "", data: {}, className: "" }
  });

  return (
    <ModalContext.Provider value={modalContext}>
      {children}
      <ModalContext.Consumer>
        {({ show, modalData, closeModalHandler }) => (
          <Modal
            show={show}
            modalData={modalData}
            closeModal={closeModalHandler}
            clearModal={clearModalHandler}
          />
        )}
      </ModalContext.Consumer>
    </ModalContext.Provider>
  );
}
