import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { MagnifyGlassSimple } from "../icons";
import { Colors, getColors } from "../utils";

function SearchTextSimple({
  id,
  color = Colors.DEFAULT,
  placeholder,
  defaultValue,
  handlerOnSubmit
}) {
  const [search, setSearch] = useState("");

  useEffect(() => setSearch(defaultValue || ""), [defaultValue]);

  const handlerOnSearch = (event) => {
    event.persist();
    setSearch(event.currentTarget.value);
  };

  const handlerOnKeyDown = (event) => {
    if (event.key === "Enter") handlerOnClick();
  };

  const handlerOnClick = () => handlerOnSubmit(search);

  const colors = getColors(color);

  return (
    <div
      className="
        pr-2
        flex
        flex-1
        flex-row
        space-x-1
        mobile:pr-0
        mobile-sm:pr-0
        tablet-sm:pr-0
      "
    >
      <div
        className={`
          p-1
          flex
          flex-1
          flex-row
          border-b
          bg-white
          items-center
          border-opacity-50
          focus-within:border-opacity-100
          ${colors.primary.border}
        `}
      >
        <input
          id={id}
          type="text"
          value={search}
          aria-label="searchInput"
          placeholder={placeholder}
          onChange={handlerOnSearch}
          onKeyDown={handlerOnKeyDown}
          className="flex flex-1 outline-none"
        />
      </div>
      <div
        onClick={handlerOnClick}
        className={`
          w-6
          h-6
          flex
          border
          flex-row
          rounded-full
          items-center
          justify-center
          cursor-pointer
          place-self-center
          ${colors.primary.border}
        `}
      >
        <MagnifyGlassSimple size={10} />
      </div>
    </div>
  );
}

SearchTextSimple.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  handlerOnSubmit: PropTypes.func,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default SearchTextSimple;
