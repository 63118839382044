import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { Logo } from "../../core/icons";
import { LoadingButton } from "../../core/buttons";
import { Form } from "../../core";
import { Colors, GradeYearTypes } from "../../core/utils";
import { updateStudent } from "../../redux/Student/actions";

function GradeYearModal({ id = "grade-year-modal", student, onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    firstName: student.firstName,
    lastName: student.lastName,
    gradeYear: null
  };

  const { handleSubmit, control, formState } = useForm({
    mode: "onChange",
    defaultValues
  });

  const logOutHandler = () => {
    navigate("/logout");
    onClose();
  };

  const fetchGradeYearTypes = (search, callback) => {
    const options = GradeYearTypes;

    callback(() =>
      options.filter((option) =>
        option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const account = {
      firstName: data.firstName,
      lastName: data.lastName,
      gradeYear: data.gradeYear.value
    };
    try {
      await dispatch(updateStudent(account));
      setIsLoading(false);
      onClose();
    } catch (error) {
      throw error;
    }
  };

  return (
    <div
      className="
        flex
        p-2
        flex-1
        flex-col
        relative
        max-h-content
        overflow-y-auto
      "
    >
      <div
        className="
          p-16
          mx-10
          flex
          flex-1
          flex-col
          justify-center
          mobile:p-0
          mobile-sm:p-0
          tablet-sm:p-0
          mobile:mx-5
          mobile-sm:mx-5
        "
      >
        <div
          className="
            z-10
            flex
            flex-row
            self-end 
            mobile:hidden
            mobile-sm:hidden
            tablet-sm:hidden
            absolute
            top-4
            right-8
          "
        >
          <Logo color="fill-black" size={50} />
        </div>
        <div className="z-10 flex flex-col items-center">
          <p
            className="
              mx-6
              text-3xl
              text-center
              text-black
              font-extrabold
              mobile:mx-0
              mobile-sm:mx-0
              tablet-sm:mx-0
            "
          >
            Almost done! We have one quick question.
          </p>
          <div className="w-3/4 my-2">
            <p className="font-normal text-black text-center">
              Please confirm that the information below is correct
            </p>
          </div>
          <div className="flex w-full flex-col overflow-none">
            <div className="pb-4 flex flex-row flex-wrap text-xsm font-bold">
              <div className="mr-1 text-primary-green">Email:</div>
              <div className="mx-1 font-medium">{student.email}</div>
              <div
                onClick={logOutHandler}
                className="ml-1 cursor-pointer text-primary-green"
              >
                (not you?)
              </div>
            </div>
            <Form id={id} onSubmit={handleSubmit(onSubmit)}>
              <Form.Input
                name="firstName"
                required={true}
                control={control}
                color={Colors.GREEN}
                labelCapitalize={false}
                errors={formState.errors}
                id="grade-year-firstName-input"
                label="First Name"
                rules={{
                  required: { value: true, message: "First name is required." }
                }}
              />
              <Form.ErrorMessage errors={formState.errors} name="firstName" />

              <Form.Input
                name="lastName"
                required={true}
                control={control}
                color={Colors.GREEN}
                labelCapitalize={false}
                errors={formState.errors}
                id="grade-year-lastName-input"
                label="Last Name"
                rules={{
                  required: { value: true, message: "Last name is required." }
                }}
              />
              <Form.ErrorMessage errors={formState.errors} name="lastName" />

              <Form.Select
                name="gradeYear"
                label="What type of student are you?"
                required={true}
                control={control}
                color={Colors.GREEN}
                loadOptions={fetchGradeYearTypes}
                errors={formState.errors}
                id="grade-year-type-select"
                rules={{
                  required: {
                    value: true,
                    message: "This field is required."
                  }
                }}
              />
              <Form.ErrorMessage errors={formState.errors} name="gradeYear" />
            </Form>
          </div>
          <div className="flex w-full items-center justify-center pt-8">
            <LoadingButton
              form={id}
              isLoading={isLoading}
              type="submit"
              id="button-save-grade-year"
              disabled={!formState.isValid}
              className="
                mt-4
                w-full
                text-white
                bg-primary-green
                border-primary-green
                hover:bg-white
                active:bg-white
                hover:text-primary-green
                active:text-primary-green
              "
            >
              Continue
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
}

GradeYearModal.propTypes = {
  id: PropTypes.string,
  student: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired
};

export default GradeYearModal;
