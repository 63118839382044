import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import AffordabilityChartMobile from "./Mobile";
import AffordabilityChartDesktop from "./Desktop";
import { SchoolStatus } from "../../../../../core/utils";

function AffordabilityChart({
  theme,
  student,
  openModal,
  isDesktop,
  chosenSchool,
  addYourMoneyPath,
  onClickViewAwardLetter,
  onClickOnboardingTooltip,
  onClickReuploadAwardLetter
}) {
  const [chartData, setChartData] = useState([]);
  const [hasAutoEstimatedAid, setHasAutoEstimatedAid] = useState(false);

  useEffect(() => {
    if (isDesktop && chosenSchool) {
      const data = [];
      const {
        tuitionAndFees,
        housingAndMeals,
        booksAndSupplies,
        transportation,
        personalExpenses
      } = chosenSchool.schoolCosts;

      let totalAward = 0;
      if (
        chosenSchool.schoolStatus === SchoolStatus.ESTIMATED ||
        chosenSchool.schoolStatus === SchoolStatus.INVALID
      ) {
        totalAward = chosenSchool.estimatedPostAwardCosts?.totalAward;
        if (chosenSchool.estimatedAids.length > 0) {
          const hasAutoEstimatedAid = chosenSchool.estimatedAids?.some(
            (aid) => aid.categorySource === "LETTER"
          );
          setHasAutoEstimatedAid(hasAutoEstimatedAid);
        }
      } else {
        totalAward = chosenSchool?.award?.postAwardSchoolCosts?.totalAward || 0;
      }

      const categories = [
        { id: "tuition", label: "Tuition", value: tuitionAndFees },
        { id: "housing", label: "Housing", value: housingAndMeals },
        { id: "books", label: "Books", value: booksAndSupplies },
        {
          id: "transportation",
          label: "Transportation",
          value: transportation
        },
        { id: "personal", label: "Personal", value: personalExpenses }
      ];

      for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        if (category.value > 0) {
          let fillPercent = 0;
          if (totalAward > 0) {
            const remainingTotalAward = totalAward - category.value;
            if (remainingTotalAward >= 0) fillPercent = 100;
            else {
              const coverage = category.value - Math.abs(remainingTotalAward);
              fillPercent =
                Math.round(
                  ((coverage * 100) / category.value + Number.EPSILON) * 10
                ) / 10;
            }
            totalAward = remainingTotalAward;
          }

          data.push({
            ...category,
            fillPercent,
            totalPercent:
              Math.round(
                ((category.value * 100) / totalCost + Number.EPSILON) * 10
              ) / 10
          });
        }
      }

      setChartData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenSchool, isDesktop]);

  const affordability =
    chosenSchool?.award?.postAwardSchoolCosts?.affordability || "unknown";

  const totalAward =
    chosenSchool.schoolStatus === SchoolStatus.ESTIMATED ||
    chosenSchool.schoolStatus === SchoolStatus.INVALID
      ? chosenSchool?.estimatedPostAwardCosts?.totalAward
      : chosenSchool?.award?.postAwardSchoolCosts?.totalAward || 0;

  const totalCost = chosenSchool?.schoolCosts?.totalCost || 0;

  const firstYearBreakdownPath = `/college/id/${chosenSchool.school.ipedsId}/first-year-breakdown`;

  return (
    <div className="flex flex-1 flex-col">
      <AffordabilityChartMobile
        theme={theme}
        student={student}
        isDesktop={isDesktop}
        openModal={openModal}
        totalAward={totalAward}
        totalCost={totalCost}
        chosenSchool={chosenSchool}
        award={chosenSchool?.award}
        affordability={affordability}
        addYourMoneyPath={addYourMoneyPath}
        hasAutoEstimatedAid={hasAutoEstimatedAid}
        firstYearBreakdownPath={firstYearBreakdownPath}
        onClickViewAwardLetter={onClickViewAwardLetter}
        onClickReuploadAwardLetter={onClickReuploadAwardLetter}
        onClickOnboardingTooltip={onClickOnboardingTooltip}
      />
      <AffordabilityChartDesktop
        theme={theme}
        student={student}
        isDesktop={isDesktop}
        chartData={chartData}
        totalAward={totalAward}
        totalCost={totalCost}
        chosenSchool={chosenSchool}
        award={chosenSchool?.award}
        affordability={affordability}
        addYourMoneyPath={addYourMoneyPath}
        hasAutoEstimatedAid={hasAutoEstimatedAid}
        onClickViewAwardLetter={onClickViewAwardLetter}
        onClickOnboardingTooltip={onClickOnboardingTooltip}
        onClickReuploadAwardLetter={onClickReuploadAwardLetter}
      />
    </div>
  );
}

AffordabilityChart.propTypes = {
  theme: PropTypes.shape(),
  openModal: PropTypes.func,
  ipedsId: PropTypes.string,
  isDesktop: PropTypes.bool,
  chosenSchool: PropTypes.shape(),
  addYourMoneyPath: PropTypes.string,
  schoolStatus: PropTypes.string,
  onClickViewAwardLetter: PropTypes.func,
  onClickReuploadAwardLetter: PropTypes.func
};

export default AffordabilityChart;
