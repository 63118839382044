import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import CollegeGuide from "../Common/CollegeGuide";

import { Panel } from "../../../core/compose";
import { ShortArrow } from "../../../core/icons";
import { Colors, decidedRoute } from "../../../core/utils";

import { useStudent } from "../../../redux/Student/hooks";

function CollegeGuidesBox({ isDesktop, selectedSchool }) {
  const scroll = useRef(null);

  const { guides } = useStudent();

  const [localGuides, setLocalGuides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (guides) {
      const localGuides = guides.filter((guide) =>
        guide.enrollmentStatuses.includes(selectedSchool.enrollmentStatus)
      );
      setLocalGuides(
        localGuides
          .map((localGuide) => ({
            url: localGuide.url,
            image: localGuide.image,
            color: localGuide.colorTheme,
            category: localGuide.category,
            title: <h2>{localGuide.title}</h2>,
            children: <h3>{localGuide.subtitle}</h3>
          }))
          .slice(0, 6)
      );
    }
  }, [guides, selectedSchool]);

  useEffect(() => {
    if (scroll.current) {
      const scrollOffset = currentIndex * 256;
      scroll.current.scrollTo({ left: scrollOffset, behavior: "smooth" });
    }
  }, [currentIndex]);

  const handlerNext = () =>
    setCurrentIndex((index) => {
      return index < localGuides.length - 1 ? index + 1 : index;
    });

  const handlerPrevious = () =>
    setCurrentIndex((index) => {
      return index !== 0 ? index - 1 : index;
    });

  const handlerNavigateTo = (index) => setCurrentIndex(index);

  if (localGuides.length === 0) return null;

  return (
    <Panel
      isDesktop={isDesktop}
      title="guides for you"
      linkColor={Colors.GREEN}
      linkLabel="Read All Guides"
      linkUrl={decidedRoute("/guides")}
      className="p-4 mx-4 mt-4 bg-white shadow-lg desktop:flex desktop:flex-col"
    >
      <div className="flex flex-col">
        <div className="flex flex-1 flex-row">
          <div
            tabIndex="0"
            role="button"
            onClick={handlerPrevious}
            className="flex items-center justify-center outline-none"
          >
            <ShortArrow size={20} />
          </div>
          <div
            ref={scroll}
            className="p-1 flex flex-1 flex-row overflow-x-scroll"
          >
            {localGuides.map((guide, index) => (
              <CollegeGuide
                key={index}
                guide={guide}
                hasDescription={true}
                className="
                  mx-2
                  flex
                  flex-1
                  flex-col
                  min-w-15rem
                "
                titleClassName="
                  mb-2
                  text-xl
                  font-extrabold
                  mobile:text-lg
                  tablet:text-lg
                  mobile-sm:text-lg
                  tablet-sm:text-lg
                "
                categoryClassName="py-2 text-xs"
                childrenClassName="pt-2 text-sm"
              />
            ))}
          </div>
          <div
            tabIndex="0"
            role="button"
            onClick={handlerNext}
            className="
              flex
              transform
              rotate-180
              items-center
              outline-none
              justify-center
            "
          >
            <ShortArrow size={20} />
          </div>
        </div>
        <div className="mt-2 flex flex-1 flex-row items-center justify-center">
          {localGuides.map((_, index) => (
            <div
              key={index}
              role="button"
              onClick={() => handlerNavigateTo(index)}
              className={`
                w-4
                h-4
                mx-2
                rounded-full
                ${
                  index === currentIndex
                    ? "bg-primary-green"
                    : "bg-secondary-green"
                }
              `}
            ></div>
          ))}
        </div>
      </div>
    </Panel>
  );
}

CollegeGuidesBox.propTypes = {
  isDesktop: PropTypes.bool,
  selectedSchool: PropTypes.shape()
};

export default CollegeGuidesBox;
