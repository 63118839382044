import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Button } from "../buttons";
import { ShortArrow } from "../icons";

function FilterItemRow({
  id,
  icon,
  title,
  active,
  children,
  activeDropdown,
  onClickHandler = null,
  handlerOnActiveDropdown,
}) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (activeDropdown !== title) setExpanded(false);
    else setExpanded(true);
  }, [title, activeDropdown]);

  return (
    <div className="relative">
      <div
        className={`
          border
          ${
            active
              ? expanded
                ? "bg-white border-black"
                : "bg-white border-primary-green"
              : expanded
              ? "bg-white border-black "
              : "bg-primary-green border-primary-green group hover:bg-white hover:border-primary-green"
          }
        `}
        onClick={onClickHandler}
      >
        <Button
          id={id}
          onClick={() => {
            if (expanded) handlerOnActiveDropdown("");
            else handlerOnActiveDropdown(title);
          }}
          className="
            p-2
            flex
            w-full
            flex-row
            relative
            border-0
            space-x-2
            items-center
            justify-center
            focus:outline-non
          "
        >
          <div
            className={`${
              active
                ? expanded
                  ? "text-primary-green"
                  : "group-hover:text-white text-primary-green"
                : expanded
                ? "text-primary-green"
                : "text-white group-hover:text-primary-green"
            }`}
          >
            {icon}
          </div>
          <span
            className={`
              text-sm
              font-bold
              capitalize
              ${
                active
                  ? expanded
                    ? "text-primary-green"
                    : "text-primary-green group-hover:text-white"
                  : expanded
                  ? "text-primary-green"
                  : "text-white group-hover:text-primary-green"
              }
            `}
          >
            {title}
          </span>
          <div
            className={`
              my-auto
              right-2
              absolute
              transform
              transition
              ${expanded ? "-rotate-90" : "-rotate-180"}
              ${
                active
                  ? expanded
                    ? "text-primary-green"
                    : "text-primary-green group-hover:text-white"
                  : expanded
                  ? "text-primary-green"
                  : "text-white group-hover:text-primary-green"
              }
            `}
          >
            <ShortArrow color="stroke-current" />
          </div>
        </Button>
        {expanded && (
          <div
            className="
              pb-4
              px-4
              z-10
              w-full
              left-0
              border
              absolute
              border-t-0
              bg-white
              border-black
            "
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
}

FilterItemRow.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.node,
  active: PropTypes.bool,
  title: PropTypes.string,
  activeDropdown: PropTypes.string,
  handlerOnActiveDropdown: PropTypes.func,
};

export default FilterItemRow;
