import React from "react";
import PropTypes from "prop-types";

import { Close } from "../../core/icons";

function EnrolledStatusModal({ onClose, schoolEnrolled }) {
  return (
    <div
      className="
        pt-2
        flex
        pb-32
        flex-1
        flex-col
        relative
      "
    >
      <div onClick={() => onClose()} className="m-4 flex flex-row self-end">
        <Close
          size={25}
          color="stroke-white"
          className="ml-auto my-auto place-self-center cursor-pointer"
        />
      </div>
      <div className="mx-10 flex flex-1 flex-col justify-center mobile:mx-5">
        <div className="z-10 flex flex-col items-center pt-8">
          <p
            className="
              m-10
              text-3xl
              capitalize
              text-center
              text-secondary-green
              font-extrabold
              mobile:mx-0
              tablet-sm:mx-0
            "
          >
            Congrats!
          </p>
          <div className="w-full">
            <p
              className="
              mx-2
              text-3xl
              normal-case
              text-center
              text-white
              font-extrabold
              mobile:mx-0
              tablet-sm:mx-0
            "
            >
              You are going to {schoolEnrolled.name}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

EnrolledStatusModal.propTypes = {
  onClose: PropTypes.func,
  schoolEnrolled: PropTypes.shape()
};

export default EnrolledStatusModal;
