import PropTypes from "prop-types";

function Item({ name, value }) {
  return (
    <div
      className="
        py-4
        px-10
        flex
        flex-1
        flex-row
        mobile:px-5
        mobile-sm:px-5
        tablet-sm:px-5
      "
    >
      <p className="font-semibold w-full">{name}</p>
      <p className="font-semibold justify-end">
        {`$${value.toLocaleString("en-US")}`}
      </p>
    </div>
  );
}

Item.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default Item;
