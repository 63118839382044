import PropTypes from "prop-types";

import Buttons from "./Buttons";
import BoxAmounts from "./BoxAmounts";
import Header from "../../Common/Header";
import Layout from "../../../Common/Layout";
import LayoutCollege from "../../LayoutCollege";

import { Button } from "../../../../core/buttons";

function HowDoesThisLook({
  data,
  onNo,
  onYes,
  onView,
  student,
  openModal,
  modalResponse
}) {
  return (
    <Layout
      student={student}
      openModal={openModal}
      modalResponse={modalResponse}
    >
      <LayoutCollege>
        <div
          className="
            min-h-screen-footer-md
            mobile:min-h-screen-footer
            mobile-sm:min-h-screen-footer
            tablet-sm:min-h-screen-footer
          "
        >
          <div className="p-4">
            <Header
              title="How does this look?"
              titleClassname="text-primary-green"
            />
          </div>
          <div
            className="
              p-8
              flex
              flex-1
              flex-col
              bg-white
              mobile:px-4
              mobile-sm:px-4
              tablet-sm:px-4
            "
          >
            <div
              className="
                border-b-0
                border-grey
                mobile:border-b
                mobile-sm:border-b
                tablet-sm:border-b
              "
            >
              <div
                className="
                  py-6
                  pr-10
                  w-2/3
                  mobile:w-full
                  mobile-sm:w-full
                  tablet-sm:w-full
                "
              >
                <p
                  className="
                    text-lg
                    font-semibold
                    mobile:text-base
                    mobile-sm:text-base
                    tablet-sm:text-base
                  "
                >
                  We're listing out all the free money that is guaranteed that
                  you won't have to pay back. That means we leave out loans and
                  work study.
                </p>
              </div>
              <p
                className="
                  pb-4
                  text-2xl
                  font-bold
                  normal-case
                  mobile:text-xl
                  mobile-sm:text-xl
                  tablet-sm:text-xl
                "
              >
                What we're seeing:
              </p>
              <div
                className="
                  flex
                  flex-1
                  flex-row
                  space-x-10
                  mobile:flex-col
                  mobile:space-x-0
                  mobile-sm:flex-col
                  mobile-sm:space-x-0
                  tablet-sm:flex-col
                  tablet-sm:space-x-0
                "
              >
                <div
                  className="
                    w-2/3
                    mobile:w-full
                    mobile-sm:w-full
                    tablet-sm:w-full
                  "
                >
                  <BoxAmounts
                    grants={data.grants}
                    totalAward={data.totalAward}
                  />
                </div>
                <div
                  className="
                    my-4
                    w-1/3
                    pl-10
                    space-y-6 
                    tablet:pl-0
                    mobile:hidden
                    mobile-sm:hidden
                    tablet-sm:hidden
                  "
                >
                  <Buttons onYes={onYes} onNo={onNo} />
                </div>
              </div>
              <div
                className="
                  my-6
                  flex
                  flex-1
                  flex-row
                  space-x-10
                  items-center
                  mobile:flex-col
                  mobile:space-x-0
                  mobile-sm:flex-col
                  mobile-sm:space-x-0
                  tablet-sm:flex-col
                  tablet-sm:space-x-0
                "
              >
                <div className="w-2/3 mobile:w-full mobile-sm:w-full tablet-sm:w-full"></div>
                <div
                  className="
                    w-1/3
                    pl-10
                    tablet:pl-0
                    mobile:pl-0
                    mobile:mt-6
                    mobile:w-full
                    mobile-sm:mt-6
                    mobile-sm:pl-0
                    mobile-sm:w-full
                    tablet-sm:mt-6
                    tablet-sm:pl-0
                    tablet-sm:w-full
                  "
                >
                  <Button
                    onClick={onView}
                    className="
                      w-full
                      bg-white
                      text-primary-green
                      border-primary-green
                      hover:text-white
                      active:text-white
                      hover:bg-primary-green
                      active:bg-primary-green
                    "
                  >
                    Edit Financial Aid
                  </Button>
                </div>
              </div>
            </div>
            <div
              className="
                pt-6
                hidden
                space-y-6
                mobile:block
                mobile-sm:block
                tablet-sm:block
              "
            >
              <Buttons onNo={onNo} onYes={onYes} />
            </div>
          </div>
        </div>
      </LayoutCollege>
    </Layout>
  );
}

HowDoesThisLook.propTypes = {
  data: PropTypes.object.isRequired,
  onNo: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  modalResponse: PropTypes.string.isRequired
};

export default HowDoesThisLook;
