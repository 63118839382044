import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "../Common/Layout";
import LayoutDesktop, {
  LayoutLeftContent,
  LayoutRightContent
} from "../Common/LayoutDesktop";

import { Button, LoadingButton } from "../../core/buttons";

import Api from "../../services/Api";
import { useStudent } from "../../redux/Student/hooks";

function JoinOrg() {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const { student } = useStudent();

  const [isReady, setIsReady] = useState(false);
  const [organization, setOrganization] = useState({});
  const [isLoadingMutation, setIsLoadingMutation] = useState(false);
  const [errorMutation, setErrorMutation] = useState();
  const [uiState, setUiState] = useState({
    title: "",
    message: "",
    buttonType: "default"
  });

  const skipOnClick = () => navigate("/");

  const acceptOnClick = async () => {
    setIsLoadingMutation(true);
    try {
      await Api.editStudentOrganizations({
        organizationIdsAdd: [organization.organizationId],
        organizationIdsRemove: []
      });
      setUiState({
        title: "Invitation Accepted",
        message: `You have successfully joined ${organization.name}.`,
        buttonType: "return"
      });
    } catch (error) {
      setErrorMutation(error);
    } finally {
      setIsLoadingMutation(false);
    }
  };

  useEffect(() => {
    const fetchOrganization = async () => {
      const org = await Api.fetchStudentOrganization({ code: orgCode });
      if (!org) {
        setUiState({
          title: "Invitation Invalid",
          message:
            "The invitation link you followed was incorrect, or the organization you are trying to join no longer exists.",
          buttonType: "return"
        });
      } else if (
        student.organizations.find(
          (o) => o.organizationId === org.organizationId
        )
      ) {
        setUiState({
          title: "Invitation Already Accepted",
          message: `You are already a member of ${org.name}. No further action is needed.`,
          buttonType: "return"
        });
      } else {
        setOrganization(org);
        setUiState({
          title: `You have been invited to join ${org.name}`,
          message: `An admin has invited you to join the ${org.name} organization so they can see the list of colleges you are researching on DecidED and support you on your decision.`,
          buttonType: "acceptOrSkip"
        });
      }
    };

    fetchOrganization();
    setIsReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isReady) {
    return <div className="w-full font-bold text-center">Loading...</div>;
  } else {
    return (
      <Layout logoWhite={true} headerBGColor="text-white bg-primary-green">
        <LayoutDesktop className="bg-primary-green min-h-screen-footer-md">
          <LayoutLeftContent>
            <p className="text-3xl font-semibold laptop:text-4xl desktop:text-5xl">
              Join us in DecidED
            </p>
          </LayoutLeftContent>
          <LayoutRightContent>
            <div className="py-4 px-6 flex border flex-col shadow-lg bg-white items-center border-black">
              <div className="flex flex-1 flex-col">
                <div className="my-12">
                  <div className="text-4xl font-extrabold text-primary-green">
                    {uiState.title}
                  </div>
                  <div className="mb-4 my-12 text-2xl text-black">
                    {uiState.message}
                  </div>
                </div>

                {errorMutation && (
                  <p className="pb-2 text-primary-red">
                    {errorMutation.errors[0].message}
                  </p>
                )}
                {uiState.buttonType === "acceptOrSkip" && (
                  <div className="flex flex-1 flex-row space-x-4">
                    <div className="w-1/2">
                      <Button
                        onClick={skipOnClick}
                        className="
                            mb-5
                            w-full
                            bg-white
                            text-primary-green
                            border-primary-green
                            hover:text-white
                            active:text-white
                            hover:bg-primary-green
                            active:bg-primary-green"
                      >
                        Skip
                      </Button>
                    </div>
                    <div className="w-1/2">
                      <LoadingButton
                        isLoading={isLoadingMutation}
                        onClick={acceptOnClick}
                        className="
                            mb-5
                            w-full
                            text-white
                            bg-primary-green
                            border-primary-green
                            hover:bg-white
                            active:bg-white
                            hover:text-primary-green
                            active:text-primary-green"
                      >
                        Accept
                      </LoadingButton>
                    </div>
                  </div>
                )}

                {uiState.buttonType === "return" && (
                  <div className="flex flex-1 flex-row">
                    <div className="w-full">
                      <Button
                        onClick={skipOnClick}
                        className="
                            mb-5
                            w-full
                            text-white
                            bg-primary-green
                            border-primary-green
                            hover:bg-white
                            active:bg-white
                            hover:text-primary-green
                            active:text-primary-green"
                      >
                        Return
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </LayoutRightContent>
        </LayoutDesktop>
      </Layout>
    );
  }
}

export default JoinOrg;
