import PropTypes from "prop-types";

function ImagesCount({ imagesCount, currentImage }) {
  return (
    <div
      className="
        py-1
        px-4
        flex
        top-0
        left-0
        absolute
        font-bold
        bg-black
        text-white
        items-center
        justify-center
      "
    >
      <p>
        {currentImage} of {imagesCount}
      </p>
    </div>
  );
}

ImagesCount.propTypes = {
  imagesCount: PropTypes.number,
  currentImage: PropTypes.number
};

export default ImagesCount;
