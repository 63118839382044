import PropTypes from "prop-types";

import CollegeStatusDropdown from "./CollegeStatusDropdown";

import { Link } from "../../../../core";
import { MapChart, SmallStar } from "../../../../core/icons";
import { Colors, CollegeType } from "../../../../core/utils";
import {
  AdmissionsOffice,
  FinAidOffice,
  MapButtonScrolldown,
  OnlineApplication
} from "../../../../utils/DataLayers";

import CaLogo from "../../../../assets/images/CA_logo.png";

function CollegeDisplay({
  color,
  school,
  openModal,
  chosenSchool,
  enrollmentStatus,
  addYourMoneyPath
}) {
  const handleOnClickMap = () => {
    document.getElementById("map").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "end"
    });
    MapButtonScrolldown({ ipeds: school?.ipedsId });
  };

  const onlineApplicationUrl = school?.onlineApplicationWebsiteUrl;
  const admissionsOfficeUrl = school?.admissionsOfficeWebsiteUrl;
  const financialAidOfficeUrl = school?.financialAidOfficeWebsiteUrl;
  const commonAppUrl = school?.commonAppUrl;

  const getSchoolYearId = () => {
    if (school.programLength)
      return `${school.programLength
        .split("_")
        .join("-")
        .toLowerCase()}-container`;
    return "years-container";
  };

  return (
    <>
      <div
        className="
          px-4
          pb-2
          flex
          flex-1
          flex-row
          text-3xl
          font-bold
          items-center
        "
      >
        <div
          id="college-detail-name"
          className="flex flex-1 flex-row space-x-2 items-center"
        >
          <h2>{school.name}</h2>
          {school.favorite && (
            <SmallStar
              width={30}
              height={30}
              fillColor="fill-alternative-yellow"
            />
          )}
        </div>
        <CollegeStatusDropdown
          color={color}
          openModal={openModal}
          labelClassName="text-sm"
          className="w-64 text-base"
          chosenSchool={chosenSchool}
          enrollmentStatus={enrollmentStatus}
          addYourMoneyPath={addYourMoneyPath}
        />
      </div>
      <div
        className="
          py-2
          px-4
          mx-4
          flex
          flex-1
          flex-col
          bg-light-gray
          tablet:flex-row
          laptop:flex-row
          desktop:flex-row
        "
      >
        <div className="flex flex-row w-1/2 font-bold space-x-4">
          <span className="place-self-center">
            {school?.city && `${school.city},`} {school.state}
          </span>
          <div className="flex flex-row space-x-1 items-center">
            <MapChart size={20} />
            <Link
              text="MAP"
              aria-label="map"
              onClick={() => handleOnClickMap()}
            />
            <div
              id={getSchoolYearId()}
              className="pl-4 text-base font-bold capitalize"
            >
              <span>
                {school.programLength === CollegeType.FOUR_YEARS ? "4" : "2"}{" "}
                year program
              </span>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-1 flex-row  ${
            commonAppUrl ? "justify-center" : "justify-end"
          } space-x-4`}
        >
          {commonAppUrl && (
            <Link
              internal={false}
              href={commonAppUrl}
              target="_blank"
              text={
                <>
                  <span className="font-bold text-primary-blue ">
                    Apply with
                  </span>{" "}
                  <img src={CaLogo} alt="Common Application Logo" />
                </>
              }
            />
          )}
          {onlineApplicationUrl && !commonAppUrl && (
            <Link
              icon={true}
              color={color}
              target="_blank"
              internal={false}
              text="Online Application"
              textClassName="font-bold"
              href={onlineApplicationUrl}
              onClick={() => OnlineApplication({ ipeds: school.ipedsId })}
            />
          )}
          {admissionsOfficeUrl && !commonAppUrl && (
            <Link
              icon={true}
              color={color}
              target="_blank"
              internal={false}
              text="Admissions Office"
              textClassName="font-bold"
              href={admissionsOfficeUrl}
              onClick={() => AdmissionsOffice({ ipeds: school.ipedsId })}
            />
          )}
          {financialAidOfficeUrl && !commonAppUrl && (
            <Link
              icon={true}
              color={color}
              target="_blank"
              internal={false}
              textClassName="font-bold"
              text="Financial Aid Office"
              href={financialAidOfficeUrl}
              onClick={() => FinAidOffice({ ipeds: school.ipedsId })}
            />
          )}
        </div>
      </div>
    </>
  );
}

CollegeDisplay.propTypes = {
  openModal: PropTypes.func,
  chosenSchool: PropTypes.shape(),
  addYourMoneyPath: PropTypes.string,
  enrollmentStatus: PropTypes.string,
  school: PropTypes.shape().isRequired,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default CollegeDisplay;
