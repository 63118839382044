export const ModalTypes = {
  ALL_SET: "ALL_SET",
  GRADE_YEAR: "GRADE_YEAR",
  TYPE_FILTER: "TYPE_FILTER",
  MAJOR_FILTER: "MAJOR_FILTER",
  FIRST_SCHOOL: "FIRST_SCHOOL",
  FIRST_LETTER: "FIRST_LETTER",
  LOCATION_FILTER: "LOCATION_FILTER",
  ENROLLED_STATUS: "ENROLLED_STATUS",
  PROFILE_JOIN_ORG: "PROFILE_JOIN_ORG",
  PROFILE_LEAVE_ORG: "PROFILE_LEAVE_ORG",
  VIEW_AWARD_LETTER: "VIEW_AWARD_LETTER",
  ADD_FINANCIAL_AID: "ADD_FINANCIAL_AID"
};

export function useAllMapStates() {
  return [
    { val: "AL", label: "Alabama", isActive: false, id: "01" },
    { val: "AK", label: "Alaska", isActive: false, id: "02" },
    { val: "AZ", label: "Arizona", isActive: false, id: "04" },
    { val: "AR", label: "Arkansas", isActive: false, id: "05" },
    { val: "CA", label: "California", isActive: false, id: "06" },
    { val: "CO", label: "Colorado", isActive: false, id: "08" },
    { val: "CT", label: "Connecticut", isActive: false, id: "09" },
    { val: "DE", label: "Delaware", isActive: false, id: "10" },
    { val: "DC", label: "District of Columbia", isActive: false, id: "11" },
    { val: "FL", label: "Florida", isActive: false, id: "12" },
    { val: "GA", label: "Georgia", isActive: false, id: "13" },
    { val: "HI", label: "Hawaii", isActive: false, id: "15" },
    { val: "ID", label: "Idaho", isActive: false, id: "16" },
    { val: "IL", label: "Illinois", isActive: false, id: "17" },
    { val: "IN", label: "Indiana", isActive: false, id: "18" },
    { val: "IA", label: "Iowa", isActive: false, id: "19" },
    { val: "KS", label: "Kansas", isActive: false, id: "20" },
    { val: "KY", label: "Kentucky", isActive: false, id: "21" },
    { val: "LA", label: "Louisiana", isActive: false, id: "22" },
    { val: "ME", label: "Maine", isActive: false, id: "23" },
    { val: "MD", label: "Maryland", isActive: false, id: "24" },
    { val: "MA", label: "Massachusetts", isActive: false, id: "25" },
    { val: "MI", label: "Michigan", isActive: false, id: "26" },
    { val: "MN", label: "Minnesota", isActive: false, id: "27" },
    { val: "MS", label: "Mississippi", isActive: false, id: "28" },
    { val: "MO", label: "Missouri", isActive: false, id: "29" },
    { val: "MT", label: "Montana", isActive: false, id: "30" },
    { val: "NE", label: "Nebraska", isActive: false, id: "31" },
    { val: "NV", label: "Nevada", isActive: false, id: "32" },
    { val: "NH", label: "New Hampshire", isActive: false, id: "33" },
    { val: "NJ", label: "New Jersey", isActive: false, id: "34" },
    { val: "NM", label: "New Mexico", isActive: false, id: "35" },
    { val: "NY", label: "New York", isActive: false, id: "36" },
    { val: "NC", label: "North Carolina", isActive: false, id: "37" },
    { val: "ND", label: "North Dakota", isActive: false, id: "38" },
    { val: "OH", label: "Ohio", isActive: false, id: "39" },
    { val: "OK", label: "Oklahoma", isActive: false, id: "40" },
    { val: "OR", label: "Oregon", isActive: false, id: "41" },
    { val: "PA", label: "Pennsylvania", isActive: false, id: "42" },
    { val: "PR", label: "Puerto Rico", isActive: false, id: "72" },
    { val: "RI", label: "Rhode Island", isActive: false, id: "44" },
    { val: "SC", label: "South Carolina", isActive: false, id: "45" },
    { val: "SD", label: "South Dakota", isActive: false, id: "46" },
    { val: "TN", label: "Tennessee", isActive: false, id: "47" },
    { val: "TX", label: "Texas", isActive: false, id: "48" },
    { val: "UT", label: "Utah", isActive: false, id: "49" },
    { val: "VT", label: "Vermont", isActive: false, id: "50" },
    { val: "VA", label: "Virginia", isActive: false, id: "51" },
    { val: "VI", label: "Virgin Islands", isActive: false, id: "78" },
    { val: "WA", label: "Washington", isActive: false, id: "53" },
    { val: "WV", label: "West Virginia", isActive: false, id: "54" },
    { val: "WI", label: "Wisconsin", isActive: false, id: "55" },
    { val: "WY", label: "Wyoming", isActive: false, id: "56" }
  ];
}
