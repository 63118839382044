import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import CollegeItem from "./CollegeItem";
import CollegesNoFound from "./CollegesNoFound";

function MyPathBody({
  id,
  student,
  colleges,
  isLoading,
  openModal,
  getDistances,
  filtersApplied,
  showUploadTutorial,
}) {
  const [timeoutIds, setTimeoutIds] = useState([]);
  const [distancesReady, setDistancesReady] = useState(false);
  const [collegesDistance, setCollegesDistance] = useState([]);

  useEffect(() => {
    if (colleges && getDistances) {
      let delayFactor = 1;
      const studentLatitude = student.lat;
      const studentLongitude = student.lon;

      const collegesIpedsId = colleges.map((college) => college.ipedsId);
      const localColleges = student.chosenSchools.filter((school) =>
        collegesIpedsId.includes(school.school.ipedsId)
      );

      localColleges.forEach((college, index) => {
        setCollegesDistance([]);
        const schoolLatitude = college.school.latitude;
        const schoolLongitude = college.school.longitude;

        const directionsService = new window.google.maps.DirectionsService();

        const getDirections = async (request) => {
          const directionRequesthandler = (response, status) => {
            const OK = window.google.maps.DirectionsStatus.OK;
            if (status === OK) {
              setCollegesDistance((collegesDistance) =>
                collegesDistance.concat([
                  {
                    ipedsId: college.school.ipedsId,
                    distance: response.routes[0].legs[0].distance.text,
                  },
                ])
              );
            }
          };

          directionsService.route(request, directionRequesthandler);
        };

        if (studentLatitude && studentLongitude) {
          const request = {
            origin: `${studentLatitude},${studentLongitude}`,
            destination: `${schoolLatitude},${schoolLongitude}`,
            travelMode: window.google.maps.TravelMode.DRIVING,
          };
          if (index > 1) {
            const id = setTimeout(function () {
              getDirections(request);
            }, (delayFactor + 1) * 1000);

            delayFactor += 1;
            setTimeoutIds((ids) => ids.concat([id]));
          } else getDirections(request);
        } else setDistancesReady(true);
      });
    }

    return () => timeoutIds.forEach((id) => window.clearTimeout(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colleges, student.lat, student.lon]);

  if (!colleges) return null;

  const getDistanceComponent = (ipedsId) => {
    const distance = collegesDistance.find(
      (distance) => distance.ipedsId === ipedsId
    );

    return distance ? (
      <>
        <span className="font-bold">{distance.distance} </span>away from your
        location.
      </>
    ) : distancesReady || collegesDistance.length === colleges.length ? (
      <span className="text-sm">
        Add your Zip Code to know how far away it's from your location.
      </span>
    ) : (
      <>Loading...</>
    );
  };

  return (
    <div
      id={id}
      className="
        py-4
        flex
        -mx-2
        flex-1
        flex-row
        flex-wrap
      "
    >
      {colleges.map((college, index) => {
        let showTooltip = false;
        let tooltipText = "";

        if (college.awardStatus === "MANUAL_REVIEW") {
          showTooltip = true;
          tooltipText =
            "Your financial aid award letter is being reviewed by our team. We’ll email you when your results are ready.";
        } else if (!college.awardStatus) {
          showTooltip = true;
          tooltipText =
            "After you upload your financial aid letter, DecidED will show you how much of the estimated costs will be covered by free money.";
        } else if (index === 0) {
          showTooltip = true;
          tooltipText =
            "Click on any college card to open the detailed college view and upload an award letter. You can, also, update your status for this school here.";
        }

        return (
          <CollegeItem
            student={student}
            college={college}
            key={college.ipedsId}
            openModal={openModal}
            showTooltip={showTooltip}
            tooltipText={tooltipText}
            id={`college-item-${index}`}
            showUploadTutorial={showUploadTutorial}
            distance={getDistanceComponent(college.ipedsId)}
          />
        );
      })}
      {!isLoading && colleges.length === 0 && (
        <CollegesNoFound filtersApplied={filtersApplied} />
      )}
    </div>
  );
}

MyPathBody.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  openModal: PropTypes.func,
  student: PropTypes.shape(),
  getDistances: PropTypes.bool,
  filtersApplied: PropTypes.bool,
  showUploadTutorial: PropTypes.bool,
  colleges: PropTypes.arrayOf(PropTypes.shape()),
};

export default MyPathBody;
