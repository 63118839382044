import PropTypes from "prop-types";

function FilterMajor({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 24 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M11.2953 0.313527C11.284 0.320711 11.2729 0.329095 11.2623 0.338619L0.297533 6.76215C0.249586 6.79279 0.208657 6.84425 0.179876 6.91009C0.151095 6.97593 0.135742 7.05321 0.135742 7.13225C0.135742 7.21129 0.151095 7.28857 0.179876 7.35441C0.208657 7.42025 0.249586 7.47171 0.297533 7.50236L4.40932 9.91118C4.40894 9.92372 4.40894 9.93628 4.40932 9.94882V16.548C4.41041 16.6265 4.42663 16.7029 4.45598 16.7675C4.48533 16.8322 4.52653 16.8824 4.57446 16.9118L11.2541 21.1523C11.3176 21.1914 11.3887 21.1914 11.4522 21.1523L18.1401 16.9118C18.1865 16.8805 18.2259 16.8295 18.2537 16.765C18.2816 16.7005 18.2966 16.6251 18.2969 16.548V9.94882C18.2971 9.94045 18.2971 9.93209 18.2969 9.92372L20.1794 8.81968C20.2451 8.78142 20.2981 8.70506 20.3268 8.60742C20.3554 8.50978 20.3574 8.39885 20.3322 8.29902C20.307 8.1992 20.2568 8.11867 20.1925 8.07514C20.1282 8.03161 20.0552 8.02866 19.9895 8.06692L11.3531 13.1355L1.12319 7.13852L11.3614 1.12901L22.0454 7.40199V17.2129C21.2884 17.4036 20.7079 18.3949 20.7079 19.5841C20.7079 20.9085 21.4281 21.9929 22.3097 21.9929C23.1912 21.9929 23.9114 20.9085 23.9114 19.5841C23.9114 18.3949 23.3309 17.4036 22.5739 17.2129V7.13852C22.5744 7.05784 22.559 6.97877 22.5295 6.91164C22.5 6.8445 22.458 6.7924 22.4087 6.76215L11.4522 0.338619C11.4028 0.30618 11.3481 0.297426 11.2953 0.313527ZM4.93775 10.2248L11.2623 13.9259C11.3234 13.9616 11.3911 13.9616 11.4522 13.9259L17.7685 10.2248V16.272L11.3531 20.3494L4.93775 16.272V10.2248ZM22.3097 17.9657C22.904 17.9657 23.3748 18.6912 23.3748 19.5841C23.3748 20.477 22.904 21.19 22.3097 21.19C21.7153 21.19 21.2363 20.477 21.2363 19.5841C21.2363 18.6912 21.7153 17.9657 22.3097 17.9657Z"
      />
    </svg>
  );
}

FilterMajor.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default FilterMajor;
