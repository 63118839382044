import { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import { Enrollment, CollegeType } from "../../../../core/utils";

const enrollmentStatusDirectory = {
  ENROLLMENT_1_INTERESTED: "Search Schools",
  ENROLLMENT_2_APPLIED: "Apply",
  ENROLLMENT_3_ACCEPTED: "Get Accepted",
  ENROLLMENT_4_UPLOADED_LETTER: "Upload an Award Letter",
  ENROLLMENT_5_ENROLLED: "Enroll",
};

function ProgressBar({ status, student, isDesktop }) {
  const [percent, setPercent] = useState(0);
  const [progress, setProgress] = useState(null);
  const [localStatus, setLocalStatus] = useState([]);
  const [linesPositions, setLinesPositions] = useState([]);

  const getAdvancedProgress = useCallback(() => {
    let progress = "";
    const firstChosenSchool = student.chosenSchools[0] || {};
    const chosenSchoolsYearType =
      student?.chosenSchoolsYearType ||
      firstChosenSchool?.school?.programLength ||
      CollegeType.FOUR_YEARS;

    student.chosenSchools.forEach((school) => {
      if (school.school.programLength === chosenSchoolsYearType) {
        if (school.enrollmentStatus > progress)
          progress = school.enrollmentStatus;
      }
    });

    return progress;
  }, [student]);

  const updateLinesPositions = useCallback(() => {
    const linesPositions = [];
    const localStatusFiltered = localStatus.filter(
      (status) => typeof status.id !== "number"
    );
    const containerPosition = document
      .getElementById("status-container")
      ?.getBoundingClientRect();

    localStatusFiltered.forEach((status, index) => {
      const labelPosition = document
        .getElementById(`status-${status.id}`)
        ?.getBoundingClientRect();

      const position = labelPosition.x - containerPosition.x;
      linesPositions.push({
        left: position + labelPosition.width / 2,
      });

      if (status.active) {
        if (isDesktop)
          setPercent(
            ((position + labelPosition.width + 8) * 100) /
              containerPosition.width
          );
        else setPercent(((index + 0.4) * 100) / 4);
      }
    });
    setLinesPositions(linesPositions);
  }, [isDesktop, localStatus]);

  useEffect(() => {
    updateLinesPositions();

    window.addEventListener("resize", updateLinesPositions);
    return () => window.removeEventListener("resize", updateLinesPositions);
  }, [updateLinesPositions]);

  useEffect(() => {
    const computedStatus = [];
    const progress = getAdvancedProgress();
    setProgress(progress);

    const statusConnectors = [
      { description: "Identify your fit factors" },
      { description: "Fill out your Fin Aid forms" },
      { description: "Compare your options" },
    ];

    status.forEach((statusItem, index) => {
      computedStatus.push({
        id: statusItem.id,
        active: statusItem.id <= progress,
        label: (
          <div className="w-24 text-center tablet:text-sm tablet:w-auto">
            {statusItem.id <= progress
              ? statusItem.name
              : enrollmentStatusDirectory[statusItem.id]}
          </div>
        ),
      });

      if (index !== status.length - 1) {
        let conector = {};
        if (index <= 2) {
          conector = statusConnectors[index];
        } else {
          conector = statusConnectors[statusConnectors.length - 1];
        }
        computedStatus.push({
          id: index,
          active: false,
          label: (
            <div className="w-20 text-xs text-center text-gray-600">
              {conector.description}
            </div>
          ),
        });
      }
    });

    setLocalStatus(computedStatus);
  }, [status, getAdvancedProgress]);

  return (
    <div className="mt-4 w-full bg-white">
      <div className="p-4 flex flex-1 flex-col">
        <div
          id="my-path-container"
          className="flex flex-1 flex-row items-center"
        >
          <p className="text-3xl font-bold">{student.firstName}'s Path</p>
        </div>
        <div
          className="
            h-8
            mt-4
            flex
            flex-1
            flex-row
            bg-white
            text-white
            rounded-full
            shadow-black
            mobile:h-6
            mobile-sm:h-6
          "
        >
          <div
            style={{ width: `${percent}%` }}
            className="
              h-8
              rounded-full
              bg-primary-green
              mobile:h-6
              mobile-sm:h-6
            "
          ></div>
        </div>
        <div className="tablet:hidden laptop:hidden desktop:hidden">
          <p className="my-3 text-sm font-bold">
            {[
              Enrollment.ENROLLMENT_4_UPLOADED_LETTER,
              Enrollment.ENROLLMENT_5_ENROLLED,
            ].includes(progress)
              ? "You are doing great! You have uploaded award letters!"
              : ""}
          </p>
        </div>
        <div className="mobile:hidden mobile-sm:hidden tablet-sm:hidden">
          <div className="h-5 px-2 w-full relative">
            {localStatus
              .filter((status) => typeof status.id !== "number")
              .map((status, index) => (
                <div
                  key={`status-line-${status.id}`}
                  className="h-5 border-r absolute border-primary-green"
                  style={{ left: `${linesPositions[index]?.left || 0}px` }}
                ></div>
              ))}
          </div>
          <div
            id="status-container"
            className="px-2 flex flex-1 flex-row justify-between"
          >
            {localStatus.map((status) => (
              <div
                id={`status-${status.id}`}
                key={`status-${status.id}`}
                className={`
                  font-semibold
                  ${status.active ? "text-primary-green" : "text-black"}
                `}
              >
                {status.label}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  isDesktop: PropTypes.bool,
  student: PropTypes.shape(),
  status: PropTypes.arrayOf(PropTypes.shape()),
};

export default ProgressBar;
