import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Layout from "../../Common/Layout";
import CollegeContainer from "./CollegeContainer";
import CollegeYearButtons from "../Common/CollegeYearButtons";

import { SmallModal } from "../../../core";
import { Button, BackButton } from "../../../core/buttons";
import { useIsDesktopMQL } from "../../../core/hooks";
import { CollegeType } from "../../../core/utils";

import {
  sortOrderChosenSchools,
  updateChosenSchoolYearType
} from "../../../redux/Student/actions";
import { useGetShowUploadTutorial } from "../../../redux/Student/hooks";
import { loadState, saveState, Items } from "../../../redux/storePersist";

import { GetIpedsId } from "../../Common/Hooks";
import { PageViewDataLayer } from "../../../utils/DataLayers";

function Compare({ student, openModal, modalResponse }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useIsDesktopMQL();

  const ipedsId = GetIpedsId();
  const showUploadTutorial = useGetShowUploadTutorial();

  const [colleges, setColleges] = useState([]);
  const [favorite, setFavorite] = useState(null);
  const [toggleProgram, setToggleProgram] = useState(false);
  const [showUploadLetterModal, setShowUploadLetterModal] = useState(false);

  useEffect(() => {
    if (student) {
      PageViewDataLayer({
        ipeds: ipedsId,
        userId: student.userId,
        screenName: "Compare Screen",
        organizationId: student.organizations.map((org) => org.organizationId)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialHeights = {
    title: 0,
    majors: 0,
    affordability: 0,
    graduationRate: 0
  };
  const [heights, setHeights] = useState(initialHeights);
  const [sectionsState, setSectionsState] = useState({
    majors: true,
    graduationRate: true,
    earnings: false,
    diversity: false,
    campusEnvironment: false,
    firstYearBreakdown: true
  });

  const getChosenSchoolList = () => {
    const firstChosenSchool = student.chosenSchools[0] || {};
    const chosenSchoolsYearType =
      student?.chosenSchoolsYearType ||
      firstChosenSchool?.school?.programLength ||
      CollegeType.FOUR_YEARS;

    return student.chosenSchools.filter(
      (school) => school.school.programLength === chosenSchoolsYearType
    );
  };

  useEffect(() => {
    setColleges([]);
    setFavorite(null);
    if (!student.chosenSchoolsYearType) {
      const firstChosenSchool = student.chosenSchools[0] || {};
      const chosenSchoolsYearType =
        firstChosenSchool?.school?.programLength || CollegeType.FOUR_YEARS;
      dispatch(updateChosenSchoolYearType({ chosenSchoolsYearType }));
    } else setToggleProgram(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student.chosenSchoolsYearType]);

  useEffect(() => {
    if (toggleProgram) {
      getFavoriteAndCollege(getChosenSchoolList());
      setToggleProgram(false);
      setHeights(initialHeights);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleProgram]);

  const setGlobalHeights = useCallback((newHeights) => {
    const { showUploadLetterModal } = loadState(Items.APP_STATE);
    if (showUploadLetterModal) setShowUploadLetterModal(showUploadLetterModal);
    setHeights((state) => ({
      title: newHeights.title > state.title ? newHeights.title : state.title,
      majors:
        newHeights.majors > state.majors ? newHeights.majors : state.majors,
      affordability:
        newHeights.affordability > state.affordability
          ? newHeights.affordability
          : state.affordability,
      graduationRate:
        newHeights.graduationRate > state.graduationRate
          ? newHeights.graduationRate
          : state.graduationRate
    }));
  }, []);

  const setGlobalSectionState = useCallback(
    (newSectionState) => setSectionsState(newSectionState),
    []
  );

  const selectAsFavorite = (college) => {
    dispatch(
      sortOrderChosenSchools({
        isFavorite: true,
        chosenSchool: college,
        newSortOrder: college.sortOrder
      })
    );
  };

  const unselectAsFavorite = (college) => {
    dispatch(
      sortOrderChosenSchools({
        isFavorite: false,
        chosenSchool: college,
        newSortOrder: college.sortOrder
      })
    );
  };

  const setNewSortOrderColleges = (college, newSortOrder) => {
    dispatch(
      sortOrderChosenSchools({
        sort: false,
        newSortOrder,
        chosenSchool: college
      })
    );
  };

  const getFavoriteAndCollege = (colleges) => {
    const favorite = colleges.find((school) => school.isFavorite);
    if (favorite) {
      setFavorite(favorite);
      setColleges(colleges.filter((school) => !school.isFavorite));
    } else {
      setFavorite(null);
      setColleges(colleges);
    }
  };

  if (!isDesktop) navigate(-1);

  return (
    <Layout
      student={student}
      openModal={openModal}
      modalResponse={modalResponse}
    >
      <div
        className="
          p-2
          flex
          mx-2
          mb-4
          mt-16
          flex-col
          bg-white
          divide-y-1
          min-h-screen
          divide-confident-grey
        "
      >
        <div className="flex flex-row justify-between">
          <BackButton onClick={() => navigate(-1)} />
          <CollegeYearButtons />
        </div>
        <div className="py-2 flex flex-1 flex-row">
          <CollegeContainer
            heights={heights}
            className="w-full"
            schools={colleges}
            favorite={favorite}
            isPell={student.isPell}
            studentState={student.state}
            ethnicity={student.ethnicity}
            sectionsState={sectionsState}
            setGlobalHeights={setGlobalHeights}
            showUploadTutorial={showUploadTutorial}
            setGlobalSectionState={setGlobalSectionState}
            selectAsFavorite={(college) => selectAsFavorite(college)}
            unselectAsFavorite={(college) => unselectAsFavorite(college)}
            setNewSortOrderColleges={(college, newSortOrder) =>
              setNewSortOrderColleges(college, newSortOrder)
            }
          />
        </div>
        {showUploadLetterModal && (
          <SmallModal open={showUploadLetterModal}>
            <>
              <p className="text-xl font-bold text-primary-green">
                Ready to upload a financial aid letter and compare your offers?
              </p>
              <p className="my-8">
                Select the "Add Your Award Letter" button for the school to see
                your financial results in DecidED.
              </p>

              <div className="flex flex-row justify-center">
                <Button
                  onClick={() => {
                    setShowUploadLetterModal(false);
                    saveState({}, Items.APP_STATE);
                  }}
                  className="
                    mx-auto
                    text-white
                    bg-primary-green
                    border-primary-green
                    hover:bg-white
                    active:bg-white
                    hover:text-primary-green
                    active:text-primary-green
                  "
                >
                  got it
                </Button>
              </div>
            </>
          </SmallModal>
        )}
      </div>
    </Layout>
  );
}

Compare.propTypes = {
  student: PropTypes.object,
  openModal: PropTypes.func.isRequired,
  modalResponse: PropTypes.string
};

export default Compare;
