import { Navigate, useParams, useNavigate, Route } from "react-router-dom";

import Hmm from "./Hmm";
import Success from "./Success";
import HowItWorks from "./HowItWorks";
import UploadImages from "./UploadImages";
import DontHaveMyAward from "./DontHaveAward";
import HowDoesThisLook from "./HowDoesThisLook";
import CustomSwitch from "../../Common/CustomSwitch";
import PrivateRoute from "../../Common/PrivateRoute";

import { getGrantsAndScholarships } from "../../../core/utils";
import { useGetChosenSchool } from "../../../redux/Student/hooks";

import {
  OnboardingNextButton,
  ViewAwardLetterButton,
  NeedSomeHelpButton,
  LetsGoButton,
} from "../../../utils/DataLayers";

function AddYourMoneyIdRoutes({ student, openModal, modalResponse }) {
  const navigate = useNavigate();
  const { ipedsId } = useParams();
  const selectedSchool = useGetChosenSchool({ ipedsId });

  const getGrantList = (aidAmounts) => {
    const grants = getGrantsAndScholarships(aidAmounts);
    return grants.map((aid, index) => {
      return { id: index, name: aid.description, value: aid.value };
    });
  };

  const nextOnClick = () => {
    navigate(`/college/add-your-money/${ipedsId}/upload-images`);
    OnboardingNextButton({ ipeds: ipedsId });
  };

  const onYesOnClick = () => {
    navigate(`/college/id/${ipedsId}`);
    LetsGoButton({ ipeds: ipedsId });
  };

  const onNoOnClick = () => {
    navigate(`/college/report-an-error/${ipedsId}`);
    NeedSomeHelpButton({ ipeds: ipedsId });
  };

  const onViewOnClick = () => {
    navigate(`/college/id/${ipedsId}/view-award-letter`);
    ViewAwardLetterButton({ ipeds: ipedsId });
  };

  return (
    <CustomSwitch>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <HowItWorks
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
              onNext={nextOnClick}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/upload-images"
        element={
          <PrivateRoute>
            <UploadImages
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
              selectedSchool={selectedSchool}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/dont-have-award"
        element={
          <PrivateRoute>
            <DontHaveMyAward
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
              onBack={() => navigate(-1)}
              onBackToHome={() => navigate(`/college/id/${ipedsId}`)}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/success"
        element={
          <PrivateRoute>
            <Success
              timeout={5000}
              onTimeout={() =>
                navigate(
                  `/college/add-your-money/${ipedsId}/how-does-this-look`,
                )
              }
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/hmm"
        element={
          <PrivateRoute>
            <Hmm
              timeout={5000}
              onTimeout={() => navigate(`/college/id/${ipedsId}`)}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/how-does-this-look"
        element={
          <PrivateRoute>
            <HowDoesThisLook
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
              onYes={onYesOnClick}
              onNo={onNoOnClick}
              onView={onViewOnClick}
              data={{
                grants: getGrantList(selectedSchool?.award?.aidAmounts || []),
                totalAward:
                  selectedSchool?.award?.postAwardSchoolCosts?.totalAward || 0,
              }}
            />
          </PrivateRoute>
        }
      />
    </CustomSwitch>
  );
}

function AddYourMoneyRoutes({ student, openModal, modalResponse }) {
  return (
    <CustomSwitch>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Navigate to="/" />
          </PrivateRoute>
        }
      />
      <Route
        path="/:ipedsId/*"
        element={
          <PrivateRoute>
            <AddYourMoneyIdRoutes
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
            />
          </PrivateRoute>
        }
      />
    </CustomSwitch>
  );
}

export default AddYourMoneyRoutes;
