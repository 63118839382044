import PropTypes from "prop-types";

function Content({ firstText, secondText }) {
  return (
    <div className="pr-20 mobile:pr-0 mobile-sm:pr-0 tablet-sm:pr-0">
      <div className="pt-4 mt-12 text-4xl font-extrabold text-primary-green">
        {firstText}
      </div>
      {secondText && (
        <div className="mb-4 mt-10 text-2xl text-black">{secondText}</div>
      )}
    </div>
  );
}

Content.propTypes = {
  firstText: PropTypes.any,
  secondText: PropTypes.any,
};

export default Content;
