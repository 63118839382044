import TagManager from "react-gtm-module";

function Conversion({ user_id }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "conversion_student_signup",
      user_id: user_id,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default Conversion;
