import { useEffect } from "react";
import ProptTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "../../../core/buttons";
import ReportErrorForm from "./ReportErrorForm";
import Header from "../../College/Common/Header";

import Api from "../../../services/Api";
import { useGetChosenSchool, useStudent } from "../../../redux/Student/hooks";
import { PageViewDataLayer } from "../../../utils/DataLayers";

function ReportAnError({ onBack, onNext }) {
  const navigate = useNavigate();
  const { ipedsId } = useParams();

  const { award } = useGetChosenSchool({ ipedsId });
  const { student } = useStudent();

  useEffect(() => {
    if (student) {
      PageViewDataLayer({
        ipeds: ipedsId,
        userId: student.userId,
        screenName: "Report an Error Screen",
        organizationId: student.organizations.map((org) => org.organizationId)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    try {
      const reportInput = {
        awardId: award.id,
        errorType: data.error.value,
        description: data.description
      };
      await Api.createAwardLetterErrorReport(reportInput);
      navigate(`/college/report-an-error/${ipedsId}/success`);
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="p-4 flex flex-1 flex-col bg-white overflow-auto">
      <Header title="Report an Error" onBack={onBack} />
      <div
        className="
          p-12
          flex
          flex-row
          space-x-8
          mobile:px-4
          mobile:py-12
          mobile:flex-col
          mobile:space-x-0
          mobile-sm:px-4
          mobile-sm:py-12
          mobile-sm:flex-col
          mobile-sm:space-x-0
          tablet-sm:px-6
          tablet-sm:py-12
          tablet-sm:flex-col
          tablet-sm:space-x-0
        "
      >
        <div className="w-2/3 mobile:w-full mobile-sm:w-full tablet-sm:w-full">
          <div
            className="
              p-16
              border
              border-black
              tablet:px-8
              tablet:py-16
              mobile:p-0
              mobile:border-0
              mobile-sm:p-0
              mobile-sm:border-0
              tablet-sm:p-0
              tablet-sm:border-0
            "
          >
            <ReportErrorForm onSubmit={onSubmit} />
          </div>
        </div>
        <div
          className="
            pt-4
            w-1/3
            mobile:w-full
            mobile-sm:w-full
            tablet-sm:w-full
          "
        >
          <div className="mt-16 flex justify-center">
            <p className="text-primary-green font-extrabold">
              Want to try again?
            </p>
          </div>
          <Button
            onClick={onNext}
            id="error-reupload-letter-button"
            className="
              my-4
              w-full
              text-white
              bg-primary-green
              border-primary-green
              hover:bg-white
              active:bg-white
              hover:text-primary-green
              active:text-primary-green
            "
          >
            re-upload my letter
          </Button>
        </div>
      </div>
    </div>
  );
}

ReportAnError.propTypes = {
  onBack: ProptTypes.func.isRequired,
  onNext: ProptTypes.func.isRequired
};

export default ReportAnError;
