import PropTypes from "prop-types";

import { Loader } from "../../";

function OrgsListModal({ data, onClick, isLoading, removeId }) {
  return (
    <>
      {data.map((item, index) => (
        <div key={`${item.id}-${item.code}`}>
          <div
            className={`
              py-1
              px-5
              flex
              flex-1
              flex-row
              ${index % 2 === 0 && "bg-light-gray"}
            `}
          >
            <div className="flex flex-row flex-1 justify-start">
              <span>
                <span className="text-sm font-semibold">{item.name}</span>
              </span>
            </div>
            <button
              id={removeId}
              onClick={() => onClick(item)}
              className="cursor-pointer hover:underline"
            >
              Leave
            </button>
          </div>
        </div>
      ))}
      {data.length === 0 && !isLoading && (
        <div className="flex flex-1 flex-col text-center font-semibold">
          <p className="text-sm my-auto">No Orgs yet!!</p>
        </div>
      )}
      {isLoading && (
        <div className="py-8">
          <Loader show={isLoading} />
        </div>
      )}
    </>
  );
}

OrgsListModal.propTypes = {
  data: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  removeId: PropTypes.string.isRequired
};

export default OrgsListModal;
