import PropTypes from "prop-types";

import { Panel, Diversity } from "../../../core/compose";

function CollegeDiversityBox({
  ethnicity,
  isDesktop,
  chosenSchool,
  handlerOnClickTooltip,
}) {
  const { award, school, schoolCosts } = chosenSchool;
  const { diversityPercentage, studentDiversityPercentage } = schoolCosts;
  const { percentLowIncome } = school;

  return (
    <Panel
      tooltip={true}
      title="diversity"
      isDesktop={isDesktop}
      tooltipCustomToggle={handlerOnClickTooltip}
      className="p-4 mx-4 mt-4 shadow-lg bg-white"
    >
      <Diversity
        ethnicity={ethnicity}
        diversityPercentage={diversityPercentage}
        financialAidPercentage={percentLowIncome}
        className="mt-4 flex flex-1 flex-col items-start"
        studentDiversityPercentage={studentDiversityPercentage}
        affordability={award?.postAwardSchoolCosts?.affordability}
      />
    </Panel>
  );
}

CollegeDiversityBox.propTypes = {
  ethnicity: PropTypes.string,
  chosenSchool: PropTypes.shape(),
  handlerOnClickTooltip: PropTypes.func,
};

export default CollegeDiversityBox;
