import { useEffect, useState } from "react";

import { Link } from "../../../../../core";
import { ButtonTooltip } from "../../../../../core/compose";
import {
  SchoolStatus,
  decidedRoute,
  Affordability
} from "../../../../../core/utils";

import { GetIpedsId } from "../../../../Common/Hooks";
import { Tooltip } from "../../../../../utils/DataLayers";

function AffordabilityTextSection({
  color,
  notAffordable,
  affordability,
  uncoveredMoney,
  tooltipClassName,
  schoolStatus,
  stateYearlySalary
}) {
  const [uncoveredValues, setUncoveredValues] = useState({
    loans: 5500,
    work: stateYearlySalary
  });

  const ipedsId = GetIpedsId();

  useEffect(() => {
    const maxLoans = 5500;
    const maxWork = stateYearlySalary;
    const maxValue = maxLoans + maxWork;

    const calculateUncoveredMoney = () => {
      if (uncoveredMoney <= 0) {
        setUncoveredValues({
          loans: 0,
          work: 0
        });
      } else if (uncoveredMoney >= maxValue) {
        setUncoveredValues({
          loans: maxLoans,
          work: maxWork
        });
      } else if (uncoveredMoney > 0 && uncoveredMoney < maxValue) {
        if (uncoveredMoney > maxLoans) {
          setUncoveredValues({
            loans: maxLoans,
            work: uncoveredMoney - maxLoans
          });
        } else {
          setUncoveredValues({
            loans: uncoveredMoney,
            work: 0
          });
        }
      }
    };
    calculateUncoveredMoney();
  }, [uncoveredMoney, stateYearlySalary]);

  let tooltipTextLoans;
  if (uncoveredMoney <= 0) {
    tooltipTextLoans = `How did we calculate this? It looks like you have your college budget covered and don't need any loans. If you're still interested, you can read more about loans on our guides.`;
  } else if (uncoveredMoney <= 5500) {
    tooltipTextLoans = `How did we calculate this? The maximum amount of loans we recommend taking out is $5,500, but with this college you can afford to take a smaller loan. You can read more about loans on our guides.`;
  } else {
    tooltipTextLoans = `How did we calculate this? The maximum amount of loans we recommend taking out is $5,500. You can read more about loans on our guides.`;
  }

  let tooltipTextWork;
  if (
    affordability === Affordability.AFFORDABLE &&
    uncoveredValues.work === 0
  ) {
    tooltipTextWork =
      "How did we calculate this? We don't think you need to work part-time to cover your college budget. If you do decide to take a part-time job, we recommend no more than 10 hours per week.";
  } else if (
    affordability === Affordability.AFFORDABLE &&
    uncoveredValues.work > 0
  ) {
    tooltipTextWork =
      "How did we calculate this? We took the minimum wage from this state and multiplied that by the number of hours in a school year you would need to work to make up the difference. We don't recommend working more than 10-15 hours a week.";
  } else if (affordability === Affordability.SOMEWHAT_AFFORDABLE) {
    tooltipTextWork =
      "How did we calculate this? We took the minimum wage from this state and multiplied that by by the number of hours in a school year you would need to work to make up the difference. We don't recommend working more than 15-20 hours a week.";
  } else if (affordability === Affordability.NOT_AFFORDABLE) {
    tooltipTextWork =
      "How did we calculate this? We took the minimum wage from this state and multiplied that by by the number of hours in a school year you would need to work to make up the difference. We strongly discourage working more than 20 hours a week.";
  }

  switch (schoolStatus) {
    case SchoolStatus.READY:
      return (
        <div className="flex flex-col">
          <p className="mt-1 mb-4 text-sm">
            You can pay this by a combination of:
          </p>
          <div
            id="loans-affordability-info"
            className="mb-5 flex flex-row items-center"
          >
            <ButtonTooltip
              tooltipColor={color}
              tooltipCloseButton={true}
              tooltipCloseOnClickOutside={true}
              tooltipClassName={tooltipClassName}
              gaOnClickFunc={(text) => Tooltip({ text: text, ipeds: ipedsId })}
              text={tooltipTextLoans}
            />
            <h3 className="flex-row text-md font-bold normal-case">
              {`Loans: $${uncoveredValues?.loans.toLocaleString("en-US")}`}
            </h3>
          </div>
          <div id="work-part-info" className="flex flex-row items-center">
            <ButtonTooltip
              tooltipColor={color}
              tooltipCloseButton={true}
              tooltipCloseOnClickOutside={true}
              tooltipClassName={tooltipClassName}
              gaOnClickFunc={(text) => Tooltip({ text: text, ipeds: ipedsId })}
              text={tooltipTextWork}
            />
            <h3 className="flex-row text-md font-bold normal-case">
              {`Work part-time: $${uncoveredValues?.work.toLocaleString(
                "en-US"
              )}`}
            </h3>
          </div>
          {notAffordable && (
            <div className="mt-2 p-2 text-sm bg-alternative-yellow">
              However, not all of your college budget is covered and you will
              need other loans or sources of income.
            </div>
          )}
        </div>
      );

    case SchoolStatus.IN_REVIEW:
      return (
        <div className="flex flex-col pt-2">
          <p className="text-sm">
            Once your award letter is reviewed, DecidED will show you how much
            of the estimated costs will be covered by free money.
          </p>
          <p className="text-sm mt-2">
            <span className="inline-block">
              <Link
                color={color}
                target="_blank"
                internal={false}
                text="Read our guides"
                href={decidedRoute("/guides")}
              />
            </span>{" "}
            for more information on budgeting and paying for college.
          </p>
        </div>
      );

    default:
      return (
        <div className="flex flex-col py-2">
          <p className="text-sm">
            After you upload your financial aid award letter, DecidED will show
            you how much of the estimated costs will be covered by free money.
          </p>
          <p className="text-sm mt-2">
            <span className="inline-block">
              <Link
                color={color}
                target="_blank"
                internal={false}
                text="Read our guides"
                href={decidedRoute("/guides")}
              />
            </span>{" "}
            for more information on budgeting and paying for college.
          </p>
        </div>
      );
  }
}

export default AffordabilityTextSection;
