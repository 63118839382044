import PropTypes from "prop-types";

function PlusSimple({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="13.787"
        x2="114.213"
        y1="64.001"
        y2="64.001"
        strokeWidth="12"
        className={color}
      />
      <line
        x1="64"
        x2="64"
        y1="13.787"
        y2="114.213"
        strokeWidth="12"
        className={color}
      />
    </svg>
  );
}

PlusSimple.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default PlusSimple;
