import PropTypes from "prop-types";

import SearchResultItem from "./SearchResultItem";
import SearchResultsNoFound from "./SearchResultsNoFound";

import { InfiniteScroll } from "../../../../../core";
import ScrollToTopButton from "../../../Common/ScrollToTopButton";

function SearchResultsBody({
  colleges = [],
  isLoading,
  religiousTypes,
  selectedColleges,
  handlerPagination,
  handlerOnClearFilters
}) {
  return (
    <>
      <InfiniteScroll
        querySelector={null}
        id="search-result-items"
        className="mt-4 py-4 space-y-3"
        handlePagination={handlerPagination}
      >
        {colleges.map((college, index) => (
          <SearchResultItem
            college={college}
            key={college?.ipedsId}
            id={`college-item-${index}`}
            religiousTypes={religiousTypes}
            selectedColleges={selectedColleges}
          />
        ))}
        {!isLoading && colleges.length === 0 && (
          <SearchResultsNoFound handlerOnClearFilters={handlerOnClearFilters} />
        )}
      </InfiniteScroll>
      <ScrollToTopButton />
    </>
  );
}

SearchResultsBody.propTypes = {
  isLoading: PropTypes.bool,
  handlerPagination: PropTypes.func,
  handlerOnClearFilters: PropTypes.func,
  colleges: PropTypes.arrayOf(PropTypes.shape()),
  religiousTypes: PropTypes.arrayOf(PropTypes.shape()),
  selectedColleges: PropTypes.arrayOf(PropTypes.shape())
};

export default SearchResultsBody;
