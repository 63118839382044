import { useState, useEffect } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import PropTypes from "prop-types";

import NoResultOptions from "./NoResultOptions";
import SearchResultOption from "./SearchResultOption";
import SearchResultContainer from "./SearchResultContainer";

import { MagnifyGlassSimple } from "../../icons";

function SearchTextModal({
  value,
  isFloat,
  isLoading,
  placeholder,
  searchResults,
  searchEndpoint,
  onSelectResult,
  ...restProps
}) {
  const [search, setSearch] = useState("");

  useEffect(() => setSearch(value), [value]);

  const handlerOnSearch = (event) => {
    event.persist();
    setSearch(event.currentTarget.value);
    searchEndpoint(event.currentTarget.value);
  };

  const handleOnSelectResult = (option) => {
    onSelectResult(option);
  };

  return (
    <div className="px-1 h-full">
      <div
        className="
          p-2
          flex
          flex-1
          flex-row
          border-b
          bg-white
          items-center
          border-opacity-50
          border-primary-green
          focus-within:border-opacity-100
        "
      >
        {isLoading && (
          <PuffLoader
            size={24}
            color="#3662F6"
            className="mr-2 flex justify-start"
          />
        )}
        {!isLoading && (
          <div className="mr-2 flex justify-start">
            <MagnifyGlassSimple size={16} color="fill-primary-green" />
          </div>
        )}
        <input
          type="text"
          value={search}
          aria-label="searchInput"
          placeholder={placeholder}
          onChange={handlerOnSearch}
          className="flex flex-1 outline-none"
          {...restProps}
        />
      </div>
      <div className={`${isFloat ? "relative" : "h-full"}`}>
        {searchResults?.length > 0 && (
          <SearchResultContainer isFloat={isFloat}>
            {searchResults.map((option) => (
              <SearchResultOption
                option={option}
                key={`${option.id}-${option.name}`}
                onClick={() => handleOnSelectResult(option)}
              />
            ))}
          </SearchResultContainer>
        )}
        {searchResults?.length === 0 && !isLoading && (
          <SearchResultContainer isFloat={isFloat}>
            <NoResultOptions />
          </SearchResultContainer>
        )}
      </div>
    </div>
  );
}

SearchTextModal.propTypes = {
  value: PropTypes.string,
  isFloat: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSelectResult: PropTypes.func,
  placeholder: PropTypes.string,
  searchEndpoint: PropTypes.func,
  searchResults: PropTypes.arrayOf(PropTypes.shape()),
};

export default SearchTextModal;
