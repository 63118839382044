import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import AffordabilityTextSection from "./AffordabilityTextSection";
import AffordabilityGuideSection from "./AffordabilityGuideSection";

import { ButtonTooltip } from "../../../../../core/compose";
import { Budget } from "../../../../../core/compose/affordability";
import {
  Affordability,
  SchoolStatus,
  getColors
} from "../../../../../core/utils";

import { GetIpedsId } from "../../../../Common/Hooks";
import { Tooltip } from "../../../../../utils/DataLayers";

function AffordabilityInfo({
  show,
  theme,
  infoCard,
  chosenSchool,
  addYourMoneyPath
}) {
  const ipedsId = GetIpedsId();
  const divRef = useRef();

  const { award, school } = chosenSchool || {};
  const affordability = award?.postAwardSchoolCosts?.affordability;
  const colors = getColors(theme.color);
  const notAffordable = affordability === Affordability.NOT_AFFORDABLE;
  const schoolStatus = chosenSchool.schoolStatus;

  const [, setGuideHeight] = useState(0);

  useEffect(() => {
    const updateGuideHeightOnResize = () => {
      if (divRef.current) setGuideHeight(divRef.current.clientHeight);
    };

    window.addEventListener("resize", updateGuideHeightOnResize);
    return () =>
      window.removeEventListener("resize", updateGuideHeightOnResize);
  }, []);

  const updateGuideHeight = (node) => {
    divRef.current = node;
    if (node) setGuideHeight(node.clientHeight);
  };

  if (!show || !chosenSchool) return null;

  let collegeCosts;
  if (
    chosenSchool?.schoolStatus === SchoolStatus.ESTIMATED ||
    chosenSchool?.schoolStatus === SchoolStatus.INVALID
  ) {
    collegeCosts =
      chosenSchool?.estimatedPostAwardCosts?.schoolCosts ??
      chosenSchool.schoolCosts.totalCollegeCosts;
  } else {
    collegeCosts = award?.postAwardSchoolCosts
      ? award.postAwardSchoolCosts.schoolCosts
      : chosenSchool.schoolCosts.totalCollegeCosts;
  }

  let lifeBudget;
  if (
    chosenSchool?.schoolStatus === SchoolStatus.ESTIMATED ||
    chosenSchool?.schoolStatus === SchoolStatus.INVALID
  ) {
    lifeBudget =
      chosenSchool?.estimatedPostAwardCosts?.lifeBudget ??
      chosenSchool.schoolCosts.lifeBudget;
  } else {
    lifeBudget = award?.postAwardSchoolCosts
      ? award.postAwardSchoolCosts.lifeBudget
      : chosenSchool.schoolCosts.lifeBudget;
  }

  const uncoveredMoney = lifeBudget + collegeCosts;

  return (
    <>
      {!infoCard ? (
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 flex-col px-4">
            <div className="px-4 py-10 flex flex-row bg-white shadow-lg">
              <div
                id="before-financial"
                className="w-1/2 flex flex-row items-center justify-center"
              >
                {uncoveredMoney <= 0 ? (
                  <h1 className="text-xl font-bold text-center capitalize">
                    {chosenSchool.schoolStatus === SchoolStatus.ESTIMATED ||
                    chosenSchool.schoolStatus === SchoolStatus.INVALID
                      ? "With estimated aid your costs could be covered."
                      : "Great news! Your costs are covered!"}
                  </h1>
                ) : (
                  <div className="flex flex-col font-bold space-y-4">
                    <p className="text-xl text-center capitalize">
                      {schoolStatus === SchoolStatus.READY
                        ? "You still need:"
                        : chosenSchool.schoolStatus ===
                            SchoolStatus.ESTIMATED ||
                          chosenSchool.schoolStatus === SchoolStatus.INVALID
                        ? "With estimated aid you would need:"
                        : "Right now you need:"}
                    </p>
                    <h1
                      className={`
                        text-5xl
                        text-center
                        tablet:text-3xl
                        laptop:text-4xl
                        ${colors.primary.text}
                      `}
                    >
                      ${uncoveredMoney.toLocaleString("en-US")}
                    </h1>
                  </div>
                )}
                {schoolStatus !== SchoolStatus.READY && (
                  <ButtonTooltip
                    tooltipCloseButton={true}
                    tooltipColor={theme.color}
                    tooltipCloseOnClickOutside={true}
                    gaOnClickFunc={(text) =>
                      Tooltip({ text: text, ipeds: ipedsId })
                    }
                    tooltipClassName="-left-48 bottom-20"
                    text="This is the total amount of money you need for college - including your college bill + your life budget."
                  />
                )}
              </div>
              <div className="w-1/2">
                <div className="flex-row self-stretch">
                  <Budget
                    color={theme.color}
                    lifeBudget={lifeBudget}
                    collegeCosts={collegeCosts}
                    customClassName="flex flex-col space-y-5"
                    classNameCost="font-medium tablet:text-sm"
                    classNameLink="font-bold tablet:text-xs laptop:text-sm"
                  />
                </div>
              </div>
            </div>
            <div
              ref={updateGuideHeight}
              className="p-4 mt-4 flex flex-1 flex-row bg-white shadow-lg items-center"
            >
              <div className="py-2 pr-4 w-1/2 flex flex-1 flex-col">
                <h2 className="text-xl font-bold">How Will I Pay for This?</h2>
                <AffordabilityTextSection
                  color={theme.color}
                  notAffordable={notAffordable}
                  uncoveredMoney={uncoveredMoney}
                  addYourMoneyPath={addYourMoneyPath}
                  affordability={affordability}
                  schoolStatus={schoolStatus}
                  stateYearlySalary={school?.stateYearlySalary}
                />
              </div>
              <div
                style={{ maxHeight: "185px" }}
                className={`
                  w-1/2
                  flex
                  flex-1
                  flex-col
                  ${notAffordable ? "mb-1 self-end" : ""}
                `}
              >
                <AffordabilityGuideSection
                  affordability={affordability}
                  schoolStatus={schoolStatus}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-1 flex-col justify-center">
          <div
            id="before-financial"
            className="mb-4 flex flex-row justify-center"
          >
            {uncoveredMoney <= 0 ? (
              <h1 className="text-xl font-bold text-center capitalize">
                {chosenSchool.schoolStatus === SchoolStatus.ESTIMATED
                  ? "With estimated aid your costs could be covered."
                  : "Great news! Your costs are covered!"}
              </h1>
            ) : (
              <div className="flex flex-col font-bold space-y-4">
                <p className="text-xl text-center capitalize">
                  {schoolStatus === SchoolStatus.READY
                    ? "You still need:"
                    : chosenSchool.schoolStatus === SchoolStatus.ESTIMATED
                    ? "With estimated aid you would need:"
                    : "Right now you need:"}
                </p>
                <h1 className={`text-5xl text-center ${colors.primary.text}`}>
                  ${uncoveredMoney.toLocaleString("en-US")}
                </h1>
              </div>
            )}
          </div>
          <h2 className="text-xl font-bold">How Will I Pay for This?</h2>
          <AffordabilityTextSection
            color={theme.color}
            notAffordable={notAffordable}
            uncoveredMoney={uncoveredMoney}
            tooltipClassName="ml-10 bottom-20"
            addYourMoneyPath={addYourMoneyPath}
            schoolStatus={schoolStatus}
            stateYearlySalary={school?.stateYearlySalary}
          />
        </div>
      )}
    </>
  );
}

AffordabilityInfo.propTypes = {
  show: PropTypes.bool,
  theme: PropTypes.shape(),
  infoCard: PropTypes.bool,
  chosenSchool: PropTypes.shape(),
  addYourMoneyPath: PropTypes.string,
  showUploadTutorial: PropTypes.bool
};

export default AffordabilityInfo;
