import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";

import NewLabel from "./NewLabel";
import StarLabel from "./StarLabel";
import CollegeItem from "./CollegeItem";

import {
  CompareButton,
  CollegeSearchMagnifyingGlass,
} from "../../../../utils/DataLayers";

import { IconButton } from "../../../../core/buttons";
import { Compare, MagnifyGlass } from "../../../../core/icons";
import { useIsDesktopMQL } from "../../../../core/hooks";

function CollegeSelector({ student, colleges, selectedSchool, className }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useIsDesktopMQL();

  const showPinned = colleges.length > 0 && isDesktop;
  const selectedIpedsId = selectedSchool?.school?.ipedsId;
  const isSearchScreen = Object.is(location.pathname, "/college/search");

  const tmpSelectedIpedsId = useRef();
  const [favorite, setFavorite] = useState(null);
  const [localColleges, setLocalColleges] = useState([]);
  const [collegesLength, setCollegesLength] = useState(0);

  useEffect(() => {
    const localColleges = colleges.filter(
      (college) => college.programLength === student.chosenSchoolsYearType,
    );
    const favorite = localColleges.find((college) => college.isFavorite);
    if (favorite) {
      setFavorite(favorite);
      setLocalColleges(localColleges.filter((college) => !college.isFavorite));
    } else {
      setFavorite(null);
      setLocalColleges(localColleges);
    }
  }, [colleges, student.chosenSchoolsYearType]);

  useEffect(() => {
    const hasNew = colleges.some((college) => college.isNew === true);

    if (isSearchScreen && collegesLength !== localColleges.length && hasNew) {
      setCollegesLength(localColleges.length);
      const element = document.getElementById("college-selector-container");
      element.scrollTo({
        behavior: "smooth",
        left: element.getBoundingClientRect().right + 105,
      });
    }
  }, [localColleges, collegesLength, isSearchScreen, colleges]);

  useEffect(() => {
    if (selectedIpedsId && !isSearchScreen) {
      const element = document.getElementById(`college-${selectedIpedsId}`);
      if (element && selectedIpedsId !== tmpSelectedIpedsId.current) {
        tmpSelectedIpedsId.current = selectedIpedsId;
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "center",
        });
      }
    }
  }, [selectedIpedsId, isSearchScreen, localColleges]);

  const handlerOnClickCompare = () => {
    CompareButton({ ipeds: selectedIpedsId });
    navigate("/college/compare");
  };

  const handlerOnClickSearch = () => {
    CollegeSearchMagnifyingGlass({ ipeds: selectedIpedsId });
    navigate("/college/search");
  };

  return (
    <div
      id="college-selector-container"
      className={`
        p-4
        flex
        flex-row
        bg-white
        divide-x-2
        divide-black
        ${className}
      `}
    >
      {(showPinned || favorite) && (
        <div
          className={`
            flex
            flex-row
            space-x-1
            ${
              favorite
                ? "mr-3 mobile-sm:mr-1 mobile:mr-1 tablet-sm:mr-1"
                : "mr-4 mobile-sm:mr-2 mobile:mr-2 tablet-sm:mr-2"
            }
          `}
        >
          {showPinned && (
            <IconButton
              label="compare"
              onClick={handlerOnClickCompare}
              className="
                my-1
                text-white
                bg-primary-green
                border-primary-green
                hover:bg-white
                hover:text-primary-green
              "
              icon={
                <Compare
                  size={45}
                  iconColor="fill-black"
                  fillColor="fill-alternative-yellow"
                  strokeColor="stroke-alternative-yellow"
                />
              }
              style={{
                width: isDesktop ? 105 : 70,
                height: isDesktop ? 105 : 70,
              }}
            />
          )}
          {favorite && (
            <div className="my-1 relative">
              <CollegeItem
                id="favorite"
                college={favorite}
                isDesktop={isDesktop}
                selectedSchool={selectedSchool}
                handleOnCollegeSelect={() =>
                  navigate(`/college/id/${favorite.ipedsId}`)
                }
              />
              <StarLabel show={true} />
            </div>
          )}
        </div>
      )}
      <div
        id="college-selector-container-1"
        className={`
          flex
          w-full
          flex-no-wrap
          items-center
          overflow-auto
          whitespace-no-wrap
          ${!isSearchScreen && "pr-3 mobile-sm:pr-1 mobile:pr-1 tablet-sm:pr-1"}
          ${
            (showPinned || favorite) &&
            "pl-3 mobile-sm:pr-1 mobile:pl-1 tablet-sm:pl-1"
          }
        `}
      >
        {localColleges &&
          localColleges.map((college) => (
            <div
              className="my-1 relative"
              key={`${college.name}-${college.ipedsId}`}
            >
              <CollegeItem
                college={college}
                isDesktop={isDesktop}
                selectedSchool={selectedSchool}
                id={`college-${college.ipedsId}`}
                handleOnCollegeSelect={() =>
                  navigate(`/college/id/${college.ipedsId}`)
                }
              />
              <NewLabel show={college.isNew} />
            </div>
          ))}
        <Tooltip
          place="bottom"
          effect="solid"
          delayShow={500}
          id="college-thumbnail-selector"
        />
      </div>
      {!isSearchScreen && (
        <div className="my-1">
          <IconButton
            icon={<MagnifyGlass size={50} />}
            onClick={handlerOnClickSearch}
            className="
              ml-4
              mobile:ml-2
              border-black
              mobile-sm:ml-2
              tablet-sm:ml-2
            "
            style={{
              height: isDesktop ? 105 : 70,
              width: isDesktop ? 105 : 70,
            }}
          />
        </div>
      )}
    </div>
  );
}

CollegeSelector.propTypes = {
  colleges: PropTypes.array,
  className: PropTypes.string,
  selectedSchool: PropTypes.shape(),
};

export default CollegeSelector;
