import PropTypes from "prop-types";

import { Close, ShortArrow } from "../../../core/icons";

function Header({ title, onBack, titleClassname = "" }) {
  return (
    <div className="flex flex-row justify-between items-center">
      {onBack && (
        <div role="button" onClick={onBack}>
          <ShortArrow size={30} />
        </div>
      )}
      <span className={`ml-4 flex-1 text-3xl font-black ${titleClassname}`}>
        {title}
      </span>
      <div role="button" onClick={onBack}>
        <Close />
      </div>
    </div>
  );
}

Header.propTypes = {
  onBack: PropTypes.func,
  titleClassname: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default Header;
