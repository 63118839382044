import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Api from "../services/Api";
import { useStudent } from "../redux/Student/hooks";
import { Items, loadState, saveState } from "../redux/storePersist";

export function useAuthentication() {
  const [token, setToken] = useState(null);
  const [ready, setReady] = useState(false);
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    getIdTokenClaims,
    logout,
  } = useAuth0();

  useEffect(() => {
    const userInteraction = async () => {
      saveState(
        {
          firstCall: true,
          isLoggedIn: true,
          validationTime: 180 * 60 * 1000,
          date: new Date().toISOString(),
        },
        Items.GTM_STATE
      );
    };

    (async () => {
      setReady(false);
      setToken(null);

      if (!isLoading) {
        if (isAuthenticated) {
          window.addEventListener("scroll", userInteraction);
          window.addEventListener("mousemove", userInteraction);

          const getToken = async (refetch = false) => {
            const { date, firstCall, validationTime } = loadState(
              Items.GTM_STATE
            );

            const currentDate = new Date();
            const loadedDate = date ? new Date(date) : new Date();
            const invalidDate =
              Math.abs(currentDate - loadedDate) > validationTime;

            const newToken = refetch
              ? Math.abs(currentDate - loadedDate) < validationTime
              : !refetch;

            if (!invalidDate && newToken) {
              await getAccessTokenSilently();
              saveState(
                {
                  date,
                  firstCall,
                  isLoggedIn: true,
                  validationTime: 180 * 60 * 1000,
                },
                Items.GTM_STATE
              );
            }
            const claims = await getIdTokenClaims();
            return claims.__raw;
          };
          Api.configure({
            token: getToken,
          });
          const token = await getToken();
          setToken(token);
          setReady(true);
        } else {
          const { date, validationTime } = loadState(Items.GTM_STATE);

          const currentDate = new Date();
          const loadedDate = date ? new Date(date) : new Date();
          const invalidDate =
            Math.abs(currentDate - loadedDate) > validationTime;

          if (invalidDate)
            saveState(
              {
                firstCall: true,
                isLoggedIn: false,
                validationTime: 2 * 60 * 1000,
                date: new Date().toISOString(),
              },
              Items.GTM_STATE
            );
          return await loginWithRedirect();
        }
      }
    })();

    return () => {
      window.removeEventListener("scroll", userInteraction);
      window.removeEventListener("mousemove", userInteraction);
    };
  }, [
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    getIdTokenClaims,
  ]);

  return { isAuthenticated: ready, token, logout };
}

export function useLogin() {
  const auth0 = useAuth0();
  const student = useStudent();
  const { isAuthenticated, logout } = useAuthentication();

  const [error, setError] = useState(null);
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [userExists, setUserExists] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        if (isAuthenticated) {
          const claims = await auth0.getIdTokenClaims();
          setProfile(claims);
          if (!student.isLoggedIn) {
            const exists = await Api.checkUserExists();
            setUserExists(exists);
          } else {
            setUserExists(true);
          }
          setIsLoading(false);
        }
      } catch (e) {
        setError(e);
      }
    })();
  }, [isAuthenticated, auth0, student.isLoggedIn]);

  return { isLoading, userExists, auth0, profile, error, logout };
}

export function forceUserToSignIn() {
  window.location.href = "/force-login";
}
