import PropTypes from "prop-types";

function ScrollToTop({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.65"
        className={color}
        d="M24.145 17.8323L21.7587 20.2525L15.0937 13.4928L15.094 29H11.719V13.4928L5.05394 20.2525L2.66767 17.8323L13.4065 6.94091L24.145 17.8323ZM27 0H0V3.423H26.9993L27 0Z"
        fill="black"
      />
    </svg>
  );
}

ScrollToTop.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default ScrollToTop;
