import { ButtonTooltip } from "../../../../../core/compose";
import {
  CoveredOutOf,
  TotalEstimatedCost,
  AffordabilityThumbs
} from "../../../../../core/compose/affordability";
import { SchoolStatus, AwardStatus } from "../../../../../core/utils";

import AffordableButtons from "../../../Common/AffordableButtons";
import AddYourMoneyButton from "../../../Common/AddYourMoneyButton";
import EstimateYourAidButton from "../../../Common/EstimateYourAidButton";
import ReuploadAwardButton from "../../../Common/ReuploadAwardButton";

import { Tooltip } from "../../../../../utils/DataLayers";
import { GetIpedsId } from "../../../../Common/Hooks";

function DescriptionContainer({ children }) {
  return (
    <div className="py-2 px-4 text-sm bg-alternative-yellow">{children}</div>
  );
}

export function getChartComponent(schoolStatus, props) {
  const {
    theme,
    award,
    student,
    totalCost,
    isDesktop,
    totalAward,
    addYourMoneyPath,
    hasAutoEstimatedAid,
    onClickViewAwardLetter,
    onClickOnboardingTooltip,
    onClickReuploadAwardLetter
  } = props;

  const ipedsId = GetIpedsId();
  const estimateYourAidPath = `/college/id/${ipedsId}/estimate-financial-aid`;

  const tooltipOptions = {
    text: "Good news! Based on your recently uploaded letter, we were able to estimate some of your financial aid",
    open: !student?.onboardingAutomaticAidEstimation,
    onClose: onClickOnboardingTooltip
  };

  switch (schoolStatus) {
    case SchoolStatus.READY:
      return (
        <>
          <div className="mb-2 flex flex-col items-center mobile:text-sm">
            <AffordabilityThumbs
              theme={theme}
              className="text-2xl"
              show={isDesktop}
            />
            <CoveredOutOf
              totalAward={totalAward}
              tooltip={true}
              color={theme.color}
              totalSchoolCosts={totalCost}
              gaOnClickFunc={(text) => Tooltip({ text: text, ipeds: ipedsId })}
              className="desktop:text-xl tablet:text-lg mobile:text-md"
              tooltipClassName={
                !isDesktop ? "-left-52 bottom-20 mobile-sm:-left-60" : ""
              }
            />
          </div>
          <AffordableButtons
            show={true}
            color={theme.color}
            className={isDesktop ? "mt-4 w-full" : "my-2 text-xs"}
            onClickViewAwardLetter={onClickViewAwardLetter}
            onClickReuploadAwardLetter={onClickReuploadAwardLetter}
          />
        </>
      );
    case SchoolStatus.INVALID:
      return (
        <>
          {isDesktop && (
            <TotalEstimatedCost
              totalCost={totalCost}
              className="mt-5 text-xl"
            />
          )}
          {totalAward > 0 && (
            <p
              className={`text-center font-bold text-primary-blue ${
                isDesktop ? "text-xl" : "text-md mb-2"
              }`}
            >
              Total estimated financial aid: ${totalAward.toLocaleString()}
            </p>
          )}
          <DescriptionContainer>
            {award?.status === AwardStatus.TIMEOUT ? (
              <p className="text-center text-sm">
                There was an issue while uploading your award image. Please try
                again.
              </p>
            ) : (
              <p className="text-center text-sm">
                The image you uploaded did not have any financial information on
                it.
              </p>
            )}
          </DescriptionContainer>
          <div
            className={`flex flex-row space-x-2 ${
              !isDesktop && "text-xs mb-4"
            }`}
          >
            <>
              <EstimateYourAidButton path={estimateYourAidPath} />
              {!isDesktop && <ReuploadAwardButton path={addYourMoneyPath} />}
            </>
            {isDesktop && <ReuploadAwardButton path={addYourMoneyPath} />}
          </div>
          {!isDesktop && (
            <TotalEstimatedCost
              totalCost={totalCost}
              className="px-3 text-lg"
            />
          )}
        </>
      );
    case SchoolStatus.IN_REVIEW:
      return isDesktop ? (
        <>
          <DescriptionContainer>
            <TotalEstimatedCost
              totalCost={totalCost}
              className="my-4 text-xl"
            />
            <div className="flex flex-row justify-center">
              <p className="mb-2 text-xl font-bold text-center text-primary-blue">
                Your financial aid award letter is being reviewed.
              </p>
              <ButtonTooltip
                tooltipCloseButton={true}
                tooltipColor={theme.color}
                tooltipCloseOnClickOutside={true}
                tooltipClassName="bottom-20"
                text="One of our team members will analyze your letter as soon as possible. We’ll email you when your results are ready."
              />
            </div>
            <AffordableButtons
              show={true}
              color={theme.color}
              className="mt-4 w-full"
              primaryLabel="View Award Letter"
              onClickViewAwardLetter={() =>
                onClickViewAwardLetter({
                  isManualReview: true
                })
              }
              onClickReuploadAwardLetter={onClickReuploadAwardLetter}
            />
          </DescriptionContainer>
        </>
      ) : (
        <>
          <DescriptionContainer>
            <p className="mb-2 text-lg font-medium text-center">
              Your financial aid award letter is being reviewed!
            </p>
            <p className="text-center text-sm">
              One of our team members will check out your award as soon as
              possible. We’ll email you when your results are ready.
            </p>
          </DescriptionContainer>
          <div className="my-2">
            <ReuploadAwardButton path={addYourMoneyPath} />
          </div>
          <TotalEstimatedCost totalCost={totalCost} className="px-3 text-lg" />
        </>
      );
    case SchoolStatus.GRANTS_REVIEWED:
      return (
        <>
          <div className="mb-4 mt-6 flex flex-col items-center">
            <CoveredOutOf
              totalAward={totalAward}
              tooltip={true}
              color={theme.color}
              totalSchoolCosts={totalCost}
              className={isDesktop ? "text-xl" : "text-sm"}
              tooltipClassName={
                !isDesktop ? "-left-28 bottom-20 mobile-sm:-left-48" : ""
              }
              gaOnClickFunc={(text) => Tooltip({ text: text, ipeds: ipedsId })}
            />
          </div>
          <DescriptionContainer>
            <p className="text-center text-sm">
              Our team double-checked your letter and we found no guaranteed
              free money in the offer. You can re-upload your letter if new
              information emerges.
            </p>
          </DescriptionContainer>
          <AffordableButtons
            show={true}
            color={theme.color}
            className={isDesktop ? "my-4 w-full" : "my-2 text-xs"}
            onClickViewAwardLetter={onClickViewAwardLetter}
            onClickReuploadAwardLetter={onClickReuploadAwardLetter}
          />
        </>
      );
    case SchoolStatus.ESTIMATED:
    case SchoolStatus.NO_AID:
      return (
        <>
          <TotalEstimatedCost
            totalCost={totalCost}
            className={`mt-5 text-${isDesktop ? "xl" : "md"}`}
          />
          {totalAward > 0 && (
            <div className="flex flex-row items-center justify-center">
              <p
                className={`text-center font-bold text-primary-blue ${
                  isDesktop ? "text-xl" : "text-md mb-2"
                }`}
              >
                Total estimated financial aid: ${totalAward.toLocaleString()}
              </p>
              {hasAutoEstimatedAid && (
                <ButtonTooltip
                  tooltipCloseButton={true}
                  tooltipColor={theme.color}
                  tooltipCloseOnClickOutside={true}
                  gaOnClickFunc={(text) =>
                    Tooltip({ text: text, ipeds: ipedsId })
                  }
                  tooltipClassName="bottom-20"
                  tooltipDirection={"BOTTOM-LEFT"}
                  tooltipOptions={tooltipOptions}
                />
              )}
            </div>
          )}
          <div
            className={`flex flex-row space-x-2 ${
              !isDesktop && "text-xs mb-4"
            }`}
          >
            <>
              <EstimateYourAidButton path={estimateYourAidPath} />
              {!isDesktop && <AddYourMoneyButton path={addYourMoneyPath} />}
            </>
            {isDesktop && <AddYourMoneyButton path={addYourMoneyPath} />}
          </div>
        </>
      );
    default:
      return isDesktop ? (
        <>
          <TotalEstimatedCost totalCost={totalCost} className="mt-5 text-xl" />
          <AddYourMoneyButton path={addYourMoneyPath} />
        </>
      ) : (
        <>
          <DescriptionContainer>
            <p className="text-center text-sm">
              Once you have your award letter, upload it to DecidED to see how
              much of the estimated costs will be covered by free money.
            </p>
          </DescriptionContainer>
          <AddYourMoneyButton path={addYourMoneyPath} />
          <TotalEstimatedCost totalCost={totalCost} className="px-3 text-lg" />
        </>
      );
  }
}
