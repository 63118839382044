import React, { useEffect, useContext, useState } from "react";
import { Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import { useJsApiLoader } from "@react-google-maps/api";

import JoinOrg from "./components/JoinOrg";
import Home from "./components/Common/Home";
import Invitation from "./components/Invitation";
import CollegeRoutes from "./components/College";
import EmailUpdate from "./components/EmailUpdate";
import CreateAccount from "./components/CreateAccount";
import SubscriptionSettings from "./components/SubscriptionSettings";
import CustomSwitch from "./components/Common/CustomSwitch";
import PrivateRoute from "./components/Common/PrivateRoute";
import ScrollToTop from "./components/Common/ScrollToTop";

import ModalContext from "./contexts/ModalContext";
import { useStudent } from "./redux/Student/hooks";
import { Items, removeState } from "./redux/storePersist";

import "./styles/tailwind.output.css";
import "react-toastify/dist/ReactToastify.css";

function SingUp() {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const redirectToSignUp = async () => {
      if (!isLoading) {
        if (!isAuthenticated)
          await loginWithRedirect({
            authorizationParams: { screen_hint: "signup" }
          });
        else navigate("/");
      }
    };

    redirectToSignUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  return null;
}

function Logout() {
  const { logout } = useAuth0();
  const returnTo = window.SERVER_DATA.REACT_APP_DECIDED_URL;
  useEffect(() => {
    removeState(Items.GTM_STATE);
    logout({ logoutParams: { returnTo } });
  });
  return null;
}

function ForceLogin() {
  const { logout } = useAuth0();
  useEffect(() => {
    removeState(Items.GTM_STATE);
    logout({ logoutParams: { returnTo: window.location.origin } });
  });
  return null;
}

function Authenticate() {
  const location = useLocation();
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const qs = new URLSearchParams(location.search.slice(1));
    setRedirect(qs.get("next") || "/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirect) return <Navigate to={redirect} />;
  return null;
}

function App() {
  const { student } = useStudent();
  const { isAuthenticated } = useAuth0();

  const modalContext = useContext(ModalContext);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: window.SERVER_DATA.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  useEffect(() => {
    try {
      const studentId = student?.studentId;
      if (studentId && !document.getElementById("userId")) {
        const userIdDiv = document.createElement("div");

        userIdDiv.id = "userId";
        userIdDiv.style = "display: none;";
        userIdDiv.setAttribute("data-student-id", studentId);

        document.body.appendChild(userIdDiv);
      }
    } catch (e) {
      console.error(e);
    }
  }, [student]);

  return (
    <>
      <ScrollToTop />
      <CustomSwitch>
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<SingUp />} />
        <Route path="/force-login" element={<ForceLogin />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/invitation/:invitationId" element={<Invitation />} />
        <Route
          path="/join-org/:orgCode"
          element={
            <PrivateRoute>
              <JoinOrg />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/authenticate"
          element={
            <PrivateRoute>
              <Authenticate />
            </PrivateRoute>
          }
        />
        <Route
          path="/subscription-settings"
          element={
            <PrivateRoute>
              <SubscriptionSettings
                openModal={modalContext.openModalHandler}
                modalResponse={modalContext.modalResponse}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/confirm-email-change/:updateId"
          element={
            <PrivateRoute>
              <EmailUpdate />
            </PrivateRoute>
          }
        />
        <Route
          path="/college/*"
          element={
            <PrivateRoute>
              {isLoaded && (
                <CollegeRoutes
                  openModal={modalContext.openModalHandler}
                  modalResponse={modalContext.modalResponse}
                  modalResponseData={modalContext.modalResponseData}
                />
              )}
            </PrivateRoute>
          }
        />
      </CustomSwitch>
      <ToastContainer />
    </>
  );
}

export default App;
