import PropTypes from "prop-types";
import { SmallStar } from "../../../../core/icons";

function StarLabel({ show }) {
  if (!show) return null;

  return (
    <div className="-top-2 -right-1 absolute">
      <SmallStar size={20} fillColor="fill-alternative-yellow" />
    </div>
  );
}

StarLabel.propTypes = {
  show: PropTypes.bool,
};

export default StarLabel;
