import { createRef, useEffect } from "react";

function GoogleMapView({
  className,
  schoolName,
  schoolLatitude,
  studentLatitude,
  schoolLongitude,
  studentLongitude,
}) {
  const mapDivRef = createRef();

  useEffect(() => {
    const infoWindow = new window.google.maps.InfoWindow();
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();

    const InfoControl = () => {
      // Set CSS for the control border.
      const controlUI = document.createElement("div");
      controlUI.title = "Route info";
      controlUI.style.margin = "10px";
      controlUI.style.height = "40px";
      controlUI.style.cursor = "pointer";
      controlUI.style.textAlign = "center";
      controlUI.style.borderRadius = "2px";
      controlUI.style.backgroundColor = "#fff";
      controlUI.style.boxShadow = "rgba(0,0,0,.3) 0px 1px 4px -1px";
      // Set CSS for the control interior.
      const controlText = document.createElement("div");
      controlText.innerHTML = "Info";
      controlText.style.fontSize = "18px";
      controlText.style.padding = "0 17px";
      controlText.style.fontWeight = "500";
      controlText.style.lineHeight = "40px";
      controlText.style.color = "rgb(0,0,0)";
      controlText.style.fontFamily = "Roboto,Arial,sans-serif";
      controlUI.appendChild(controlText);
      controlUI.addEventListener("click", () => {
        infoWindow.open(map, marker);
      });
      return controlUI;
    };

    const directionRequesthandler = (response, status) => {
      if (status === "OK") {
        directionsRenderer.setDirections(response);
        infoWindow.setContent(`
          <div class="p-2 flex flex-col font-semibold items-left justify-left">
            <p class="mb-2 text-confident-blue">Route Info:</p>
            <p class="text-black">Destination: <span class="text-confident-blue">${schoolName}</span></p>
            <p class="text-black">Distance: <span class="text-confident-blue">${response.routes[0].legs[0].distance.text}</span></p>
            <p class="text-black">Time: <span class="text-confident-blue">${response.routes[0].legs[0].duration.text}</span></p>
          </div>
        `);
      }
    };

    const map = new window.google.maps.Map(mapDivRef.current, {
      zoom: studentLatitude && studentLongitude ? 7 : 15,
      center: { lat: Number(schoolLatitude), lng: Number(schoolLongitude) },
      mapTypeControlOptions: {
        mapTypeIds: [
          window.google.maps.MapTypeId.ROADMAP,
          window.google.maps.MapTypeId.TERRAIN,
        ],
      },
    });

    const marker = new window.google.maps.Marker({
      map: map,
      title: schoolName,
      position: { lat: Number(schoolLatitude), lng: Number(schoolLongitude) },
    });

    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
      InfoControl()
    );

    if (studentLatitude && studentLongitude) {
      directionsRenderer.setMap(map);

      directionsService.route(
        {
          origin: `${studentLatitude},${studentLongitude}`,
          destination: `${schoolLatitude},${schoolLongitude}`,
          travelMode: window.google.maps.TravelMode.DRIVING,
          transitOptions: {
            departureTime: new Date(),
          },
        },
        directionRequesthandler
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolName, studentLatitude, studentLongitude]);

  return (
    <div className={`h-96 flex flex-1 ${className}`}>
      <div className="flex flex-1" ref={mapDivRef} id="map"></div>
    </div>
  );
}

export default GoogleMapView;
