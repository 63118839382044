import PropTypes from "prop-types";

function Compare({
  size = 25,
  iconColor = "fill-white",
  fillColor = "fill-black",
  strokeColor = "stroke-black"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 45 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1.69231"
        width="43"
        strokeWidth="2"
        height="36.0769"
        className={`${fillColor} ${strokeColor}`}
      />
      <path
        className={iconColor}
        d="M17.9592 8.32629C17.6961 8.33626 17.4465 8.4726 17.2633 8.70641L12.1072 15.0846L9.63085 12.7145C9.41807 12.5121 9.14907 12.4224 8.8829 12.4651C8.61672 12.5078 8.37512 12.6794 8.21113 12.9423C8.04714 13.2051 7.97415 13.5377 8.00818 13.8671C8.04222 14.1964 8.1805 14.4956 8.39268 14.6989L11.574 17.7399C11.7687 17.9254 12.0114 18.0169 12.2567 17.9974C12.502 17.9779 12.7332 17.8486 12.9071 17.6337L18.6913 10.4785C18.838 10.303 18.9385 10.0767 18.9794 9.82935C19.0204 9.582 18.9999 9.32512 18.9208 9.09239C18.8416 8.85967 18.7074 8.66198 18.5358 8.52527C18.3642 8.38855 18.1632 8.31919 17.9592 8.32629Z"
      />
      <path
        className={iconColor}
        d="M28.3323 10.926L30.916 13.4991L28.3323 16.0721C28.227 16.1766 28.1434 16.3009 28.0864 16.4378C28.0294 16.5747 28 16.7216 28 16.8699C28 17.0182 28.0294 17.1651 28.0864 17.302C28.1434 17.4389 28.227 17.5632 28.3323 17.6677C28.4367 17.773 28.561 17.8566 28.6979 17.9136C28.8348 17.9707 28.9816 18 29.1299 18C29.2782 18 29.425 17.9707 29.5619 17.9136C29.6988 17.8566 29.823 17.773 29.9275 17.6677L32.5 15.0834L35.0725 17.6677C35.177 17.773 35.3012 17.8566 35.4381 17.9136C35.575 17.9707 35.7218 18 35.8701 18C36.0184 18 36.1652 17.9707 36.3021 17.9136C36.439 17.8566 36.5633 17.773 36.6677 17.6677C36.773 17.5632 36.8566 17.4389 36.9136 17.302C36.9706 17.1651 37 17.0182 37 16.8699C37 16.7216 36.9706 16.5747 36.9136 16.4378C36.8566 16.3009 36.773 16.1766 36.6677 16.0721L34.084 13.4991L36.6677 10.926C36.8792 10.7144 36.9981 10.4275 36.9981 10.1282C36.9981 9.82902 36.8792 9.54206 36.6677 9.33048C36.4562 9.1189 36.1693 9.00003 35.8701 9.00003C35.571 9.00003 35.2841 9.1189 35.0725 9.33048L32.5 11.9148L29.9275 9.33048C29.8227 9.22571 29.6984 9.14261 29.5615 9.08591C29.4247 9.02921 29.278 9.00003 29.1299 9.00003C28.9818 9.00003 28.8351 9.02921 28.6982 9.08591C28.5614 9.14261 28.437 9.22571 28.3323 9.33048C28.2275 9.43524 28.1445 9.55961 28.0878 9.69649C28.0311 9.83337 28.0019 9.98008 28.0019 10.1282C28.0019 10.2764 28.0311 10.4231 28.0878 10.56C28.1445 10.6969 28.2275 10.8212 28.3323 10.926Z"
      />
      <path
        className={iconColor}
        d="M23.0769 7.61542C22.7709 7.61542 22.4774 7.73698 22.261 7.95337C22.0447 8.16976 21.9231 8.46324 21.9231 8.76926V31.8462C21.9231 32.1522 22.0447 32.4457 22.261 32.6621C22.4774 32.8785 22.7709 33 23.0769 33C23.383 33 23.6764 32.8785 23.8928 32.6621C24.1092 32.4457 24.2308 32.1522 24.2308 31.8462V8.76926C24.2308 8.46324 24.1092 8.16976 23.8928 7.95337C23.6764 7.73698 23.383 7.61542 23.0769 7.61542Z"
      />
      <path
        className={iconColor}
        d="M18.4615 20.3077H9.23075C8.92473 20.3077 8.63125 20.4293 8.41486 20.6457C8.19847 20.8621 8.0769 21.1556 8.0769 21.4616C8.0769 21.7676 8.19847 22.0611 8.41486 22.2775C8.63125 22.4939 8.92473 22.6154 9.23075 22.6154H18.4615C18.7675 22.6154 19.061 22.4939 19.2774 22.2775C19.4938 22.0611 19.6154 21.7676 19.6154 21.4616C19.6154 21.1556 19.4938 20.8621 19.2774 20.6457C19.061 20.4293 18.7675 20.3077 18.4615 20.3077Z"
      />
      <path
        className={iconColor}
        d="M18.4615 24.9231H9.23075C8.92473 24.9231 8.63125 25.0447 8.41486 25.2611C8.19847 25.4775 8.0769 25.7709 8.0769 26.077C8.0769 26.383 8.19847 26.6765 8.41486 26.8929C8.63125 27.1092 8.92473 27.2308 9.23075 27.2308H18.4615C18.7675 27.2308 19.061 27.1092 19.2774 26.8929C19.4938 26.6765 19.6154 26.383 19.6154 26.077C19.6154 25.7709 19.4938 25.4775 19.2774 25.2611C19.061 25.0447 18.7675 24.9231 18.4615 24.9231Z"
      />
      <path
        className={iconColor}
        d="M18.4615 29.5385H9.23075C8.92473 29.5385 8.63125 29.6601 8.41486 29.8765C8.19847 30.0928 8.0769 30.3863 8.0769 30.6923C8.0769 30.9984 8.19847 31.2918 8.41486 31.5082C8.63125 31.7246 8.92473 31.8462 9.23075 31.8462H18.4615C18.7675 31.8462 19.061 31.7246 19.2774 31.5082C19.4938 31.2918 19.6154 30.9984 19.6154 30.6923C19.6154 30.3863 19.4938 30.0928 19.2774 29.8765C19.061 29.6601 18.7675 29.5385 18.4615 29.5385Z"
      />
      <path
        className={iconColor}
        d="M36.9232 20.3077H27.6924C27.3864 20.3077 27.0929 20.4293 26.8765 20.6457C26.6601 20.8621 26.5386 21.1556 26.5386 21.4616C26.5386 21.7676 26.6601 22.0611 26.8765 22.2775C27.0929 22.4939 27.3864 22.6154 27.6924 22.6154H36.9232C37.2292 22.6154 37.5227 22.4939 37.7391 22.2775C37.9555 22.0611 38.077 21.7676 38.077 21.4616C38.077 21.1556 37.9555 20.8621 37.7391 20.6457C37.5227 20.4293 37.2292 20.3077 36.9232 20.3077Z"
      />
      <path
        className={iconColor}
        d="M36.9232 24.9231H27.6924C27.3864 24.9231 27.0929 25.0447 26.8765 25.2611C26.6601 25.4775 26.5386 25.7709 26.5386 26.077C26.5386 26.383 26.6601 26.6765 26.8765 26.8929C27.0929 27.1092 27.3864 27.2308 27.6924 27.2308H36.9232C37.2292 27.2308 37.5227 27.1092 37.7391 26.8929C37.9555 26.6765 38.077 26.383 38.077 26.077C38.077 25.7709 37.9555 25.4775 37.7391 25.2611C37.5227 25.0447 37.2292 24.9231 36.9232 24.9231Z"
      />
      <path
        className={iconColor}
        d="M36.9232 29.5385H27.6924C27.3864 29.5385 27.0929 29.6601 26.8765 29.8765C26.6601 30.0928 26.5386 30.3863 26.5386 30.6923C26.5386 30.9984 26.6601 31.2918 26.8765 31.5082C27.0929 31.7246 27.3864 31.8462 27.6924 31.8462H36.9232C37.2292 31.8462 37.5227 31.7246 37.7391 31.5082C37.9555 31.2918 38.077 30.9984 38.077 30.6923C38.077 30.3863 37.9555 30.0928 37.7391 29.8765C37.5227 29.6601 37.2292 29.5385 36.9232 29.5385Z"
      />
    </svg>
  );
}

Compare.propTypes = {
  size: PropTypes.number,
  iconColor: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string
};

export default Compare;
