import Types from "./types";
import { SortOrderColleges } from "../../utils/SortOrderColleges";

const INITIAL_STATE = {
  schemaTypes: null,
  isLoggedIn: false,
  isLoading: false,
  student: null,
  guides: null,
  error: null,
};

export const studentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
    case Types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case Types.SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case Types.SET_GUIDES:
      return {
        ...state,
        guides: action.guides,
      };
    case Types.SET_SCHEMA_TYPES:
      return {
        ...state,
        schemaTypes: action.schemaTypes,
      };
    case Types.SET_STUDENT:
      return {
        ...state,
        student: action.student,
      };
    case Types.ADD_CHOSEN_SCHOOL:
      const pairedIdAdd = action.chosenSchool.pairedId;
      const chosenSchoolsAdd =
        state.student?.chosenSchools?.filter(
          (x) => x.pairedId !== pairedIdAdd
        ) || [];
      return {
        ...state,
        student: {
          ...state.student,
          chosenSchools: [...chosenSchoolsAdd, action.chosenSchool],
        },
      };
    case Types.UPDATE_CHOSEN_SCHOOL:
      const pairedIdUpdate = action.chosenSchool.pairedId;
      const chosenSchoolsUpdate =
        state.student?.chosenSchools?.map((x) => {
          if (x.pairedId === pairedIdUpdate) return action.chosenSchool;
          return x;
        }) || [];
      return {
        ...state,
        student: {
          ...state.student,
          chosenSchools: [...chosenSchoolsUpdate],
        },
      };
    case Types.REMOVE_CHOSEN_SCHOOL:
      const pairedIdRemove = action.pairedId;
      const chosenSchoolsRemove =
        state.student?.chosenSchools?.filter(
          (x) => x.pairedId !== pairedIdRemove
        ) || [];
      return {
        ...state,
        student: {
          ...state.student,
          chosenSchools: [...chosenSchoolsRemove],
        },
      };
    case Types.RESET_CHOSEN_SCHOOLS:
      return {
        ...state,
        student: {
          ...state.student,
          chosenSchools: [],
        },
      };
    case Types.UPDATE_CHOSEN_SCHOOLS:
      const sort = action.newSort.sort;
      const isFavorite = action.newSort.isFavorite;
      const newSortOrder = action.newSort.newSortOrder;
      const currentSchool = action.newSort.chosenSchool;
      const remainChosenSchools = state.student?.chosenSchools?.filter(
        (chosenSchool) =>
          chosenSchool.school.programLength !==
          state.student?.chosenSchoolsYearType
      );
      const currentChosenSchools = state.student?.chosenSchools?.filter(
        (chosenSchool) =>
          chosenSchool.school.programLength ===
          state.student?.chosenSchoolsYearType
      );
      const newChosenSchools = SortOrderColleges({
        colleges: currentChosenSchools,
        college: currentSchool,
        newSortOrder,
        isFavorite,
        sort,
      });
      return {
        ...state,
        student: {
          ...state.student,
          chosenSchools: [...remainChosenSchools, ...newChosenSchools],
        },
      };
    case Types.ADD_CHOSEN_SCHOOL_MAJOR:
      const newChosenSchoolsAddMajor = state.student?.chosenSchools.map(
        (school) => {
          if (school.pairedId === action.pairedId) {
            return {
              ...school,
              selectedMajors: school.selectedMajors.concat(action.major),
            };
          }
          return school;
        }
      );
      return {
        ...state,
        student: {
          ...state.student,
          chosenSchools: [...newChosenSchoolsAddMajor],
        },
      };
    case Types.REMOVE_CHOSEN_SCHOOL_MAJOR:
      const newChosenSchoolsRemoveMajor = state.student?.chosenSchools.map(
        (school) => {
          if (school.pairedId === action.pairedId) {
            return {
              ...school,
              selectedMajors: school.selectedMajors.filter(
                (major) => major.code !== action.major.code
              ),
            };
          }
          return school;
        }
      );
      return {
        ...state,
        student: {
          ...state.student,
          chosenSchools: [...newChosenSchoolsRemoveMajor],
        },
      };
    case Types.UPDATE_CHOSEN_SCHOOL_YEAR_TYPE:
      return {
        ...state,
        student: {
          ...state.student,
          chosenSchoolsYearType: action.chosenSchoolsYearType,
        },
      };
    default:
      return state;
  }
};
