import PropTypes from "prop-types";

function AffordabilityLabel({ show, className }) {
  if (!show) return null;

  return (
    <h2
      className={`
        ${className ? className : "mb-2 text-base font-bold "}
      `}
    >
      Affordability
    </h2>
  );
}

AffordabilityLabel.propTypes = {
  show: PropTypes.bool,
};

export default AffordabilityLabel;
