import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import DotsButton from "./DotsButton";
import AddToMyListButton from "./AddToMyListButton";
import { DeleteSchoolModal } from "../../../../Modals";

import { FilterType } from "../../../../../core/icons";
import { CollegeType } from "../../../../../core/utils";
import { useIsDesktopMQL } from "../../../../../core/hooks";

import Api from "../../../../../services/Api";
import { joinChosenSchool } from "../../../../../redux/Student/actions";
import { ExploreCollegesAddToList } from "../../../../../utils/DataLayers";

import CaLogo from "../../../../../assets/images/CA_logo.png";

const collegeMapDictionary = {
  SMALL: "Small",
  MEDIUM: "Medium",
  LARGE: "Large",
  PUBLIC: "Public",
  PRIVATE: "Private",
  FOR_PROFIT: "For-Profit"
};

function ItemInfo({ name, data, className }) {
  return (
    <div className={`mx-1 text-xs flex flex-row ${className}`}>
      <p className="font-bold">
        {name}: <span className="font-normal">{data}</span>
      </p>
    </div>
  );
}

ItemInfo.propTypes = {
  name: PropTypes.string,
  data: PropTypes.string,
  className: PropTypes.string
};

function SearchResultItem({ id, college, religiousTypes, selectedColleges }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useIsDesktopMQL();

  const [addCollege, setAddCollege] = useState(college.add);
  const [deletedCollege, setDeletedCollege] = useState(null);
  const [showDeleteCollege, setShowDeleteCollege] = useState(false);

  const handlerAddCollege = async ({ schoolId }) => {
    ExploreCollegesAddToList({ ipeds: schoolId });
    setAddCollege(false);
    const chosenSchool = await Api.chooseSchool({ schoolId });
    dispatch(joinChosenSchool(chosenSchool));
  };

  const showMission = college.religiousAffiliation !== "NOT_APPLICABLE";
  const mission = religiousTypes.find(
    (relogious) => relogious.code === college.religiousAffiliation
  );

  const handlerRemoveCollege = async (college) => {
    const selectedCollege =
      selectedColleges.find(
        (selected) => selected.ipedsId === college?.ipedsId
      ) || null;
    setDeletedCollege(selectedCollege);
    setShowDeleteCollege(!showDeleteCollege);
  };

  const openCollegeDashboard = () => {
    if (!addCollege) navigate(`/college/id/${college?.ipedsId}`);
  };

  return (
    <div
      className="
        py-6
        flex
        px-12
        flex-1
        flex-row
        bg-white
        space-x-4
        items-center
        mobile:px-4
        mobile-sm:px-4
        mobile:space-x-2
        mobile-sm:space-x-2
        tablet-sm:px-4
      "
    >
      <div
        id={`${id}-logo`}
        onClick={() => openCollegeDashboard()}
        className={`
          flex
          flex-row
          items-center
          ${!addCollege && "cursor-pointer"}
        `}
      >
        <div
          className={`
            flex
            border
            items-center
            justify-center 
            border-black
            ${!college.logo && "bg-white"}
          `}
          style={{
            width: 80,
            height: 80,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${college.logo})`
          }}
        >
          {!college.logo && (
            <span
              className="
                text-5xl
                font-semibold
                mobile:text-3xl
                mobile-sm:text-3xl
                tablet-sm:text-4xl
              "
            >
              {college.name[0]}
            </span>
          )}
        </div>
      </div>
      <div
        id={`${id}-info`}
        onClick={() => openCollegeDashboard()}
        className={`
          -mt-2
          flex
          w-full
          flex-col
          justify-start
          overflow-hidden
          mobile:mt-0
          mobile-sm:mt-0
          tablet-sm:mt-0
          ${!addCollege && "cursor-pointer"}
        `}
      >
        <p
          className="
            text-2xl
            truncate
            font-bold
            mobile:text-lg
            mobile-sm:text-lg
            tablet-sm:text-lg
          "
        >
          {college.name}
        </p>
        <p className="mobile:text-sm mobile-sm:text-sm tablet-sm:text-sm">
          {college?.city && `${college.city},`} {college.state}
        </p>
        <div
          className="
            flex
            flex-row
            items-center
            mobile:mt-2
            mobile:-ml-1
            mobile-sm:mt-2
            mobile-sm:-ml-1
            tablet-sm:mt-2
            tablet-sm:-ml-1
            space-x-4
          "
        >
          <div className="mobile:hidden mobile-sm:hidden tablet-sm:hidden">
            <FilterType size={20} />
          </div>
          <ItemInfo
            name="Type"
            data={`${collegeMapDictionary[college.control]}, ${
              college.programLength === CollegeType.FOUR_YEARS ? "4" : "2"
            } ${isDesktop ? "year" : "yr"}`}
          />
          <ItemInfo name="Size" data={collegeMapDictionary[college.size]} />
          {showMission && (
            <ItemInfo
              name="Mission"
              data={mission?.name}
              className="mobile:hidden mobile-sm:hidden tablet-sm:hidden"
            />
          )}
          <ItemInfo
            data={college.graduationRate}
            name={`${isDesktop ? "Graduation" : "Grad."} Rate`}
          />
          {college?.commonAppUrl && <img src={CaLogo} alt="Common App logo" />}
        </div>
      </div>
      <div
        className="
          place-self-start
          mobile:place-self-center
          mobile-sm:place-self-center
          tablet-sm:place-self-center
        "
      >
        <div className="mobile:hidden mobile-sm:hidden tablet-sm:hidden">
          <AddToMyListButton
            id={id}
            add={addCollege}
            handlerAddCollege={() =>
              handlerAddCollege({ schoolId: college.ipedsId })
            }
            handlerRemoveCollege={() => handlerRemoveCollege(college)}
          />
        </div>
        <div className="hidden mobile:flex mobile-sm:flex tablet-sm:flex">
          <DotsButton
            id={id}
            add={addCollege}
            handlerAddCollege={() =>
              handlerAddCollege({ schoolId: college.ipedsId })
            }
            handlerRemoveCollege={() => handlerRemoveCollege(college)}
          />
        </div>
      </div>
      {showDeleteCollege && (
        <DeleteSchoolModal
          redirect={false}
          open={showDeleteCollege}
          chosenSchool={deletedCollege}
          deleteSchoolCancelHandler={() => {
            setShowDeleteCollege(!showDeleteCollege);
          }}
          deleteSchoolConfirmHandler={() => {
            setAddCollege(true);
            setShowDeleteCollege(!showDeleteCollege);
          }}
        />
      )}
    </div>
  );
}

SearchResultItem.propTypes = {
  id: PropTypes.string,
  college: PropTypes.shape(),
  religiousTypes: PropTypes.arrayOf(PropTypes.shape()),
  selectedColleges: PropTypes.arrayOf(PropTypes.shape())
};

export default SearchResultItem;
