import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import AccountInfoForm from "./AccountInfoForm";
import ProfileInfoForm, { isAct } from "./ProfileInfoForm";

import { Close } from "../../core/icons";
import { Link, SideMenu, SmallModal } from "../../core";
import { Button, BackButton } from "../../core/buttons";
import { TitleRow, InfoRow, ActionRow } from "../../core/labels";

import { updateStudent, updateStudentLocal } from "../../redux/Student/actions";

import { ModalTypes } from "./utils";
import {
  Colors,
  ethnicityDisplay,
  scrollIntoViewIfNeeded,
  getGradeYearLabel
} from "../../core/utils";

import Api, { ErrorTypes } from "../../services/Api";
import { AccountInfoEdit, ProfileInfoEdit } from "../../utils/DataLayers";
import { GetIpedsId } from "./Hooks";

function StudentProfile({ isOpen, student, onClose, openModal }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ipedsId = GetIpedsId();

  const divBackRef = useRef();
  const divContainerRef = useRef();
  const divContainerOrgsRef = useRef();
  const [hasScroll, setHasScroll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasOrgsScroll, setHasOrgsScroll] = useState(false);
  const [errorAccDeleted, setErrorAccDeleted] = useState(null);
  const [confirmAccDeleted, setConfirmAccDeleted] = useState(false);
  const [openEditAccountInfo, setOpenEditAccountInfo] = useState(false);
  const [openEditProfileInfo, setOpenEditProfileInfo] = useState(false);

  useEffect(() => {
    const container = divContainerRef.current;
    const containerOrgs = divContainerOrgsRef.current;

    const handleScroll = (element) => {
      if (element.scrollTop > 0) setHasScroll(true);
      else setHasScroll(false);
    };
    const handleOrgsScroll = (element) => {
      if (element.scrollTop > 0) setHasOrgsScroll(true);
      else setHasOrgsScroll(false);
    };

    container.addEventListener("scroll", () => handleScroll(container));
    containerOrgs.addEventListener("scroll", () =>
      handleOrgsScroll(containerOrgs)
    );
    return () => {
      container.removeEventListener("scroll", () => handleScroll(container));
      containerOrgs.removeEventListener("scroll", () =>
        handleOrgsScroll(containerOrgs)
      );
    };
  });

  const toggleEditAccount = () => {
    if (!openEditAccountInfo) AccountInfoEdit({ ipeds: ipedsId });
    setOpenEditAccountInfo(!openEditAccountInfo);
  };

  const toggleEditProfile = () => {
    if (!openEditProfileInfo) ProfileInfoEdit({ ipeds: ipedsId });
    setOpenEditProfileInfo(!openEditProfileInfo);
  };

  const onSubmitAccountInfo = async (submitData) => {
    scrollIntoViewIfNeeded(divBackRef);

    const account = {
      email: submitData.email,
      lastName: submitData.lastName,
      firstName: submitData.firstName,
      phoneNumber: submitData.phoneNumber ? `+1${submitData.phoneNumber}` : null
    };
    try {
      await dispatch(updateStudent(account));
      toggleEditAccount();
    } catch (error) {
      if (error.type === ErrorTypes.graphQLError) {
        if (error.errors[0].extensions.code === "DuplicateEmailAddress") {
          return {
            type: "DuplicateEmailAddress",
            message: error.errors[0].message
          };
        } else if (error.errors[0].extensions.code === "EmailAddressSetByOAuth") {
          return {
            type: "EmailAddressSetByOAuth",
            message: error.errors[0].message
          };
        }
      } else throw error;
    }
  };

  const deleteAccountConfirmHandler = () => {
    setIsModalOpen(!isModalOpen);
    setConfirmAccDeleted(false);
    setErrorAccDeleted(null);
  };

  const onSubmitProfileInfo = async (data) => {
    scrollIntoViewIfNeeded(divBackRef);
    const profile = {
      gpa: data.gpa ? Number(data.gpa) : null,
      zip: data.zip ? Number(data.zip) : null,
      ethnicity: data.ethnicity,
      gradeYear: data.gradeYear
    };

    if (data.satact) {
      if (isAct(data.satact)) {
        profile.actScore = data.satact ? Number(data.satact) : null;
      } else {
        profile.satScore = data.satact ? Number(data.satact) : null;
      }
    } else {
      profile.satScore = null;
      profile.actScore = null;
    }

    try {
      await dispatch(updateStudent(profile));
      toggleEditProfile();
    } catch (error) {
      if (error.type === ErrorTypes.graphQLError) {
        if (error.errors[0].extensions.code === "ZipCodeNotFound") {
          return {
            type: "ZipCodeNotFound",
            message: error.errors[0].message
          };
        }
      } else throw error;
    }
  };

  const logOutHandler = () => navigate("/logout");

  const deleteAccount = async () => {
    const response = await Api.deleteStudent();
    if (response.ok) {
      setConfirmAccDeleted(true);
      setTimeout(() => {
        navigate("/logout");
      }, 2000);
    } else {
      setConfirmAccDeleted(false);
      setErrorAccDeleted(response.url);
    }
  };

  const addOrganizationHandle = () => {
    openModal({
      data: [],
      type: ModalTypes.PROFILE_JOIN_ORG,
      className: "w-40rem h-35rem overflow-hidden"
    });
  };

  const leaveOrganizationHandle = (org) => {
    openModal({
      data: { org, student },
      type: ModalTypes.PROFILE_LEAVE_ORG,
      className: "w-40rem overflow-hidden"
    });
  };

  const satActScore =
    student.satScore != null ? student.satScore : student.actScore;

  const activeTabIndex =
    !isOpen || isModalOpen || openEditAccountInfo || openEditProfileInfo;

  return (
    <div
      id="profile-drawer"
      ref={divContainerRef}
      className="h-full w-full bg-white shadow-lg overflow-y-auto"
    >
      <div
        ref={divBackRef}
        className={`
          p-4
          flex
          top-0
          flex-1
          sticky
          flex-row
          bg-white
          items-center
          ${hasScroll && "shadow-lg"}
        `}
      >
        <BackButton
          tabIndex={activeTabIndex ? -1 : null}
          onClick={() => {
            if (student.emailChangeRequestSent) {
              dispatch(
                updateStudentLocal({
                  ...student,
                  emailChangeRequestSent: false
                })
              );
            }
            onClose();
          }}
        />
      </div>
      <div className="px-4 laptop:px-6 desktop:px-6">
        <TitleRow large={true} text="Your Account"></TitleRow>
        <div className="p-4 mb-6 border border-black">
          <TitleRow
            text="Account Info"
            id="button-profile-edit-account"
            onClick={() => toggleEditAccount()}
          />
          <InfoRow
            id="name"
            label="name"
            labelColor="text-primary-green"
            text={`${student?.firstName} ${student?.lastName}`}
          />
          <InfoRow
            id="phone-number"
            label="phone number"
            labelColor="text-primary-green"
            text={`${student?.phoneNumber || ""}`}
          />
          <InfoRow
            id="email"
            label="email"
            labelColor="text-primary-green"
            text={student?.email}
          />
          {student.emailChangeRequestSent && (
            <p className="text-sm text-center font-semibold text-primary-green">
              We have sent an email to the new address to confirm this change.
            </p>
          )}
          <Button
            tabIndex={activeTabIndex ? -1 : null}
            className="
              w-full
              text-xs
              text-white
              bg-primary-green
              border-primary-green
              hover:bg-white
              active:bg-white
              hover:text-primary-green
              active:text-primary-green
            "
            onClick={() => navigate("/subscription-settings")}
          >
            subscription settings
          </Button>
        </div>
        <div className="p-4 mb-6 border border-black">
          <TitleRow
            text="Profile Info"
            id="button-profile-edit-profile"
            onClick={() => toggleEditProfile()}
          />
          <InfoRow
            label="Grade"
            labelColor="text-primary-green"
            text={`${getGradeYearLabel(student.gradeYear) || ""}`}
          />
          <InfoRow
            label="gpa"
            className="uppercase"
            labelColor="text-primary-green"
            text={`${
              student.gpa !== null && student.gpa >= 0 ? student.gpa : ""
            }`}
          />
          <InfoRow
            label="sat/act"
            className="uppercase"
            labelColor="text-primary-green"
            text={`${
              satActScore !== null && satActScore >= 0 ? satActScore : ""
            }`}
          />
          <InfoRow
            label="zip code"
            text={`${student.zip || ""}`}
            labelColor="text-primary-green"
          />
          <InfoRow
            label="race/ethnicity"
            labelColor="text-primary-green"
            text={ethnicityDisplay(student.ethnicity)}
          />
        </div>
        <div className="p-4 mb-6 border border-black">
          <TitleRow text="Organization Info" />
          <div
            ref={divContainerOrgsRef}
            className={`
              py-2
              mb-4
              max-h-10rem
              overflow-y-auto
              ${hasOrgsScroll && "shadow-inner"}
            `}
          >
            {student?.organizations?.map((org) => (
              <div key={org.organizationId}>
                <ActionRow
                  text={org.name}
                  onClick={() => leaveOrganizationHandle(org)}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-1 text-sm flex-row items-center justify-center">
            <Link
              color={Colors.GREEN}
              text="Join An Organization"
              id="organization-join-organization"
              tabIndex={activeTabIndex ? -1 : null}
              onClick={() => addOrganizationHandle()}
            />
          </div>
        </div>
        <div
          className="
            py-4
            mb-6
            flex
            flex-1
            flex-row
            space-x-4
            items-center
            justify-center
          "
        >
          <Button
            id="delete-account-button"
            tabIndex={activeTabIndex ? -1 : null}
            onClick={() => setIsModalOpen(!isModalOpen)}
            className="
              w-full
              bg-white
              text-primary-green
              border-primary-green
              hover:text-white
              active:text-white
              hover:bg-primary-green
              active:bg-primary-green
            "
          >
            delete account
          </Button>
          <Button
            id="sign-out-button"
            onClick={logOutHandler}
            tabIndex={activeTabIndex ? -1 : null}
            className="
              w-full
              text-white
              bg-primary-green
              border-primary-green
              hover:bg-white
              active:bg-white
              hover:text-primary-green
              active:text-primary-green
            "
          >
            sign out
          </Button>
        </div>
      </div>

      <SideMenu open={openEditAccountInfo} direction="rtl">
        <div className="p-6 h-full w-full bg-white">
          <div className="mb-6 flex flex-1 flex-row items-center">
            <TitleRow text="Edit Account Info" />
            <div
              role="button"
              onClick={() => toggleEditAccount()}
              id="button-profile-close-edit-account"
            >
              <Close />
            </div>
          </div>
          <AccountInfoForm
            id="form-edit-account"
            isOpen={openEditAccountInfo}
            onSubmit={onSubmitAccountInfo}
            defaultValues={{
              ...student,
              openEditAccountInfo
            }}
          />
        </div>
      </SideMenu>
      <SideMenu open={openEditProfileInfo} direction="rtl">
        <div className="p-6 h-full w-full bg-white ">
          <div className="mb-6 flex flex-1 flex-row items-center">
            <TitleRow text="Edit Profile Info" />
            <div
              role="button"
              onClick={() => toggleEditProfile()}
              id="button-profile-close-edit-profile"
            >
              <Close />
            </div>
          </div>
          <ProfileInfoForm
            id="form-edit-profile"
            isOpen={openEditProfileInfo}
            onSubmit={onSubmitProfileInfo}
            defaultValues={{
              ...student,
              satActScore,
              openEditProfileInfo
            }}
          />
        </div>
      </SideMenu>

      {isModalOpen && (
        <SmallModal open={isModalOpen}>
          {!confirmAccDeleted && !errorAccDeleted && (
            <div className="flex flex-1 flex-col">
              <p className="text-xl font-semibold text-primary-green">
                Are you sure you want to delete your DecidED account?
              </p>
              <p className="mt-1 text-black font-semibold">
                This will be a permanent change and cannot be undone.
              </p>
              <div className="mt-12 flex flex-1 flex-row space-x-4">
                <Button
                  id="button-delete-account-cancel"
                  tabIndex={!isModalOpen ? -1 : null}
                  onClick={deleteAccountConfirmHandler}
                  className="
                    w-full
                    bg-white
                    text-primary-green
                    border-primary-green
                    hover:text-white
                    active:text-white
                    hover:bg-primary-green
                    active:bg-primary-green
                  "
                >
                  Cancel
                </Button>
                <Button
                  onClick={deleteAccount}
                  id="button-delete-account-delete"
                  tabIndex={!isModalOpen ? -1 : null}
                  className="
                    w-full
                    text-white
                    bg-primary-green
                    border-primary-green
                    hover:bg-white
                    active:bg-white
                    hover:text-primary-green
                    active:text-primary-green
                  "
                >
                  Delete
                </Button>
              </div>
            </div>
          )}
          {errorAccDeleted && (
            <div className="mt-10 flex flex-1 flex-col">
              <p className="text-xl font-semibold text-primary-green">
                You can't delete your DecidED account from here!
              </p>
              <span className="mt-1 text-black font-semibold">
                It seems your account is also used in one of our other apps. You
                can delete your account there,{" "}
                <div className="inline-block">
                  <Link
                    target="_blank"
                    internal={false}
                    text="click here"
                    color={Colors.GREEN}
                    href={errorAccDeleted}
                  />
                </div>{" "}
                to open it.
              </span>
              <div
                className="
                  mt-12
                  flex
                  flex-1
                  flex-row
                  items-center
                  justify-center
                "
              >
                <Button
                  tabIndex={!isModalOpen ? -1 : null}
                  onClick={deleteAccountConfirmHandler}
                  id="button-delete-account-error-confirm"
                  className="
                    w-1/2
                    bg-white
                    text-primary-green
                    border-primary-green
                    hover:text-white
                    active:text-white
                    hover:border-white
                    active:border-white
                    hover:bg-primary-green
                    active:bg-primary-green
                  "
                >
                  close
                </Button>
              </div>
            </div>
          )}
          {confirmAccDeleted && (
            <div className="mt-10 flex flex-1 flex-col">
              <p className="text-xl font-semibold text-primary-green">
                Your account has been permanently deleted!
              </p>
              <p className="mt-1 text-black font-semibold">DecidED Team</p>
            </div>
          )}
        </SmallModal>
      )}
    </div>
  );
}

StudentProfile.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  openModal: PropTypes.func,
  student: PropTypes.shape().isRequired
};

export default StudentProfile;
