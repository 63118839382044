import TagManager from "react-gtm-module";

function ReportAnErrorBreakdown({ ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "report_an_error_breakdown",
      ipeds: ipeds,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default ReportAnErrorBreakdown;
