import { Navigate, useNavigate, useParams, Route } from "react-router-dom";
import PropTypes from "prop-types";

import Success from "./Success";
import ReportAnError from "./ReportAnError";
import LayoutCollege from "../LayoutCollege";
import CustomSwitch from "../../Common/CustomSwitch";
import PrivateRoute from "../../Common/PrivateRoute";
import LayoutDesktop from "../../Common/LayoutDesktop";

import { useGetShowUploadTutorial } from "../../../redux/Student/hooks";
import Layout from "../../Common/Layout";

function ReportAnErrorIdRoutes({ student, openModal, modalResponse }) {
  const navigate = useNavigate();
  const { ipedsId } = useParams();

  const showUploadTutorial = useGetShowUploadTutorial();

  const addYourMoneyPath = `/college/add-your-money/${ipedsId}${
    !showUploadTutorial ? "/upload-images" : ""
  }`;

  return (
    <CustomSwitch>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
            >
              <LayoutCollege>
                <ReportAnError
                  onBack={() => navigate(-1)}
                  onNext={() => navigate(addYourMoneyPath)}
                />
              </LayoutCollege>
            </Layout>
          </PrivateRoute>
        }
      />

      <Route
        path="/success"
        element={
          <PrivateRoute>
            <LayoutDesktop className="min-h-screen bg-secondary-green">
              <Success onBack={() => navigate(`/college/id/${ipedsId}`)} />
            </LayoutDesktop>
          </PrivateRoute>
        }
      />
    </CustomSwitch>
  );
}

function ReportAnErrorRoutes({ student, openModal, modalResponse }) {
  return (
    <CustomSwitch>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Navigate to="/" />
          </PrivateRoute>
        }
      />
      <Route
        path="/:ipedsId/*"
        element={
          <PrivateRoute>
            <ReportAnErrorIdRoutes
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
            />
          </PrivateRoute>
        }
      />
    </CustomSwitch>
  );
}

ReportAnErrorRoutes.propTypes = {
  student: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  modalResponse: PropTypes.string
};

export default ReportAnErrorRoutes;
