import TagManager from "react-gtm-module";

function RequestNewLink({ ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "request_new_link",
      ipeds: ipeds,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default RequestNewLink;
