import TagManager from "react-gtm-module";

function PageViewDataLayer({
  ipeds,
  userId,
  screenName,
  searchType,
  searchMajor,
  searchState,
  organizationId,
  countOfSearchResults,
}) {
  const tagManagerArgs = {
    dataLayer: {
      count_of_search_results: countOfSearchResults,
      search_state: searchState,
      search_major: searchMajor,
      search_type: searchType,
      user_id: userId,
      ipeds: ipeds,
      event: "interactionEvent",
      eventName: "page_view",
      screen_name: screenName,
      organization_id: organizationId,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
}

export default PageViewDataLayer;
