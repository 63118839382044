import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import FinancialAidList from "./FinancialAidList";

import { Form } from "../../../core";
import { Button, LoadingButton } from "../../../core/buttons";

import { Colors } from "../../../core/utils";
import { waitForTimeout } from "../../../core/hooks";

import { dollarFormat, localStringToNumber } from "./Utils";

function AddFinancialAidForm({
  id,
  onSubmit,
  defaultValues,
  requestedClose,
  setConfirmModal,
  saveChangesError,
  requestCloseHandle
}) {
  const [formValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [financialAids, setFinancialAids] = useState([]);
  const [invalidValue, setInvalidValue] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);

  const {
    reset,
    watch,
    setError,
    clearErrors,
    handleSubmit,
    control,
    formState
  } = useForm({
    mode: "onChange",
    defaultValues
  });

  const [type, name, amount] = watch(["type", "name", "amount"]);

  const setSaveData = useCallback(() => {
    requestCloseHandle({
      type: type,
      name: name,
      financialAids,
      amount: localStringToNumber(amount)
    });
  }, [type, name, amount, financialAids, requestCloseHandle]);

  useEffect(
    () => setFinancialAids(defaultValues.financialAids),
    [defaultValues.financialAids]
  );

  useEffect(() => {
    if (requestedClose) setSaveData();
  }, [setSaveData, requestedClose]);

  useEffect(() => {
    if (saveChangesError) {
      if (saveChangesError.includes("type"))
        setError("type", {
          message: "Type option is required."
        });
      if (saveChangesError.includes("name"))
        setError("name", {
          message: "Name is required."
        });
      if (saveChangesError.includes("amount"))
        setError("amount", {
          message: "Amount is required."
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveChangesError]);

  useEffect(() => {
    if (
      financialAids.length > 0 ||
      !isEqual({ type, name, amount }, { type: null, name: "", amount: "" })
    )
      setConfirmModal(false);
    else setConfirmModal(true);
  }, [type, name, amount, financialAids, defaultValues, setConfirmModal]);

  useEffect(() => setFormValid(formState.isValid), [formState.isValid]);

  const fetchTypeOptionList = (search, callback) => {
    const options = [
      {
        id: "grant",
        value: "GRANT",
        label: "Grant"
      },
      {
        id: "scholarship",
        value: "SCHOLARSHIP",
        label: "Scholarship"
      }
    ];

    callback(() =>
      options.filter((option) =>
        option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  const handlerOnResetForm = async () => {
    setFinancialAids((financialAids) =>
      financialAids.concat([
        {
          type,
          name,
          amount,
          added: true,
          id: `${type.id}-${name}-${amount}`.toLowerCase()
        }
      ])
    );
    reset({ type: null, name: "", amount: "" });

    const timeoutId = await waitForTimeout(1);
    clearErrors();
    clearTimeout(timeoutId);
  };

  const handlerOnRemove = (removeFinancialAid) =>
    setFinancialAids((financialAids) =>
      financialAids.filter(
        (financialAid) => financialAid.id !== removeFinancialAid.id
      )
    );

  const dissableButton = !formValid && financialAids.length === 0;

  return (
    <>
      <div className="p-1 h-full pt-2 overflow-auto">
        <FinancialAidList
          financialAids={financialAids}
          onRemoveFinancialAid={handlerOnRemove}
        />

        <Form
          id={id}
          onSubmit={handleSubmit(
            async (form) => {
              setIsLoading(true);
              setSomethingWentWrong(false);
              try {
                const formAddFinancialAidsData = financialAids.concat([
                  {
                    added: true,
                    type: form.type,
                    name: form.name,
                    amount: localStringToNumber(form.amount),
                    id: `${type.id}-${name}-${amount}`.toLowerCase()
                  }
                ]);
                await onSubmit(formAddFinancialAidsData);
              } catch (error) {
                setSomethingWentWrong(true);
              }
            },
            async () => {
              if (financialAids.length > 0) {
                clearErrors();

                setIsLoading(true);
                setSomethingWentWrong(false);
                try {
                  await onSubmit(financialAids);
                } catch (error) {
                  setSomethingWentWrong(true);
                }
              }
            }
          )}
        >
          <Form.Select
            name="type"
            required={true}
            control={control}
            color={Colors.GREEN}
            placeholder="Select"
            label="Type of Financial Aid"
            id="financial-aid-type-input"
            loadOptions={fetchTypeOptionList}
            rules={{
              required: {
                value: true,
                message: "Type option is required."
              }
            }}
          />
          <Form.ErrorMessage errors={formState.errors} name="type" />

          <Form.Input
            name="name"
            required={true}
            control={control}
            color={Colors.GREEN}
            labelCapitalize={false}
            errors={formState.errors}
            id="financial-aid-name-input"
            label="Grant or Scholarship Name"
            rules={{
              required: { value: true, message: "Name is required." },
              maxLength: {
                value: 150,
                message: "Maximum 150 characters allowed."
              }
            }}
          />
          <Form.ErrorMessage errors={formState.errors} name="name" />
          <Form.Input
            name="amount"
            required={true}
            control={control}
            color={Colors.GREEN}
            errors={formState.errors}
            label="Financial Aid Amount"
            id="financial-aid-amount-input"
            rules={{
              required: { value: true, message: "Amount is required." },
              max: {
                value: 999999,
                message: "Maximum value allowed is 999,999."
              }
            }}
            transform={{
              input: (value) =>
                value !== "" ? dollarFormat.format(value) : "",
              output: (event) => {
                if (event.target.value !== "$") {
                  const valueNumber = localStringToNumber(event.target.value);
                  if (isNaN(valueNumber) || valueNumber < 0)
                    setInvalidValue(true);
                  else setInvalidValue(false);
                  return !isNaN(valueNumber) && valueNumber >= 0
                    ? valueNumber
                    : amount;
                }
                return "";
              }
            }}
          />
          <Form.ErrorMessage errors={formState.errors} name="amount" />
          {invalidValue && (
            <p className="text-sm font-semibold text-primary-red">
              You can add only positive numbers!
            </p>
          )}

          <div className="mt-1">
            {somethingWentWrong && (
              <p className="text-sm font-semibold text-primary-red">
                Something went wrong, please try again later!
              </p>
            )}
          </div>
        </Form>
      </div>
      <div
        className="
          py-5
          flex
          mt-auto
          flex-row
          space-x-4
          mobile:flex-col
          mobile:space-x-0
          mobile-sm:flex-col
          mobile-sm:space-x-0
          tablet-sm:flex-col
          tablet-sm:space-x-0
        "
      >
        <Button
          disabled={dissableButton}
          onClick={handlerOnResetForm}
          className="
            mb-5
            bg-white
            text-primary-green
            border-primary-green
            hover:text-white
            active:text-white
            hover:bg-primary-green
            active:bg-primary-green
            mobile:w-full
            desktop:w-1/2
            laptop:w-1/2
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          Add Another Award?
        </Button>
        <LoadingButton
          form={id}
          type="submit"
          value="submit"
          isLoading={isLoading}
          disabled={dissableButton}
          id="create-edit-list-button"
          className="
            mb-5
            ml-auto
            text-white
            bg-primary-green
            border-primary-green
            hover:bg-white
            active:bg-white
            hover:text-primary-green
            active:text-primary-green
            desktop:w-1/2
            laptop:w-1/2
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          Continue
        </LoadingButton>
      </div>
    </>
  );
}

AddFinancialAidForm.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func,
  requestedClose: PropTypes.bool,
  setConfirmModal: PropTypes.func,
  defaultValues: PropTypes.shape(),
  requestCloseHandle: PropTypes.func,
  saveChangesError: PropTypes.arrayOf(PropTypes.string)
};

export default AddFinancialAidForm;
