import { useState } from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";

import { Close } from "../../core/icons";

import { ModalTypes } from "./utils";
import {
  AllSetModal,
  JoinOrgModal,
  LeaveOrgModal,
  GradeYearModal,
  TypeFilterModal,
  MajorFilterModal,
  FirstSchoolModal,
  FirstPostAwardModal,
  LocationFilterModal,
  ViewAwardLetterModal,
  AddAdditionalFinancialAidModal,
  EnrolledStatusModal,
} from "../Modals";

ReactModal.setAppElement("#modal-root");

function Modal({ show, closeModal, modalData, clearModal }) {
  const [confirmModal, setConfirmModal] = useState(false);
  const [requestedClose, setRequestedClose] = useState(false);

  const getModal = (modalData) => {
    switch (modalData.type) {
      case ModalTypes.PROFILE_JOIN_ORG:
        return {
          isFixed: false,
          title: "Edit Your Organizations",
          component: (
            <JoinOrgModal
              orgs={[]}
              requestedClose={requestedClose}
              setConfirmModal={setConfirmModal}
              setRequestedClose={setRequestedClose}
              onClose={() => closeModal(modalData.type)}
            />
          ),
        };
      case ModalTypes.PROFILE_LEAVE_ORG:
        return {
          isFixed: false,
          title: "Edit Your Organizations",
          component: (
            <LeaveOrgModal
              org={modalData.data.org}
              student={modalData.data.student}
              setConfirmModal={setConfirmModal}
              onClose={() => closeModal(modalData.type)}
            />
          ),
        };
      case ModalTypes.VIEW_AWARD_LETTER:
        return {
          isFixed: false,
          title: "View Award Letter",
          component: <ViewAwardLetterModal image={modalData.data} />,
        };
      case ModalTypes.ADD_FINANCIAL_AID:
        return {
          isFixed: false,
          title: "Add Additional Financial Aid",
          component: (
            <AddAdditionalFinancialAidModal
              requestedClose={requestedClose}
              setConfirmModal={setConfirmModal}
              setRequestedClose={setRequestedClose}
              onClose={(financialAids) =>
                closeModal(modalData.type, { financialAids })
              }
            />
          ),
        };
      case ModalTypes.LOCATION_FILTER:
        return {
          isFixed: false,
          title: "Search by Location",
          component: (
            <LocationFilterModal
              filters={modalData.data.filters}
              onClose={({ type, filters }) => {
                closeModal(modalData.type);
                modalData.data.onSubmit({ type, filters });
              }}
            />
          ),
        };
      case ModalTypes.MAJOR_FILTER:
        return {
          isFixed: false,
          title: "Search by Major",
          component: (
            <MajorFilterModal
              filters={modalData.data.filters}
              onClose={({ type, filters }) => {
                closeModal(modalData.type);
                modalData.data.onSubmit({ type, filters });
              }}
            />
          ),
        };
      case ModalTypes.TYPE_FILTER:
        return {
          isFixed: false,
          title: "Search by Type",
          component: (
            <TypeFilterModal
              filters={modalData.data.filters}
              religiousTypes={modalData.data.religiousTypes}
              onClose={({ type, filters }) => {
                closeModal(modalData.type);
                modalData.data.onSubmit({ type, filters });
              }}
            />
          ),
        };
      case ModalTypes.ALL_SET:
        return {
          isFixed: true,
          isColoredBg: true,
          component: <AllSetModal onClose={() => closeModal(modalData.type)} />,
        };
      case ModalTypes.GRADE_YEAR:
        return {
          isFixed: true,
          isColoredBg: false,
          component: (
            <GradeYearModal
              onClose={() => closeModal(modalData.type)}
              student={modalData.data.student}
            />
          ),
          customClassName: "absolute inset-1/10",
        };
      case ModalTypes.FIRST_SCHOOL:
        return {
          isFixed: true,
          isColoredBg: true,
          component: (
            <FirstSchoolModal onClose={() => closeModal(modalData.type)} />
          ),
        };
      case ModalTypes.FIRST_LETTER:
        return {
          isFixed: true,
          isColoredBg: true,
          component: (
            <FirstPostAwardModal onClose={() => closeModal(modalData.type)} />
          ),
        };
      case ModalTypes.ENROLLED_STATUS:
        return {
          isFixed: true,
          isColoredBg: true,
          component: (
            <EnrolledStatusModal
              onClose={() => closeModal(modalData.type)}
              schoolEnrolled={modalData.data.schoolEnrolled}
            />
          ),
        };
      default:
        return {
          isFixed: false,
          title: "Modal Tittle",
          component: <div>Modal component</div>,
        };
    }
  };

  const onRequestCloseHandle = () => {
    setRequestedClose(true);
    if (!confirmModal) {
      if (
        modalData.type !== ModalTypes.PROFILE_JOIN_ORG &&
        modalData.type !== ModalTypes.ADD_FINANCIAL_AID
      )
        finishCloseModal();
    } else finishCloseModal();
  };

  const finishCloseModal = () => {
    if (confirmModal) {
      closeModal(modalData.type);
    } else {
      closeModal();
    }
  };

  const onAfterClose = () => {
    setConfirmModal(false);
    setRequestedClose(false);
    clearModal();
  };

  const modal = getModal(modalData);

  return !modal.isFixed ? (
    <ReactModal
      isOpen={show}
      className={`
        ${modalData.className ? modalData.className : "w-40rem"}
        top-1/6
        mx-auto
        flex-col
        absolute
        left-1/10
        right-1/10
        bottom-1/8
        bg-white
        outline-none
        mobile:top-0
        mobile:w-full
        mobile:h-full
        mobile:left-0
        mobile:right-0
        mobile:bottom-0
        mobile-sm:top-0
        mobile-sm:w-full
        mobile-sm:h-full
        mobile-sm:left-0
        mobile-sm:right-0
        mobile-sm:bottom-0
        tablet-sm:top-0
        tablet-sm:w-full
        tablet-sm:h-full
        tablet-sm:left-0
        tablet-sm:right-0
        tablet-sm:bottom-0
      `}
      overlayClassName="
        z-30
        fixed
        top-0
        left-0
        right-0
        bottom-0
        bg-transparent-primary-green
      "
      onRequestClose={onRequestCloseHandle}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onAfterClose={onAfterClose}
    >
      <div className="flex flex-col h-full">
        <div
          className="
            flex
            flex-row
            tablet:p-6
            laptop:p-6
            desktop:p-6
            mobile:pt-6
            mobile:px-6
            mobile-sm:pt-6
            mobile-sm:px-6
            tablet-sm:pt-6
            tablet-sm:px-6
          "
        >
          <h1 className="font-bold text-3xl">{modal.title}</h1>
          <div
            onClick={finishCloseModal}
            className="ml-auto my-auto place-self-center cursor-pointer"
          >
            <Close />
          </div>
        </div>
        {modal.component}
      </div>
    </ReactModal>
  ) : (
    <ReactModal
      isOpen={show}
      className={`
        w-40rem
        flex-col
        mx-auto
        outline-none
        mobile:px-5
        mobile:py-10
        mobile:top-0
        mobile:w-full
        mobile:h-full
        mobile:left-0
        mobile:right-0
        mobile:bottom-0
        mobile-sm:px-5
        mobile-sm:py-10
        mobile-sm:top-0
        mobile-sm:w-full
        mobile-sm:h-full
        mobile-sm:left-0
        mobile-sm:right-0
        mobile-sm:bottom-0
        tablet-sm:px-10
        tablet-sm:py-24
        tablet-sm:top-0
        tablet-sm:w-full
        tablet-sm:h-full
        tablet-sm:left-0
        tablet-sm:right-0
        tablet-sm:bottom-0
        ${
          modal.isColoredBg
            ? "bg-primary-green border-4 border-white shadow-xl"
            : "bg-white"
        }
        ${
          modal.customClassName
            ? modal.customClassName
            : "relative top-1/6 bottom-1/4"
        }
      `}
      overlayClassName={`
        z-30
        fixed
        top-0
        left-0
        right-0
        bottom-0
        ${!modal.isColoredBg && "bg-transparent-primary-green"}
      `}
      onRequestClose={onRequestCloseHandle}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={true}
      onAfterClose={onAfterClose}
    >
      <div className="flex flex-col h-full">{modal.component}</div>
    </ReactModal>
  );
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  clearModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.any,
  }).isRequired,
};

export default Modal;
