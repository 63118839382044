import PropTypes from "prop-types";

import { Panel, GraduationRate } from "../../../core/compose";
import Description from "../../../core/compose/GraduationRate/Description";
import { getTheme } from "../../../core/utils";
import { GradRateExpand } from "../../../utils/DataLayers";

function CollegeGraduationBox({ chosenSchool, studentEthnicity }) {
  const { award, schoolCosts, school } = chosenSchool;
  const { graduationRate, ethnicityGroup } = schoolCosts;
  const { programLength } = school;

  const affordability = award?.postAwardSchoolCosts?.affordability;

  const theme = getTheme({ affordability });

  return (
    <Panel
      initiallyOpened={true}
      title="graduation rate"
      className="p-4 mt-4 mx-4 bg-white shadow-lg"
      gaToggleCall={() =>
        GradRateExpand({ ethnicity: studentEthnicity, ipeds: school?.ipedsId })
      }
      expandableContent={<Description color={theme.color} />}
    >
      <GraduationRate
        affordability={affordability}
        graduationRate={graduationRate}
        ethnicityGroup={ethnicityGroup}
        ethnicityStudent={studentEthnicity}
        schoolProgramLength={programLength}
      />
    </Panel>
  );
}

CollegeGraduationBox.propTypes = {
  chosenSchool: PropTypes.shape(),
  studentEthnicity: PropTypes.string
};

export default CollegeGraduationBox;
