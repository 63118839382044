import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Item from "./Item";
import Header from "../../Common/Header";
import Layout from "../../../Common/Layout";
import LayoutCollege from "../../LayoutCollege";

import { Link } from "../../../../core";
import { Colors } from "../../../../core/utils";
import { Button } from "../../../../core/buttons";
import {
  Email,
  LaptopMag,
  OkayHandUp,
  ShortArrow
} from "../../../../core/icons";
import { useIsDesktopMQL } from "../../../../core/hooks";

function HowItWorks({ student, openModal, modalResponse, onNext }) {
  const navigate = useNavigate();
  const { ipedsId } = useParams();

  const isDesktop = useIsDesktopMQL();
  const screenshotsGuide = `/college/add-your-money/${ipedsId}/dont-have-award`;
  return (
    <Layout
      student={student}
      openModal={openModal}
      modalResponse={modalResponse}
    >
      <LayoutCollege>
        <div
          className="
            min-h-screen-footer-md
            mobile:min-h-screen-footer
            mobile-sm:min-h-screen-footer
            tablet-sm:min-h-screen-footer
          "
        >
          <div className="p-4">
            <Header title="How it works" />
          </div>
          <div
            className="
              p-8
              flex
              flex-1
              flex-col
              bg-white
              overflow-auto
              mobile:px-4
              mobile-sm:px-4
              tablet-sm:px-4
            "
          >
            <div
              className="
                pb-6
                flex
                flex-1
                flex-col
                bg-white
              "
            >
              <div
                className="
                  my-8
                  px-4
                  flex
                  py-12
                  flex-row
                  space-x-4
                  mobile:py-4
                  mobile-sm:py-4
                  tablet-sm:py-4
                  mobile:flex-col
                  tablet-sm:flex-col
                  mobile-sm:flex-col
                  mobile:space-x-0
                  mobile-sm:space-x-0
                  tablet-sm:space-x-0
                "
              >
                <div
                  className="
                    w-1/3
                    mobile:w-full
                    mobile-sm:w-full
                    tablet-sm:w-full
                  "
                >
                  <Item
                    number={1}
                    title="Find Your Financial Aid Award"
                    icon={
                      <LaptopMag
                        height={!isDesktop ? 50 : 80}
                        width={!isDesktop ? 60 : 100}
                      />
                    }
                    description={`After you're accepted, the school will give you a "financial aid award" showing your grants and scholarships.`}
                    divider={false}
                  />
                </div>
                <div
                  className="
                    w-1/3
                    border-l
                    border-black
                    mobile:w-full
                    mobile-sm:w-full
                    tablet-sm:w-full
                    mobile:border-l-0
                    mobile-sm:border-l-0
                    tablet-sm:border-l-0
                  "
                >
                  <Item
                    number={2}
                    title="Take Screenshots of the Award & Upload It"
                    icon={
                      <Email
                        size={!isDesktop ? 50 : 80}
                        fillColor="fill-primary-green"
                      />
                    }
                    description="Once you find your financial aid award, take a screenshot or picture of all the sections that show finances, and upload them to DecidED to see your affordability rating."
                  />
                </div>
                <div
                  className="
                    pr-2
                    w-1/3
                    border-l
                    border-black
                    mobile:w-full
                    mobile-sm:w-full
                    tablet-sm:w-full
                    mobile:border-l-0
                    mobile-sm:border-l-0
                    tablet-sm:border-l-0
                  "
                >
                  <Item
                    number={3}
                    buttonOnClick={onNext}
                    buttonText="Get Started"
                    title="Let Us Do All the Math!"
                    icon={<OkayHandUp size={!isDesktop ? 50 : 80} />}
                  />
                </div>
              </div>
              <div
                className="
                  flex
                  flex-1
                  flex-row
                  mobile:flex-col
                  mobile-sm:flex-col
                  tablet-sm:flex-col
                "
              >
                <div
                  className="
                    mx-4
                    w-1/2
                    mobile:mx-0
                    mobile-sm:mx-0
                    tablet-sm:mx-0
                    mobile:mb-8
                    mobile-sm:mb-8
                    tablet-sm:mb-8
                    mobile:w-full
                    mobile-sm:w-full
                    tablet-sm:w-full
                  "
                >
                  <p className="font-bold">Having trouble?</p>
                  <Link
                    icon={true}
                    color={Colors.GREEN}
                    textClassName="font-bold"
                    onClick={() => navigate(screenshotsGuide)}
                    text="Next steps if you can't find your award letter"
                    customIcon={
                      <div className="transform place-self-end rotate-180">
                        <ShortArrow size={16} color="stroke-current" />
                      </div>
                    }
                  />
                  <Button
                    className="
                      mx-4
                      mt-10
                      hidden
                      w-full
                      text-white
                      bg-primary-green
                      border-primary-green
                      mobile:mx-0
                      mobile-sm:mx-0
                      tablet-sm:mx-0
                      mobile:block
                      mobile-sm:block
                      tablet-sm:block
                    "
                    onClick={onNext}
                  >
                    get started
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutCollege>
    </Layout>
  );
}

HowItWorks.propTypes = {
  student: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  modalResponse: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired
};

export default HowItWorks;
