import PropTypes from "prop-types";

function MagnifyGlassSimple({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41815 11.101C7.43347 11.8121 8.64008 12.1951 9.90571 12.1951C11.5344 12.1951 13.0654 11.561 14.2169 10.4091C16.5937 8.03186 16.5937 4.1637 14.2166 1.7869C13.0652 0.635056 11.5342 0.000976562 9.90555 0.000976562C8.27659 0.000976562 6.74571 0.635056 5.59451 1.7869C4.44299 2.93842 3.80859 4.46946 3.80859 6.0981C3.80859 7.36154 4.19054 8.56661 4.89963 9.58109L0 14.481L1.51932 16L6.41815 11.101ZM6.71755 2.90962C7.56891 2.0581 8.70123 1.5893 9.90571 1.5893C11.1099 1.5893 12.2424 2.05826 13.094 2.90946C13.9457 3.7613 14.4148 4.8933 14.4148 6.09778C14.4148 7.30226 13.9457 8.43474 13.0942 9.28642C12.2425 10.1378 11.1102 10.6067 9.90587 10.6067C8.70123 10.6067 7.56923 10.1378 6.71755 9.28642C4.95947 7.52834 4.95947 4.6677 6.71755 2.90962Z"
      />
    </svg>
  );
}

MagnifyGlassSimple.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default MagnifyGlassSimple;
