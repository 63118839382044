import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { Button } from "../../../core/buttons";

function ReuploadAwardButton({ path }) {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate(path)}
      id="affordability-report-error-button"
      className="
        mt-4
        w-full
        text-xsm
        text-white
        font-extrabold
        bg-primary-blue
        border-primary-blue
        hover:bg-white
        active:bg-white
        hover:text-primary-blue 
        active:text-primary-blue
      "
    >
      reupload award letter
    </Button>
  );
}

ReuploadAwardButton.propTypes = {
  path: PropTypes.string
};

export default ReuploadAwardButton;
