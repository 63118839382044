import TagManager from "react-gtm-module";

function ZipCodeToolTipConfirm({ ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "zip_code_tool_tip_confirm",
      ipeds: ipeds,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default ZipCodeToolTipConfirm;
