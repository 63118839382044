import PropTypes from "prop-types";

function Loans({ size = 25, color = "fill-black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 29 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.8619 18.1245C10.7948 18.1245 10.928 18.7925 11.0613 19.3269H12.2607H13.4601C13.4601 19.1933 13.4601 18.3917 13.0603 17.7237C12.6605 16.9221 11.9942 16.3876 11.0613 16.1204V14.25H8.79576V16.1204C7.32981 16.5213 6.39694 17.7237 6.39694 19.3269C6.39694 21.5982 8.12942 22.2662 9.32882 22.667C10.6615 23.2014 11.0613 23.335 11.0613 24.2702C11.0613 24.5375 11.0613 25.0719 9.8619 25.0719C8.92902 25.0719 8.66249 24.8047 8.66249 23.7358H6.26367C6.26367 25.4727 7.06328 26.8087 8.66249 27.2095V29.08H10.928V27.3431C12.394 26.9423 13.3268 25.8735 13.3268 24.2702C13.3268 21.5982 11.3278 20.9302 10.1284 20.5293C8.79576 20.1285 8.66249 19.8613 8.66249 19.4605C8.66249 18.3917 9.06229 18.1245 9.8619 18.1245Z"
        fill={color}
      />
      <path
        d="M19.8565 20.2622C18.5238 19.8614 18.3905 19.5942 18.3905 19.1934C18.3905 18.5254 18.7903 18.1246 19.59 18.1246C20.5228 18.1246 20.6561 18.7926 20.7894 19.327H21.9888H23.1882C23.1882 18.1246 22.5218 16.5213 20.7894 15.9869V14.1165H18.5238V15.9869C17.0579 16.3877 16.125 17.5901 16.125 19.1934C16.125 21.4646 17.8575 22.1326 19.0569 22.5335C20.3896 23.0679 20.7894 23.2015 20.7894 24.1367C20.7894 24.4039 20.7894 24.9383 19.59 24.9383C18.6571 24.9383 18.3905 24.6711 18.3905 23.6023H16.125C16.125 25.3391 16.9246 26.6752 18.5238 27.076V28.9464H20.7894V27.2096C22.2553 26.8088 23.1882 25.7399 23.1882 24.1367C23.0549 21.4646 21.0559 20.663 19.8565 20.2622Z"
        fill={color}
      />
      <path
        d="M28.5194 0.22168H0.533203V43.9099H28.6526V0.22168H28.5194ZM2.79875 2.49293H26.2538V7.30265H2.79875V2.49293ZM26.2538 9.57391V33.7561H2.79875V9.57391H26.2538ZM26.2538 41.6387H2.79875V36.0273H26.2538V41.6387Z"
        fill={color}
      />
      <path
        d="M14.5264 36.8289C13.4602 36.8289 12.5273 37.7641 12.5273 38.8329C12.5273 39.9017 13.4602 40.837 14.5264 40.837C15.5925 40.837 16.5254 39.9017 16.5254 38.8329C16.5254 37.7641 15.5925 36.8289 14.5264 36.8289Z"
        fill={color}
      />
      <path d="M17.192 3.82886H11.8613V6.10011H17.192V3.82886Z" fill={color} />
    </svg>
  );
}

Loans.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Loans;
