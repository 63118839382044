import TagManager from "react-gtm-module";

function ProgressTrackerDropdown({ affordability_state, ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "progress_tracker_dropdown",
      affordability_state: affordability_state,
      ipeds: ipeds,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default ProgressTrackerDropdown;
