import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { Form } from "../../core";
import { LoadingButton } from "../../core/buttons";
import { Colors } from "../../core/utils";
import {
  UnsubscribeFromAllButton,
  UpdateSubscriptionSettingsButton
} from "../../utils/DataLayers";
import { GetIpedsId } from "../Common/Hooks";

function SubscriptionSettingsForm({
  onSubmit,
  defaultValues,
  unsubscribeFromAll
}) {
  const formID = "subscription-settings-from-id";
  const ipedsId = GetIpedsId();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [anyChanges, setAnyChanges] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);

  const { watch, setValue, handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues
  });

  const [emailReceive, emailNotifications] = watch([
    "emailReceive",
    "emailNotifications"
  ]);

  const unsubscribeButton = !anyChanges && (emailReceive || emailNotifications);

  useEffect(() => {
    if (isEqual({ emailReceive, emailNotifications }, defaultValues)) {
      setAnyChanges(false);
    } else setAnyChanges(true);
  }, [defaultValues, emailReceive, emailNotifications]);

  return (
    <>
      <div className="p-4">
        <Form
          id={formID}
          onSubmit={handleSubmit(async (form) => {
            UpdateSubscriptionSettingsButton({ ipeds: ipedsId });
            setIsLoading(true);
            try {
              setSomethingWentWrong(false);
              await onSubmit(form);
            } catch (error) {
              console.error(error);
              setSomethingWentWrong(true);
            } finally {
              setIsLoading(false);
              setAnyChanges(false);
            }
          })}
        >
          <Form.Checkbox
            control={control}
            color={Colors.GREEN}
            name="emailNotifications"
            label="App notifications. Includes messages from my advisors."
          />
          <Form.Checkbox
            control={control}
            name="emailReceive"
            color={Colors.GREEN}
            label="News about upcoming DecidED updates and events."
          />

          <div className="mt-1">
            {somethingWentWrong && (
              <p className="text-sm font-semibold text-primary-red">
                Something went wrong, please try again later!
              </p>
            )}
          </div>
        </Form>
      </div>

      <div className="py-5 px-5 w-full mt-auto">
        <div className="flex flex-1 flex-row justify-center space-x-5">
          <LoadingButton
            type="submit"
            form={formID}
            isLoading={isLoading}
            disabled={!anyChanges}
            className="
              w-1/2
              text-white
              bg-primary-green
              border-primary-green
              hover:bg-white
              active:bg-white
              hover:text-primary-green
              active:text-primary-green
            "
          >
            update
          </LoadingButton>

          <LoadingButton
            isLoading={isLoading2}
            disabled={!unsubscribeButton}
            className="
              w-1/2
              text-white
              bg-primary-green
              border-primary-green
              hover:bg-white
              active:bg-white
              hover:text-primary-green
              active:text-primary-green
            "
            onClick={async () => {
              UnsubscribeFromAllButton({ ipeds: ipedsId });
              setIsLoading2(true);
              try {
                setSomethingWentWrong(false);
                await unsubscribeFromAll();

                setValue("emailNotifications", false);
                setValue("emailReceive", false);
              } catch (error) {
                console.error(error);
                setSomethingWentWrong(true);
              } finally {
                setIsLoading2(false);
              }
            }}
          >
            unsubscribe from all
          </LoadingButton>
        </div>
      </div>
    </>
  );
}

SubscriptionSettingsForm.propTypes = {
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.shape(),
  unsubscribeFromAll: PropTypes.func
};

export default SubscriptionSettingsForm;
