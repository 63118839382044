import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import GoogleMapView from "./GoogleMap";
import CollegeSelector from "./CollegeSelector";
import AffordabilityBox from "./AffordabilityBox";
import CollegeMajorsBox from "./CollegeMajorsBox";
import CollegeGuidesBox from "./CollegeGuidesBox";
import FirstYearBreakdown from "./FirstYearBreakdown";
import CollegeEarningsBox from "./CollegeEarningsBox";
import CollegeDiversityBox from "./CollegeDiversityBox";
import CollegeGraduationBox from "./CollegeGraduationBox";
import CollegeCampusEnvironment from "./CollegeCampusEnviroment";

import { DeleteSchoolModal } from "../../Modals";
import InputTooltip from "../Common/InputTooltip";
import DeleteButton from "../Common/DeleteButton";
import CollegeYearButtons from "../Common/CollegeYearButtons";
import { InputTooltipType } from "../Common/InputTooltip/utils";
import {
  DeleteSchoolStartCP,
  PageViewDataLayer
} from "../../../utils/DataLayers";

function DetailDesktop({
  student,
  isDesktop,
  openModal,
  selectedSchool,
  showZipTooltip,
  showUploadTutorial,
  showEthnicityTooltip,
  handlerShowZipTooltip,
  handlerShowEthnicityTooltip
}) {
  const { ipedsId } = useParams();
  const [deleteSchool, setDeleteSchool] = useState(false);

  const deleteSchoolConfirmToggle = () => {
    if (!deleteSchool) DeleteSchoolStartCP({ ipeds: ipedsId });
    setDeleteSchool((prevState) => !prevState);
  };

  useEffect(() => {
    if (student) {
      PageViewDataLayer({
        ipeds: ipedsId,
        userId: student.userId,
        screenName: "College Detail",
        organizationId: student.organizations.map((org) => org.organizationId)
      });
    }
  }, [ipedsId, student]);

  const commonProps = {
    student,
    isDesktop,
    chosenSchool: selectedSchool
  };

  return (
    <>
      <div className="pr-4 flex flex-row justify-end">
        <CollegeYearButtons redirect={true} />
      </div>
      <div className="pt-4 pl-4 pr-4">
        <CollegeSelector
          student={student}
          className="shadow-lg"
          selectedSchool={selectedSchool}
          colleges={student?.chosenSchools?.map((x) => ({
            ...x.school,
            isNew: x.isNew,
            sortOrder: x.sortOrder,
            isFavorite: x.isFavorite
          }))}
        />
      </div>
      <div className="mt-2">
        <AffordabilityBox
          {...commonProps}
          openModal={openModal}
          showUploadTutorial={showUploadTutorial}
        />
      </div>
      <div className="flex flex-1 flex-row">
        <div className="p-4 w-1/2 flex flex-1 flex-col">
          <FirstYearBreakdown
            showArrow={false}
            {...commonProps}
            studentState={student?.state}
            showUploadTutorial={showUploadTutorial}
          />
          <div className="flex flex-col relative items-center justify-center">
            <InputTooltip
              value={student.zip}
              open={showZipTooltip}
              chosenSchool={selectedSchool}
              type={InputTooltipType.ZIP_CODE}
              handlerOnClose={() => handlerShowZipTooltip(false)}
            />
            <div className="block w-full">
              <CollegeCampusEnvironment
                {...commonProps}
                handlerOnClickTooltip={() => handlerShowZipTooltip(true)}
              />
              <GoogleMapView
                className="shadow-lg"
                studentLatitude={student.lat}
                studentLongitude={student.lon}
                schoolName={selectedSchool.school.name}
                schoolLatitude={selectedSchool.school.latitude}
                schoolLongitude={selectedSchool.school.longitude}
              />
            </div>
          </div>
        </div>
        <div className="pt-2 w-1/2 flex flex-1 flex-col">
          <CollegeMajorsBox chosenSchool={selectedSchool} />
          <CollegeGraduationBox
            chosenSchool={selectedSchool}
            studentEthnicity={student.ethnicity}
          />
          <CollegeEarningsBox {...commonProps} />
          <div className="flex flex-col relative items-center justify-center">
            <InputTooltip
              value={student.ethnicity}
              open={showEthnicityTooltip}
              chosenSchool={selectedSchool}
              type={InputTooltipType.ETHNICITY}
              handlerOnClose={() => handlerShowEthnicityTooltip(false)}
            />
            <div className="block w-full">
              <CollegeDiversityBox
                {...commonProps}
                ethnicity={student.ethnicity}
                handlerOnClickTooltip={() => handlerShowEthnicityTooltip(true)}
              />
            </div>
          </div>
          <CollegeGuidesBox
            isDesktop={isDesktop}
            selectedSchool={selectedSchool}
          />
          <DeleteButton
            chosenSchool={selectedSchool}
            onClick={deleteSchoolConfirmToggle}
          />
          {deleteSchool && (
            <DeleteSchoolModal
              open={deleteSchool}
              chosenSchool={selectedSchool}
              deleteSchoolCancelHandler={deleteSchoolConfirmToggle}
              deleteSchoolConfirmHandler={deleteSchoolConfirmToggle}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default DetailDesktop;
