import PropTypes from "prop-types";

function LayoutLeftContent({ children }) {
  return (
    <div
      className="
        flex
        flex-col
        space-y-6
        text-white
        mobile-sm:mb-6
        mobile:mb-8
        tablet-sm:mb-8
        tablet:mb-8
        laptop:w-1/2
        laptop:px-6
        desktop:w-1/2
        desktop:px-6
      "
    >
      {children}
    </div>
  );
}

LayoutLeftContent.propTypes = {
  children: PropTypes.any
};

function LayoutRightContent({ children }) {
  return (
    <div
      className="
        laptop:w-1/2
        laptop:px-6
        desktop:w-1/2
        desktop:px-6
      "
    >
      {children}
    </div>
  );
}

LayoutRightContent.propTypes = {
  children: PropTypes.any
};

function LayoutCenterContent({ children, className }) {
  return (
    <div
      className={`
        p-10
        w-full
        my-auto
        mx-auto
        bg-white
        mobile-sm:p-5
        mobile:p-6
        tablet-sm:p-6
        tablet:p-6
        laptop:w-2/3
        desktop:w-2/3
        ${className}
      `}
    >
      {children}
    </div>
  );
}

LayoutCenterContent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

function LayoutDesktop({
  children,
  className = "bg-primary-green min-h-screen-footer"
}) {
  return (
    <div
      className={`
        p-4
        flex
        flex-1
        flex-col
        laptop:flex-row
        desktop:flex-row
        laptop:justify-center
        desktop:justify-center
        ${className}
      `}
    >
      <div
        className="
          flex
          flex-1
          flex-col
          laptop:flex-row
          laptop:max-w-75rem
          desktop:flex-row
          desktop:max-w-75rem
        "
      >
        {children}
      </div>
    </div>
  );
}

LayoutDesktop.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export { LayoutLeftContent, LayoutRightContent, LayoutCenterContent };
export default LayoutDesktop;
