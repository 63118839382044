function NoResultOptions() {
  return (
    <div
      className="
        flex
        h-full
        text-center
        items-center
        justify-center
        mobile:text-sm
        mobile-sm:text-sm
        tablet-sm:text-sm
      "
    >
      <span>
        Your search produced no matching items. Please edit and try again.
      </span>
    </div>
  );
}

export default NoResultOptions;
