import PropTypes from "prop-types";

function LaptopMag({
  width = 100,
  height = 80,
  color = "fill-black",
  iconColor = "stroke-black"
}) {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 102 70"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M101.077 64.2015C101.064 63.9724 100.997 63.7475 100.84 63.5609L92.834 54.3674C93.1649 53.9304 93.3983 53.4129 93.4025 52.8232V2.61761C93.4025 1.17517 92.2316 0 90.7891 0H10.322C8.87952 0 7.70435 1.17517 7.70435 2.61761V52.8232C7.70435 53.4426 7.95041 53.9856 8.31102 54.4311L0.267276 63.5566C0.106062 63.7391 0.0424248 63.9639 0.0254549 64.1973C0.0254549 64.2227 0 64.2354 0 64.2566V68.6349C0 69.2203 0.475158 69.6955 1.06062 69.6955H100.038C100.623 69.6955 101.098 69.2203 101.098 68.6349V64.2566C101.103 64.2354 101.081 64.2227 101.077 64.2015ZM9.82559 2.61761C9.82559 2.34609 10.0504 2.12124 10.322 2.12124H90.7849C91.0564 2.12124 91.277 2.34185 91.277 2.61761V52.8189C91.277 52.9292 91.2219 53.0225 91.1582 53.1032C90.9334 53.1159 90.7085 53.1625 90.5261 53.3153H10.322C10.0504 53.3153 9.82559 53.0947 9.82559 52.8232V2.61761ZM10.2626 55.428C10.2838 55.428 10.3007 55.4408 10.322 55.4408H90.7849C90.8358 55.4408 90.8782 55.4153 90.9291 55.4111L97.7129 63.2003H3.4152L10.2626 55.428ZM2.12548 67.5785V65.3215H98.9814V67.5785H2.12548Z"
      />
      <path
        className={color}
        d="M67.2944 59.874H33.8127C33.2272 59.874 32.7521 60.3492 32.7521 60.9346C32.7521 61.5201 33.2272 61.9953 33.8127 61.9953H67.2944C67.8798 61.9953 68.355 61.5201 68.355 60.9346C68.355 60.3492 67.8798 59.874 67.2944 59.874Z"
      />
      <path
        className={color}
        d="M53.1583 50.0957H47.5794C46.994 50.0957 46.5188 50.5709 46.5188 51.1563C46.5188 51.7418 46.994 52.2169 47.5794 52.2169H53.1583C53.7437 52.2169 54.2189 51.7418 54.2189 51.1563C54.2189 50.5709 53.7437 50.0957 53.1583 50.0957Z"
      />
      <path
        strokeWidth="1.5"
        fillRule="evenodd"
        clipRule="evenodd"
        className={iconColor}
        d="M48.8429 31.4375L41.9782 40.5474C41.6284 41.0115 40.9716 41.1066 40.4995 40.7508L39.4229 39.9395C38.956 39.5877 38.8614 38.9259 39.2109 38.4621L45.8143 29.699C43.6168 27.8623 42.2188 25.1008 42.2188 22.0127C42.2188 16.4829 46.7017 12 52.2316 12C57.7614 12 62.2443 16.4829 62.2443 22.0127C62.2443 27.5426 57.7614 32.0255 52.2316 32.0255C51.0422 32.0255 49.9012 31.8181 48.8429 31.4375Z"
      />
      <path
        strokeWidth="1.5"
        className={iconColor}
        d="M52.2194 28.6336C55.8575 28.6336 58.8067 25.6844 58.8067 22.0463C58.8067 18.4082 55.8575 15.459 52.2194 15.459C48.5813 15.459 45.6321 18.4082 45.6321 22.0463C45.6321 25.6844 48.5813 28.6336 52.2194 28.6336Z"
      />
    </svg>
  );
}

LaptopMag.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
  height: PropTypes.number,
  iconColor: PropTypes.string
};

export default LaptopMag;
