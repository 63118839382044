import PropTypes from "prop-types";

function SearchResultContainer({ isFloat, children }) {
  return (
    <div
      id="list-of-options"
      className={`
        px-4
        bg-white
        overflow-auto
        ${
          isFloat
            ? "z-10 w-full top-0 h-15rem absolute shadow-lg"
            : "pb-10 mt-2 h-full"
        }
      `}
    >
      {children}
    </div>
  );
}

SearchResultContainer.propTypes = {
  isFloat: PropTypes.bool,
  children: PropTypes.any,
};

export default SearchResultContainer;
