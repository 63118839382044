import { useEffect, useMemo } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import Viewer from "./Viewer";

import { getGrantsAndScholarships } from "../../../core/utils";
import { useGetChosenSchool, useStudent } from "../../../redux/Student/hooks";
import { PageViewDataLayer } from "../../../utils/DataLayers";

function ViewAwardLetterScreen({
  openModal,
  modalResponse,
  modalResponseData
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { ipedsId } = useParams();

  const isManualReview = location.state;

  const selectedSchool = useGetChosenSchool({ ipedsId });
  const { student } = useStudent();

  useEffect(() => {
    if (student) {
      PageViewDataLayer({
        ipeds: ipedsId,
        userId: student.userId,
        screenName: "Edit Financial Aid",
        organizationId: student.organizations.map((org) => org.organizationId)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGrantList = (aidAmounts) => {
    const grants = getGrantsAndScholarships(aidAmounts);
    return grants.map((aid) => {
      return {
        id: aid.id,
        value: aid.value,
        isNew: aid.isNew,
        name: aid.description,
        type: { value: aid.category },
        categorySource: aid.categorySource
      };
    });
  };

  const data = useMemo(
    () => ({
      schoolName: selectedSchool?.school?.name,
      file: selectedSchool?.award?.downloadUrl || "",
      grants: getGrantList(selectedSchool?.award?.aidAmounts || [])
    }),
    [selectedSchool]
  );

  if (!selectedSchool.award) {
    navigate(`/college/id/${ipedsId}`);
    return null;
  }

  return (
    <Viewer
      data={data}
      filePurged={selectedSchool?.award?.filePurged || false}
      openModal={openModal}
      modalResponse={modalResponse}
      chosenSchool={selectedSchool}
      onBack={() => navigate(-1)}
      isManualReview={isManualReview}
      modalResponseData={modalResponseData}
      onLook={() => navigate(`/college/id/${ipedsId}`)}
      onError={() => navigate(`/college/report-an-error/${ipedsId}`)}
    />
  );
}

ViewAwardLetterScreen.propTypes = {
  openModal: PropTypes.func,
  modalResponse: PropTypes.string,
  modalResponseData: PropTypes.shape()
};

export default ViewAwardLetterScreen;
