export const ListTypes = {
  ADVISOR_STUDENT_LIST_GROUP: "ADVISOR_STUDENT_LIST_GROUP",
  ADVISOR_STUDENTS_LIST_MODAL: "ADVISOR_STUDENTS_LIST_MODAL",
  ADVISOR_ADVISORS_LIST_MODAL: "ADVISOR_ADVISORS_LIST_MODAL",
  ADVISOR_GROUPS_LIST_MODAL: "ADVISOR_GROUPS_LIST_MODAL",
  STUDENT_ORGANIZATIONS_LIST_MODAL: "STUDENT_ORGANIZATIONS_LIST_MODAL",
};

export function getPlaceholder(type) {
  switch (type) {
    case ListTypes.ADVISOR_STUDENTS_LIST_MODAL:
      return "No Students Yet!!";
    case ListTypes.ADVISOR_ADVISORS_LIST_MODAL:
      return "No Advisors Yet!!";
    default:
      return "No Items Yet!!";
  }
}
