import { combineReducers } from "redux";
import { studentReducer } from "./Student/reducer";
import { notificationsReducer } from "./Notifications/reducer";

const rootReducer = combineReducers({
  student: studentReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
