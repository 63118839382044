import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import EarningsCard from "./Cards/EarningsCard";
import DiversityCard from "./Cards/DiversityCard";
import AffordabilityCard from "./Cards/AffordabilityCard";
import CollegeMajorsCard from "./Cards/CollegeMajorsCard";
import GraduationRateCard from "./Cards/GraduationRateCard";
import CampusEnvironmentCard from "./Cards/CampusEnvironmentCard";
import FirstYearBreakdownCard from "./Cards/FirstYearBreakdownCard";

import { SmallStar } from "../../../core/icons";
import { getEnrollmentIcon } from "../../../core/utils";

import { useStudent } from "../../../redux/Student/hooks";

function CollegeCard({
  id,
  school,
  heights,
  ethnicity,
  setHeights,
  marginRight,
  studentState,
  sectionsState,
  setSectionsState,
  showUploadTutorial,
  selectAsFavorite,
}) {
  const { schemaTypes } = useStudent();

  const scrollRef = useRef(null);
  const heightRefTitle = useRef(null);
  const heightRefMajors = useRef(null);
  const heightRefAffordability = useRef(null);
  const heightRefGraduationRate = useRef(null);
  const [currentEnrollmentOption, setCurrentEnrollmentOption] = useState(null);

  useEffect(() => {
    const enrollmentStatus =
      schemaTypes?.find((type) => type.name === "_EnrollmentStatus")
        ?.enumValues || [];

    const enrollmentStatusOption = enrollmentStatus.find(
      (status) => status.name === school.enrollmentStatus,
    );

    setCurrentEnrollmentOption({
      value: enrollmentStatusOption?.name,
      label: enrollmentStatusOption?.description,
      icon: getEnrollmentIcon({ enrollment: enrollmentStatusOption?.name }),
    });
  }, [schemaTypes, school.enrollmentStatus]);

  useEffect(() => {
    setTimeout(() => {
      const newHeights = {
        title: heightRefTitle.current?.clientHeight || 0,
        majors: heightRefMajors.current?.clientHeight || 0,
        affordability: heightRefAffordability.current?.clientHeight || 0,
        graduationRate: heightRefGraduationRate.current?.clientHeight || 0,
      };
      setHeights(newHeights);
    }, [500]);
  }, [setHeights]);

  const callbackToggle = (toggle, name) => {
    const newSectionState = {
      ...sectionsState,
      [name]: toggle,
    };
    setSectionsState(newSectionState);
  };

  const executeScroll = () => scrollRef.current.scrollIntoView();

  return (
    <div
      className={`
        p-4
        flex
        w-24rem
        flex-col
        space-y-4
        outline-none
        bg-light-gray
        ${marginRight ? marginRight : "mr-6"}
      `}
    >
      <div
        id={`${id}-detail`}
        className="flex flex-row px-1 justify-between items-start"
      >
        <h2
          ref={heightRefTitle}
          className="font-bold text-2xl w-11/12"
          style={{ minHeight: `${heights.title}px` }}
        >
          {school.school.name}
        </h2>
        <div className="flex flex-col">
          <div
            className="cursor-auto"
            data-tooltip-id="enrollment-icon-label"
            id={`${id}-enrollment-icon-label`}
            data-tooltip-content={currentEnrollmentOption?.label}
          >
            {currentEnrollmentOption?.icon}
          </div>
          <div
            style={{ minHeight: `${heights.title}px` }}
            className="mb-3 pb-3 flex items-end justify-center"
          >
            <div
              role="button"
              onClick={() => selectAsFavorite(school)}
              id={`${id}-college-item-favorite-button`}
            >
              <SmallStar
                fillColor={
                  school.isFavorite ? "fill-alternative-yellow" : "fill-white"
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        id={`${id}-affordability`}
        ref={heightRefAffordability}
        style={{ minHeight: `${heights.affordability}px` }}
      >
        <AffordabilityCard
          chosenSchool={school}
          id={`${id}-affordability`}
          executeScroll={executeScroll}
          showUploadTutorial={showUploadTutorial}
        />
      </div>
      <div
        id={`${id}-majors`}
        ref={heightRefMajors}
        style={{
          minHeight: `${sectionsState.majors ? heights.majors : 0}px`,
        }}
      >
        <CollegeMajorsCard
          id={`${id}-majors`}
          chosenSchool={school}
          open={sectionsState.majors}
          externalToggle={sectionsState.majors}
          callbackToggle={(toggle) => callbackToggle(toggle, "majors")}
        />
      </div>
      <div
        id={`${id}-graduation-rate`}
        ref={heightRefGraduationRate}
        style={{
          minHeight: `${
            sectionsState.graduationRate ? heights.graduationRate : 0
          }px`,
        }}
      >
        <GraduationRateCard
          chosenSchool={school}
          id={`${id}-graduation-rate`}
          ethnicityStudent={ethnicity}
          open={sectionsState.graduationRate}
          externalToggle={sectionsState.graduationRate}
          schoolProgramLength={school.school.programLength}
          ethnicityGroup={school.schoolCosts.ethnicityGroup}
          graduationRate={school.schoolCosts.graduationRate}
          callbackToggle={(toggle) => callbackToggle(toggle, "graduationRate")}
        />
      </div>
      <div id={`${id}-earning`}>
        <EarningsCard
          id={`${id}-earning`}
          chosenSchool={school}
          open={sectionsState.earnings}
          externalToggle={sectionsState.earnings}
          callbackToggle={(toggle) => callbackToggle(toggle, "earnings")}
        />
      </div>
      <div id={`${id}-diversity`}>
        <DiversityCard
          ethnicity={ethnicity}
          chosenSchool={school}
          id={`${id}-diversity`}
          open={sectionsState.diversity}
          externalToggle={sectionsState.diversity}
          callbackToggle={(toggle) => callbackToggle(toggle, "diversity")}
        />
      </div>
      <div id={`${id}-campus-environment`}>
        <CampusEnvironmentCard
          chosenSchool={school}
          id={`${id}-campus-environment`}
          open={sectionsState.campusEnvironment}
          externalToggle={sectionsState.campusEnvironment}
          callbackToggle={(toggle) =>
            callbackToggle(toggle, "campusEnvironment")
          }
        />
      </div>
      <div id={`${id}-first-year-breakdown`} ref={scrollRef}>
        <FirstYearBreakdownCard
          chosenSchool={school}
          studentState={studentState}
          id={`${id}-first-year-breakdown`}
          showUploadTutorial={showUploadTutorial}
          open={sectionsState.firstYearBreakdown}
          externalToggle={sectionsState.firstYearBreakdown}
          callbackToggle={(toggle) =>
            callbackToggle(toggle, "firstYearBreakdown")
          }
        />
      </div>
    </div>
  );
}

CollegeCard.propTypes = {
  id: PropTypes.string,
  ethnicity: PropTypes.string,
  marginRight: PropTypes.string,
  studentState: PropTypes.string,
  showUploadTutorial: PropTypes.bool,
  school: PropTypes.shape().isRequired,
  setHeights: PropTypes.func.isRequired,
  setSectionsState: PropTypes.func.isRequired,
  sectionsState: PropTypes.shape().isRequired,
  selectAsFavorite: PropTypes.func.isRequired,
  heights: PropTypes.shape({
    title: PropTypes.number,
    affordability: PropTypes.number,
    graduationRate: PropTypes.number,
  }).isRequired,
};

export default CollegeCard;
