import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../core/buttons";

function EstimateYourAidButton({ path, onMouseEnter, onMouseLeave }) {
  const navigate = useNavigate();

  const handlerOnClick = () => {
    navigate(path);
  };

  return (
    <Button
      className="
        mt-4
        w-full
        text-xsm
        text-primary-blue
        font-extrabold
        bg-white
        border-primary-blue
        hover:bg-primary-blue
        active:bg-primary-blue
        hover:text-white
        active:text-white
      "
      onClick={handlerOnClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      Estimate Your Aid
    </Button>
  );
}

EstimateYourAidButton.propTypes = {
  path: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

export default EstimateYourAidButton;
