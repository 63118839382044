import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { Button } from "../../../core/buttons";
import {
  ReportAnErrorBreakdown,
  AddAwardBreakdown,
} from "../../../utils/DataLayers";

function FirstYearButtons({
  colors,
  successfulAward,
  addYourMoneyPath,
  reportAnErrorPath,
}) {
  const navigate = useNavigate();
  const { ipedsId } = useParams();

  const handlerAddYourMoneyOnClick = () => {
    AddAwardBreakdown({ ipeds: ipedsId });
    navigate(addYourMoneyPath);
  };

  const handlerReportAnErrorClick = () => {
    ReportAnErrorBreakdown({ ipeds: ipedsId });
    navigate(reportAnErrorPath);
  };

  const getComposeBg = (bg) => {
    switch (bg) {
      case "bg-primary-red":
        return "text-white bg-primary-red hover:bg-white active:bg-white hover:text-primary-red active:text-primary-red";
      case "bg-primary-blue":
        return "text-white bg-primary-blue hover:bg-white active:bg-white hover:text-primary-blue active:text-primary-blue";
      case "bg-primary-green":
        return "text-white bg-primary-green hover:bg-white active:bg-white hover:text-primary-green active:text-primary-green";
      case "bg-primary-orange":
        return "text-white bg-primary-orange hover:bg-white active:bg-white hover:text-primary-orange active:text-primary-orange";

      default:
        return "text-black bg-white hover:bg-light-gray active:bg-light-gray";
    }
  };

  return (
    <div className="flex flex-1 flex-row justify-center">
      <div
        className={`
          w-full
          ${!successfulAward ? "flex flex-1 flex-col" : "hidden"}
        `}
      >
        <Button
          onClick={handlerAddYourMoneyOnClick}
          className={`
            my-2
            py-3
            w-full
            h-full
            text-xsm
            text-white
            font-extrabold
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue 
            active:text-primary-blue
          `}
          id="fy-upload-award-button"
        >
          add your award letter
        </Button>
      </div>
      <div
        className={`
          w-full
          ${successfulAward ? "flex flex-1 flex-col" : "hidden"}
        `}
      >
        <Button
          onClick={handlerReportAnErrorClick}
          className={`
            my-2
            py-3
            w-full
            h-full
            text-xsm
            normal-case
            ${colors.primary.border}
            ${getComposeBg(colors.primary.bg)}
          `}
          id="fy-report-error-button"
        >
          Report an Error
        </Button>
      </div>
    </div>
  );
}

FirstYearButtons.propTypes = {
  colors: PropTypes.shape(),
  successfulAward: PropTypes.bool,
  addYourMoneyPath: PropTypes.string.isRequired,
  reportAnErrorPath: PropTypes.string.isRequired,
};

export default FirstYearButtons;
