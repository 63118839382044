import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import StudentProfile from "./StudentProfile";
import NavigationMenu from "./NavigationMenu";

import { Button } from "../../core/buttons";
import { Notifications } from "../../core/compose";
import { Banner, SideMenu, LinkCustom } from "../../core/";
import { Colors, decidedRouteSupport } from "../../core/utils";
import { waitForTimeout, useIsDesktopMQL } from "../../core/hooks";
import {
  NotificationStatus,
  getNotificationList
} from "../../core/compose/Notifications/utils";

import { Logo, Profile, Notification, HamburgerMenu } from "../../core/icons";

import { useRefetchStudent } from "../../redux/Student/hooks";
import { useNotifications } from "../../redux/Notifications/hooks";
import {
  fetchNotifications,
  markNotificationAsRead,
  resetNotificationsState
} from "../../redux/Notifications/actions";

import Api from "../../services/Api";
import {
  NotificationsPopUp,
  CompareNavBar,
  SearchNavBar
} from "../../utils/DataLayers";
import { GetIpedsId } from "./Hooks";

function SimpleHeader({
  logoWhite = true,
  headerBGColor = "bg-primary-green"
}) {
  return (
    <div
      className={`
        px-8
        py-4
        z-20
        flex
        top-0
        flex-1
        sticky
        flex-row
        items-center
        justify-between
        ${headerBGColor}
      `}
    >
      <div className="flex flex-col items-center justify-center">
        {logoWhite ? <Logo size={40} color="fill-white" /> : <Logo size={40} />}
        <span className="font-black">DecidED.</span>
      </div>
    </div>
  );
}

SimpleHeader.propTypes = {
  logoWhite: PropTypes.bool,
  headerBGColor: PropTypes.string
};

function Header({
  student,
  openModal,
  modalResponse,
  logoWhite = false,
  headerShadow = true,
  headerBGColor = "bg-white"
}) {
  const isDesktop = useIsDesktopMQL();
  const ipedsId = GetIpedsId();

  const [data, setData] = useState([]);
  const [doFetch, setDoFetch] = useState(false);
  const [resetData, setResetData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [markAsRead, setMarkAsRead] = useState(false);
  const [markAllAsRead, setMarkAllAsRead] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);
  const [openUserNotifications, setOpenUserNotifications] = useState(false);
  const [newNotificationsCounter, setNewNotificationsCounter] = useState(0);

  useRefetchStudent({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationsState = useNotifications();

  const toggleStudentProfile = useCallback(() => {
    setOpenUserProfile(!openUserProfile);
  }, [openUserProfile]);

  const toggleNavigationMenu = useCallback(() => {
    setOpenNavigationMenu(!openNavigationMenu);
  }, [openNavigationMenu]);

  const toggleNotificationsDrawer = useCallback(() => {
    setOpenUserNotifications(!openUserNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openUserNotifications]);

  const handleUserNotificationsMarkAll = async () => {
    setMarkAllAsRead(true);

    dispatch(resetNotificationsState());

    await Api.readNotifications({ notificationIds: null });

    setData(() => {
      setDoFetch(() => {
        setCurrentPage(1);

        return true;
      });

      return [];
    });
  };

  const callbackLetterReviewed = (content) => {
    navigate(`/college/id/${content.schoolId ? content.schoolId : content}`);
    setOpenUserNotifications(false);
  };

  const callbackViewInvitation = (invitationId) => {
    navigate(`/invitation/${invitationId}`);
  };

  const handleUserNotificationMark = async (notificationId, status, close) => {
    if (status === NotificationStatus.NEW) {
      setMarkAsRead(true);
      await Api.readNotifications({ notificationIds: [notificationId] });
      dispatch(markNotificationAsRead({ notificationId }));
      setData((localData) =>
        localData.map((notification) => {
          if (notification.id === notificationId)
            return {
              ...notification,
              status: NotificationStatus.READ
            };

          return notification;
        })
      );
    }

    if (close) setOpenUserNotifications(false);
  };

  const handlePagination = (isIntersecting) => {
    const getNotifications = () => {
      setIsLoadingPage(true);
      const newData = getNotificationList(
        notificationsState.pages[currentPage - 1],
        {
          letterReviewed: callbackLetterReviewed,
          viewInvitation: callbackViewInvitation,
          markAsRead: handleUserNotificationMark
        }
      );
      setData((data) => data.concat(newData));

      setCurrentPage(currentPage + 1);
      setIsLoadingPage(false);
      if (currentPage + 1 <= notificationsState.pageCount) setDoFetch(true);
    };

    if (
      openUserNotifications &&
      isIntersecting &&
      !resetData &&
      !notificationsState.isLoading &&
      Object.is(notificationsState.error, null)
    ) {
      if (currentPage <= notificationsState.pageCount)
        if (!isLoadingPage) getNotifications();
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    const getNotifications = () => {
      dispatch(
        fetchNotifications({ page: { pageNumber: currentPage, pageSize: 15 } })
      );

      if (isSubscribed) setDoFetch(false);
    };

    if (doFetch) getNotifications();
    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, doFetch]);

  useEffect(() => {
    setNewNotificationsCounter(notificationsState.newItemsCount);
  }, [notificationsState.newItemsCount]);

  useEffect(() => {
    const resetDataCallback = async () => {
      setResetData(true);
      setCurrentPage(1);

      await waitForTimeout(100);

      setData([]);
      setResetData(false);
    };

    if (!markAsRead && !markAllAsRead) resetDataCallback();
    else if (markAsRead) setMarkAsRead(false);
    else setMarkAllAsRead(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotificationsCounter]);

  const compareOnClick = () => {
    navigate("/college/compare");
    CompareNavBar({ ipeds: ipedsId });
  };

  const searchOnClick = () => {
    navigate("/college/search");
    SearchNavBar({ ipeds: ipedsId });
  };

  const viewResultsDataLayer = () => {
    NotificationsPopUp({ ipeds: ipedsId });
  };

  const activeStyle = "text-primary-green";

  return (
    <>
      <header
        className={`
          py-2
          px-4  
          z-30
          flex
          top-0
          flex-1
          sticky
          flex-row
          items-center
          justify-center
          mobile:py-6
          mobile-sm:py-6
          tablet-sm:py-6
          ${headerBGColor}
          ${headerShadow ? "shadow-lg" : ""}
        `}
      >
        <div
          className="
            flex
            flex-1
            flex-row
            items-center
            justify-center
            tablet:hidden
            laptop:hidden
            desktop:hidden
          "
        >
          {logoWhite ? (
            <HamburgerMenu
              size={30}
              color="fill-white"
              className="cursor-pointer"
              onClick={toggleNavigationMenu}
            />
          ) : (
            <HamburgerMenu
              size={30}
              className="cursor-pointer"
              onClick={toggleNavigationMenu}
            />
          )}
          <h1
            className="
              mx-4
              flex
              flex-1
              text-2xl
              font-black
              items-center
              justify-start
            "
          >
            <a href="/college">DecidED.</a>
          </h1>
        </div>

        <div
          className="
            flex
            flex-1
            flex-row
            mobile:hidden
            mobile-sm:hidden
            tablet-sm:hidden
          "
        >
          <Button
            id="header-button-decided"
            className="border-white"
            onClick={() => navigate("/college/my-path")}
          >
            <div className="flex flex-col items-center justify-center">
              {logoWhite ? (
                <Logo size={40} color="fill-white" />
              ) : (
                <Logo size={40} />
              )}
              <span className="font-black">DecidED.</span>
            </div>
          </Button>
          <div className="flex flex-1 flex-row justify-end items-center">
            <Button
              id="header-button-my-path"
              onClick={() => navigate("/college/my-path")}
              className={`
                border-white
                hover:text-alternative-green
                ${
                  window.location.pathname === "/college/my-path" && activeStyle
                }
              `}
            >
              {student.firstName}'s path
            </Button>
            <Button
              id="header-button-compare"
              onClick={compareOnClick}
              className={`
                border-white
                hover:text-alternative-green
                ${
                  window.location.pathname === "/college/compare" && activeStyle
                }
              `}
            >
              compare
            </Button>
            <Button
              id="header-button-search"
              onClick={searchOnClick}
              className={`
                border-white
                hover:text-alternative-green
                ${window.location.pathname === "/college/search" && activeStyle}
              `}
            >
              search
            </Button>
            <LinkCustom
              target="_blank"
              id="header-button-help"
              href={decidedRouteSupport()}
              className="py-2 px-5 font-extrabold hover:text-alternative-green"
            >
              Help
            </LinkCustom>
          </div>
        </div>
        {student && (
          <div className="flex flex-row justify-end items-center">
            <Notification
              size={isDesktop ? 38 : 33}
              className="mx-2 cursor-pointer"
              id="button-header-notifications"
              onClick={toggleNotificationsDrawer}
              notifications={newNotificationsCounter}
            />
            <Profile
              size={isDesktop ? 42 : 37}
              id="button-header-profile"
              boxColor="fill-primary-green"
              onClick={toggleStudentProfile}
              className="mx-2 cursor-pointer"
            />
          </div>
        )}
      </header>

      <SideMenu open={openNavigationMenu} direction="ltr">
        <NavigationMenu
          student={student}
          isOpen={openNavigationMenu}
          onClose={toggleNavigationMenu}
          preSignIn={student ? false : true}
        />
      </SideMenu>

      {student && (
        <>
          <SideMenu direction="rtl" open={openUserProfile}>
            <StudentProfile
              student={student}
              openModal={openModal}
              isOpen={openUserProfile}
              modalResponse={modalResponse}
              onClose={toggleStudentProfile}
            />
          </SideMenu>
          <SideMenu direction="rtl" open={openUserNotifications}>
            <Notifications
              color={Colors.GREEN}
              notifications={data}
              open={openUserNotifications}
              handlePagination={handlePagination}
              onClose={toggleNotificationsDrawer}
              newNotifications={newNotificationsCounter}
              onMarkAll={handleUserNotificationsMarkAll}
              totalNotifications={notificationsState.itemsCount}
              viewResultsDataLayer={viewResultsDataLayer}
              isLoading={
                (markAllAsRead || notificationsState.isLoading) &&
                openUserNotifications
              }
            />
          </SideMenu>
        </>
      )}
      <Banner color={Colors.GREEN} content={student.banner} />
    </>
  );
}

Header.propTypes = {
  logoWhite: PropTypes.bool,
  openModal: PropTypes.func,
  student: PropTypes.shape(),
  headerShadow: PropTypes.bool,
  headerBGColor: PropTypes.string,
  modalResponse: PropTypes.string
};

export { SimpleHeader };
export default Header;
