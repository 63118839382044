import TagManager from "react-gtm-module";

function GradRateExpand({ ethnicity, ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "grad_rate_expand",
      ethnicity: ethnicity,
      ipeds: ipeds,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default GradRateExpand;
