import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Close, Question } from "../../core/icons";

import { updateStudent } from "../../redux/Student/actions";

function FirstSchoolModal({ onClose }) {
  const dispatch = useDispatch();

  const markOnboardingFirstSchoolDone = () => {
    dispatch(updateStudent({ onboardingChosenSchool: true }));
    onClose();
  };

  return (
    <div
      id="added-first-school-modal"
      className="
        pt-2
        flex
        pb-10
        flex-1
        flex-col
        relative
        justify-center
      "
    >
      <div
        id="close-added-first-school-modal-button"
        onClick={() => markOnboardingFirstSchoolDone()}
        className="m-4 flex flex-row self-end cursor-pointer"
      >
        <Close
          size={25}
          color="stroke-white"
          className="ml-auto my-auto place-self-center"
        />
      </div>
      <div
        className="
          z-10
          flex
          mx-10
          flex-1
          text-xl
          flex-col
          font-bold
          text-center
          text-white
          justify-center
          mobile:mx-5
        "
      >
        <p className="capitalize text-calm-orange">Great job!</p>
        <p className="capitalize">You added your first school!</p>
        <div className="my-5 flex flex-row justify-center">
          <Question size={35} />
        </div>
        <p className="tablet:mx-24 laptop:mx-24 desktop:mx-24">
          Click the white circles to learn more about how we got these numbers.
        </p>
        <p className="mt-10 hidden tablet:block laptop:block desktop:block">
          This college profile page gives information on costs and other fit
          factors to help you decide on the best college for you
        </p>
      </div>
    </div>
  );
}

FirstSchoolModal.propTypes = {
  onClose: PropTypes.func,
};

export default FirstSchoolModal;
