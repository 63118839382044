import PropTypes from "prop-types";

function StarsPattern({
  width = 100,
  height = 150,
  className = "",
  color = "fill-primary-green"
}) {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 116 122"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M78.3636 51.248L81.6508 54.8296L86.3836 50.4857L90.4457 54.9115L93.797 51.8356L89.7349 47.4098L94.4421 43.0894L91.1549 39.5078L86.4477 43.8282L82.3856 39.4024L79.0343 42.4784L83.0964 46.9041L78.3636 51.248Z"
      />
      <path
        className={color}
        d="M91.4676 82.2437L93.6777 86.5737L99.3995 83.6533L102.13 89.0041L106.182 86.9361L103.451 81.5854L109.142 78.6808L106.932 74.3507L101.241 77.2553L98.5102 71.9046L94.4585 73.9726L97.1895 79.3233L91.4676 82.2437Z"
      />
      <path
        className={color}
        d="M60.0831 104.69L63.2523 108.377L68.1237 104.189L72.0399 108.744L75.4893 105.779L71.5732 101.223L76.4183 97.0583L73.2492 93.3719L68.4041 97.537L64.4879 92.9816L61.0384 95.947L64.9546 100.502L60.0831 104.69Z"
      />
      <path
        className={color}
        d="M41.9934 65.8544L44.999 69.6754L50.0482 65.7037L53.7622 70.4254L57.3376 67.613L53.6235 62.8913L58.6454 58.9411L55.6398 55.1201L50.6179 59.0703L46.9039 54.3486L43.3285 57.161L47.0426 61.8827L41.9934 65.8544Z"
      />
      <path
        className={color}
        d="M9.40356 53.5809L12.4091 57.4019L17.4583 53.4302L21.1724 58.1519L24.7477 55.3395L21.0337 50.6179L26.0556 46.6677L23.05 42.8467L18.0281 46.7969L14.314 42.0752L10.7387 44.8876L14.4527 49.6092L9.40356 53.5809Z"
      />
      <path
        className={color}
        d="M39.3323 24.0575L42.3379 27.8785L47.387 23.9068L51.1011 28.6285L54.6765 25.8161L50.9624 21.0944L55.9843 17.1442L52.9787 13.3232L47.9568 17.2734L44.2428 12.5518L40.6674 15.3641L44.3814 20.0858L39.3323 24.0575Z"
      />
      <path
        className={color}
        d="M24.8741 93.8447L27.3391 98.0348L32.8761 94.7775L35.9222 99.9554L39.8429 97.6488L36.7969 92.471L42.304 89.2313L39.839 85.0411L34.3319 88.2808L31.2859 83.103L27.3651 85.4095L30.4111 90.5874L24.8741 93.8447Z"
      />
    </svg>
  );
}

StarsPattern.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string
};

export default StarsPattern;
