import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { ShortArrow } from "../../../core/icons";
import FirstYearButtons from "../Common/FirstYearButtons";
import { Panel, FirstYearBreakdown } from "../../../core/compose";

import Api from "../../../services/Api";
import { AwardStatus, getTheme, getColors } from "../../../core/utils";
import { Tooltip } from "../../../utils/DataLayers";

function FirstYearBreakdownBox({
  isDesktop,
  showArrow,
  chosenSchool,
  studentState,
  showUploadTutorial,
  tooltipCostClassName,
  tooltipBudgetClassName,
  tooltipGrantsClassName,
}) {
  const navigate = useNavigate();
  const { award } = chosenSchool;

  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability,
  });
  const colors = getColors(theme.color);

  const errorPending = award?.pendingError?.errorType;
  const successfulAward = award?.status === AwardStatus.READY;
  const inReview =
    award?.status === AwardStatus.PENDING ||
    award?.status === AwardStatus.MANUAL_REVIEW;

  function handleArrowClick(e) {
    e.preventDefault();
    navigate(`/college/id/${chosenSchool.school.ipedsId}`);
  }

  const addYourMoneyPath = `/college/add-your-money/${
    chosenSchool.school.ipedsId
  }${!showUploadTutorial ? "/upload-images" : ""}`;
  const reportAnErrorPath = `/college/report-an-error/${chosenSchool.school.ipedsId}`;

  const handleUpdateAward = (isIntersecting) => {
    if (isIntersecting)
      setTimeout(() => {
        Api.readAwardUpdate({ chosenSchoolId: chosenSchool.pairedId });
      }, 2000);
  };

  return (
    <div
      className="
        bg-grey
        tablet:shadow-lg
        laptop:shadow-lg
        desktop:shadow-lg
        tablet:bg-white
        laptop:bg-white
        desktop:bg-white
      "
    >
      {showArrow && (
        <div className="px-2 py-4 flex flex-1 items-center">
          <div role="button" className="flex mr-4" onClick={handleArrowClick}>
            <ShortArrow size={40} />
          </div>
          <div className="flex flex-1 flex-col">
            <p className="font-semibold text-xl">First Year Breakdown</p>
            <span className="font-meidum text-xs">
              {chosenSchool.school.name}
            </span>
          </div>
        </div>
      )}
      {isDesktop && (
        <div className="pt-4 px-4 flex flex-col">
          <p className="flex font-medium capitalize">first year breakdown</p>
          <p className="my-2 text-xl font-bold">Your Estimated Costs</p>
          <p className="text-sm font-medium">
            Learn more about your costs and determine your lifestyle choices to
            reduce or increase costs.
          </p>
        </div>
      )}

      <FirstYearBreakdown
        tooltip={true}
        colors={colors}
        color={theme.color}
        isDesktop={isDesktop}
        chosenSchool={chosenSchool}
        studentState={studentState}
        handleUpdateAward={handleUpdateAward}
        tooltipCostClassName={tooltipCostClassName}
        tooltipBudgetClassName={tooltipBudgetClassName}
        tooltipGrantsClassName={tooltipGrantsClassName}
        gaOnClickFunc={(text) =>
          Tooltip({ text: text, ipeds: chosenSchool?.school?.ipedsId })
        }
      />

      {!successfulAward && !errorPending && !isDesktop && (
        <Panel
          tooltip={true}
          isDesktop={isDesktop}
          id="grants-scholarships"
          tooltipColor={theme.color}
          title="Grants & Scholarships"
          className="p-4 mt-4 bg-white mobile:shadow-md"
          gaOnClickFunc={(text) =>
            Tooltip({ text: text, ipeds: chosenSchool?.school?.ipedsId })
          }
          titleClassName="text-xsm font-semibold capitalize"
          tooltipClassName="-ml-36 bottom-20 mobile-sm:-ml-44"
          tooltipLabel="This is free money to help you pay for college!"
        >
          <div className="flex flex-1 flex-col">
            <span className="mt-4 text-sm font-base">
              {inReview ? (
                <>
                  One of our team members will check out your award as soon as
                  possible. We'll email you when your results are ready.
                </>
              ) : !award ? (
                <>
                  Add you award letter to view your potential grants and
                  scholarships from this university.
                </>
              ) : (
                <>
                  There were no grants & scholarships listed on your{" "}
                  <span className="font-semibold">
                    financial aid award letter
                  </span>
                  .
                </>
              )}
            </span>
            <p className="text-2xl font-semibold mt-4">{theme.label}</p>
          </div>
        </Panel>
      )}

      <div className="px-4">
        <FirstYearButtons
          colors={colors}
          successfulAward={successfulAward}
          addYourMoneyPath={addYourMoneyPath}
          reportAnErrorPath={reportAnErrorPath}
        />
      </div>
    </div>
  );
}

FirstYearBreakdownBox.propTypes = {
  isDesktop: PropTypes.bool,
  showArrow: PropTypes.bool,
  studentState: PropTypes.string,
  chosenSchool: PropTypes.shape(),
  showUploadTutorial: PropTypes.bool,
  tooltipCostClassName: PropTypes.string,
  tooltipBudgetClassName: PropTypes.string,
  tooltipGrantsClassName: PropTypes.string,
};

export default FirstYearBreakdownBox;
