import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import Viewer from "./Viewer";

import { getGrantsAndScholarships } from "../../../core/utils";
import { useGetChosenSchool, useStudent } from "../../../redux/Student/hooks";
import { PageViewDataLayer } from "../../../utils/DataLayers";

function EstimateFinancialAidScreen({
  openModal,
  modalResponse,
  modalResponseData
}) {
  const navigate = useNavigate();
  const { ipedsId } = useParams();
  const { student } = useStudent();
  const selectedSchool = useGetChosenSchool({ ipedsId });

  const [hasAutoEstimatedAid, setHasAutoEstimatedAid] = useState(false);

  useEffect(() => {
    if (student) {
      PageViewDataLayer({
        ipeds: ipedsId,
        userId: student.userId,
        screenName: "Estimate Financial Aid",
        organizationId: student.organizations.map((org) => org.organizationId)
      });
    }

    if (selectedSchool.estimatedAids.length > 0) {
      const hasAutoEstimatedAid = selectedSchool.estimatedAids?.some(
        (aid) => aid.categorySource === "LETTER"
      );
      setHasAutoEstimatedAid(hasAutoEstimatedAid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGrantList = (aidAmounts) => {
    const grants = getGrantsAndScholarships(aidAmounts);
    return grants.map((aid) => {
      return {
        id: aid.id,
        value: aid.value,
        isNew: aid.isNew,
        name: aid.description,
        type: { value: aid.category },
        categorySource: aid.categorySource
      };
    });
  };

  const data = {
    hasAutoEstimatedAid,
    schoolName: selectedSchool?.school?.name,
    grants: getGrantList(selectedSchool?.estimatedAids || [])
  };

  return (
    <Viewer
      data={data}
      openModal={openModal}
      modalResponse={modalResponse}
      chosenSchool={selectedSchool}
      modalResponseData={modalResponseData}
      onLook={() => navigate(`/college/id/${ipedsId}`)}
    />
  );
}

EstimateFinancialAidScreen.propTypes = {
  openModal: PropTypes.func,
  modalResponse: PropTypes.string,
  modalResponseData: PropTypes.shape()
};

export default EstimateFinancialAidScreen;
