import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../core/buttons";
import { Colors, getColors } from "../../../core/utils";

function FirstYearBreakdownButton({
  path,
  color = Colors.DEFAULT,
  className = ""
}) {
  const navigate = useNavigate();

  const colors = getColors(color);

  return (
    <div className={className}>
      <Button
        id="first-year-breakdown-button"
        onClick={() => navigate(path)}
        className={`
          w-full
          text-black  
          border-black
          font-extrabold
          ${colors.secondary.bg}
        `}
      >
        first year breakdown
      </Button>
    </div>
  );
}

FirstYearBreakdownButton.propTypes = {
  path: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default FirstYearBreakdownButton;
