import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

import { getIcon } from "./utils";
import { getChartColors } from "../utils";

function AffordabilityChartDesktop({ data, affordability }) {
  const divSvgRef = useRef();
  const constainerRef = useRef();
  const showLabels = useRef(false);

  const [radius, setRadius] = useState(0);

  useEffect(() => {
    const strokeWidth = 1.5;
    const paddingFactor = 0.0157;
    const innerRadiusFactor = 0.5;
    const outerRadiusFactor = 0.95;
    const offsetRadiusFactor = 0.7;

    const xySlice = radius;
    const svgSize = radius * 2;

    const computedRadius = radius * offsetRadiusFactor;
    const innerRadius = computedRadius * innerRadiusFactor;
    const outerRadius = computedRadius * outerRadiusFactor;

    const iconSize = (outerRadius - innerRadius) * 0.55;
    const iconInset = iconSize / 2;

    const divSvg = d3.select(divSvgRef.current);

    if (svgSize > 0) {
      const sliceColor = getChartColors(affordability);

      const handlerOnMouseOver = (event) => {
        if (!event.currentTarget) return;

        const id = event.currentTarget.getAttribute("id");
        d3.select(event.currentTarget).attr("transform", "scale(1.05)");
        d3.select(event.currentTarget).attr("filter", "url(#dropshadow)");

        d3.select(`#label-line-${id}`).style("display", "block");
        d3.select(`#label-label-${id}`).style("display", "block");
        d3.select(`#label-percent-${id}`).style("display", "block");
        d3.select(`#labels-${id}`).attr("transform", "scale(1.05)");
      };

      const handlerOnMouseOut = (event) => {
        if (!event.currentTarget) return;

        const id = event.currentTarget.getAttribute("id");
        d3.select(event.currentTarget).attr("filter", null);
        d3.select(event.currentTarget).attr("transform", null);

        if (!showLabels.current) {
          d3.select(`#label-line-${id}`).style("display", "none");
          d3.select(`#label-label-${id}`).style("display", "none");
          d3.select(`#label-percent-${id}`).style("display", "none");
        }
        d3.select(`#labels-${id}`).attr("transform", null);
      };

      const handlerOnClick = () => {
        const newShowLabels = !showLabels.current;

        if (newShowLabels) {
          d3.selectAll("polyline").style("display", "block");
          d3.selectAll("text").style("display", "block");
        } else {
          d3.selectAll("polyline").style("display", "none");
          d3.selectAll("text").style("display", "none");
        }

        showLabels.current = newShowLabels;
      };

      const svg = divSvg
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 ${svgSize} ${svgSize}`);

      const defs = svg.append("defs");
      const filter = defs.append("filter").attr("id", "dropshadow");

      filter
        .append("feGaussianBlur")
        .attr("in", "SourceAlpha")
        .attr("stdDeviation", 4)
        .attr("result", "blur");
      filter
        .append("feOffset")
        .attr("in", "blur")
        .attr("dx", 2)
        .attr("dy", 2)
        .attr("result", "offsetBlur");

      const feMerge = filter.append("feMerge");

      feMerge.append("feMergeNode").attr("in", "offsetBlur");
      feMerge.append("feMergeNode").attr("in", "SourceGraphic");

      const layer1 = svg
        .append("g")
        .attr("transform", `translate(${xySlice}, ${xySlice})`);
      const layer2 = svg
        .append("g")
        .attr("transform", `translate(${xySlice}, ${xySlice})`);

      let initialRadians = 0;
      data.forEach((category) => {
        const angleSection = (category.totalPercent / 100) * 360;
        const radiansSection = (angleSection * Math.PI) / 180;

        const categoryPercent = affordability ? category.fillPercent : 100;
        const fillPercent =
          Math.min(Math.max(0, categoryPercent || 0), 100) / 100;

        const sliceContainer = d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .startAngle(initialRadians + paddingFactor)
          .endAngle(initialRadians + radiansSection - paddingFactor);

        const sliceContent = d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .startAngle(initialRadians + paddingFactor)
          .endAngle(
            initialRadians + radiansSection * fillPercent - paddingFactor
          );

        const groupSlice = layer1
          .append("g")
          .attr("id", `slice-${category.id}`)
          .on("mouseover", handlerOnMouseOver)
          .on("mouseout", handlerOnMouseOut)
          .on("click", handlerOnClick);

        groupSlice
          .append("path")
          .attr("d", sliceContainer)
          .style("stroke-width", `${strokeWidth}px`)
          .style("stroke", "#F3F3F3")
          .style("fill", "#F3F3F3");

        groupSlice
          .append("path")
          .attr("d", sliceContent)
          .attr("stroke-linecap", "round")
          .attr(
            "class",
            `fill-current stroke-current ${
              affordability ? sliceColor.primary : sliceColor.secondary
            }`
          )
          .style("visibility", fillPercent > 0 ? "show" : "hidden")
          .style("stroke-width", `${strokeWidth}px`);

        groupSlice
          .append("path")
          .attr("d", sliceContainer)
          .style("stroke-width", `${strokeWidth}px`)
          .style("fill", "transparent")
          .style("stroke", "#000000");

        const groupPosition = sliceContainer.centroid();
        if (category.totalPercent >= 7) {
          getIcon({
            iconSize,
            id: category.id,
            color: "#000000",
            group: groupSlice,
            x: groupPosition[0] - iconInset,
            y: groupPosition[1] - iconInset
          });
        }

        const xLine = groupPosition[0];
        const yLine = groupPosition[1];
        const limit = outerRadius * 0.65;
        const inset1 = outerRadius * 0.22;
        const inset2 = outerRadius * 0.53;
        const inset = category.totalPercent >= 7 ? iconSize / 2 : 0;

        const refXPoint =
          radius *
          (xLine > (category.id === "housing" ? -20 : 0) ? 0.9275 : -0.9275);
        const refYPoint = {
          housing: radius * 0.72,
          books: radius * (yLine > 0 ? 0.72 : -0.32),
          personal: radius * (yLine > 30 ? 0.87 : -0.87),
          transportation: radius * (yLine > 30 ? 0.56 : -0.62)
        };
        const refComputedYLine = refYPoint[category.id]
          ? refYPoint[category.id] > yLine
            ? yLine + inset
            : yLine - inset
          : null;
        const refComputedYLine2 = refYPoint[category.id];

        const computedXLine =
          xLine > (category.id === "housing" ? -20 : 0)
            ? xLine + inset1
            : xLine - inset1;
        const computedYLine = refComputedYLine
          ? refComputedYLine
          : yLine > 0
          ? yLine + inset
          : yLine - inset;
        const computedYLine2 = refComputedYLine2
          ? refComputedYLine2
          : yLine > 0
          ? yLine > limit
            ? yLine + inset2
            : yLine + inset1
          : yLine < -limit
          ? yLine - inset2
          : yLine - inset1;

        const group = layer2
          .append("g")
          .attr("id", `labels-slice-${category.id}`);

        group
          .append("polyline")
          .style("fill", "none")
          .style("display", !showLabels.current ? "none" : "block")
          .attr("id", `label-line-slice-${category.id}`)
          .attr("stroke-width", strokeWidth)
          .attr("stroke", "#000000")
          .attr(
            "points",
            `${xLine},${computedYLine}
             ${computedXLine},${computedYLine2}
             ${refXPoint},${computedYLine2}`
          );

        const fontSize = radius * 0.072;
        const yTextOffset1 = computedYLine2 - radius * 0.018;
        const yTextOffset2 = computedYLine2 - radius * 0.005 + fontSize;

        group
          .append("text")
          .text(category.label)
          .style("font-size", fontSize)
          .style(
            "text-anchor",
            xLine > (category.id === "housing" ? -20 : 0) ? "end" : "start"
          )
          .style("display", !showLabels.current ? "none" : "block")
          .attr("id", `label-label-slice-${category.id}`)
          .attr(
            "transform",
            `translate(
              ${refXPoint}, ${yTextOffset1}
            )`
          );

        group
          .append("text")
          .text(`${category.totalPercent}%`)
          .style("font-size", fontSize)
          .style(
            "text-anchor",
            xLine > (category.id === "housing" ? -20 : 0) ? "end" : "start"
          )
          .style("display", !showLabels.current ? "none" : "block")
          .attr("id", `label-percent-slice-${category.id}`)
          .attr(
            "transform",
            `translate(
              ${refXPoint}, ${yTextOffset2}
            )`
          );

        initialRadians += radiansSection;
      });
    }

    return () => divSvg.selectAll("*").remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, affordability, radius]);

  useEffect(() => {
    getRadius();

    window.addEventListener("resize", getRadius);
    return () => window.removeEventListener("resize", getRadius);
  }, []);

  const getRadius = () => {
    if (constainerRef.current) {
      const node = constainerRef.current;
      setRadius(node.getBoundingClientRect().width / 2);
    }
  };

  return (
    <div ref={constainerRef}>
      <div ref={divSvgRef} />
    </div>
  );
}

AffordabilityChartDesktop.propTypes = {
  affordability: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape())
};

export default AffordabilityChartDesktop;
