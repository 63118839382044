import { Navigate, Route } from "react-router-dom";
import PropTypes from "prop-types";

import MyPath from "./MyPath";
import Search from "./Search";
import Compare from "./Compare";
import CollegeDetail from "./Detail";
import Layout from "../Common/Layout";
import LayoutCollege from "./LayoutCollege";
import ViewAwardLetter from "./ViewAwardLetter";
import AddYourMoneyRoutes from "./AddYourMoney";
import ReportAnErrorRoutes from "./ReportAnError";
import CustomSwitch from "../Common/CustomSwitch";
import PrivateRoute from "../Common/PrivateRoute";
import FirstYearBreakdownScreen from "./FirstYearBreakdown";
import EstimateFinancialAidScreen from "./EstimateFinancialAid";

import { useStudent } from "../../redux/Student/hooks";

function CollegeIdRoutes({
  student,
  openModal,
  modalResponse,
  modalResponseData
}) {
  return (
    <CustomSwitch>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Navigate to="/" />
          </PrivateRoute>
        }
      />
      <Route
        path="/:ipedsId/*"
        element={
          <PrivateRoute>
            <Layout
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
            >
              <LayoutCollege>
                <div
                  className="
                min-h-screen-footer-md
                mobile:min-h-screen-footer
                mobile-sm:min-h-screen-footer
                tablet-sm:min-h-screen-footer
              "
                >
                  <CustomSwitch>
                    <Route
                      path="/"
                      element={
                        <PrivateRoute>
                          <CollegeDetail
                            student={student}
                            openModal={openModal}
                            modalResponse={modalResponse}
                          />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/view-award-letter"
                      element={
                        <PrivateRoute>
                          <ViewAwardLetter
                            openModal={openModal}
                            modalResponse={modalResponse}
                            modalResponseData={modalResponseData}
                          />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/estimate-financial-aid"
                      element={
                        <PrivateRoute>
                          <EstimateFinancialAidScreen
                            openModal={openModal}
                            modalResponse={modalResponse}
                            modalResponseData={modalResponseData}
                          />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/first-year-breakdown"
                      element={
                        <PrivateRoute>
                          <FirstYearBreakdownScreen student={student} />
                        </PrivateRoute>
                      }
                    />
                  </CustomSwitch>
                </div>
              </LayoutCollege>
            </Layout>
          </PrivateRoute>
        }
      />
    </CustomSwitch>
  );
}

function CollegeRoutes({ openModal, modalResponse, modalResponseData }) {
  const { student } = useStudent();

  return (
    <CustomSwitch>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Navigate to="/" />
          </PrivateRoute>
        }
      />
      <Route
        path="/id/*"
        element={
          <PrivateRoute>
            <CollegeIdRoutes
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
              modalResponseData={modalResponseData}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-path"
        element={
          <PrivateRoute>
            <MyPath
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/search"
        element={
          <PrivateRoute>
            <Search
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/compare"
        element={
          <PrivateRoute>
            <Compare
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/add-your-money/*"
        element={
          <PrivateRoute>
            <AddYourMoneyRoutes
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/report-an-error/*"
        element={
          <PrivateRoute>
            <ReportAnErrorRoutes
              student={student}
              openModal={openModal}
              modalResponse={modalResponse}
            />
          </PrivateRoute>
        }
      />
    </CustomSwitch>
  );
}

CollegeRoutes.propTypes = {
  student: PropTypes.shape(),
  modalResponse: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  modalResponseData: PropTypes.shape()
};

export default CollegeRoutes;
