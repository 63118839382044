import { useCallback, useEffect, useState } from "react";

import CollegeGuide from "../../../Common/CollegeGuide";

import { SchoolStatus } from "../../../../../core/utils";
import { useStudent } from "../../../../../redux/Student/hooks";

function AffordabilityGuideSection({ affordability, schoolStatus }) {
  const { guides } = useStudent();

  const [guide, setGuide] = useState(null);

  const getGuide = useCallback(() => {
    let guide = null;
    switch (schoolStatus) {
      case SchoolStatus.READY:
        guide = guides.find((guide) =>
          guide.awardLetterStatuses.includes("READY")
        );

        if (guide) return { ...guide, buttonText: "Read Our Guide on Loans" };
        return null;

      case SchoolStatus.INVALID:
        guide = guides.find(
          (guide) =>
            guide.awardLetterStatuses.includes("INVALID") ||
            guide.awardLetterStatuses.includes("BAD_FILE") ||
            guide.awardLetterStatuses.includes("CANCELED") ||
            guide.awardLetterStatuses.includes("NOT_SUPPORTED") ||
            guide.awardLetterStatuses.includes("SIZE_LIMIT_EXCEEDED")
        );

        if (guide) return { ...guide, buttonText: "Read Our Guide on Loans" };
        return null;

      case SchoolStatus.IN_REVIEW:
        guide = guides.find(
          (guide) =>
            guide.awardLetterStatuses.includes("PENDING") ||
            guide.awardLetterStatuses.includes("MANUAL_REVIEW")
        );

        if (guide) return { ...guide, buttonText: "Read Our Guide on Loans" };
        return null;

      default:
        guide = guides.find((guide) =>
          guide.awardLetterStatuses.includes("NO_LETTER")
        );

        if (guide)
          return { ...guide, buttonText: "How To Find Your Award Letter" };
        return null;
    }
  }, [guides, schoolStatus]);

  useEffect(() => {
    if (guides) setGuide(getGuide());
  }, [guides, getGuide]);

  if (!guide) return null;

  return (
    <CollegeGuide
      guide={guide}
      hasTitle={false}
      hasButton={true}
      hasDescription={false}
      affordability={affordability}
      className="flex flex-1 flex-col"
      childrenClassName="pt-2 mr-5 text-sm"
    />
  );
}

export default AffordabilityGuideSection;
