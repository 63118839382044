import PropTypes from "prop-types";

function PayingForCollege({ size = 25, color = "fill-black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 47 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.315 17.7868V16.718C46.315 16.5844 46.315 16.5844 46.315 16.4508C46.315 7.49936 39.1186 0.151181 30.1896 0.0175781H28.9902V17.9204H45.1156L46.315 17.7868ZM31.389 15.5156V2.28883C38.1857 2.95685 43.6497 8.56818 44.0495 15.5156H31.389Z"
        fill={color}
      />
      <path
        d="M11.2663 27.6735C11.5329 27.6735 12.0659 27.6735 12.0659 28.4751H13.2653H14.4648C14.4648 28.3415 14.4647 27.6735 14.0649 27.0054C13.6651 26.3374 13.1321 25.803 12.4657 25.5358V24.0662H10.2002V25.5358C9.00078 25.9366 8.20117 27.0054 8.20117 28.3415C8.20117 30.3455 9.80038 31.0135 10.8665 31.2807C12.0659 31.6815 12.1992 31.8152 12.1992 32.4832C12.1992 32.7504 12.1992 33.0176 11.3996 33.0176C10.7333 33.0176 10.4667 32.884 10.4667 32.0824H8.20117C8.20117 33.6856 9.00078 34.7544 10.2002 35.1552V36.6249H12.4657V35.2888C13.6651 34.888 14.4648 33.9528 14.4648 32.6168C14.4648 30.2119 12.599 29.6775 11.5329 29.2767C10.4667 28.8759 10.4667 28.7423 10.4667 28.4751C10.3335 28.0743 10.4667 27.6735 11.2663 27.6735Z"
        fill={color}
      />
      <path
        d="M19.3953 36.6247C21.261 37.4263 23.26 37.8272 25.3923 37.8272C34.4545 37.8272 41.9175 30.3454 41.9175 21.2603V20.0579H26.5917V4.82715H25.3923C16.5967 4.82715 9.40022 11.7745 9.00042 20.4587C4.33605 21.5276 0.871094 25.5357 0.871094 30.479C0.871094 36.2239 5.53546 40.7664 11.1327 40.7664C14.5976 40.7664 17.5295 39.1632 19.3953 36.6247ZM33.9214 32.6166L26.9915 22.4628H39.6519C39.1189 26.6045 36.9866 30.3454 33.9214 32.6166ZM24.1929 7.23201V22.4628H24.3262L24.1929 22.5964L31.9224 33.9527C29.9234 35.0215 27.7911 35.5559 25.3923 35.5559C23.6598 35.5559 22.0606 35.2887 20.5947 34.7543C21.1278 33.4182 21.5276 32.0822 21.5276 30.6126C21.5276 25.0012 16.9965 20.3251 11.3992 20.3251C11.799 13.2442 17.3963 7.76642 24.1929 7.23201ZM11.1327 38.4952C6.73487 38.4952 3.13664 34.8879 3.13664 30.479C3.13664 26.0701 6.73487 22.4628 11.1327 22.4628C15.5305 22.4628 19.1287 26.0701 19.1287 30.479C19.1287 34.8879 15.5305 38.4952 11.1327 38.4952Z"
        fill={color}
      />
    </svg>
  );
}

PayingForCollege.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default PayingForCollege;
