import TagManager from "react-gtm-module";

function ExploreCollegesAddToList({ ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "explore_colleges_add_to_list",
      ipeds: ipeds,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default ExploreCollegesAddToList;
