import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import AffordableButtons from "../../Common/AffordableButtons";
import AddYourMoneyButton from "../../Common/AddYourMoneyButton";
import ReuploadAwardButton from "../../Common/ReuploadAwardButton";

import { AffordabilityCard as CommonAffordabilityCard } from "../../../../core/compose/cards";
import { getTheme } from "../../../../core/utils";
import { ReuploadAwardAffordabilityBox } from "../../../../utils/DataLayers";

function AffordabilityCard({
  id,
  chosenSchool,
  executeScroll,
  showUploadTutorial
}) {
  const navigate = useNavigate();

  if (!chosenSchool) return null;

  const { award, school } = chosenSchool || {};

  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability
  });

  const onClickViewAwardLetter = () => {
    navigate(`/college/id/${school?.ipedsId}/view-award-letter`);
  };

  const onClickReuploadAwardLetter = () => {
    ReuploadAwardAffordabilityBox({
      affordability_state: award?.postAwardSchoolCosts?.affordability,
      ipeds: school?.ipedsId
    });
    navigate(
      `/college/add-your-money/${school?.ipedsId}${
        !showUploadTutorial ? "/upload-images" : ""
      }`
    );
  };

  const addYourMoneyPath = `/college/add-your-money/${school?.ipedsId}${
    !showUploadTutorial ? "/upload-images" : ""
  }`;

  const addYourMoneyComponent = <AddYourMoneyButton path={addYourMoneyPath} />;

  const reuploadAwardComponent = (
    <div className="my-2">
      <ReuploadAwardButton path={addYourMoneyPath} />
    </div>
  );

  const affordableButtonsComponent = (
    <AffordableButtons
      show={true}
      color={theme.color}
      className="my-2 w-full"
      onClickViewAwardLetter={onClickViewAwardLetter}
      onClickReuploadAwardLetter={onClickReuploadAwardLetter}
    />
  );

  return (
    <CommonAffordabilityCard
      id={id}
      chosenSchool={chosenSchool}
      executeScroll={executeScroll}
      addYourMoneyComponent={addYourMoneyComponent}
      reuploadAwardComponent={reuploadAwardComponent}
      affordableButtonsComponent={affordableButtonsComponent}
      totalEstimated={chosenSchool?.estimatedPostAwardCosts?.totalAward}
    />
  );
}

AffordabilityCard.propTypes = {
  id: PropTypes.string,
  executeScroll: PropTypes.func,
  chosenSchool: PropTypes.shape(),
  showUploadTutorial: PropTypes.bool
};

export default AffordabilityCard;
