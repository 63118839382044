import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Logo, Close } from "../../core/icons";
import { Button } from "../../core/buttons";

import { useStudent } from "../../redux/Student/hooks";
import { updateStudent } from "../../redux/Student/actions";

function AllSetModal({ onClose }) {
  const { student } = useStudent();
  const dispatch = useDispatch();

  const markOnboardingSignUpDone = () => {
    dispatch(updateStudent({ onboardingSignUp: true }));
    onClose();
  };

  return (
    <div
      id="all-set-modal"
      className="
        pt-2
        flex
        pb-16
        flex-1
        flex-col
        relative
      "
    >
      <div
        onClick={() => markOnboardingSignUpDone()}
        className="z-10 m-4 flex flex-row self-end"
      >
        <Close
          size={25}
          color="stroke-white"
          className="ml-auto my-auto place-self-center cursor-pointer"
        />
      </div>
      <div
        className="
          mx-10
          flex
          flex-1
          flex-col
          justify-center
          mobile:mx-5
          mobile-sm:mx-5
        "
      >
        <div className="z-10 flex flex-row self-center">
          <Logo color="fill-white" size={100} />
        </div>
        <div className="pt-8 z-10 flex flex-col items-center">
          <p
            className="
              mx-6
              text-3xl
              capitalize
              text-center
              text-white
              font-extrabold
              mobile:mx-0
              mobile-sm:mx-0
              tablet-sm:mx-0
            "
          >
            <span>You are all set</span> {student.firstName}!
          </p>
          <div className="w-3/4">
            <p className="font-normal text-white text-center">
              Let's get started!
            </p>
          </div>
          <div className="flex w-full items-center justify-center pt-8">
            <Button
              id="close-all-set-modal-button"
              className="
                py-2
                w-1/2
                text-2xl
                bg-white
                border-white
                text-primary-green
                hover:text-white
                active:text-white
                hover:bg-primary-green
                active:bg-primary-green
                mobile:w-full
                mobile:text-xl
                mobile-sm:w-full
                mobile-sm:text-xl
                tablet-sm:text-xl
              "
              onClick={() => markOnboardingSignUpDone()}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

AllSetModal.propTypes = {
  onClose: PropTypes.func
};

export default AllSetModal;
