import {
  EthnicityTooltipConfirm,
  ZipCodeToolTipConfirm,
} from "../../../../utils/DataLayers";

import { GetIpedsId } from "../../../Common/Hooks";

export const InputTooltipType = {
  ZIP_CODE: "ZIP_CODE",
  ETHNICITY: "ETHNICITY",
};

export const getComposeText = (text) => {
  switch (text) {
    case "text-primary-red":
      return "hover:text-primary-red active:text-primary-red";
    case "text-primary-blue":
      return "hover:text-primary-blue active:text-primary-blue";
    case "text-primary-green":
      return "hover:text-primary-green active:text-primary-green";
    case "text-primary-orange":
      return "hover:text-primary-orange active:text-primary-orange";

    default:
      return "hover:text-light-gray active:text-light-gray";
  }
};

export const getComposeBg = (bg) => {
  switch (bg) {
    case "bg-primary-red":
      return "bg-white text-primary-red hover:text-white active:text-white hover:bg-primary-red active:bg-primary-red";
    case "bg-primary-blue":
      return "bg-white text-primary-blue hover:text-white active:text-white hover:bg-primary-blue active:bg-primary-blue";
    case "bg-primary-green":
      return "bg-white text-primary-green hover:text-white active:text-white hover:bg-primary-green active:bg-primary-green";
    case "bg-primary-orange":
      return "bg-white text-primary-orange hover:text-white active:text-white hover:bg-primary-orange active:bg-primary-orange";

    default:
      return "text-black bg-white hover:bg-light-gray active:bg-light-gray";
  }
};

export function getContent(type) {
  const ipedsId = GetIpedsId();
  switch (type) {
    case InputTooltipType.ETHNICITY:
      return {
        inputSelect: true,
        title: "Want more personalized information?",
        description:
          "List your race/ethnicity for more personal insights into graduation rates and diversity on campus.",
        gaConfirmCall: (ethnicity) =>
          EthnicityTooltipConfirm({
            ethnicity: ethnicity,
            ipeds: ipedsId,
          }),
      };
    case InputTooltipType.ZIP_CODE:
      return {
        inputText: true,
        title: "Your zip code can tell you more.",
        description:
          "Enter your zip code to see if you qualify for in state tuition and your travel distance.",
        gaConfirmCall: () => ZipCodeToolTipConfirm({ ipeds: ipedsId }),
      };

    default:
      return {
        title: "No title",
        description: "No description",
      };
  }
}
