import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import NewLabel from "../../Detail/CollegeSelector/NewLabel";
import StarLabel from "../../Detail/CollegeSelector/StarLabel";
import CollegeItem from "../../Detail/CollegeSelector/CollegeItem";

import { Compare } from "../../../../core/icons";
import { IconButton } from "../../../../core/buttons";

import { CollegeType } from "../../../../core/utils";
import { useIsDesktopMQL } from "../../../../core/hooks";

function DoubleCollegeSelector({ colleges, className }) {
  const navigate = useNavigate();
  const isDesktop = useIsDesktopMQL();

  const showPinned = colleges.length > 0 && isDesktop;

  const [localColleges2, setLocalColleges2] = useState([]);
  const [localColleges4, setLocalColleges4] = useState([]);
  const [colleges2Length, setColleges2Length] = useState(0);
  const [colleges4Length, setColleges4Length] = useState(0);

  useEffect(() => {
    setLocalColleges2(
      colleges.filter(
        (college) => college.programLength === CollegeType.TWO_YEARS
      )
    );
    setLocalColleges4(
      colleges.filter(
        (college) => college.programLength === CollegeType.FOUR_YEARS
      )
    );
  }, [colleges]);

  useEffect(() => {
    const hasNew2 = localColleges2.some((college) => college.isNew === true);
    const hasNew4 = localColleges4.some((college) => college.isNew === true);

    if (colleges2Length !== localColleges2.length && hasNew2) {
      setColleges2Length(localColleges2.length);
      const element = document.getElementById("college-selector-container-2");
      element.scrollTo({
        behavior: "smooth",
        left: element.scrollWidth
      });
    }
    if (colleges4Length !== localColleges4.length && hasNew4) {
      setColleges4Length(localColleges4.length);
      const element = document.getElementById("college-selector-container-1");
      element.scrollTo({
        behavior: "smooth",
        left: element.scrollWidth
      });
    }
  }, [localColleges2, localColleges4, colleges2Length, colleges4Length]);

  const handleOnCollegeSelect = (ipedsId) => navigate(`/college/id/${ipedsId}`);

  return (
    <div
      id="college-selectors-container"
      className={`
        p-4
        flex
        flex-row
        bg-white
        divide-x-2
        divide-black
        ${className}
      `}
    >
      {showPinned && (
        <div
          className="
            pt-1
            mr-4
            flex
            flex-row
            space-x-1
            mobile:mr-2 
            mobile-sm:mr-2
            tablet-sm:mr-2
          "
        >
          {showPinned && (
            <IconButton
              label="compare"
              onClick={() => navigate("/college/compare")}
              className="
                my-1
                text-white
                bg-primary-green
                border-primary-green
                hover:bg-white
                hover:text-primary-green
              "
              icon={
                <Compare
                  size={45}
                  iconColor="fill-black"
                  fillColor="fill-alternative-yellow"
                  strokeColor="stroke-alternative-yellow"
                />
              }
              style={{
                width: isDesktop ? 105 : 70,
                height: isDesktop ? 105 : 70
              }}
            />
          )}
        </div>
      )}
      {localColleges4.length > 0 && (
        <div
          id="college-selector-container-1"
          className={`
            pt-1
            flex
            pr-3
            w-full
            mobile:pr-1 
            flex-no-wrap
            items-center
            overflow-auto
            tablet-sm:pr-1
            whitespace-no-wrap
            ${showPinned ? "pl-4 mobile:pl-1 tablet-sm:pl-1" : "pl-4"}
          `}
        >
          <div
            style={{
              width: isDesktop ? "120px" : "110px",
              marginLeft: isDesktop ? "-68px" : "-63px"
            }}
            className="
              z-10
              px-2
              text-xs
              absolute
              font-bold
              transform
              -rotate-90
              text-center
              bg-white
            "
          >
            4 Year
          </div>
          {localColleges4 &&
            localColleges4.map((college, index) => (
              <div
                className="my-1 relative self-start"
                key={`${college.name}-${college.ipedsId}`}
              >
                <CollegeItem
                  college={college}
                  isDesktop={isDesktop}
                  id={`college-item-${index}-four-years`}
                  handleOnCollegeSelect={() =>
                    handleOnCollegeSelect(college.ipedsId)
                  }
                />
                <NewLabel show={college.isNew} />
                <StarLabel show={college.isFavorite} />
              </div>
            ))}
        </div>
      )}
      {localColleges2.length > 0 && (
        <div
          id="college-selector-container-2"
          className={`
            pt-1
            flex
            w-full
            flex-no-wrap
            items-center
            overflow-auto
            whitespace-no-wrap
            ${showPinned ? "pl-4 mobile:pl-1 tablet-sm:pl-1" : "pl-4"}
          `}
        >
          <div
            style={{
              width: isDesktop ? "120px" : "110px",
              marginLeft: isDesktop ? "-68px" : "-63px"
            }}
            className="
              z-10
              px-2
              text-xs
              absolute
              font-bold
              transform
              -rotate-90
              text-center
              bg-white
            "
          >
            2 Year
          </div>
          {localColleges2 &&
            localColleges2.map((college, index) => (
              <div
                className="my-1 relative self-start"
                key={`${college.name}-${college.ipedsId}`}
              >
                <CollegeItem
                  college={college}
                  isDesktop={isDesktop}
                  id={`college-item-${index}-two-years`}
                  handleOnCollegeSelect={() =>
                    handleOnCollegeSelect(college.ipedsId)
                  }
                />
                <NewLabel show={college.isNew} />
                <StarLabel show={college.isFavorite} />
              </div>
            ))}
        </div>
      )}
      <Tooltip
        place="bottom"
        effect="solid"
        delayShow={500}
        id="college-thumbnail-selector"
      />
    </div>
  );
}

DoubleCollegeSelector.propTypes = {
  colleges: PropTypes.array,
  className: PropTypes.string
};

export default DoubleCollegeSelector;
