import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Button } from "../../../core/buttons";
import { CollegeType } from "../../../core/utils";

import { useStudent } from "../../../redux/Student/hooks";
import { updateChosenSchoolYearType } from "../../../redux/Student/actions";
import { FourYearSelect, TwoYearSelect } from "../../../utils/DataLayers";
import { GetIpedsId } from "../../Common/Hooks";

function CollegeYearButtons({ redirect }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { student, isLoading } = useStudent();
  const ipedsId = GetIpedsId();

  const [show, setShow] = useState(false);
  const [firstSchools, setFirstSchools] = useState([]);

  useEffect(() => {
    let firstTwoYearSchool = null;
    let firstFourYearSchool = null;
    for (const chosenSchool of student.chosenSchools) {
      if (
        firstTwoYearSchool === null &&
        chosenSchool.school.programLength === CollegeType.TWO_YEARS
      ) {
        firstTwoYearSchool = chosenSchool;
      } else if (
        firstFourYearSchool === null &&
        chosenSchool.school.programLength === CollegeType.FOUR_YEARS
      ) {
        firstFourYearSchool = chosenSchool;
      }
      if (firstTwoYearSchool != null && firstFourYearSchool != null) {
        break;
      }
    }

    setShow(firstTwoYearSchool != null && firstFourYearSchool != null);

    if (show) {
      setFirstSchools([firstTwoYearSchool, firstFourYearSchool]);
    }
  }, [student.chosenSchools, show]);

  const activeStyle = "bg-primary-green text-white border-primary-green";
  const defaultStyle =
    "bg-white text-primary-green border-primary-green hover:bg-primary-green hover:text-white hover:border-primary-green";

  const handlerOnChangeCollegesType = (newCollegesType) => {
    newCollegesType === CollegeType.TWO_YEARS
      ? TwoYearSelect({ ipeds: ipedsId })
      : FourYearSelect({ ipeds: ipedsId });
    if (newCollegesType !== student.chosenSchoolsYearType) {
      dispatch(
        updateChosenSchoolYearType({ chosenSchoolsYearType: newCollegesType }),
      );

      if (redirect) {
        newCollegesType === CollegeType.TWO_YEARS
          ? navigate(`/college/id/${firstSchools[0].school.ipedsId}`)
          : navigate(`/college/id/${firstSchools[1].school.ipedsId}`);
      }
    }
  };

  if (show)
    return (
      <div
        id="toggle-year-buttons-container"
        className="
          flex
          flex-row
          mobile:my-2
          tablet:my-2
          mobile-sm:my-2
          tablet-sm:my-2
          mobile:text-sm
          mobile-sm:text-sm
        "
      >
        <Button
          disabled={isLoading}
          id="toggle-four-year-button"
          onClick={() => handlerOnChangeCollegesType(CollegeType.FOUR_YEARS)}
          className={`
            px-5
            ${
              student.chosenSchoolsYearType === CollegeType.FOUR_YEARS
                ? activeStyle
                : defaultStyle
            }
          `}
        >
          <span className="place-self-center font-semibold">four year</span>
        </Button>
        <Button
          disabled={isLoading}
          id="toggle-two-year-button"
          onClick={() => handlerOnChangeCollegesType(CollegeType.TWO_YEARS)}
          className={`
            px-5
            ${
              student.chosenSchoolsYearType === CollegeType.TWO_YEARS
                ? activeStyle
                : defaultStyle
            }
          `}
        >
          <span className="place-self-center font-semibold">two year</span>
        </Button>
      </div>
    );

  return null;
}

CollegeYearButtons.propTypes = {
  redirect: PropTypes.bool,
};

export default CollegeYearButtons;
