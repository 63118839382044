import PropTypes from "prop-types";

function LayoutCollege({ children, isCentered = true }) {
  return (
    <div
      className="
        h-full
        laptop:flex
        desktop:flex
        laptop:flex-1
        desktop:flex-1
        laptop:bg-light-gray
        laptop:justify-center
        desktop:bg-light-gray
        desktop:justify-center
      "
    >
      {isCentered ? (
        <div
          className="
          w-full
          justify-center
          laptop:max-w-60rem
          desktop:max-w-75rem
        "
        >
          <div
            className="
              pt-4
              w-full
              laptop:px-4
              desktop:px-4
            "
          >
            {children}
          </div>
        </div>
      ) : (
        <div className="w-full h-full justify-center">{children}</div>
      )}
    </div>
  );
}

LayoutCollege.propTypes = {
  children: PropTypes.any,
  isCentered: PropTypes.bool
};

export default LayoutCollege;
