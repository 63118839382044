import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Confirm from "./Common/Confirm";

import { InfoRow } from "../../core/labels";
import { ItemsList } from "../../core/compose";
import { SimpleText } from "../../core/inputs";
import { LoadingButton } from "../../core/buttons";
import { ListTypes } from "../../core/compose/ItemsList/utils";
import { Colors } from "../../core/utils";

import { useStudent } from "../../redux/Student/hooks";
import { updateStudentLocal } from "../../redux/Student/actions";

import Api from "../../services/Api";

function containsOrg(org, orgs) {
  for (var x in orgs) {
    if (orgs[x].id === org.id) return true;
  }
  return false;
}

function getData(data, selected) {
  if (!data) return null;

  return data
    .filter((item) => !selected.includes(item.organizationId))
    .map((item) => {
      return {
        id: item.organizationId,
        name: item.name
      };
    });
}

function JoinOrg({
  onClick,
  saveData,
  leaveOrg,
  requestedClose,
  setConfirmModal,
  showLeaveHandle,
  requestCloseHandle
}) {
  const [error, setError] = useState();
  const [addOrgs, setAddOrgs] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [codeSearch, setCodeSearch] = useState("");
  const [removeOrgs, setRemoveOrgs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [orgsSelected, setOrgsSelected] = useState([]);
  const [showInOrgMessage, setShowInOrgMessage] = useState(false);

  const searchCode = async () => {
    if (codeSearch === "") return;

    setError();
    setIsLoading2(true);
    setShowInOrgMessage(false);
    try {
      const org = await Api.fetchStudentOrganization({
        code: codeSearch.toUpperCase()
      });
      handleOnOrgSelect({
        id: org.organizationId,
        name: org.name,
        joinGroupCode: codeSearch.toUpperCase()
      });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading2(false);
      setCodeSearch("");
    }
  };

  const handleOnClick = (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      onClick({ addOrgs, removeOrgs });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnOrgSelect = (org) => {
    setUpdated(true);
    setConfirmModal(false);
    const inOrg = containsOrg(org, orgsSelected);

    if (!inOrg) {
      setOrgsSelected((state) => state.concat([org]));
      setAddOrgs((state) => state.concat([org.id]));
    } else if (inOrg) {
      setShowInOrgMessage(true);
      setAddOrgs((state) => state.concat([org.id]));
    }
    setRemoveOrgs((state) => state.filter((item) => item !== org.id));
  };

  const handleOnOrgRemove = (org) => {
    setUpdated(true);
    setConfirmModal(false);
    if (org.id) setAddOrgs((state) => state.filter((item) => org.id !== item));
    setOrgsSelected((state) => state.filter((item) => item.id !== org.id));
    setRemoveOrgs((state) =>
      org.id
        ? !state.includes(org.id)
          ? state.concat([org.id])
          : state
        : state
    );
  };

  const showLeaveConfirmation = (org) => {
    const data = {
      addOrgs,
      updated,
      removeOrgs,
      orgsSelected
    };
    requestCloseHandle(data, false);
    showLeaveHandle(org);
  };

  useEffect(() => {
    if (requestedClose) {
      const data = {
        addOrgs,
        updated,
        removeOrgs,
        orgsSelected
      };
      requestCloseHandle(data);
    }
  }, [
    addOrgs,
    updated,
    removeOrgs,
    orgsSelected,
    requestedClose,
    requestCloseHandle
  ]);

  useEffect(() => {
    setConfirmModal(true);

    setUpdated(saveData?.updated);
    setAddOrgs(saveData?.addOrgs || []);
    setRemoveOrgs(saveData?.removeOrgs || []);
    setOrgsSelected(saveData?.orgsSelected || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  useEffect(() => {
    if (leaveOrg) handleOnOrgRemove(leaveOrg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveOrg]);

  return (
    <>
      <div className="overflow-auto px-1">
        <InfoRow
          label="Join an organization"
          text="Or if you want to add a new organization, add a code here:"
          className="normal-case"
        />
        <div className="flex flex-row flex-1 space-x-4 my-3">
          <div className="w-2/3">
            <SimpleText
              value={codeSearch}
              color={Colors.GREEN}
              id="organizations-code-input"
              placeholder="Add an Organization code"
              onChange={(event) => setCodeSearch(event.target.value)}
            />
          </div>
          <div className="py-2 w-1/3">
            <LoadingButton
              onClick={searchCode}
              isLoading={isLoading2}
              id="organizations-join-button"
              className="
                w-full
                h-full
                text-white
                bg-primary-green
                border-primary-green
                hover:bg-white
                active:bg-white
                hover:text-primary-green
                active:text-primary-green
              "
            >
              Join
            </LoadingButton>
          </div>
        </div>

        {error && <p className="pb-2 text-primary-red">{error.message}</p>}

        <InfoRow label="Organizations you are in" className="normal-case" />
        <ItemsList
          className="
            flex
            flex-col
            border
            min-h-6rem
            max-h-8rem
            mobile:max-h-full
            divide-grey
            border-black
            divide-grey
            overflow-hidden
          "
          isLoading={false}
          data={orgsSelected}
          onClick={showLeaveConfirmation}
          removeId="organizations-leave-button"
          type={ListTypes.STUDENT_ORGANIZATIONS_LIST_MODAL}
        />

        {showInOrgMessage && (
          <p className="pb-2 text-primary-green">
            That code belongs to an Organization you already selected
          </p>
        )}
      </div>
      <div className="mt-auto py-5 flex flex-row space-x-2">
        <div className="pr-5 mb-5 w-1/2 text-xs">
          <p>
            By joining an organization you are agreeing to share your DecidED
            profile information with that organization.
          </p>
        </div>
        <LoadingButton
          disabled={!updated}
          isLoading={isLoading}
          onClick={handleOnClick}
          id="organizations-save-button"
          className="
            mb-5
            w-1/2
            text-white
            bg-primary-green
            border-primary-green
            hover:bg-white
            active:bg-white
            hover:text-primary-green
            active:text-primary-green
          "
        >
          Save Edits
        </LoadingButton>
      </div>
    </>
  );
}

JoinOrg.propTypes = {
  onClick: PropTypes.func.isRequired,
  requestCloseHandle: PropTypes.func.isRequired,
  requestedClose: PropTypes.bool.isRequired,
  showLeaveHandle: PropTypes.func.isRequired,
  saveData: PropTypes.shape(),
  leaveOrg: PropTypes.shape()
};

function LeaveOrg({
  saveData,
  leaveOrg,
  requestedClose,
  cancelLeaveHandle,
  requestCloseHandle,
  confirmLeaveHandle
}) {
  const firstText = (
    <span>
      Are you sure you want to leave{" "}
      <span className="text-black">{leaveOrg?.name}</span>?
    </span>
  );

  useEffect(() => {
    if (requestedClose) requestCloseHandle(saveData);
  }, [requestCloseHandle, requestedClose, saveData]);

  return (
    <Confirm
      secondButton={true}
      firstText={firstText}
      onClick={confirmLeaveHandle}
      buttonText="Leave Organization"
      secondOnClick={cancelLeaveHandle}
    />
  );
}

LeaveOrg.propTypes = {
  leaveOrg: PropTypes.shape(),
  saveData: PropTypes.shape(),
  requestedClose: PropTypes.bool.isRequired,
  cancelLeaveHandle: PropTypes.func.isRequired,
  requestCloseHandle: PropTypes.func.isRequired,
  confirmLeaveHandle: PropTypes.func.isRequired
};

function ConfirmContent({ addedOrgs, alreadyBelong }) {
  function List({ data, itemColor }) {
    return (
      <ul>
        {data.map((item) => (
          <li
            key={item}
            className={`list-disc list-inside font-base text-base ${itemColor}`}
          >
            <span>{item}</span>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="w-full">
      <div className="m-4">
        {addedOrgs.length > 0 && (
          <>
            <p className="text-bold-blue font-semibold text-base mb-4">
              You linked to{" "}
              {addedOrgs.length === 1
                ? "this organization"
                : "these organizations"}
              :
            </p>
            <List data={addedOrgs} itemColor="text-bold-blue" />
          </>
        )}
        {alreadyBelong.length > 0 && (
          <div className="mt-4">
            <p className="font-semibold text-base mb-4">
              You already belong to{" "}
              {alreadyBelong.length === 1
                ? "this organization"
                : "these organizations"}
              :
            </p>
            <List data={alreadyBelong} itemColor="text-bold-grey" />
          </div>
        )}
      </div>
    </div>
  );
}

ConfirmContent.propTypes = {
  addedOrgs: PropTypes.arrayOf(PropTypes.string).isRequired,
  alreadyBelong: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default function JoinOrgModal({
  onClose,
  requestedClose,
  setConfirmModal,
  setRequestedClose
}) {
  const [save, setSave] = useState(false);
  const [leave, setLeave] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [leaveOrg, setLeaveOrg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [secondText, setSecondText] = useState("");

  const dispatch = useDispatch();
  const { student } = useStudent();

  const firstTextConfirmation = `You have updated your organizations info.`;

  const editOrgsOnClick = async ({ addOrgs, removeOrgs }) => {
    try {
      const data = await Api.editStudentOrganizations({
        organizationIdsAdd: addOrgs,
        organizationIdsRemove: removeOrgs
      });
      dispatch(
        updateStudentLocal({
          ...data.student,
          chosenSchoolsYearType: student.chosenSchoolsYearType
        })
      );
      const { addedOrgs, alreadyInOrgs } = data.editStudentOrgs;
      setSecondText(
        <ConfirmContent
          addedOrgs={addedOrgs.map((org) => org.name)}
          alreadyBelong={alreadyInOrgs.map((org) => org.name)}
        />
      );
      setConfirm(true);
      setConfirmModal(true);
    } catch (error) {
      throw error;
    }
  };

  const requestCloseHandle = (saveData, showSave = true) => {
    setSaveData(saveData);
    if (showSave) setSave(true);
  };

  const backCloseHandle = () => {
    setSave(false);
    setRequestedClose(false);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    await editOrgsOnClick({
      addOrgs: saveData.addOrgs,
      removeOrgs: saveData.removeOrgs
    });
    setIsLoading(false);
    setSave(false);
    setRequestedClose(false);
  };

  const showLeaveHandle = (org) => {
    setLeave(() => {
      setLeaveOrg(org);
      return true;
    });
  };

  const confirmLeaveHandle = () => setLeave(false);

  const cancelLeaveHandle = () => {
    setLeave(() => {
      setLeaveOrg(null);
      return false;
    });
  };

  useEffect(() => {
    if (!saveData.orgsSelected)
      setSaveData({
        orgsSelected: getData(student?.organizations || [], [])
      });
  }, [student.organizations, saveData.orgsSelected]);

  return (
    <div className="flex flex-col h-full px-6 pb-6">
      {!confirm && !save && !leave && (
        <JoinOrg
          saveData={saveData}
          leaveOrg={leaveOrg}
          onClick={editOrgsOnClick}
          requestedClose={requestedClose}
          setConfirmModal={setConfirmModal}
          showLeaveHandle={showLeaveHandle}
          requestCloseHandle={requestCloseHandle}
        />
      )}
      {confirm && !save && !leave && (
        <Confirm
          onClick={onClose}
          buttonText="Close"
          secondText={secondText}
          firstText={firstTextConfirmation}
        />
      )}
      {leave && !save && (
        <LeaveOrg
          leaveOrg={leaveOrg}
          saveData={saveData}
          requestedClose={requestedClose}
          cancelLeaveHandle={cancelLeaveHandle}
          requestCloseHandle={requestCloseHandle}
          confirmLeaveHandle={confirmLeaveHandle}
        />
      )}
      {save && (
        <Confirm
          secondButton={true}
          isLoading={isLoading}
          onClick={saveChanges}
          buttonText="Save Changes"
          secondOnClick={backCloseHandle}
          firstText="Do you want to save your changes?"
        />
      )}
    </div>
  );
}

JoinOrgModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  setRequestedClose: PropTypes.func.isRequired,
  requestedClose: PropTypes.bool.isRequired
};
