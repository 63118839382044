import { useEffect } from "react";
import PropTypes from "prop-types";

import DefaultScreen from "../../Common/DefaultScreen";

function Success({ timeout, onTimeout }) {
  useEffect(() => {
    const id = setTimeout(onTimeout, timeout);
    return () => {
      if (typeof id === "number") clearTimeout(id);
    };
  }, [timeout, onTimeout]);

  return (
    <DefaultScreen title="Upload Complete">
      <p className="text-xl font-bold">Let's check out your results.</p>
    </DefaultScreen>
  );
}

Success.propTypes = {
  onTimeout: PropTypes.func,
  timeout: PropTypes.number,
};

export default Success;
