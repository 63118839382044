import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import CollegeDetailMobile from "./DetailMobile";
import CollegeDetailDesktop from "./DetailDesktop";
import { useIsDesktopMQL } from "../../../core/hooks";
import Api from "../../../services/Api";
import { ModalTypes } from "../../Common/utils";
import { updateChosenSchool } from "../../../redux/Student/actions";
import {
  useGetChosenSchool,
  useGetShowUploadTutorial
} from "../../../redux/Student/hooks";

function CollegeDetail({ student, openModal, modalResponse }) {
  const dispatch = useDispatch();
  const { ipedsId } = useParams();
  const isDesktop = useIsDesktopMQL();
  const showUploadTutorial = useGetShowUploadTutorial();
  const selectedSchool = useGetChosenSchool({ ipedsId });
  const [showZipTooltip, setShowZipTooltip] = useState(false);
  const [showEthnicityTooltip, setShowEthnicityTooltip] = useState(false);

  useEffect(() => {
    if (!student.onboardingChosenSchool) {
      openModal({ type: ModalTypes.FIRST_SCHOOL });
    } else if (
      student.onboardingChosenSchool &&
      selectedSchool?.award !== null &&
      !student.onboardingLetter
    ) {
      openModal({ type: ModalTypes.FIRST_LETTER });
    }
  }, [
    openModal,
    student.onboardingLetter,
    student.onboardingChosenSchool,
    selectedSchool?.award
  ]);

  useEffect(() => {
    if (student.onboardingChosenSchool) {
      setShowZipTooltip(!student.zip);
      setShowEthnicityTooltip(!student.ethnicity);
    }
  }, [student.onboardingChosenSchool, student.zip, student.ethnicity]);

  useEffect(() => {
    if (modalResponse === ModalTypes.FIRST_SCHOOL) {
      setShowZipTooltip(!student.zip);
      setShowEthnicityTooltip(!student.ethnicity);
    }
  }, [modalResponse, student.zip, student.ethnicity]);

  useEffect(() => {
    const updateNewCollege = async () => {
      const { pairedId, sortOrder, livingChoice } = selectedSchool || {};
      const chosenSchool = await Api.updateChosenSchool({
        pairedId,
        sortOrder,
        livingChoice,
        isNew: false
      });
      dispatch(updateChosenSchool(chosenSchool));
    };

    if (selectedSchool?.isNew) {
      updateNewCollege();
    }
  }, [dispatch, selectedSchool]);

  if (!selectedSchool) return null;

  const commonProps = {
    student,
    openModal,
    selectedSchool,
    showZipTooltip,
    showUploadTutorial,
    showEthnicityTooltip,
    handlerShowZipTooltip: setShowZipTooltip,
    handlerShowEthnicityTooltip: setShowEthnicityTooltip
  };

  return isDesktop ? (
    <CollegeDetailDesktop isDesktop {...commonProps} />
  ) : (
    <CollegeDetailMobile {...commonProps} />
  );
}

CollegeDetail.propTypes = {
  modalResponse: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  student: PropTypes.shape().isRequired
};

export default CollegeDetail;
