import { useEffect, useRef, useState } from "react";
import { trim } from "lodash";
import PropTypes from "prop-types";

import FilterResults from "../../FilterResults";
import SortByButton, { SortFields, SortOptions } from "./SortByButton";
import CaLogo from "../../../../../assets/images/CA_logo.png";

import { ItemsCounter } from "../../../../../core";
import { Filter } from "../../../../../core/icons";
import { Button } from "../../../../../core/buttons";
import { CategoryPill } from "../../../../../core/compose";
import { SearchTextSimple } from "../../../../../core/inputs";
import { Colors } from "../../../../../core/utils";

function SearchResultsHeader({
  sortBy,
  filters,
  collegesCount,
  religiousTypes,
  handlerOnSubmitSortBy,
  handlerOnClearFilters,
  handlerOnSubmitFilters
}) {
  const divRef = useRef(null);

  const [activeDropdown, setActiveDropdown] = useState("");
  const [showFilterResults, setShowFilterResults] = useState(false);
  const [categoriesSelected, setCategoriesSelected] = useState([]);

  useEffect(() => {
    setCategoriesSelected(
      Object.keys(filters)
        .filter((key) => key !== "name" || !filters[key])
        .map((key) => {
          if (key === "hasCommonApp") {
            return {
              name: key,
              iconSrc: CaLogo,
              filters: filters[key]
            };
          }
          return { name: key, filters: filters[key] };
        })
    );
  }, [filters]);

  const toggleFilterResults = () => setShowFilterResults(!showFilterResults);

  const handlerOnActiveDropdown = (activeDropdown) =>
    setActiveDropdown(activeDropdown);

  const handlerOnOpenDropdown = (activeDropdown) => {
    toggleFilterResults();
    handlerOnActiveDropdown(activeDropdown);
  };

  const getSortLabel = () => {
    let label = "Filtered results are sorted from ";

    switch (sortBy.attr) {
      case SortFields.NAME:
        return (label += sortBy.order === SortOptions.ASC ? "A–Z" : "Z–A");
      case SortFields.PROGRAM_LENGTH:
        return (label +=
          sortBy.order === SortOptions.ASC
            ? "Four Year – Two Year"
            : "Two Year – Four Year");
      default:
        return label;
    }
  };

  return (
    <div className="mt-4 bg-white">
      <div className="m-4 flex flex-1 flex-col">
        <div className="flex flex-col">
          <div
            id="explore-colleges"
            className="flex flex-1 flex-row justify-between"
          >
            <p className="text-2xl font-bold place-self-end">
              Explore Colleges
            </p>
            <div className="flex flex-col">
              <div
                role="button"
                id="clear-all-button"
                onClick={() => handlerOnClearFilters()}
                className="
                  text-sm
                  font-bold
                  min-w-6rem
                  text-right
                  cursor-pointer
                  text-primary-green
                "
              >
                Clear All
              </div>
              <div className="hidden mobile:flex mobile-sm:flex tablet-sm:flex">
                <ItemsCounter
                  color="text-black"
                  numberItems={collegesCount}
                  pluralDescriptionItems="colleges found"
                  singularDescriptionItems="college found"
                />
              </div>
            </div>
          </div>
          <div
            className="
              flex
              flex-1
              flex-row
              justify-between
              mobile:flex-col
              mobile-sm:flex-col
              tablet-sm:flex-col
            "
          >
            <div
              className="
                w-3/5
                mobile:w-full
                mobile:text-sm
                mobile-sm:w-full
                mobile-sm:text-sm
                tablet-sm:w-full
                tablet-sm:text-sm
              "
            >
              <p>{getSortLabel()}</p>
            </div>
            <div
              className="
                w-2/5
                mobile:w-full
                mobile-sm:w-full
                tablet-sm:w-full
              "
            >
              <div
                className="
                  flex
                  flex-row
                  justify-between
                  mobile:hidden
                  mobile-sm:hidden
                  tablet-sm:hidden
                "
              >
                <ItemsCounter
                  color="text-black"
                  numberItems={collegesCount}
                  pluralDescriptionItems="colleges found"
                  singularDescriptionItems="college found"
                />
                <SortByButton
                  sortBy={sortBy}
                  handlerOnSubmitSortBy={handlerOnSubmitSortBy}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="
            mt-4
            flex
            flex-1
            flex-row
            mobile:flex-col
            mobile-sm:flex-col
            tablet-sm:flex-col
          "
        >
          <div
            className="
              w-3/5
              flex
              flex-col
              mobile:w-full
              mobile:flex-row
              mobile-sm:w-full
              mobile-sm:flex-row
              tablet-sm:w-full
              tablet-sm:flex-row
            "
          >
            <div
              className="
                mt-2
                flex
                flex-1
                flex-row
                mobile:mb-1
                mobile:flex-col
                mobile-sm:mb-1
                mobile-sm:flex-col
                tablet-sm:mb-1
                tablet-sm:flex-col
              "
            >
              <div
                ref={divRef}
                className="
                  mr-5
                  flex
                  flex-row
                  relative
                  items-center
                  mobile:mr-0
                  mobile-sm:mr-0
                  tablet-sm:mr-0
                "
              >
                <Button
                  id="filter-results-button"
                  onClick={toggleFilterResults}
                  className="
                    py-0
                    flex
                    flex-row
                    space-x-2
                    bg-white
                    items-center
                    border-white
                    focus:outline-none
                  "
                >
                  <Filter size={25} color="fill-primary-green" />
                  <span className="text-sm font-bold capitalize">
                    filter results
                  </span>
                </Button>
                <FilterResults
                  divRef={divRef}
                  filters={filters}
                  show={showFilterResults}
                  toggle={toggleFilterResults}
                  activeDropdown={activeDropdown}
                  religiousTypes={religiousTypes}
                  onSubmit={handlerOnSubmitFilters}
                  handlerOnActiveDropdown={handlerOnActiveDropdown}
                />
              </div>
              <div
                className="
                  flex
                  flex-row
                  flex-wrap
                  items-center
                  mobile:mt-2
                  mobile-sm:mt-2
                  tablet-sm:mt-2
                "
              >
                {categoriesSelected.map((category) => (
                  <CategoryPill
                    key={category.name}
                    iconSrc={category?.iconSrc}
                    name={category.name}
                    quantity={category.filters?.selected}
                    handlerOnClearFilters={handlerOnClearFilters}
                    handlerOnOpenDropdown={handlerOnOpenDropdown}
                  />
                ))}
              </div>
            </div>
          </div>
          <div
            className="
              w-2/5
              flex
              flex-col
              mobile:w-full
              mobile:flex-row
              mobile:space-x-2
              mobile-sm:w-full
              mobile-sm:flex-row
              mobile-sm:space-x-2
              tablet-sm:w-full
              tablet-sm:flex-row
              tablet-sm:space-x-2
            "
          >
            <SearchTextSimple
              color={Colors.GREEN}
              defaultValue={filters?.name?.search}
              handlerOnSubmit={(value) => {
                if (trim(value).length > 0) {
                  handlerOnSubmitFilters({
                    type: "name",
                    filters: { search: value }
                  });
                } else {
                  handlerOnSubmitFilters({ type: "name", filters: {} });
                }
              }}
            />
            <div
              className="
                hidden
                place-self-center
                mobile:flex
                mobile-sm:flex
                tablet-sm:flex
              "
            >
              <SortByButton
                sortBy={sortBy}
                handlerOnSubmitSortBy={handlerOnSubmitSortBy}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SearchResultsHeader.propTypes = {
  sortBy: PropTypes.shape(),
  filters: PropTypes.shape(),
  collegesCount: PropTypes.number,
  handlerOnClearFilters: PropTypes.func,
  handlerFiltersOnSubmit: PropTypes.func,
  religiousTypes: PropTypes.arrayOf(PropTypes.shape())
};

export default SearchResultsHeader;
