import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import FirstYearBreakdown from "../Detail/FirstYearBreakdown";

import {
  useGetChosenSchool,
  useGetShowUploadTutorial,
} from "../../../redux/Student/hooks";

function FirstYearBreakdownScreen({ student }) {
  const { ipedsId } = useParams();

  const showUploadTutorial = useGetShowUploadTutorial();
  const selectedSchool = useGetChosenSchool({ ipedsId });

  return (
    <FirstYearBreakdown
      showArrow={true}
      isDesktop={false}
      chosenSchool={selectedSchool}
      studentState={student?.state}
      showUploadTutorial={showUploadTutorial}
      tooltipCostClassName="-left-20 bottom-9 mobile-sm:-left-32"
      tooltipBudgetClassName="-left-10 bottom-20 mobile-sm:-left-28"
      tooltipGrantsClassName="-left-28 bottom-20 mobile-sm:-left-44"
    />
  );
}

FirstYearBreakdownScreen.propTypes = {
  student: PropTypes.shape(),
};

export default FirstYearBreakdownScreen;
