import TagManager from "react-gtm-module";

function CollegeSearchMagnifyingGlass({ ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "college_search_magnifying_glass",
      ipeds: ipeds,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default CollegeSearchMagnifyingGlass;
