import PropTypes from "prop-types";

import { Button } from "../../../core/buttons";
import { getTheme, getColors } from "../../../core/utils";

function DeleteButton({ onClick, chosenSchool }) {
  const { award } = chosenSchool;

  const getComposeMainBg = (bg) => {
    switch (bg) {
      case "bg-primary-red":
        return "text-white bg-primary-red hover:bg-white active:bg-white hover:text-primary-red active:text-primary-red";
      case "bg-primary-blue":
        return "text-white bg-primary-blue hover:bg-white active:bg-white hover:text-primary-blue active:text-primary-blue";
      case "bg-primary-green":
        return "text-white bg-primary-green hover:bg-white active:bg-white hover:text-primary-green active:text-primary-green";
      case "bg-primary-orange":
        return "text-white bg-primary-orange hover:bg-white active:bg-white hover:text-primary-orange active:text-primary-orange";

      default:
        return "text-black bg-white hover:bg-light-gray active:bg-light-gray";
    }
  };

  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability,
  });

  const colors = getColors(theme.color);

  return (
    <div className="mx-4 my-4 flex">
      <Button
        onClick={onClick}
        id="delete-school-button"
        className={`
          py-3
          flex
          w-full
          text-lg
          items-center
          justify-center
          ${colors.primary.border}
          ${getComposeMainBg(colors.primary.bg)}
        `}
      >
        delete school
      </Button>
    </div>
  );
}

DeleteButton.propTypes = {
  onClick: PropTypes.func,
  chosenSchool: PropTypes.shape(),
};

export default DeleteButton;
