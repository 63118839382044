import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Close } from "../../../../../core/icons";

function Item({
  name,
  edit,
  value,
  isNew,
  added,
  handlerOnEdit,
  handlerOnRemove
}) {
  const [localValue, setLocalValue] = useState(value.toLocaleString("en-US"));
  const [invalidValue, setInvalidValue] = useState(false);
  const inputRef = useRef(null);

  const handlerOnChange = (event) => {
    const input = event.target;
    const cursorPosition = input.selectionStart;
    const originalValue = input.value;
    const formattedValue = originalValue.replace(/,/g, "");

    if (
      formattedValue === "" ||
      (Number(formattedValue) >= 0 && !isNaN(Number(formattedValue)))
    ) {
      setInvalidValue(false);
      const newValue = Number(formattedValue).toLocaleString("en-US");
      setLocalValue(newValue);
      handlerOnEdit(Number(formattedValue));

      setTimeout(() => {
        const newCursorPosition =
          cursorPosition + (newValue.length - originalValue.length);
        input.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    } else {
      setInvalidValue(true);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="p-2 flex flex-1 flex-row items-center">
        {isNew && added && (
          <span className="mr-2 text-sm font-bold text-primary-green">
            New!
          </span>
        )}
        <p className="font-semibold w-full">{name}</p>
        {added || edit ? (
          <>
            $
            <div className="p-1 ml-2 border border-primary-green">
              <input
                type="text"
                autoCapitalize="none"
                id="box-edit-aid-input"
                placeholder="0"
                value={localValue}
                onChange={handlerOnChange}
                ref={inputRef}
                className={`w-full text-sm text-right font-medium text-black outline-none`}
              />
            </div>
            <div role="button" className="ml-2" onClick={handlerOnRemove}>
              <Close size={15} />
            </div>
          </>
        ) : (
          <p className="font-semibold justify-end">
            ${value.toLocaleString("en-US")}
          </p>
        )}
      </div>
      {invalidValue && (
        <p className="text-sm text-right font-semibold text-primary-red">
          You can add only positive numbers!
        </p>
      )}
    </div>
  );
}

Item.propTypes = {
  edit: PropTypes.bool,
  added: PropTypes.bool,
  isNew: PropTypes.bool,
  handlerOnEdit: PropTypes.func,
  handlerOnRemove: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
};

export default Item;
