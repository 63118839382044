import PropTypes from "prop-types";

function Download({ size = 25, color = "fill-black" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <path className={color} d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
    </svg>
  );
}

Download.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Download;
