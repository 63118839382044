import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { SmallModal } from "../../../core";
import { Button } from "../../../core/buttons";
import { CollegeType, getTheme, getColors } from "../../../core/utils";

import { GetIpedsId, useSpinner } from "../../Common/Hooks";
import { useStudent } from "../../../redux/Student/hooks";
import {
  deleteChosenSchool,
  updateChosenSchoolYearType
} from "../../../redux/Student/actions";
import { DeleteSchoolConfirmCP } from "../../../utils/DataLayers";

function DeleteSchoolModal({
  open,
  redirect = true,
  chosenSchool,
  deleteSchoolCancelHandler,
  deleteSchoolConfirmHandler
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ipedsId = GetIpedsId();

  const { student } = useStudent();

  const { award } = chosenSchool;

  const getComposeBg = (bg) => {
    switch (bg) {
      case "bg-primary-red":
        return {
          primary:
            "text-white bg-primary-red border-primary-red hover:bg-white active:bg-white hover:text-primary-red active:text-primary-red",
          secondary:
            "bg-white text-primary-red border-primary-red hover:text-white active:text-white hover:bg-primary-red active:bg-primary-red"
        };
      case "bg-primary-blue":
        return {
          primary:
            "text-white bg-primary-blue border-primary-blue hover:bg-white active:bg-white hover:text-primary-blue active:text-primary-blue",
          secondary:
            "bg-white text-primary-blue border-primary-blue hover:text-white active:text-white hover:bg-primary-blue active:bg-primary-blue"
        };
      case "bg-primary-green":
        return {
          primary:
            "text-white bg-primary-green border-primary-green hover:bg-white active:bg-white hover:text-primary-green active:text-primary-green",
          secondary:
            "bg-white text-primary-green border-primary-green hover:text-white active:text-white hover:bg-primary-green active:bg-primary-green"
        };
      case "bg-primary-orange":
        return {
          primary:
            "text-white bg-primary-orange border-primary-orange hover:bg-white active:bg-white hover:text-primary-orange active:text-primary-orange",
          secondary:
            "bg-white text-primary-orange border-primary-orange hover:text-white active:text-white hover:bg-primary-orange active:bg-primary-orange"
        };

      default:
        return {
          primary:
            "bg-black text-white border-black hover:bg-white active:bg-white hover:text-black active:text-black",
          secondary:
            "bg-white text-black border-black hover:text-white active:text-white hover:bg-black active:bg-black"
        };
    }
  };

  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability
  });

  const colors = getColors(theme.color);
  const composeBg = getComposeBg(colors.primary.bg);

  const updateProgramLength = () => {
    const programLength = chosenSchool?.school?.programLength;
    const filteredSchools =
      student?.chosenSchools
        .filter((school) => school.pairedId !== chosenSchool.pairedId)
        .filter((school) => school.school.programLength === programLength) ||
      [];

    if (filteredSchools.length === 0 && student?.chosenSchools.length > 0) {
      const firstChosenSchool =
        student?.chosenSchools.filter(
          (school) => school.school.programLength !== programLength
        )[0] || {};
      const chosenSchoolsYearType =
        firstChosenSchool?.school?.programLength || CollegeType.FOUR_YEARS;
      dispatch(updateChosenSchoolYearType({ chosenSchoolsYearType }));
    }
  };

  const deleteHandler = async () => {
    DeleteSchoolConfirmCP({ ipeds: ipedsId });
    updateProgramLength();
    await deleteSchoolConfirmHandler();
    dispatch(deleteChosenSchool({ pairedId: chosenSchool.pairedId }));
    if (redirect) navigate("/");

    window.scrollTo(0, 0);
  };

  const deleteHandlerWithSpinner = useSpinner(deleteHandler);

  return (
    <SmallModal open={open}>
      <div className="flex flex-1 flex-col min-h-10rem">
        <div className="flex flex-1 flex-col">
          <p className={`text-xl font-semibold ${colors.primary.text}`}>
            Are you sure you want to delete this school?
          </p>
          <p className="font-semibold">You can add back at any time.</p>
        </div>
        <div className="flex flex-1 flex-row items-end space-x-2">
          <Button
            id="delete-school-cancel-button"
            onClick={deleteSchoolCancelHandler}
            className={`w-full ${composeBg.secondary}`}
          >
            Cancel
          </Button>
          <Button
            id="delete-school-confirm-button"
            onClick={deleteHandlerWithSpinner}
            className={`w-full ${composeBg.primary}`}
          >
            Delete
          </Button>
        </div>
      </div>
    </SmallModal>
  );
}

DeleteSchoolModal.propTypes = {
  open: PropTypes.bool,
  chosenSchool: PropTypes.shape(),
  deleteSchoolCancelHandler: PropTypes.func,
  deleteSchoolConfirmHandler: PropTypes.func,
  redirect: PropTypes.bool
};

export default DeleteSchoolModal;
