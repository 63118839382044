import PropTypes from "prop-types";

import { Button, LoadingButton } from "../../../core/buttons";

import Content from "./Content";

function Confirm({
  onClick,
  firstText,
  secondText,
  secondOnClick,
  isLoading = false,
  secondButton = false,
  secondButtonText = "Back",
  buttonText = "Go to My Groups"
}) {
  return (
    <>
      <div className="overflow-auto px-1">
        <Content firstText={firstText} secondText={secondText} />
      </div>
      <div className="mt-auto py-5 flex flex-row">
        <div className="flex flex-row flex-1 space-x-4">
          <div className="w-1/2">
            {secondButton && (
              <Button
                onClick={secondOnClick}
                className="
                  w-full
                  h-full
                  bg-white
                  normal-case
                  text-primary-green
                  border-primary-green
                  hover:text-white
                  active:text-white
                  hover:bg-primary-green
                  active:bg-primary-green
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                {secondButtonText}
              </Button>
            )}
          </div>
          <div className="w-1/2">
            <LoadingButton
              onClick={onClick}
              isLoading={isLoading}
              className="
                w-full
                h-full
                normal-case
                text-white
                bg-primary-green
                border-primary-green
                hover:bg-white
                active:bg-white
                hover:text-primary-green
                active:text-primary-green
                mobile:text-sm
                mobile-sm:text-sm
              "
            >
              {buttonText}
            </LoadingButton>
          </div>
        </div>
      </div>
    </>
  );
}

Confirm.propTypes = {
  firstText: PropTypes.any,
  secondText: PropTypes.any,
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string,
  secondButton: PropTypes.bool,
  secondOnClick: PropTypes.func,
  secondButtonText: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default Confirm;
