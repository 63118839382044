import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Layout from "../Common/Layout";
import LayoutDesktop, { LayoutCenterContent } from "../Common/LayoutDesktop";

import VerifyAccount from "./VerifyAccount";
import CreateAccountScreen from "./CreateAccountScreen";

import { useLogin } from "../../utils/auth";
import { Items, removeState } from "../../redux/storePersist";

function CreateAccount() {
  const navigate = useNavigate();
  const location = useLocation();

  const [showVerify, setShowVerify] = useState(false);

  const { isLoading, userExists, profile, auth0 } = useLogin();

  const qs = new URLSearchParams(location.search.slice(1));
  const redirectUri = qs.get("next") || "/";

  const goToSignIn = async () => {
    removeState(Items.GTM_STATE);
    return await auth0.logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  useEffect(() => {
    if (profile.sub && !profile.email_verified) setShowVerify(true);

    if (userExists && profile.email_verified) navigate(redirectUri);
  }, [profile, userExists, redirectUri, navigate]);

  if (userExists && profile.email_verified) {
    return (
      <>
        <p>User already exists.</p>
        <p>Redirecting to homepage...</p>
      </>
    );
  }

  if (isLoading)
    return (
      <div className="w-full font-bold text-center">
        <p>Loading...</p>
      </div>
    );

  return (
    <Layout showHeader={false}>
      <LayoutDesktop height="min-h-screen-footer">
        {!showVerify ? (
          <LayoutCenterContent className="shadow-lg">
            <CreateAccountScreen
              profile={profile}
              goToSignIn={goToSignIn}
              redirectUri={redirectUri}
            />
          </LayoutCenterContent>
        ) : (
          <LayoutCenterContent className="shadow-lg relative overflow-hidden">
            <VerifyAccount />
          </LayoutCenterContent>
        )}
      </LayoutDesktop>
    </Layout>
  );
}

export default CreateAccount;
