import PropTypes from "prop-types";

function UnhappyFace({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M10.25 13.0501C11.2166 13.0501 11.9999 12.2926 11.9999 11.3585C11.9999 10.4245 11.2163 9.66696 10.25 9.66696C9.28345 9.66696 8.50012 10.4245 8.50012 11.3585C8.50012 12.2926 9.28345 13.0501 10.25 13.0501ZM19.75 13.0501C20.7166 13.0501 21.4999 12.2926 21.4999 11.3585C21.4999 10.4245 20.7162 9.66696 19.75 9.66696C18.7837 9.66696 18.0001 10.4245 18.0001 11.3585C18.0001 12.2926 18.7834 13.0501 19.75 13.0501ZM21.9999 22.2334L23.4998 21.75C20.4998 14.9834 9.49963 14.9834 6.49957 21.75L7.99945 22.2334C10 17.4001 20 17.4001 21.9999 22.2334ZM15 27.0667C7.5 27.0667 1.99994 21.75 1.99994 14.5C1.99994 7.25 7.5 1.93327 15 1.93327C22.5 1.93327 28.0001 7.25 28.0001 14.5C28.0001 21.75 22.5 27.0667 15 27.0667ZM15 29C23.5001 29 30 22.7165 30 14.5C30 6.28351 23.5001 0 15 0C6.49988 0 0 6.28322 0 14.5C0 22.7168 6.49988 29 15 29Z"
      />
    </svg>
  );
}

UnhappyFace.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

export default UnhappyFace;
