import PropTypes from "prop-types";

import { Colors } from "../../../../core/utils";
import { PlusSimple } from "../../../../core/icons";
import SearchMajors from "../../../../core/compose/YourMajors/SearchMajors";
import { MajorsSearchCP } from "../../../../utils/DataLayers";
import { GetIpedsId } from "../../../Common/Hooks";

function PopularMajors({
  color,
  schoolName,
  selectMajor,
  popularMajors,
  searchMajorsResults,
}) {
  const ipedsId = GetIpedsId();

  return (
    <div className="flex flex-1 flex-col">
      <SearchMajors
        color={color}
        gaOnClick={() => MajorsSearchCP({ ipeds: ipedsId })}
        id="majors-search-bar"
        selectMajor={selectMajor}
        searchMajorsResults={searchMajorsResults}
      />
      {popularMajors.length > 0 && (
        <>
          <div className="my-2 flex flex-1 flex-col">
            <p className="text-sm font-bold truncate normal-case">
              Popular majors at {schoolName}
            </p>
          </div>
          {popularMajors.map((major, index) => (
            <div
              key={major.code}
              className={`
                p-2
                flex
                content-center
                justify-between
                ${index % 2 ? "bg-white" : "bg-light-gray"}
              `}
            >
              <p className="font-medium text-black w-11/12">{major.name}</p>
              <div
                role="button"
                onClick={() => selectMajor(major)}
                className="w-1/12 place-self-center"
              >
                <PlusSimple size={20} />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

PopularMajors.propTypes = {
  selectMajor: PropTypes.func,
  schoolName: PropTypes.string,
  searchMajorsResults: PropTypes.func,
  popularMajors: PropTypes.arrayOf(PropTypes.shape()),
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT,
  ]),
};

export default PopularMajors;
