import { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import BoxAmounts from "./BoxAmounts";
import Header from "../../Common/Header";

import { SmallModal, Dropdown } from "../../../../core";
import { LivingChoice } from "../../../../core/utils";
import { Button } from "../../../../core/buttons";

import Api from "../../../../services/Api";
import { ModalTypes } from "../../../Common/utils";
import { useSpinner } from "../../../Common/Hooks";
import { updateChosenSchool } from "../../../../redux/Student/actions";

function Viewer({
  data,
  onLook,
  openModal,
  chosenSchool,
  modalResponse,
  modalResponseData
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const EXAMPLE_GRANTS = useMemo(
    () => [
      {
        added: true,
        type: { id: "grant", value: "GRANT", label: "Grant" },
        name: "Federal Pell Grant",
        amount: "",
        id: "grant-federal-0"
      },
      {
        added: true,
        type: { id: "grant", value: "GRANT", label: "Grant" },
        name: "State Grant",
        amount: "",
        id: "grant-state-0"
      },
      {
        added: true,
        type: { id: "grant", value: "GRANT", label: "Grant" },
        name: "Other Grants or Scholarships",
        amount: "",
        id: "grant-other-0"
      }
    ],
    []
  );

  const livingChoiceOptions = [
    {
      id: LivingChoice.ON_CAMPUS,
      label: "On Campus",
      enabled: true
    },
    {
      id: LivingChoice.OFF_CAMPUS,
      label: "Off Campus",
      enabled: true
    },
    {
      id: LivingChoice.WITH_FAMILY,
      label: "With Family",
      enabled: true
    }
  ];

  const [removeAid, setRemoveAid] = useState(null);
  const [financialAids, setFinancialAids] = useState([]);
  const [initialLivingChoice, setInitialLivingChoice] = useState(null);
  const [livingChoiceSelected, setLivingChoiceSelected] = useState(null);
  const [removeFinancialAids, setRemoveFinancialAids] = useState([]);
  const [onBackConfirmation, setOnBackConfirmation] = useState(false);
  const [showRemoveAidModal, setShowRemoveAidModal] = useState(false);
  const [showLeaveEditModal, setShowLeaveEditModal] = useState(false);

  const handlerLivingChoiseSelect = (data) => setLivingChoiceSelected(data);

  const onAdd = useCallback(() => {
    openModal({ type: ModalTypes.ADD_FINANCIAL_AID });
  }, [openModal]);

  const removeAidOnClick = useCallback(() => {
    handlerOnRemoveFinancialAid(removeAid.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeAid]);

  const cancelRemoveAidOnClick = useCallback(() => {
    setRemoveAid(null);
    setShowRemoveAidModal(false);
  }, []);

  const leaveEditOnClick = useCallback(() => {
    if (onBackConfirmation) navigate(-1);
    else onLook();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBackConfirmation, onLook]);

  const cancelLeaveEditOnClick = useCallback(() => {
    setShowLeaveEditModal(!showLeaveEditModal);
  }, [showLeaveEditModal]);

  const handlerRemoveFinancialAids = useCallback((id) => {
    if (isNaN(Number(id))) return;

    setRemoveFinancialAids((prevRemoveFinancialAids) =>
      prevRemoveFinancialAids.includes(id)
        ? prevRemoveFinancialAids
        : [...prevRemoveFinancialAids, id]
    );
  }, []);

  const handlerOnRemoveFinancialAid = useCallback(
    (removeId) => {
      const localRemoveAid = financialAids.find(
        (financialAid) => financialAid.id === removeId
      );
      if (!removeAid) {
        setRemoveAid(localRemoveAid);
        setShowRemoveAidModal(true);
        return;
      }
      if (removeAid) {
        handlerRemoveFinancialAids(removeId);
        setShowRemoveAidModal(false);
        setRemoveAid(null);
      }

      setFinancialAids((prevFinancialAids) =>
        prevFinancialAids.filter((financialAid) => financialAid.id !== removeId)
      );
    },
    [financialAids, handlerRemoveFinancialAids, removeAid]
  );

  const handlerOnEditFinancialAid = useCallback(
    ({ value, editId }) => {
      setFinancialAids((prevFinancialAids) => {
        handlerRemoveFinancialAids(editId);
        return prevFinancialAids.map((financialAid) => {
          if (financialAid.id === editId)
            return { ...financialAid, added: true, amount: value };
          return financialAid;
        });
      });
    },
    [handlerRemoveFinancialAids]
  );

  const handlerOnSaveEdit = useSpinner(async () => {
    const addAidAmounts = financialAids
      .filter((financialAid) => financialAid?.added && financialAid.amount > 0)
      .map((financialAid) => ({
        value: financialAid.amount,
        description: financialAid.name,
        category: financialAid.type.value
      }));

    const mutationData = {
      livingChoice: livingChoiceSelected.id,
      chosenSchoolId: chosenSchool.pairedId,
      addAidAmounts: addAidAmounts.length > 0 ? addAidAmounts : null,
      removeAidAmounts:
        removeFinancialAids.length > 0 ? removeFinancialAids : null
    };
    const data = await Api.editEstimatedAids(mutationData);
    await dispatch(updateChosenSchool({ ...chosenSchool, ...data }));
    navigate(-1);
  });

  const closeModal = useCallback(
    (modalType) => {
      if (modalType) {
        switch (modalType) {
          case ModalTypes.ADD_FINANCIAL_AID:
            if (modalResponseData?.financialAids)
              setFinancialAids((prevFinancialAids) => {
                if (prevFinancialAids.length > 0)
                  return [
                    ...prevFinancialAids,
                    ...modalResponseData.financialAids.filter(
                      (data) =>
                        !prevFinancialAids
                          .map((financialAid) => financialAid.id)
                          .includes(data.id)
                    )
                  ];
                return modalResponseData.financialAids;
              });
            break;
          default:
            break;
        }
      }
    },
    [modalResponseData?.financialAids]
  );

  useEffect(() => {
    closeModal(modalResponse);
  }, [closeModal, modalResponse]);

  useEffect(() => {
    if (data.grants.length > 0) {
      const getStudentGrants = (excludedAids) =>
        data.grants
          .filter(
            (aid) =>
              (aid.categorySource === "STUDENT" ||
                aid.categorySource === "LETTER" ||
                aid.categorySource === "ADVISOR") &&
              !excludedAids.includes(aid.id)
          )
          .map((aid) => ({
            edit: true,
            id: aid.id,
            name: aid.name,
            type: aid.type,
            amount: aid.value,
            categorySource: aid.categorySource
          }));

      setFinancialAids((prevFinancialAids) => {
        const excludedAids = [
          ...removeFinancialAids,
          ...prevFinancialAids.map((financialAid) => financialAid.id)
        ];
        if (prevFinancialAids.length > 0)
          return [...prevFinancialAids, ...getStudentGrants(excludedAids)];
        return getStudentGrants(excludedAids);
      });
    } else {
      setFinancialAids(EXAMPLE_GRANTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chosenSchool.estimatedAids.length > 0) {
      const initialLivingChoice = livingChoiceOptions.find(
        (option) => option.id === chosenSchool.livingChoice
      );
      setLivingChoiceSelected(initialLivingChoice);
      setInitialLivingChoice(initialLivingChoice);
    } else {
      setLivingChoiceSelected(livingChoiceOptions[0]);
      setInitialLivingChoice(livingChoiceOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenSchool.livingChoice]);

  const disableButton = useMemo(
    () =>
      removeFinancialAids.length === 0 &&
      !financialAids.some((financialAid) => financialAid.added) &&
      livingChoiceSelected?.id === initialLivingChoice?.id,
    [
      financialAids,
      removeFinancialAids,
      livingChoiceSelected,
      initialLivingChoice
    ]
  );

  return (
    <>
      <div className="p-4">
        <Header
          title="Estimate Financial Aid"
          onBack={() => {
            if (!disableButton) {
              setShowLeaveEditModal(true);
              setOnBackConfirmation(true);
            } else navigate(-1);
          }}
        />
      </div>
      <div
        className="
          p-8
          flex
          flex-1
          flex-col
          bg-white
          mobile:px-4
          mobile-sm:px-4
          tablet-sm:px-4
        "
      >
        <div
          className="
            flex
            flex-1
            flex-row
            mobile:flex-col
            mobile-sm:flex-col
            tablet-sm:flex-col
          "
        >
          <div
            className="
              w-2/3
              pr-10
              mobile:pr-0
              mobile:w-full
              mobile-sm:pr-0
              mobile-sm:w-full
              tablet-sm:pr-0
              tablet-sm:w-full
            "
          >
            <h2 className="text-3xl font-bold mb-4 text-primary-green">New!</h2>
            <p className="mb-6 font-bold text-xs">
              {data.hasAutoEstimatedAid
                ? "DecidED is allowing students to input estimated financial aid benefits to get a glimpse of how affordable a college may be. We already found some potential aid amounts in a letter you uploaded to another school. See further notes below.:   "
                : `To address the FAFSA delays for the 2024-25 academic year, DecidED
              is allowing students to input estimated financial aid benefits to
              get a glimpse of how affordable a college may be. Not sure where
              to start? Here are our suggestions: `}
            </p>
            <div className="flex flex-row mb-6 items-center">
              <div className="bg-primary-green text-white text-2xl font-bold w-12 h-12 flex-shrink-0 flex items-center justify-center mr-4">
                1
              </div>
              <div>
                <h3 className="text-2xl mobile:text-lg text-primary-green font-bold mb-2">
                  {data.hasAutoEstimatedAid
                    ? "Your potential Pell Grant"
                    : "Calculate your potential Pell Grant"}
                </h3>
                <p className="mb-2 text-xs">
                  {data.hasAutoEstimatedAid
                    ? "The maximum amount potential Pell grant for 2024-25 is $7,395, depending on your SAI.  We have entered the amount you should receive for Pell based on your other letter(s)"
                    : `The maximum amount potential Pell grant for 2024-25 is $7,395,
                  depending on your SAI. You can estimate your potential Pell
                  grant by filling in this form: `}
                  {!data.hasAutoEstimatedAid && (
                    <a
                      href="https://studentaid.gov/aid-estimator/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500 underline"
                    >
                      https://studentaid.gov/aid-estimator/
                    </a>
                  )}
                </p>
              </div>
            </div>
            <div className="flex flex-row mb-6 items-center break-all">
              <div className="bg-primary-green text-white text-2xl font-bold w-12 h-12 flex-shrink-0 flex items-center justify-center mr-4">
                2
              </div>
              <div>
                <h3 className="text-2xl mobile:text-lg text-primary-green font-bold mb-2">
                  Look up potential State Grants
                </h3>
                <p className="mb-2 text-xs">
                  Almost every U.S. state has grants or scholarship programs for
                  aspiring college students. Check out this map to research your
                  state's offers:
                  <a
                    href="https://www.nasfaa.org/State_Financial_Aid_Programs"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500 underline"
                  >
                    https://www.nasfaa.org/State_Financial_Aid_Programs
                  </a>
                </p>
              </div>
            </div>
            <div className="flex flex-row mb-6 items-center">
              <div className="bg-primary-green text-white text-2xl font-bold w-12 h-12 flex-shrink-0 flex items-center justify-center mr-4">
                3
              </div>
              <div>
                <h3 className="text-2xl mobile:text-lg text-primary-green font-bold mb-2">
                  Other sources of Grants & Scholarships
                </h3>
                <p className="text-xs">
                  Add any other grants or scholarships that you have been
                  awarded or expect to be awarded. Keep in mind this is just an
                  estimate; you will need to confirm final amounts by uploading
                  your financial aid letter.
                </p>
              </div>
            </div>
            <div className="flex flex-row mb-6 items-center">
              <div className="bg-primary-green text-white text-2xl font-bold w-12 h-12 flex-shrink-0 flex items-center justify-center mr-4">
                4
              </div>
              <div>
                <h3 className="text-2xl mobile:text-lg text-primary-green font-bold mb-2">
                  Select your housing
                </h3>
                <Dropdown
                  options={livingChoiceOptions}
                  currentOption={livingChoiceSelected}
                  handleOnSelect={handlerLivingChoiseSelect}
                />
              </div>
            </div>
          </div>
          <div
            className="
              my-4
              w-1/3
              mobile:w-full
              mobile-sm:w-full
              tablet-sm:w-full
              mobile:flex-col
              mobile-sm:flex-col
              tablet-sm:flex-col
            "
          >
            <p className="pl-2 mb-4 font-bold normal-case">
              {data.hasAutoEstimatedAid
                ? `We have added estimated aid based on your other financial aid letter. You can make edits or add other free aid amounts below.`
                : `Enter $ amounts for all of the potential grants and scholarships
              you may receive below.`}
            </p>
            <BoxAmounts
              grants={data.grants}
              financialAids={financialAids}
              removeFinancialAids={removeFinancialAids}
              handlerOnEditFinancialAid={handlerOnEditFinancialAid}
              handlerOnRemoveFinancialAid={handlerOnRemoveFinancialAid}
            />
            <Button
              onClick={onAdd}
              className="
                  mt-6
                  w-full
                  text-primary-green
                  bg-white
                  border-primary-green
                  hover:bg-primary-green
                  active:bg-primary-green
                  hover:text-white
                  active:text-white
                "
            >
              Add More Estimated Financial Aid
            </Button>
            <Button
              disabled={disableButton}
              onClick={handlerOnSaveEdit}
              className="
                  mt-6
                  w-full
                  text-white
                  bg-primary-green
                  border-primary-green
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-green
                  active:text-primary-green
                "
            >
              Save Edits
            </Button>
          </div>
        </div>
      </div>

      {showRemoveAidModal && (
        <SmallModal open={showRemoveAidModal}>
          <div className="flex flex-1 flex-col">
            <p className="text-xl font-semibold text-primary-green">
              Are you sure you want to delete {removeAid?.name}?
            </p>
            <p className="mt-1 text-black font-semibold">
              This action may affect the affordability of this school.
            </p>
            <div className="mt-16 flex flex-1 flex-row space-x-4">
              <Button
                id="cancel-leave-edit-button"
                onClick={cancelRemoveAidOnClick}
                tabIndex={!showRemoveAidModal ? -1 : null}
                className="
                  w-full
                  bg-white
                  text-primary-green
                  border-primary-green
                  hover:text-white
                  active:text-white
                  hover:bg-primary-green
                  active:bg-primary-green
                "
              >
                Cancel
              </Button>
              <Button
                onClick={removeAidOnClick}
                id="confirm-leave-edit-button"
                tabIndex={!showRemoveAidModal ? -1 : null}
                className="
                  w-full
                  text-white
                  bg-primary-green
                  border-primary-green
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-green
                  active:text-primary-green
                "
              >
                Confirm
              </Button>
            </div>
          </div>
        </SmallModal>
      )}

      {showLeaveEditModal && (
        <SmallModal open={showLeaveEditModal}>
          <div className="flex flex-1 flex-col">
            <p className="text-xl font-semibold text-primary-green">
              Are you sure you want to quit without saving your edits?
            </p>
            <p className="mt-1 text-black font-semibold">
              You will lose all the unsaved changes.
            </p>
            <div className="mt-16 flex flex-1 flex-row space-x-4">
              <Button
                id="cancel-leave-edit-button"
                onClick={cancelLeaveEditOnClick}
                tabIndex={!showLeaveEditModal ? -1 : null}
                className="
                  w-full
                  bg-white
                  text-primary-green
                  border-primary-green
                  hover:text-white
                  active:text-white
                  hover:bg-primary-green
                  active:bg-primary-green
                "
              >
                Cancel
              </Button>
              <Button
                onClick={leaveEditOnClick}
                id="confirm-leave-edit-button"
                tabIndex={!showLeaveEditModal ? -1 : null}
                className="
                  w-full
                  text-white
                  bg-primary-green
                  border-primary-green
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-green
                  active:text-primary-green
                "
              >
                Leave
              </Button>
            </div>
          </div>
        </SmallModal>
      )}
    </>
  );
}

Viewer.propTypes = {
  data: PropTypes.object.isRequired,
  onLook: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  chosenSchool: PropTypes.string.isRequired,
  modalResponse: PropTypes.string.isRequired,
  modalResponseData: PropTypes.object.isRequired
};

export default Viewer;
