import PropTypes from "prop-types";

function ZoomControlButtons({
  zoomIn,
  zoomOut,
  resetTransform,
  currentZoomPercentage
}) {
  return (
    <div className="flex top-0 right-0 absolute justify-end">
      <div className="py-1 px-3 font-bold bg-black text-white">
        {currentZoomPercentage + "%"}
      </div>
      <div className="mr-1">
        <button
          onClick={zoomIn}
          className="py-1 px-3 font-bold bg-black text-white"
        >
          +
        </button>
        <button
          onClick={zoomOut}
          className="py-1 px-3 font-bold bg-black text-white"
        >
          -
        </button>
      </div>
      <button
        onClick={resetTransform}
        className="py-1 px-4 font-bold bg-black text-white"
      >
        Reset
      </button>
    </div>
  );
}

ZoomControlButtons.propTypes = {
  zoomIn: PropTypes.func,
  zoomOut: PropTypes.func,
  resetTransform: PropTypes.func,
  currentZoomPercentage: PropTypes.number
};

export default ZoomControlButtons;
