import PropTypes from "prop-types";
import FilterByMajor from "../College/Search/FilterByMajor";

export default function MajorFilterModal({ filters, onClose }) {
  return (
    <div
      className="
        py-5
        flex
        px-10
        flex-1
        flex-col
        mobile:px-5
        tablet-sm:px-5
        overflow-hidden
      "
    >
      <FilterByMajor
        isModal={true}
        filters={filters?.selected || []}
        onSubmit={({ type, filters }) => onClose({ type, filters })}
      />
    </div>
  );
}

MajorFilterModal.propTypes = {
  onClose: PropTypes.func,
  filters: PropTypes.shape()
};
