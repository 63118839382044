import PropTypes from "prop-types";

import { Button } from "../../../core/buttons";
import { Colors, getColors } from "../../../core/utils";

function AffordableButtons({
  show,
  color,
  className,
  onClickViewAwardLetter,
  onClickReuploadAwardLetter,
  primaryLabel = "edit financial aid",
  secondaryLabel = "reupload award letter"
}) {
  if (!show) return null;

  const getComposeMainBg = (bg) => {
    switch (bg) {
      case "bg-primary-red":
        return "text-white bg-primary-red hover:bg-white active:bg-white hover:text-primary-red active:text-primary-red";
      case "bg-primary-blue":
        return "text-white bg-primary-blue hover:bg-white active:bg-white hover:text-primary-blue active:text-primary-blue";
      case "bg-primary-green":
        return "text-white bg-primary-green hover:bg-white active:bg-white hover:text-primary-green active:text-primary-green";
      case "bg-primary-orange":
        return "text-white bg-primary-orange hover:bg-white active:bg-white hover:text-primary-orange active:text-primary-orange";

      default:
        return "text-black bg-white hover:bg-light-gray active:bg-light-gray";
    }
  };

  const getComposeSecondBg = (bg) => {
    switch (bg) {
      case "bg-primary-red":
        return "bg-white text-primary-red hover:text-white active:text-white hover:bg-primary-red active:bg-primary-red";
      case "bg-primary-blue":
        return "bg-white text-primary-blue hover:text-white active:text-white hover:bg-primary-blue active:bg-primary-blue";
      case "bg-primary-green":
        return "bg-white text-primary-green hover:text-white active:text-white hover:bg-primary-green active:bg-primary-green";
      case "bg-primary-orange":
        return "bg-white text-primary-orange hover:text-white active:text-white hover:bg-primary-orange active:bg-primary-orange";

      default:
        return "text-black bg-white hover:bg-light-gray active:bg-light-gray";
    }
  };

  const colors = getColors(color);
  const composeMainBg = getComposeMainBg(colors.primary.bg);
  const composeSecondBg = getComposeSecondBg(colors.primary.bg);

  return (
    <div className={`flex flex-row space-x-2 ${className}`}>
      <div className="w-1/2">
        <Button
          onClick={onClickReuploadAwardLetter}
          id="affordability-report-error-button"
          className={`
            py-2
            w-full
            h-full
            text-xsm
            ${composeSecondBg}
            ${colors.primary.border}
          `}
        >
          {secondaryLabel}
        </Button>
      </div>
      <div className="w-1/2">
        <Button
          id="edit-financial-aid-button"
          onClick={onClickViewAwardLetter}
          className={`
            py-2
            w-full
            h-full
            text-xsm
            ${composeMainBg}
            ${colors.primary.border}
          `}
        >
          {primaryLabel}
        </Button>
      </div>
    </div>
  );
}

AffordableButtons.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  onClickViewAwardLetter: PropTypes.func,
  onClickReuploadAwardLetter: PropTypes.func,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default AffordableButtons;
