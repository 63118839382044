import TagManager from "react-gtm-module";

function ViewAwardLetterButton({ ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "view_award_letter_button",
      ipeds: ipeds,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default ViewAwardLetterButton;
