import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Layout from "../Common/Layout";
import LayoutCollege from "../College/LayoutCollege";
import SubscriptionSettingsForm from "./SubscriptionSettingsForm";

import { BackButton } from "../../core/buttons";

import { useStudent } from "../../redux/Student/hooks";
import { updateStudent } from "../../redux/Student/actions";

function SubscriptionSettings({ openModal, modalResponse }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { student } = useStudent();

  const [defaultValues, setDefaultValues] = useState(null);

  const onSubmit = async (data) => {
    const subscriptionSettings = {
      emailReceive: data.emailReceive,
      emailNotifications: data.emailNotifications
    };
    await dispatch(updateStudent(subscriptionSettings));
  };

  const unsubscribeFromAll = async () => {
    const subscriptionSettings = {
      emailReceive: false,
      emailNotifications: false
    };
    await dispatch(updateStudent(subscriptionSettings));
  };

  useEffect(() => {
    setDefaultValues({
      emailReceive: student.emailReceive,
      emailNotifications: student.emailNotifications
    });
  }, [student]);

  if (!defaultValues) return null;

  return (
    <Layout
      student={student}
      openModal={openModal}
      modalResponse={modalResponse}
    >
      <LayoutCollege>
        <div
          className="
            p-4
            mb-4
            flex
            flex-1
            flex-col
            bg-white
            min-h-screen-footer-lg
            mobile:min-h-screen-footer
            mobile-sm:min-h-screen-footer
            tablet-sm:min-h-screen-footer
          "
        >
          <div className="divide-y divide-solid divide-light-gray">
            <div className="pb-3">
              <BackButton onClick={() => navigate("/")} />
            </div>
            <div className="py-4">
              <h1
                className="
                  text-2xl
                  font-semibold
                  mobile:text-xl
                  tablet:text-xl
                  mobile-sm:text-xl
                  tablet-sm:text-xl
                "
              >
                Update your subscription settings:
              </h1>
            </div>
          </div>
          <SubscriptionSettingsForm
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            unsubscribeFromAll={unsubscribeFromAll}
          />
        </div>
      </LayoutCollege>
    </Layout>
  );
}

SubscriptionSettings.propTypes = {
  openModal: PropTypes.func.isRequired,
  modalResponse: PropTypes.string
};

export default SubscriptionSettings;
