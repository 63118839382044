import PropTypes from "prop-types";

function ViewEye({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        strokeWidth="2"
        className={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M16,7C9.934,7,4.798,10.776,3,16c1.798,5.224,6.934,9,13,9s11.202-3.776,13-9C27.202,10.776,22.066,7,16,7z"
      />
      <circle
        r="5"
        cx="16"
        cy="16"
        fill="none"
        id="XMLID_12_"
        strokeWidth="2"
        className={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

ViewEye.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default ViewEye;
