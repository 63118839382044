import TagManager from "react-gtm-module";

function AddAwardBreakdown({ ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "award_break_down",
      ipeds: ipeds,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default AddAwardBreakdown;
