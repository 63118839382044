import TagManager from "react-gtm-module";

function ReuploadAwardAffordabilityBox({ affordability_state, ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "reupload_affordability_box",
      affordability_state: affordability_state,
      ipeds: ipeds,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default ReuploadAwardAffordabilityBox;
