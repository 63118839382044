import PropTypes from "prop-types";

function ComparingColleges({ size = 25, color = "fill-black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 37 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.0512 21.0607L29.2545 9.30359H19.2595V8.50197C20.9919 7.96756 22.3246 6.36431 22.3246 4.36027C22.3246 1.95541 20.4589 0.0849609 18.0601 0.0849609C15.6612 0.0849609 13.7955 1.95541 13.7955 4.36027C13.7955 6.36431 15.1282 7.96756 16.8606 8.50197V9.30359H6.73232L0.868546 21.0607H0.46875V22.2631C0.46875 25.2024 3.66717 28.008 7.26539 28.008C10.7303 28.008 14.062 25.336 14.062 22.2631V21.0607H13.3957L7.93173 11.5748H16.8606V35.757H1.40162V38.0283H34.3187V35.757H19.2595V11.4412H28.1884L23.524 20.9271H23.1242V22.1295C23.1242 25.0688 26.3226 27.8744 29.9209 27.8744C33.5191 27.8744 36.7175 25.2024 36.7175 22.1295V20.9271H36.0512V21.0607ZM16.1943 4.22666C16.1943 3.15784 17.1272 2.22262 18.1933 2.22262C19.2595 2.22262 20.1923 3.15784 20.1923 4.22666C20.1923 5.29549 19.2595 6.23071 18.1933 6.23071C17.1272 6.23071 16.1943 5.29549 16.1943 4.22666ZM7.13213 25.6032C5.26638 25.6032 3.66716 24.5344 3.00082 23.3319H11.2634C10.5971 24.5344 8.99787 25.6032 7.13213 25.6032ZM3.40063 21.0607L6.73232 14.1133L10.7303 21.0607H3.40063ZM29.3878 14.1133L33.3858 21.0607H26.0561L29.3878 14.1133ZM29.7876 25.6032C27.9219 25.6032 26.3226 24.5344 25.6563 23.3319H33.9189C33.2526 24.5344 31.6533 25.6032 29.7876 25.6032Z"
        fill={color}
      />
    </svg>
  );
}

ComparingColleges.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default ComparingColleges;
