import { useState } from "react";
import PropTypes from "prop-types";

import { PlusShape, MagnifyGlassSimple } from "../icons";

function SearchFloatBox({
  label,
  className,
  searchResults,
  onSelectResult,
  searchEndpoint,
}) {
  const [search, setSearch] = useState("");

  const handlerOnChange = (event) => {
    const searchText = event.target.value;
    setSearch(searchText);
    searchEndpoint(searchText);
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <span
        className="
          text-sm
          select-none
          mobile:text-xs
          mobile-sm:text-xs
          tablet-sm:text-xs
        "
      >
        {label}
      </span>
      <div className="relative min-h-3rem">
        <div
          className={`
            px-3
            w-full
            border
            bg-white
            divide-y-4
            mobile:w-full
            border-black
            mobile-sm:w-full
            tablet-sm:w-full
            divide-light-gray
            focus-within:shadow-black
            ${searchResults && searchResults.length && "top-0 left-0 absolute"}
          `}
        >
          <div className="w-full relative">
            <input
              type="text"
              value={search}
              onChange={handlerOnChange}
              className="w-full text-sm focus:outline-none"
            />
            <div className="mt-1 top-0 right-0 absolute">
              <MagnifyGlassSimple size={15} color="fill-primary-green" />
            </div>
          </div>
          {searchResults && searchResults.length > 0 && (
            <div className="pt-1 h-24 text-sm overflow-y-auto">
              {searchResults.map((result, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setSearch("");
                    onSelectResult(result);
                  }}
                  className="flex flex-row items-center cursor-pointer"
                >
                  <div className="w-4 mr-3">
                    <PlusShape size={15} color="fill-primary-green" />
                  </div>
                  <span className="truncate">{result.name}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

SearchFloatBox.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  searchResults: PropTypes.array,
  onSelectResult: PropTypes.func,
  searchEndpoint: PropTypes.func,
};

export default SearchFloatBox;
