import PropTypes from "prop-types";

import FilterByType from "./FilterByType";
import FilterByMajor from "./FilterByMajor";
import FilterByLocation from "./FilterByLocation";

import { FloatBox } from "../../../core";
import { Button } from "../../../core/buttons";
import { FilterItemRow } from "../../../core/compose";

import {
  Filter,
  FilterType,
  FilterMajor,
  FilterLocation
} from "../../../core/icons";

import { useIsDesktopMQL } from "../../../core/hooks";
import {
  ExploreCollegesLocation,
  ExploreCollegesMajor,
  ExploreCollegesType
} from "../../../utils/DataLayers";
import { GetIpedsId } from "../../Common/Hooks";

import CaLogo from "../../../assets/images/CA_logo.png";

function FilterResults({
  show,
  divRef,
  toggle,
  filters,
  onSubmit,
  activeDropdown,
  religiousTypes,
  handlerOnActiveDropdown
}) {
  const isDesktop = useIsDesktopMQL();
  const ipedsId = GetIpedsId();

  if (!show) return null;

  const handlerLocationOnClick = () => {
    if (activeDropdown !== "location") {
      ExploreCollegesLocation({ ipeds: ipedsId });
    }
  };

  const handlerMajorOnClick = () => {
    if (activeDropdown !== "major") {
      ExploreCollegesMajor({ ipeds: ipedsId });
    }
  };

  const handlerTypeOnClick = () => {
    if (activeDropdown !== "type") {
      ExploreCollegesType({ ipeds: ipedsId });
    }
  };

  const handleOutsideClick = () => {
    handlerOnActiveDropdown("");
    toggle();
  };

  const handlerOnSubmit = ({ type, filters }) => {
    onSubmit({ type, filters });
    handleOutsideClick();
  };

  const hasFiltersLocation = () => {
    const filtersMajor = filters?.location?.selected || [];
    return filtersMajor.length > 0;
  };

  const hasFiltersMajor = () => {
    const filtersMajor = filters?.major?.selected || [];
    return filtersMajor.length > 0;
  };

  const hasFiltersType = () => {
    const filtersType = filters?.type || {};
    return Object.keys(filtersType).some((key) => filtersType[key]);
  };

  const hasCommonAppFilter = () => {
    return filters?.hasCommonApp?.value ?? false;
  };

  return (
    <FloatBox
      open={show}
      containerRef={divRef}
      width={isDesktop ? 640 : "100%"}
      handleOnClickOutside={handleOutsideClick}
      className="p-4 pb-8 top-0 shadow-lg space-y-4 bg-white border-black"
    >
      <Button
        onClick={handleOutsideClick}
        className="
          py-0
          flex
          flex-row
          space-x-2
          items-center
          border-white
          focus:outline-none
        "
      >
        <Filter size={25} color="fill-primary-green" />
        <span className="font-bold capitalize text-primary-green">filter</span>
      </Button>
      <FilterItemRow
        title="major"
        id="filter-major-button"
        active={hasFiltersMajor()}
        activeDropdown={activeDropdown}
        onClickHandler={handlerMajorOnClick}
        handlerOnActiveDropdown={handlerOnActiveDropdown}
        icon={<FilterMajor size={22} color="fill-current" />}
      >
        <div className="flex flex-1 h-35rem flex-col overflow-hidden">
          <FilterByMajor
            onSubmit={handlerOnSubmit}
            filters={filters?.major?.selected || []}
          />
        </div>
      </FilterItemRow>
      <FilterItemRow
        title="location"
        id="filter-location-button"
        active={hasFiltersLocation()}
        activeDropdown={activeDropdown}
        onClickHandler={handlerLocationOnClick}
        handlerOnActiveDropdown={handlerOnActiveDropdown}
        icon={<FilterLocation size={22} color="fill-current" />}
      >
        <div className="flex flex-1 h-35rem flex-col overflow-hidden">
          <FilterByLocation
            onSubmit={handlerOnSubmit}
            filters={filters?.location?.selected || []}
          />
        </div>
      </FilterItemRow>
      <FilterItemRow
        title="type"
        id="filter-type-button"
        active={hasFiltersType()}
        activeDropdown={activeDropdown}
        onClickHandler={handlerTypeOnClick}
        handlerOnActiveDropdown={handlerOnActiveDropdown}
        icon={<FilterType size={22} color="stroke-current" />}
      >
        <div className="flex flex-1 h-35rem flex-col overflow-hidden">
          <FilterByType
            onSubmit={handlerOnSubmit}
            filters={filters?.type || {}}
            religiousTypes={religiousTypes}
          />
        </div>
      </FilterItemRow>
      <Button
        onClick={() => {
          handlerOnSubmit({
            type: "hasCommonApp",
            filters: { value: true }
          });
        }}
        className={`
          flex flex-row justify-center items-center space-x-2 w-full border-primary-green
          ${
            hasCommonAppFilter()
              ? "bg-white text-primary-green hover:bg-primary-green hover:text-white"
              : "bg-primary-green text-white hover:bg-white hover:text-primary-green"
          }
        `}
      >
        <span>Apply with</span> <img src={CaLogo} alt="Common App logo" />
      </Button>
    </FloatBox>
  );
}

FilterResults.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  filters: PropTypes.shape(),
  activeDropdown: PropTypes.string,
  religiousTypes: PropTypes.arrayOf(PropTypes.shape())
};

export default FilterResults;
