export function SortOrderColleges({
  college,
  colleges,
  newSortOrder,
  isFavorite = false,
  sort = true,
}) {
  const pairedId = college.pairedId;
  const oldSortOrder = college.sortOrder;

  let newSortColleges = [];

  if (oldSortOrder !== newSortOrder) {
    if (newSortOrder > oldSortOrder) {
      newSortColleges = colleges.map((school) => {
        if (school.pairedId === pairedId) {
          return {
            ...school,
            sortOrder: newSortOrder,
          };
        } else if (
          school.sortOrder > oldSortOrder &&
          school.sortOrder <= newSortOrder
        ) {
          return {
            ...school,
            sortOrder: school.sortOrder - 1,
          };
        } else return school;
      });
    } else {
      newSortColleges = colleges.map((school) => {
        if (school.pairedId === pairedId) {
          return {
            ...school,
            sortOrder: newSortOrder,
          };
        } else if (
          school.sortOrder < oldSortOrder &&
          school.sortOrder >= newSortOrder
        ) {
          return {
            ...school,
            sortOrder: school.sortOrder + 1,
          };
        } else return school;
      });
    }
  } else
    newSortColleges = colleges.map((school) => {
      if (school.pairedId === pairedId)
        return {
          ...school,
          isFavorite,
        };
      return { ...school, isFavorite: false };
    });

  return sort
    ? newSortColleges.sort((a, b) => a.sortOrder - b.sortOrder)
    : newSortColleges;
}
