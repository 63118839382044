import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { Button } from "../../../../core/buttons";

function CollegesNoFound({ filtersApplied }) {
  const navigate = useNavigate();

  return (
    <div
      id="colleges-not-found-message"
      className="
        flex
        py-12
        px-12
        flex-1
        flex-col
        mobile:px-4
        mobile:py-24
        items-center
        mobile-sm:px-4
        mobile-sm:py-24
        tablet-sm:px-4
        tablet-sm:py-24
        justify-center
      "
    >
      <p
        className="
          text-2xl
          font-bold
          text-center
          laptop:w-2/3
          tablet:w-2/3
          desktop:w-1/2
          mobile:text-xl
          mobile-sm:text-lg
          tablet-sm:text-xl
        "
      >
        {filtersApplied ? (
          <span>No results found</span>
        ) : (
          <span>
            Start searching for schools to add to your list. Once schools are
            added, they will be shown here.
          </span>
        )}
      </p>
      {filtersApplied && (
        <div className="font-bold">Try refining your search to get results</div>
      )}
      <Button
        onClick={() => navigate("/college/search")}
        className="
          mt-16
          w-1/3
          text-white
          tablet:w-1/2
          mobile:w-full
          mobile-sm:w-full
          tablet-sm:w-full
          bg-primary-green
          border-primary-green
          hover:bg-white
          active:bg-white
          hover:text-primary-green
          active:text-primary-green
        "
      >
        search schools
      </Button>
    </div>
  );
}

CollegesNoFound.propTypes = {
  filtersApplied: PropTypes.bool,
};

export default CollegesNoFound;
