import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { debounce, trim } from "lodash";
import PropTypes from "prop-types";

import { SearchTextDropdownBtn } from "../../../../core/inputs";

import Api from "../../../../services/Api";
import { joinChosenSchool } from "../../../../redux/Student/actions";
import { SchoolSearchMagnifyingGlass } from "../../../../utils/DataLayers";
import { GetIpedsId } from "../../../Common/Hooks";

function CollegeSearch({ onSubmit, collegesSelected }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ipedsId = GetIpedsId();

  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [collegeResults, setCollegeResults] = useState(null);

  const searchCollegesDebounce = useMemo(
    () =>
      debounce(async (search) => {
        if (trim(search).length > 0) {
          setIsLoading(true);
          const { items } = await Api.searchSchool({
            input: { name: search },
            page: { pageNumber: 1, pageSize: 30 },
          });
          setCollegeResults(
            items
              .filter((item) => !collegesSelected.includes(item.ipedsId))
              .map((item) => ({ name: item.name, ipedsId: item.ipedsId })),
          );
          setIsLoading(false);
        } else setCollegeResults(null);
      }, 500),
    [collegesSelected],
  );

  const searchColleges = useCallback(searchCollegesDebounce, [
    searchCollegesDebounce,
  ]);

  const handlerOnSubmit = () => {
    SchoolSearchMagnifyingGlass({ ipeds: ipedsId });
    if (trim(search).length > 0)
      onSubmit({ type: "name", filters: { search } });
  };
  const handlerOnClick = () => handlerOnSubmit();

  const handlerOnKeyDown = (event) => {
    if (event.key === "Enter") handlerOnSubmit();
  };

  const handlerOnChange = (event) => {
    setSearch(event.currentTarget.value);
    searchColleges(event.currentTarget.value);
  };

  const handlerOnSelectCollege = async ({ schoolId }) => {
    setSearch("");
    setCollegeResults(null);
    const chosenSchool = await Api.chooseSchool({ schoolId });

    dispatch(joinChosenSchool(chosenSchool));
    navigate(`/college/id/${schoolId}`);
  };

  return (
    <SearchTextDropdownBtn
      search={search}
      isLoading={isLoading}
      id="search-dashboard-input"
      collegeResults={collegeResults}
      handlerOnClick={handlerOnClick}
      handlerOnChange={handlerOnChange}
      placeholder="Search for a college"
      handlerOnKeyDown={handlerOnKeyDown}
      handlerOnSelectCollege={handlerOnSelectCollege}
    />
  );
}

CollegeSearch.propTypes = {
  onSubmit: PropTypes.func,
  collegesSelected: PropTypes.arrayOf(PropTypes.string),
};

export default CollegeSearch;
