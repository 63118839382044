import PropTypes from "prop-types";

import { Panel, Earnings } from "../../../core/compose";
import Description from "../../../core/compose/Earnings/Description";
import { getTheme } from "../../../core/utils";
import { EarningsExpand, Tooltip } from "../../../utils/DataLayers";

function CollegeEarningsBox({ isDesktop, chosenSchool, tooltipClassName }) {
  const { award, school } = chosenSchool;
  const { earnings } = school;
  const affordability = award?.postAwardSchoolCosts?.affordability;

  const theme = getTheme({ affordability });

  return (
    <Panel
      tooltip={true}
      title="earnings"
      isDesktop={isDesktop}
      initiallyOpened={true}
      tooltipColor={theme.color}
      gaToggleCall={() => EarningsExpand({ ipeds: school?.ipedsId })}
      tooltipClassName={tooltipClassName}
      className="p-4 mt-4 mx-4 bg-white shadow-lg"
      expandableContent={<Description color={theme.color} />}
      tooltipLabel="This number shows the average potential earnings for students from this school 6 years after graduation."
      gaOnClickFunc={(text) => Tooltip({ text: text, ipeds: school?.ipedsId })}
    >
      <Earnings
        earnings={earnings}
        className="
          desktop:flex
          desktop:flex-row
          desktop:items-center
          desktop:justify-center
        "
      />
    </Panel>
  );
}

CollegeEarningsBox.propTypes = {
  isDesktop: PropTypes.bool,
  chosenSchool: PropTypes.shape(),
  tooltipClassName: PropTypes.string,
};

export default CollegeEarningsBox;
