import TagManager from "react-gtm-module";

function SchoolSearchMagnifyingGlass({ ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "school_search_magnifying_glass",
      ipeds: ipeds,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default SchoolSearchMagnifyingGlass;
