import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

import Api from "../../../services/Api";
import SpinnerContext from "../../../contexts/SpinnerContext";

export function useSpinner(callback) {
  const setSpinner = useContext(SpinnerContext);
  return async function withSpinner(...args) {
    try {
      setSpinner(true);
      return await callback(...args);
    } finally {
      setSpinner(false);
    }
  };
}

export function useMajorSearch({ name }) {
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [pending, setPending] = useState(false);
  useEffect(() => {
    async function search() {
      setResult(null);
      setError(null);
      const normalizedName = (name || "").trim();
      try {
        if (normalizedName) {
          setPending(true);
          setResult(
            await Api.searchMajors({
              name: normalizedName
            })
          );
        }
      } catch (e) {
        setError(e);
      } finally {
        setPending(false);
      }
    }

    search();
  }, [name]);

  return {
    isLoading: pending,
    error,
    result
  };
}

export function GetIpedsId() {
  const { ipedsId } = useParams();
  return ipedsId;
}
