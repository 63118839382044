import { useState } from "react";
import PropTypes from "prop-types";

import Option from "./Option";
import Header from "../../../Common/Header";

import { SmallModal } from "../../../../../core";
import { Button } from "../../../../../core/buttons";
import {
  Dorms,
  House,
  Apartment,
  QuestionShape,
} from "../../../../../core/icons";

import { LivingChoice as LivingChoiceEnum } from "../../../../../core/utils";
import { HousingNextButton } from "../../../../../utils/DataLayers";
import { GetIpedsId } from "../../../../Common/Hooks";

function LivingChoice({
  onBack,
  onSelect,
  universityName,
  hasOnCampusHousing,
}) {
  const [selection, setSelection] = useState("");
  const [showNotSureModal, setShowNotSureModal] = useState(false);
  const ipedsId = GetIpedsId();

  const onCampus = LivingChoiceEnum.ON_CAMPUS;
  const offCampus = LivingChoiceEnum.OFF_CAMPUS;
  const withFamily = LivingChoiceEnum.WITH_FAMILY;
  const notSure = "notsure";

  const toggleOnCampus = () =>
    setSelection(selection !== onCampus ? onCampus : "");
  const toggleOffCampus = () =>
    setSelection(selection !== offCampus ? offCampus : "");
  const toggleWithFamily = () =>
    setSelection(selection !== withFamily ? withFamily : "");
  const toggleNotSure = () =>
    setSelection(selection !== notSure ? notSure : "");

  const handleOnSelect = () => {
    if (selection === "notsure") {
      setShowNotSureModal(true);
    } else {
      onSelect(selection);
    }
    HousingNextButton({ ipeds: ipedsId });
  };

  const handleOnGotIt = () => {
    setShowNotSureModal(false);
    onSelect(hasOnCampusHousing ? onCampus : offCampus);
  };

  const housingOptions = [
    {
      show: hasOnCampusHousing,
      selected: selection === onCampus,
      text: "Living in a dorm on campus",
      icon: <Dorms size={40} color="stroke-current" />,
      toggle: toggleOnCampus,
    },
    {
      show: true,
      selected: selection === withFamily,
      text: "Living with family at home",
      icon: <House size={40} color="stroke-current" />,
      toggle: toggleWithFamily,
    },
    {
      show: true,
      selected: selection === offCampus,
      text: "Living in an apartment off campus",
      icon: <Apartment size={40} color="stroke-current" />,
      toggle: toggleOffCampus,
    },
    {
      show: true,
      selected: selection === notSure,
      text: "Not sure!",
      icon: <QuestionShape size={40} color="stroke-current" />,
      toggle: toggleNotSure,
    },
  ];

  return (
    <>
      <div className="p-4">
        <Header title="One Last Question" onBack={onBack} />
      </div>
      <div
        className="
          p-8
          flex
          flex-1
          flex-col
          bg-white
          mobile:px-4
          mobile-sm:px-4
          tablet-sm:px-4
          overflow-scroll
        "
      >
        <div className="mt-4 flex flex-1 flex-col justify-between">
          <p className="text-xl font-bold text-primary-green ">
            Where is {universityName} expecting you to live?
          </p>
          <div
            className="
              flex
              py-6
              my-6
              flex-row
              space-x-16
              mobile:flex-col
              mobile:space-x-0
              mobile:space-y-10
              mobile-sm:flex-col
              mobile-sm:space-x-0
              mobile-sm:space-y-10
              tablet-sm:flex-col
              tablet-sm:space-x-0
              tablet-sm:space-y-10
            "
          >
            <div
              className="
                flex
                flex-1
                flex-row
                flex-wrap
                mobile:flex-col
                mobile-sm:flex-col
                tablet-sm:flex-col
              "
            >
              {housingOptions.map((housingOption, index) => (
                <div
                  key={index}
                  className={`
                    p-5
                    w-1/2
                    mobile:w-full
                    mobile-sm:w-full
                    tablet-sm:w-full
                    ${!housingOption.show && "hidden"}
                  `}
                >
                  <Option
                    icon={housingOption.icon}
                    text={housingOption.text}
                    onClick={housingOption.toggle}
                    selected={housingOption.selected}
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            className="
              flex
              flex-1
              flex-row
              space-x-10
              mobile:flex-col
              mobile:space-x-0
              mobile-sm:flex-col
              mobile-sm:space-x-0
              tablet-sm:flex-col
              tablet-sm:space-x-0
            "
          >
            <div className="w-1/2 mobile:hidden mobile-sm:hidden tablet-sm:hidden"></div>
            <div
              className="
                pl-16
                w-1/2
                mobile:pl-0
                mobile:w-full
                mobile-sm:pl-0
                mobile-sm:w-full
                tablet-sm:pl-0
                tablet-sm:w-full
              "
            >
              <Button
                onClick={handleOnSelect}
                disabled={selection === "" && !showNotSureModal}
                className="
                  w-full
                  text-white
                  bg-primary-green
                  border-primary-green
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-green
                  active:text-primary-green
                "
              >
                next
              </Button>
            </div>
          </div>

          {showNotSureModal && (
            <SmallModal open={showNotSureModal}>
              <>
                <p className="text-xl font-bold text-primary-green">
                  Not sure where you’re going to live yet?
                </p>
                <p className="my-8">
                  It's ok! We ask this question to calculate your college costs.
                  For now, we'll use the safest cost estimate and assume that
                  you'll{" "}
                  {hasOnCampusHousing
                    ? "live on campus"
                    : "live in off campus housing"}
                  . When you decide where you'll live, come back and update your
                  information.
                </p>

                <div className="flex flex-row justify-center">
                  <Button
                    onClick={handleOnGotIt}
                    className="
                      mx-auto
                      text-white
                      bg-primary-green
                      border-primary-green
                      hover:bg-white
                      active:bg-white
                      hover:text-primary-green
                      active:text-primary-green
                    "
                  >
                    got it
                  </Button>
                </div>
              </>
            </SmallModal>
          )}
        </div>
      </div>
    </>
  );
}

LivingChoice.propTypes = {
  onBack: PropTypes.func,
  onSelect: PropTypes.func,
  universityName: PropTypes.string,
  hasOnCampusHousing: PropTypes.bool,
};

export default LivingChoice;
