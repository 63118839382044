import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { LinkCustom } from "../../core/";
import { Close } from "../../core/icons";
import { Button } from "../../core/buttons";
import { decidedRouteSupport } from "../../core/utils";

function NavigationMenu({ open, student, onClose, preSignIn }) {
  const navigate = useNavigate();

  const logOutHandler = () => navigate("/logout");

  const activeStyle = "text-secondary-green";

  return (
    <nav
      className="
        p-4  
        flex
        w-full
        h-full
        flex-col
        shadow-lg
        overflow-auto
        bg-primary-green
      "
    >
      <div
        className="
          mb-6
          flex
          flex-row
          relative
          text-4xl
          font-bold
          text-white
          items-center
          justify-center
        "
      >
        <div
          role="button"
          onClick={onClose}
          tabIndex={!open ? -1 : null}
          id="button-close-navigation-menu"
        >
          <Close color="stroke-white" />
        </div>
        <span className="mx-auto">Menu</span>
      </div>

      <div
        className="
          flex
          flex-1
          flex-col
          text-2xl
          divide-y-2
          text-white
          justify-center
          divide-white
        "
      >
        <div>
          <Button
            id="navigation-menu-button-my-path"
            onClick={() => navigate("/college/my-path")}
            className={`
              my-2
              w-full
              border-primary-green
              ${window.location.pathname === "/college/my-path" && activeStyle}
            `}
          >
            {student.firstName}'s path
          </Button>
        </div>
        <div>
          <Button
            id="navigation-menu-button-search"
            onClick={() => navigate("/college/search")}
            className={`
              my-2
              w-full
              border-primary-green
              ${window.location.pathname === "/college/search" && activeStyle}
            `}
          >
            search
          </Button>
        </div>
        <div>
          <LinkCustom
            target="_blank"
            href={decidedRouteSupport()}
            id="navigation-menu-button-help"
            className="w-full font-extrabold"
          >
            <p className="my-2 py-2 text-center">Help</p>
          </LinkCustom>
        </div>
      </div>

      {!preSignIn && (
        <Button
          onClick={logOutHandler}
          tabIndex={!open ? -1 : null}
          className="
            bg-white
            border-white
            text-primary-green
            active:text-white
            active:bg-primary-green
          "
        >
          Sign Out
        </Button>
      )}
    </nav>
  );
}

NavigationMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  preSignIn: PropTypes.bool,
  student: PropTypes.shape(),
};

export default NavigationMenu;
