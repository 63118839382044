import { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import JSZip from "jszip";

import Buttons from "./Buttons";
import BoxAmounts from "./BoxAmounts";
import Header from "../../Common/Header";
import MobileViewer from "./MobileViewer";
import DesktopViewer from "./DesktopViewer";

import { SmallModal } from "../../../../core";
import { Button } from "../../../../core/buttons";
import { ShortArrow } from "../../../../core/icons";

import Api from "../../../../services/Api";
import { ModalTypes } from "../../../Common/utils";
import { useSpinner } from "../../../Common/Hooks";
import { updateChosenSchool } from "../../../../redux/Student/actions";

function Viewer({
  data,
  onLook,
  onBack,
  onError,
  openModal,
  filePurged,
  chosenSchool,
  modalResponse,
  isManualReview,
  modalResponseData
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPdf, setIsPdf] = useState(false);
  const [isZip, setIsZip] = useState(false);
  const [awardPdf, setAwardPdf] = useState(null);
  const [removeAid, setRemoveAid] = useState(null);
  const [awardImages, setAwardImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [financialAids, setFinancialAids] = useState([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [removeFinancialAids, setRemoveFinancialAids] = useState([]);
  const [onBackConfirmation, setOnBackConfirmation] = useState(false);
  const [showRemoveAidModal, setShowRemoveAidModal] = useState(false);
  const [showLeaveEditModal, setShowLeaveEditModal] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);

  const openImage = () =>
    openModal({
      type: ModalTypes.VIEW_AWARD_LETTER,
      data: awardImages[currentImage].url,
      className:
        "w-40rem tablet:max-h-40rem laptop:max-h-40rem desktop:max-h-40rem"
    });

  const onAdd = () =>
    openModal({
      type: ModalTypes.ADD_FINANCIAL_AID
    });

  const onReupload = () => {
    navigate(
      `/college/add-your-money/${chosenSchool?.school?.ipedsId}/upload-images`
    );
  };

  const removeAidOnClick = () => handlerOnRemoveFinancialAid(removeAid.id);

  const cancelRemoveAidOnClick = () => {
    setRemoveAid(null);
    setShowRemoveAidModal(false);
  };

  const leaveEditOnClick = () => {
    if (onBackConfirmation) onBack();
    else onLook();
  };

  const cancelLeaveEditOnClick = () =>
    setShowLeaveEditModal(!showLeaveEditModal);

  const handlerRemoveFinancialAids = (id) => {
    if (isNaN(Number(id))) return;

    setRemoveFinancialAids((removeFinancialAids) =>
      removeFinancialAids.includes(id)
        ? removeFinancialAids
        : removeFinancialAids.concat([id])
    );
  };

  const handlerOnRemoveFinancialAid = (removeId) => {
    const localRemoveAid = financialAids.find(
      (financialAid) => financialAid.id === removeId
    );
    if (!removeAid) {
      setRemoveAid(localRemoveAid);
      setShowRemoveAidModal(true);
      return;
    }
    if (removeAid) {
      handlerRemoveFinancialAids(removeId);
      setShowRemoveAidModal(false);
      setRemoveAid(null);
    }

    setFinancialAids((financialAids) =>
      financialAids.filter((financialAid) => financialAid.id !== removeId)
    );
  };

  const handlerOnEditFinancialAid = ({ value, editId }) => {
    setFinancialAids((financialAids) => {
      handlerRemoveFinancialAids(editId);
      return financialAids.map((financialAid) => {
        if (financialAid.id === editId)
          return { ...financialAid, added: true, amount: value };
        return financialAid;
      });
    });
  };

  const handlerOnSaveEdit = useSpinner(async () => {
    const addAidAmounts = financialAids
      .filter((financialAid) => financialAid?.added)
      .map((financialAid) => ({
        value: financialAid.amount,
        description: financialAid.name,
        category: financialAid.type.value
      }));

    const mutationData = {
      letterId: chosenSchool.award.id,
      addAidAmounts: addAidAmounts.length > 0 ? addAidAmounts : null,
      removeAidAmounts:
        removeFinancialAids.length > 0 ? removeFinancialAids : null
    };
    const award = await Api.editAwardLetterResults(mutationData);

    const newChosenSchool = {
      ...chosenSchool,
      award
    };
    dispatch(updateChosenSchool(newChosenSchool));
    setFinancialAids([]);
    setRemoveFinancialAids([]);
    onBack();
  });

  const downloadPdf = () => {
    let link = document.createElement("a");
    link.href = selectedFileUrl;
    link.download = `${data.schoolName.split(" ").join("")}_AwardLetter.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const closeModal = useCallback(
    (modalType) => {
      if (modalType) {
        switch (modalType) {
          case ModalTypes.ADD_FINANCIAL_AID:
            if (modalResponseData?.financialAids)
              setFinancialAids((financialAids) => {
                if (financialAids.length > 0)
                  return financialAids.concat(
                    modalResponseData.financialAids.filter(
                      (data) =>
                        !financialAids
                          .map((financialAid) => financialAid.id)
                          .includes(data.id)
                    )
                  );
                return modalResponseData.financialAids;
              });
            break;
          default:
            break;
        }
      }
    },
    [modalResponseData?.financialAids]
  );

  useEffect(() => {
    closeModal(modalResponse);
  }, [closeModal, modalResponse]);

  useEffect(() => {
    const getStudentGrants = (excludedAids) =>
      data.grants
        .filter(
          (aid) =>
            (aid.categorySource === "STUDENT" ||
              aid.categorySource === "ADVISOR") &&
            !excludedAids.includes(aid.id)
        )
        .map((aid) => ({
          edit: true,
          id: aid.id,
          name: aid.name,
          type: aid.type,
          amount: aid.value,
          categorySource: aid.categorySource
        }));

    setFinancialAids((financialAids) => {
      const excludedAids = removeFinancialAids.concat(
        financialAids.map((financialAid) => financialAid.id)
      );
      if (financialAids.length > 0)
        return financialAids.concat(getStudentGrants(excludedAids));
      return getStudentGrants(excludedAids);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.grants]);

  useEffect(() => {
    if (selectedFileUrl !== data.file) {
      if (data.file.includes(".zip")) setIsZip(true);
      else if (data.file.includes(".pdf")) setIsPdf(true);

      setSelectedFileUrl(data.file);
    }
  }, [selectedFileUrl, data.file]);

  useEffect(() => {
    const fetchZip = async () => {
      if (selectedFileUrl) {
        try {
          const response = await fetch(selectedFileUrl);
          const blob = await response.blob();
          if (isZip) unzipFile(blob);
          else if (isPdf) getPdf(blob);
          else getImage(blob);
        } catch (error) {
          setSomethingWentWrong(true);
        }
      }
    };

    const getPdf = (blob) => {
      const url = URL.createObjectURL(
        new Blob([blob], { type: "application/pdf" })
      );
      setAwardPdf(url);
    };

    const getImage = (blob) => {
      const url = URL.createObjectURL(blob);
      const newFile = { id: "AwardLetter", url };
      setAwardImages([newFile]);
    };

    const unzipFile = async (blob) => {
      var zip = new JSZip();

      const zipFile = await zip.loadAsync(blob);
      Object.keys(zipFile.files).forEach(async (filename) => {
        const fileType = getFileType(filename.split(".").slice(-1)[0]);
        const fileContent = await zip.file(filename).async("blob");
        const url = URL.createObjectURL(
          new Blob([fileContent], { type: fileType })
        );
        const newFile = { id: filename, url };
        setAwardImages((state) =>
          !containsFile(newFile, state) ? state.concat([newFile]) : state
        );
      });
    };

    const getFileType = (extension) => {
      switch (extension) {
        case "png":
          return "image/png";
        case "jpeg":
          return "image/jpeg";
        case "jpg":
          return "image/jpg";
        default:
          return "";
      }
    };

    const containsFile = (file, awardImages) => {
      for (var x in awardImages) {
        if (awardImages[x].id === file.id) return true;
      }
      return false;
    };

    fetchZip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFileUrl]);

  const disableButton = useMemo(
    () =>
      removeFinancialAids.length !== 0
        ? false
        : !financialAids.some((financialAid) => financialAid.added),
    [financialAids, removeFinancialAids]
  );

  return (
    <>
      <div className="p-4">
        <Header
          title="Edit Financial Aid"
          onBack={() => {
            if (!disableButton) {
              setShowLeaveEditModal(true);
              setOnBackConfirmation(true);
            } else onBack();
          }}
        />
      </div>
      <div
        className="
          p-8
          flex
          flex-1
          flex-col
          bg-white
          mobile:px-4
          mobile-sm:px-4
          tablet-sm:px-4
        "
      >
        <div
          className="
            mb-4
            flex
            flex-1
            flex-row
            justify-end
            mobile:hidden
            mobile-sm:hidden
            tablet-sm:hidden
          "
        >
          <div
            className="
              w-1/2
              flex
              flex-row
              space-x-6
              tablet:w-2/3
              laptop:w-2/3
            "
          >
            <Buttons
              onAdd={onAdd}
              onError={onError}
              onReupload={onReupload}
              showReuploadButton={isManualReview}
            />
          </div>
        </div>
        <div
          className="
            flex
            flex-1
            flex-row
            mobile:hidden
            mobile-sm:hidden
            tablet-sm:hidden
          "
        >
          <div
            className="
              w-2/3
              pr-10
              mobile:pr-0
              mobile:w-full
              mobile-sm:pr-0
              mobile-sm:w-full
              tablet-sm:pr-0
              tablet-sm:w-full
            "
          >
            <DesktopViewer
              awardPdf={awardPdf}
              awardImages={awardImages}
              currentImage={currentImage}
              filePurged={filePurged}
              setCurrentImage={setCurrentImage}
              somethingWentWrong={somethingWentWrong}
            />
          </div>
          {isManualReview ? (
            <div className="w-1/3 h-1/3 py-2 px-4 bg-alternative-yellow space-y-2">
              <p className="font-bold text-xl">Your letter in under review</p>
              <p>
                One of our team members will analyze your financial aid letter
                as soon as possible. We'll email you when your results are
                ready. If you receive a new letter, you can reupload it using
                the button above.{" "}
              </p>
            </div>
          ) : (
            <div
              className="
              my-4
              w-1/3
              mobile:w-full
              mobile-sm:w-full
              tablet-sm:w-full
            "
            >
              {financialAids.length > 0 || removeFinancialAids.length > 0 ? (
                <div
                  role="button"
                  onClick={() =>
                    !disableButton ? setShowLeaveEditModal(true) : onLook()
                  }
                  className="
                  pl-2
                  mb-4
                  flex
                  w-fit
                  flex-row
                  font-bold
                  space-x-2
                  normal-case
                  items-center
                "
                >
                  <ShortArrow size={15} />
                  <span>Leave Edit</span>
                </div>
              ) : (
                <p className="pl-2 mb-4 font-bold normal-case">
                  What we're seeing:
                </p>
              )}
              <BoxAmounts
                grants={data.grants}
                financialAids={financialAids}
                removeFinancialAids={removeFinancialAids}
                handlerOnEditFinancialAid={handlerOnEditFinancialAid}
                handlerOnRemoveFinancialAid={handlerOnRemoveFinancialAid}
              />
              {(financialAids.length > 0 || removeFinancialAids.length > 0) && (
                <Button
                  disabled={disableButton}
                  onClick={handlerOnSaveEdit}
                  className="
                  mt-6
                  w-full
                  text-white
                  bg-primary-green
                  border-primary-green
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-green
                  active:text-primary-green
                "
                >
                  Save
                </Button>
              )}
              <p className="pl-2 mt-4 font-bold">
                Keep in mind we are only showing free money from the award
                letter. That means we left out any potential loans or work
                study. We also leave out any health insurance grants, as those
                fees are not included in the estimated tuition and fees on
                DecidED.
              </p>
            </div>
          )}
        </div>
        <div
          className="
            hidden
            divide-y-2
            divide-grey
            mobile:block
            mobile-sm:block
            tablet-sm:block
          "
        >
          <MobileViewer
            isPdf={isPdf}
            awardPdf={awardPdf}
            openImage={openImage}
            filePurged={filePurged}
            downloadPdf={downloadPdf}
            awardImages={awardImages}
            currentImage={currentImage}
            setCurrentImage={setCurrentImage}
            somethingWentWrong={somethingWentWrong}
          />
          <div className="pt-8 flex flex-col space-y-6">
            <div className="flex flex-col">
              <BoxAmounts
                grants={data.grants}
                financialAids={financialAids}
                removeFinancialAids={removeFinancialAids}
                handlerOnEditFinancialAid={handlerOnEditFinancialAid}
                handlerOnRemoveFinancialAid={handlerOnRemoveFinancialAid}
              />
            </div>
            {(financialAids.length > 0 || removeFinancialAids.length > 0) && (
              <Button
                disabled={disableButton}
                onClick={handlerOnSaveEdit}
                className="
                  w-full
                  text-white
                  bg-primary-green
                  border-primary-green
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-green
                  active:text-primary-green
                "
              >
                Save
              </Button>
            )}
            <Buttons onAdd={onAdd} onError={onError} />
          </div>
        </div>
      </div>

      {showRemoveAidModal && (
        <SmallModal open={showRemoveAidModal}>
          <div className="flex flex-1 flex-col">
            <p className="text-xl font-semibold text-primary-green">
              Are you sure you want to delete {removeAid?.name}?
            </p>
            <p className="mt-1 text-black font-semibold">
              This action may affect the affordability of this school.
            </p>
            <div className="mt-16 flex flex-1 flex-row space-x-4">
              <Button
                id="cancel-leave-edit-button"
                onClick={cancelRemoveAidOnClick}
                tabIndex={!showRemoveAidModal ? -1 : null}
                className="
                  w-full
                  bg-white
                  text-primary-green
                  border-primary-green
                  hover:text-white
                  active:text-white
                  hover:bg-primary-green
                  active:bg-primary-green
                "
              >
                Cancel
              </Button>
              <Button
                onClick={removeAidOnClick}
                id="confirm-leave-edit-button"
                tabIndex={!showRemoveAidModal ? -1 : null}
                className="
                  w-full
                  text-white
                  bg-primary-green
                  border-primary-green
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-green
                  active:text-primary-green
                "
              >
                Confirm
              </Button>
            </div>
          </div>
        </SmallModal>
      )}

      {showLeaveEditModal && (
        <SmallModal open={showLeaveEditModal}>
          <div className="flex flex-1 flex-col">
            <p className="text-xl font-semibold text-primary-green">
              Are you sure you want to quit without saving your edits?
            </p>
            <p className="mt-1 text-black font-semibold">
              You will lose all the unsaved changes.
            </p>
            <div className="mt-16 flex flex-1 flex-row space-x-4">
              <Button
                id="cancel-leave-edit-button"
                onClick={cancelLeaveEditOnClick}
                tabIndex={!showLeaveEditModal ? -1 : null}
                className="
                  w-full
                  bg-white
                  text-primary-green
                  border-primary-green
                  hover:text-white
                  active:text-white
                  hover:bg-primary-green
                  active:bg-primary-green
                "
              >
                Cancel
              </Button>
              <Button
                onClick={leaveEditOnClick}
                id="confirm-leave-edit-button"
                tabIndex={!showLeaveEditModal ? -1 : null}
                className="
                  w-full
                  text-white
                  bg-primary-green
                  border-primary-green
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-green
                  active:text-primary-green
                "
              >
                Leave
              </Button>
            </div>
          </div>
        </SmallModal>
      )}
    </>
  );
}

Viewer.propTypes = {
  openModal: PropTypes.func,
  filePurged: PropTypes.bool,
  modalResponse: PropTypes.string,
  onLook: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  modalResponseData: PropTypes.shape(),
  chosenSchool: PropTypes.shape().isRequired
};

export default Viewer;
