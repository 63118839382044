import { useRef, useState } from "react";
import PropTypes from "prop-types";

import { FloatBox } from "../../../../../core";
import { ArrowNav, ShortArrow } from "../../../../../core/icons/";
import { useIsDesktopMQL } from "../../../../../core/hooks";

const SortOptions = { ASC: "ASC", DESC: "DESC" };
const SortFields = { NAME: "NAME", PROGRAM_LENGTH: "PROGRAM_LENGTH" };

function SortOption({ children, isSelected, handlerOnClick }) {
  const styleSort = "text-lg font-bold";

  return (
    <div
      role="button"
      id="sort-by-asc-button"
      onClick={handlerOnClick}
      className={`
        flex
        flex-row
        space-x-2
        items-center
        ${isSelected && styleSort}
      `}
    >
      {children}
    </div>
  );
}

SortOption.propTypes = {
  isSelected: PropTypes.bool,
  handlerOnClick: PropTypes.func,
};

function SortByButton({ sortBy, handlerOnSubmitSortBy }) {
  const divRef = useRef(null);
  const isDesktop = useIsDesktopMQL();

  const [showSortBy, setShowSortBy] = useState(false);
  const [localSortBy, setLocalSortBy] = useState(sortBy);

  const toggleSortBy = () => setShowSortBy(!showSortBy);

  const handleOutsideClick = (state) => setShowSortBy(state);

  const handlerOnClickSort = ({ attr, order }) => {
    setLocalSortBy({ attr, order });
    handlerOnSubmitSortBy({ attr, order });
    toggleSortBy();
  };

  return (
    <div ref={divRef}>
      <div
        role="button"
        onClick={toggleSortBy}
        className="px-2 flex flex-row items-center space-x-1 min-w-6rem"
      >
        <span
          className="
            font-medium
            text-primary-green
            mobile:text-sm
            mobile-sm:text-sm
          "
        >
          Sort By
        </span>
        <div
          className={`
            transform
            transition
            ${showSortBy ? "-rotate-90" : "-rotate-180"}
          `}
        >
          <ShortArrow size={isDesktop ? 25 : 20} />
        </div>
      </div>
      <div className="relative">
        <FloatBox
          width={270}
          open={showSortBy}
          containerRef={divRef}
          id="sort-by-colleges-button"
          handleOnClickOutside={handleOutsideClick}
          className="p-2 mt-2 right-2 border shadow-lg bg-white border-black"
        >
          <div className="flex flex-col items-start space-y-2">
            <SortOption
              isSelected={
                localSortBy.attr === SortFields.NAME &&
                localSortBy.order === SortOptions.ASC
              }
              handlerOnClick={() =>
                handlerOnClickSort({
                  attr: SortFields.NAME,
                  order: SortOptions.ASC,
                })
              }
            >
              <span>Sort A</span>
              <div className="transform rotate-180">
                <ArrowNav size={16} />
              </div>
              <span>Z</span>
            </SortOption>
            <SortOption
              isSelected={
                localSortBy.attr === SortFields.NAME &&
                localSortBy.order === SortOptions.DESC
              }
              handlerOnClick={() =>
                handlerOnClickSort({
                  attr: SortFields.NAME,
                  order: SortOptions.DESC,
                })
              }
            >
              <span>Sort Z</span>
              <div className="transform rotate-180">
                <ArrowNav size={16} />
              </div>
              <span>A</span>
            </SortOption>
            <SortOption
              isSelected={
                localSortBy.attr === SortFields.PROGRAM_LENGTH &&
                localSortBy.order === SortOptions.DESC
              }
              handlerOnClick={() =>
                handlerOnClickSort({
                  attr: SortFields.PROGRAM_LENGTH,
                  order: SortOptions.DESC,
                })
              }
            >
              <span>Sort by Two Year</span>
              <div className="transform rotate-180">
                <ArrowNav size={16} />
              </div>
              <span>Four Year</span>
            </SortOption>
            <SortOption
              isSelected={
                localSortBy.attr === SortFields.PROGRAM_LENGTH &&
                localSortBy.order === SortOptions.ASC
              }
              handlerOnClick={() =>
                handlerOnClickSort({
                  attr: SortFields.PROGRAM_LENGTH,
                  order: SortOptions.ASC,
                })
              }
            >
              <span>Sort by Four Year</span>
              <div className="transform rotate-180">
                <ArrowNav size={16} />
              </div>
              <span>Two Year</span>
            </SortOption>
          </div>
        </FloatBox>
      </div>
    </div>
  );
}

SortByButton.propTypes = {
  sortBy: PropTypes.shape(),
  handlerOnSubmitSortBy: PropTypes.func,
};

export { SortOptions, SortFields };
export default SortByButton;
