import { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Content from "./Common/Content";
import Confirm from "./Common/Confirm";
import { LoadingButton } from "../../core/buttons";

import { updateStudentLocal } from "../../redux/Student/actions";

import Api from "../../services/Api";

function LeaveOrg({ org, onClick }) {
  const firstText = (
    <span>
      Are you sure you want to leave{" "}
      <span className="text-black">{org.name}</span>?
    </span>
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = (event) => {
    event.preventDefault();
    setIsLoading(true);
    onClick();
  };

  return (
    <>
      <div className="overflow-auto px-1">
        <Content firstText={firstText} />
      </div>
      <div className="py-5 mt-auto flex flex-row">
        <LoadingButton
          isLoading={isLoading}
          onClick={handleOnClick}
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-green
            border-primary-green
            hover:bg-white
            active:bg-white
            hover:text-primary-green
            active:text-primary-green
          "
        >
          Leave Organization
        </LoadingButton>
      </div>
    </>
  );
}

LeaveOrg.propTypes = {
  org: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired
};

export default function LeaveOrgModal({
  org,
  student,
  onClose,
  setConfirmModal
}) {
  const dispatch = useDispatch();

  const [confirm, setConfirm] = useState(false);
  const firstTextConfirmation = (
    <span>
      No problem! You have left <span className="text-black">"{org.name}"</span>{" "}
      organization.
    </span>
  );

  const leaveOrgOnClick = async () => {
    const studentUpdate = await Api.leaveStudentOrganization({
      organizationIds: [org.organizationId]
    });
    dispatch(
      updateStudentLocal({
        ...studentUpdate,
        chosenSchoolsYearType: student.chosenSchoolsYearType
      })
    );
    setConfirm(true);
    setConfirmModal(true);
  };

  return (
    <div className="flex flex-col h-full px-6 pb-6">
      {!confirm ? (
        <LeaveOrg org={org} onClick={leaveOrgOnClick} />
      ) : (
        <Confirm
          onClick={onClose}
          buttonText="Close"
          firstText={firstTextConfirmation}
        />
      )}
    </div>
  );
}

LeaveOrgModal.propTypes = {
  org: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  student: PropTypes.shape().isRequired
};
