import PropTypes from "prop-types";

import { PlusShape } from "../../icons";

function SearchResultOption({ option, onClick }) {
  return (
    <div
      onClick={onClick}
      className="
        my-2
        flex
        flex-1
        flex-row
        cursor-pointer
        mobile:text-sm
        mobile-sm:text-sm
        tablet-sm:text-sm
      "
    >
      <div className="mr-2">
        <PlusShape size={20} color="fill-primary-green" />
      </div>
      <span className="truncate">{option.name}</span>
    </div>
  );
}

SearchResultOption.propTypes = {
  onClick: PropTypes.func,
  option: PropTypes.shape(),
};

export default SearchResultOption;
