import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { FloatBox } from "../../../../core";
import { Button } from "../../../../core/buttons";
import { CloseMini } from "../../../../core/icons";
import { Select, SimpleText } from "../../../../core/inputs";

import {
  InputTooltipType,
  getContent,
  getComposeBg,
  getComposeText
} from "./utils";
import {
  Ethnicity,
  getTheme,
  getColors,
  ethnicityDisplay
} from "../../../../core/utils";

import { ErrorTypes } from "../../../../services/Api";
import { updateStudent } from "../../../../redux/Student/actions";

function InputTooltipBox({
  open,
  type,
  value = "",
  chosenSchool,
  handlerOnClose
}) {
  const dispatch = useDispatch();

  const { award } = chosenSchool;

  const tooltipContent = getContent(type);
  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability
  });
  const colors = getColors(theme.color);
  const composeBg = getComposeBg(colors.primary.bg);
  const composeText = getComposeText(colors.primary.text);

  const [errors, setErrors] = useState(null);
  const [zipCode, setZipCode] = useState("");
  const [ethnicity, setEthnicity] = useState(null);

  useEffect(() => {
    const zipCodeValue = value || "";
    const ethnicityValue = {
      value,
      label: ethnicityDisplay(value) || "Prefer not to disclose"
    };

    setZipCode(zipCodeValue);
    setEthnicity(ethnicityValue);

    if (type === InputTooltipType.ZIP_CODE) {
      if (zipCodeValue !== "") handlerOnClose();
    } else if (type === InputTooltipType.ETHNICITY) {
      if (ethnicityValue.name !== "Prefer not to disclose") handlerOnClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handlerOnChange = (event) => {
    const value = event.currentTarget.value;
    if (value && !/^[0-9]{5}$/.test(value))
      setErrors({ [type]: "Zip Code must be a 5 digit number." });
    else setErrors(null);
    setZipCode(value);
  };

  const fetchEthnicityOptions = (search, callback) => {
    const options = [
      { value: null, label: "Prefer not to disclose" },
      { value: Ethnicity.AIAN, label: ethnicityDisplay(Ethnicity.AIAN) },
      { value: Ethnicity.ASIAN, label: ethnicityDisplay(Ethnicity.ASIAN) },
      { value: Ethnicity.BLACK, label: ethnicityDisplay(Ethnicity.BLACK) },
      {
        value: Ethnicity.HISPANIC,
        label: ethnicityDisplay(Ethnicity.HISPANIC)
      },
      { value: Ethnicity.NHPI, label: ethnicityDisplay(Ethnicity.NHPI) },
      { value: Ethnicity.WHITE, label: ethnicityDisplay(Ethnicity.WHITE) }
    ];

    callback(() =>
      options.filter((option) =>
        option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  const onChangeSelect = (ethnicity) => setEthnicity(ethnicity);

  const handlerOnSubmit = async () => {
    if (type === InputTooltipType.ZIP_CODE) {
      tooltipContent.gaConfirmCall();
      if (!errors)
        try {
          await dispatch(
            updateStudent({ zip: zipCode ? Number(zipCode) : null })
          );
          if (zipCode) handlerOnClose();
        } catch (exception) {
          if (exception.type === ErrorTypes.graphQLError) {
            const noUSZipCode = exception.errors.find(
              (error) => error.extensions?.code === "ZipCodeNotFound"
            );
            if (noUSZipCode)
              setErrors({ [type]: "Please enter a valid US zip code" });
            else setErrors({ [type]: exception.errors[0].message });
          } else {
            setErrors({
              [type]: "Something went wrong, please try again later!"
            });
          }
        }
    } else if (type === InputTooltipType.ETHNICITY) {
      tooltipContent.gaConfirmCall(value);
      await dispatch(updateStudent({ ethnicity: ethnicity.value }));
      handlerOnClose();
    }
  };

  return (
    <FloatBox
      open={open}
      color={theme.color}
      className={`p-4 ${colors.secondary.bg}`}
    >
      <div className="flex flex-1 flow-row justify-end">
        <div role="button" onClick={() => handlerOnClose()}>
          <CloseMini size={20} />
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        <p className="mb-4 font-bold">{tooltipContent.title}</p>
        <p className="text-sm font-normal">{tooltipContent.description}</p>
        {tooltipContent.inputText && (
          <div className="my-2">
            <SimpleText
              name={type}
              maxLength="5"
              errors={errors}
              value={zipCode}
              color={theme.color}
              onChange={handlerOnChange}
            />
          </div>
        )}
        {tooltipContent.inputSelect && (
          <div className="my-2">
            <Select
              value={ethnicity}
              color={theme.color}
              defaultValue={ethnicity}
              onChange={onChangeSelect}
              loadOptions={fetchEthnicityOptions}
            />
          </div>
        )}
        {errors && (
          <p className="text-sm font-bold text-primary-red">{errors[type]}</p>
        )}
        <div className="mt-2 flex flex-1 flex-row">
          <div className="w-1/2">
            <Button
              onClick={handlerOnSubmit}
              className={`
                py-1
                w-full
                text-sm
                ${composeBg}
                ${colors.primary.border}
              `}
            >
              enter
            </Button>
          </div>
          <div
            role="button"
            onClick={() => handlerOnClose()}
            className={`
              flex
              w-1/2
              underline
              justify-end
              items-center
              ${composeText}
            `}
          >
            <span>No, thanks</span>
          </div>
        </div>
      </div>
    </FloatBox>
  );
}

InputTooltipBox.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.any,
  type: PropTypes.string,
  handlerOnClose: PropTypes.func,
  chosenSchool: PropTypes.shape()
};

export default InputTooltipBox;
