import TagManager from "react-gtm-module";

function SessionStart() {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "session_start_student",
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default SessionStart;
