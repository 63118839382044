import PropTypes from "prop-types";

import ImagesCount from "./ImagesCount";
import ImagesButtonsControl from "./ImagesButtonsControl";

import { Button } from "../../../../core/buttons";
import { PurgedLetter } from "../../../../core/compose";
import { ViewEye, Download } from "../../../../core/icons";

function MobileViewer({
  isPdf,
  awardPdf,
  openImage,
  filePurged,
  awardImages,
  downloadPdf,
  currentImage,
  setCurrentImage,
  somethingWentWrong
}) {
  return (
    <div>
      <div
        className="
          flex
          w-full
          border
          h-25rem
          items-center
          border-black
          bg-secondary-green
        "
      >
        {filePurged ? (
          <PurgedLetter />
        ) : isPdf ? (
          <div className="w-full h-full relative">
            {awardPdf && (
              <>
                <embed src={awardPdf} className="w-full h-full" />
                <div
                  className="
                      top-0
                      left-0
                      bottom-0
                      right-0
                      absolute
                      bg-transparent
                    "
                />
              </>
            )}
            {somethingWentWrong && (
              <div className="flex w-full h-full justify-center items-center">
                <p className="font-bold text-gray-500">Something went wrong!</p>
              </div>
            )}
            <Button
              className="
                  w-12
                  h-12
                  flex
                  right-0
                  absolute
                  bottom-0
                  border-0
                  items-center
                  justify-center
                  bg-secondary-green
                "
              disabled={somethingWentWrong}
              onClick={() => downloadPdf()}
            >
              <div>
                <Download size={30} />
              </div>
            </Button>
          </div>
        ) : (
          <div className="w-full h-full relative">
            <div
              className="w-full h-full bg-cover bg-no-repeat"
              style={{
                backgroundImage: `url(${awardImages[currentImage]?.url})`
              }}
            >
              {somethingWentWrong && (
                <div className="flex h-full justify-center items-center">
                  <p className="font-bold text-gray-500">
                    Something went wrong!
                  </p>
                </div>
              )}
            </div>
            {awardImages.length > 1 && (
              <ImagesCount
                currentImage={currentImage + 1}
                imagesCount={awardImages.length}
              />
            )}
            <Button
              className="
                  w-12
                  h-12
                  flex
                  right-0
                  absolute
                  bottom-0
                  border-0
                  items-center
                  justify-center
                  bg-secondary-green
                "
              disabled={somethingWentWrong}
              onClick={() => openImage()}
            >
              <div>
                <ViewEye size={30} />
              </div>
            </Button>
          </div>
        )}
      </div>
      {awardImages.length > 1 && (
        <div className="pb-4">
          <ImagesButtonsControl
            disablePrev={currentImage === 0}
            disableNext={currentImage === awardImages.length - 1}
            imagesCount={awardImages.length}
            setCurrentImage={setCurrentImage}
          />
        </div>
      )}
    </div>
  );
}

MobileViewer.propTypes = {
  isPdf: PropTypes.bool,
  openImage: PropTypes.func,
  filePurged: PropTypes.bool,
  awardPdf: PropTypes.string,
  downloadPdf: PropTypes.func,
  awardImages: PropTypes.array,
  currentImage: PropTypes.number,
  setCurrentImage: PropTypes.func,
  somethingWentWrong: PropTypes.bool
};

export default MobileViewer;
