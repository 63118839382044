import Types from "./types";

import Api from "../../services/Api";

function setIsLoggedIn(isLoggedIn) {
  return {
    type: Types.SET_IS_LOGGED_IN,
    isLoggedIn,
  };
}

function setIsLoading(isLoading) {
  return {
    type: Types.SET_IS_LOADING,
    isLoading,
  };
}

function setStudent(student) {
  return {
    type: Types.SET_STUDENT,
    student,
  };
}

function setError(error) {
  return {
    type: Types.SET_ERROR,
    error,
  };
}

function setGuides(guides) {
  return {
    type: Types.SET_GUIDES,
    guides,
  };
}

function setSchemaTypes(schemaTypes) {
  return {
    type: Types.SET_SCHEMA_TYPES,
    schemaTypes,
  };
}

function addChosenSchool(chosenSchool) {
  return {
    type: Types.ADD_CHOSEN_SCHOOL,
    chosenSchool,
  };
}

function removeChosenSchool({ pairedId }) {
  return {
    type: Types.REMOVE_CHOSEN_SCHOOL,
    pairedId,
  };
}

function updateChosenSchools({ sort, isFavorite, chosenSchool, newSortOrder }) {
  return {
    type: Types.UPDATE_CHOSEN_SCHOOLS,
    newSort: { sort, isFavorite, chosenSchool, newSortOrder },
  };
}

function addChoosenSchoolMajor({ major, pairedId }) {
  return {
    type: Types.ADD_CHOSEN_SCHOOL_MAJOR,
    pairedId,
    major,
  };
}

function removeChoosenSchoolMajor({ major, pairedId }) {
  return {
    type: Types.REMOVE_CHOSEN_SCHOOL_MAJOR,
    pairedId,
    major,
  };
}

export function updateChosenSchool(chosenSchool) {
  return {
    type: Types.UPDATE_CHOSEN_SCHOOL,
    chosenSchool,
  };
}

export function updateChosenSchoolYearType({ chosenSchoolsYearType }) {
  return {
    type: Types.UPDATE_CHOSEN_SCHOOL_YEAR_TYPE,
    chosenSchoolsYearType,
  };
}

export function resetChosenSchools() {
  return {
    type: Types.RESET_CHOSEN_SCHOOLS,
  };
}

export function fetchGuides() {
  return async (dispatch) => {
    try {
      dispatch(setError(null));

      const { guides, __schema } = await Api.fetchGuides();

      dispatch(setGuides(guides));
      dispatch(setSchemaTypes(__schema?.types));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function fetchStudent({ refetch = false }) {
  return async (dispatch, getState) => {
    try {
      dispatch(setError(null));
      dispatch(setIsLoading(true));

      const student = await Api.fetchStudent({ refetch });

      dispatch(setIsLoggedIn(true));
      dispatch(
        setStudent({
          ...student,
          chosenSchoolsYearType:
            getState()?.student?.student?.chosenSchoolsYearType,
        }),
      );
    } catch (error) {
      dispatch(setError(error));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function updateStudentFlags({ onboardingFirstSchoolTooltip }) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setIsLoading(true));

    try {
      await Api.updateStudentFlags({
        onboardingFirstSchoolTooltip,
      });
    } catch (error) {
      dispatch(setError(error));
      return error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function updateStudent({
  gpa,
  zip,
  email,
  actScore,
  satScore,
  lastName,
  firstName,
  gradeYear,
  ethnicity,
  phoneNumber,
  emailReceive,
  satScoreGuess,
  onboardingSignUp,
  onboardingLetter,
  onboardingSearch,
  emailNotifications,
  onboardingChosenSchool,
  onboardingFirstSchoolTooltip,
}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setError(null));
      dispatch(setIsLoading(true));

      const student = await Api.updateStudent({
        gpa,
        zip,
        email,
        actScore,
        satScore,
        lastName,
        firstName,
        gradeYear,
        ethnicity,
        phoneNumber,
        emailReceive,
        satScoreGuess,
        onboardingSignUp,
        onboardingLetter,
        onboardingSearch,
        emailNotifications,
        onboardingChosenSchool,
        onboardingFirstSchoolTooltip,
      });

      dispatch(
        setStudent({
          ...student,
          chosenSchoolsYearType:
            getState()?.student?.student?.chosenSchoolsYearType,
        }),
      );
    } catch (error) {
      dispatch(setError(error));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function updateStudentLocal(student) {
  return (dispatch) => {
    dispatch(setStudent(student));
  };
}

export function joinChosenSchool(chosenSchool) {
  return (dispatch) => {
    dispatch(addChosenSchool(chosenSchool));
  };
}

export function deleteChosenSchool({ pairedId }) {
  return async (dispatch) => {
    try {
      await Api.deleteChosenSchool({ pairedId });
      dispatch(removeChosenSchool({ pairedId }));
    } catch (error) {
      dispatch(setError(error));
      throw error;
    }
  };
}

export function sortOrderChosenSchools({
  sort,
  isFavorite,
  chosenSchool,
  newSortOrder,
}) {
  return async (dispatch) => {
    try {
      const pairedId = chosenSchool.pairedId;
      const livingChoice = chosenSchool.livingChoice;
      await Api.updateChosenSchool({
        pairedId,
        isFavorite,
        livingChoice,
        sortOrder: newSortOrder,
      });
      dispatch(
        updateChosenSchools({
          sort,
          isFavorite,
          chosenSchool,
          newSortOrder,
        }),
      );
    } catch (error) {
      dispatch(setError(error));
      throw error;
    }
  };
}

export function updateEnrollmentStatus({ chosenSchool, enrollmentStatus }) {
  return async (dispatch) => {
    try {
      const pairedId = chosenSchool.pairedId;
      const livingChoice = chosenSchool.livingChoice;
      const chosenSchoolUpdated = await Api.updateChosenSchool({
        pairedId,
        livingChoice,
        enrollmentStatus,
      });
      dispatch(updateChosenSchool(chosenSchoolUpdated));
    } catch (error) {
      dispatch(setError(error));
      throw error;
    }
  };
}

export function addMajor({ major, pairedId }) {
  return (dispatch) => {
    try {
      Api.selectMajor({ major, pairedId });
      dispatch(addChoosenSchoolMajor({ major, pairedId }));
    } catch (error) {
      dispatch(setError(error));
      throw error;
    }
  };
}

export function removeMajor({ major, pairedId }) {
  return (dispatch) => {
    try {
      Api.deleteSelectedMajor({ major, pairedId });
      dispatch(removeChoosenSchoolMajor({ major, pairedId }));
    } catch (error) {
      dispatch(setError(error));
      throw error;
    }
  };
}
