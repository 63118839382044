import TagManager from "react-gtm-module";

function AffordabilityBoxGuide({ affordability_state, ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "affordability_box_guide",
      ipeds: ipeds,
      affordability_state: affordability_state,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default AffordabilityBoxGuide;
