import PropTypes from "prop-types";

import { PlusShape } from "../../icons";

function SearchResults({ colors, colleges, onSelect }) {
  const getComposeBg = (bg) => {
    switch (bg) {
      case "bg-secondary-red":
        return "hover:bg-secondary-red";
      case "bg-secondary-blue":
        return "hover:bg-secondary-blue";
      case "bg-secondary-green":
        return "hover:bg-secondary-green";
      case "bg-secondary-orange":
        return "hover:bg-secondary-orange";

      default:
        return "hover:bg-light-gray";
    }
  };

  const composeBg = getComposeBg(colors.secondary.bg);

  return (
    <div
      className="
        p-4
        left-2
        border
        top-100
        right-2
        absolute
        border-t-0
        bg-white
        max-h-10rem
        text-black
        shadow-black
        border-black
        overflow-scroll
      "
    >
      {colleges.map((college) => (
        <div
          role="button"
          key={college?.ipedsId}
          onClick={() => onSelect({ schoolId: college.ipedsId })}
          className={`py-1 flex flex-1 flex-row overflow-hidden ${composeBg}`}
        >
          <div className="mr-2">
            <PlusShape size={23} />
          </div>
          <span className="truncate">{college.name}</span>
        </div>
      ))}
      {colleges.length === 0 && (
        <div className="py-1 flex flex-1 flex-row justify-center">
          <span className="text-center">
            Your search produced no matching items. Please edit and try again.
          </span>
        </div>
      )}
    </div>
  );
}

SearchResults.propTypes = {
  onSelect: PropTypes.func,
  colors: PropTypes.shape(),
  colleges: PropTypes.arrayOf(PropTypes.any),
};

export default SearchResults;
