import PropTypes from "prop-types";

function Email({
  size = 25,
  fillColor = "fill-white",
  strokeColor = "stroke-black"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 65 86"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.570144"
        y="0.417721"
        width="25.4708"
        height="17.738"
        className={strokeColor}
        transform="matrix(0.988237 -0.152931 0.152051 0.988373 22.7103 39.0471)"
      />
      <path
        className={strokeColor}
        d="M23.4396 39.9043L37.0953 46.7449L48.3167 36.0545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeMiterlimit="10"
        className={strokeColor}
        d="M20.5109 1H55.3514V59.7051H20.5109V1ZM20.9702 1.47074V59.2344H54.8921V1.47074H20.9702Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeMiterlimit="10"
        className={strokeColor}
        d="M20.5106 29.9208C20.5107 29.9209 20.5107 29.9209 20.3496 30.0896C20.1885 30.2583 20.1885 30.2583 20.1885 30.2583L20.1877 30.2575L20.1848 30.2544L20.1732 30.242C20.1629 30.231 20.1474 30.2145 20.127 30.193C20.0862 30.15 20.0257 30.0867 19.9477 30.0064C19.7916 29.8457 19.5654 29.617 19.2857 29.3456C18.7258 28.8024 17.9532 28.0901 17.1004 27.4107C16.2459 26.73 15.319 26.089 14.4497 25.6824C13.5704 25.271 12.8 25.1231 12.2289 25.3475C12.2079 25.3557 12.1642 25.3849 12.1059 25.4757C12.0487 25.5646 11.9892 25.6946 11.9302 25.871C11.8122 26.2234 11.7082 26.724 11.6191 27.3599C11.4413 28.6292 11.3286 30.395 11.2671 32.4956C11.144 36.6943 11.226 42.2 11.3903 47.6783C11.5546 53.1554 11.8011 58.6008 12.0065 62.6772C12.1092 64.7153 12.2016 66.411 12.2684 67.5971C12.3017 68.1902 12.3287 68.6558 12.3473 68.9732C12.3566 69.1318 12.3638 69.2534 12.3687 69.3353L12.3761 69.4589C12.3761 69.4592 12.3761 69.4594 12.1504 69.4748C11.9246 69.4903 11.9246 69.4901 11.9245 69.4898L11.9171 69.3658C11.9122 69.2837 11.905 69.1619 11.8956 69.003C11.877 68.6853 11.85 68.2193 11.8166 67.6258C11.7498 66.4388 11.6574 64.7421 11.5546 62.7029C11.3491 58.6245 11.1025 53.1754 10.938 47.6936C10.7736 42.2128 10.6913 36.6949 10.8148 32.4806C10.8765 30.3747 10.9898 28.5866 11.1715 27.2892C11.2622 26.6417 11.3714 26.1056 11.5038 25.71C11.57 25.5123 11.645 25.3404 11.7321 25.2048C11.8181 25.071 11.9284 24.9531 12.0719 24.8968C12.8156 24.6046 13.7276 24.8194 14.6321 25.2425C15.5468 25.6704 16.5053 26.3359 17.3719 27.0262C18.2401 27.7179 19.0243 28.4411 19.5909 28.9908C19.8744 29.2658 20.1038 29.4978 20.2626 29.6613C20.342 29.743 20.4038 29.8076 20.4458 29.852C20.4669 29.8741 20.483 29.8912 20.4939 29.9029L20.5063 29.9162L20.5095 29.9196L20.5104 29.9206L20.5106 29.9208Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeMiterlimit="10"
        className={strokeColor}
        d="M59.1223 25.1722C56.6796 25.1722 54.7542 26.5761 54.7542 28.2566C54.7542 29.937 56.6796 31.3409 59.1223 31.3409C61.5651 31.3409 63.4905 29.937 63.4905 28.2566C63.4905 26.5761 61.5651 25.1722 59.1223 25.1722ZM54.2447 28.2566C54.2447 26.3037 56.4588 24.7622 59.1223 24.7622C61.7859 24.7622 64 26.3037 64 28.2566C64 30.2094 61.7859 31.7509 59.1223 31.7509C56.4588 31.7509 54.2447 30.2094 54.2447 28.2566Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeMiterlimit="10"
        className={strokeColor}
        d="M59.1223 32.242C56.6796 32.242 54.7542 33.9266 54.7542 35.9432C54.7542 37.9598 56.6796 39.6445 59.1223 39.6445C61.5651 39.6445 63.4905 37.9598 63.4905 35.9432C63.4905 33.9266 61.5651 32.242 59.1223 32.242ZM54.2447 35.9432C54.2447 33.5998 56.4588 31.75 59.1223 31.75C61.7859 31.75 64 33.5998 64 35.9432C64 38.2866 61.7859 40.1364 59.1223 40.1364C56.4588 40.1364 54.2447 38.2866 54.2447 35.9432Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeMiterlimit="10"
        className={strokeColor}
        d="M59.1223 40.6292C56.6796 40.6292 54.7542 42.3138 54.7542 44.3304C54.7542 46.347 56.6796 48.0317 59.1223 48.0317C61.5651 48.0317 63.4905 46.347 63.4905 44.3304C63.4905 42.3138 61.5651 40.6292 59.1223 40.6292ZM54.2447 44.3304C54.2447 41.987 56.4588 40.1372 59.1223 40.1372C61.7859 40.1372 64 41.987 64 44.3304C64 46.6738 61.7859 48.5237 59.1223 48.5237C56.4588 48.5237 54.2447 46.6738 54.2447 44.3304Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeMiterlimit="10"
        className={strokeColor}
        d="M59.1223 48.9334C56.6796 48.9334 54.7542 50.3373 54.7542 52.0178C54.7542 53.6983 56.6796 55.1022 59.1223 55.1022C61.5651 55.1022 63.4905 53.6983 63.4905 52.0178C63.4905 50.3373 61.5651 48.9334 59.1223 48.9334ZM54.2447 52.0178C54.2447 50.065 56.4588 48.5234 59.1223 48.5234C61.7859 48.5234 64 50.065 64 52.0178C64 53.9706 61.7859 55.5121 59.1223 55.5121C56.4588 55.5121 54.2447 53.9706 54.2447 52.0178Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeMiterlimit="10"
        className={strokeColor}
        d="M37.2341 76.1719L52.2072 59.7065L52.5639 60.0141L37.5907 76.4794L37.2341 76.1719Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeMiterlimit="10"
        className={strokeColor}
        d="M8.65212 68.0923L40.0213 78.002L36.5613 84.8652L36.1518 84.6457L39.3561 78.2897L8.89605 68.6671L1.41588 84.6935L1 84.4871L8.65212 68.0923Z"
      />
      <rect
        x="40.1444"
        y="7.54346"
        width="9.73462"
        height="9.76638"
        className={`${fillColor} ${strokeColor}`}
      />
    </svg>
  );
}

Email.propTypes = {
  size: PropTypes.number,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string
};

export default Email;
