import PropTypes from "prop-types";

function FilterType({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 22 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        strokeMiterlimit="10"
        d="M11.0044 4.65918L14.6699 7.5341L18.3366 10.6704H11.0044H3.66992L7.33659 7.53256L11.0044 4.65918Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M10.9648 4.92042V2.8877"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M15.1519 1H10.9648V3.06557H15.1519V1Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M20.3178 23.7718V10.7334H1.68555V23.7718"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M5.38782 12.751H3.34668V15.1335H5.38782V12.751Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M5.38782 16.7344H3.34668V19.1169H5.38782V16.7344Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M9.83704 12.751H7.7959V15.1335H9.83704V12.751Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M9.83704 16.7344H7.7959V19.1169H9.83704V16.7344Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M13.9562 12.751H11.915V15.1335H13.9562V12.751Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M13.9562 16.7344H11.915V19.1169H13.9562V16.7344Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M18.0822 12.751H16.041V15.1335H18.0822V12.751Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M18.0822 16.7344H16.041V19.1169H18.0822V16.7344Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M13.2907 20.8633H9.62402V23.9996H13.2907V20.8633Z"
      />
      <path className={color} d="M0 24H22" strokeMiterlimit="10" />
    </svg>
  );
}

FilterType.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default FilterType;
