import PropTypes from "prop-types";

import { CloseMini } from "../icons";

function CategoryPill({
  name,
  iconSrc,
  quantity,
  handlerOnOpenDropdown,
  handlerOnClearFilters
}) {
  const handlerOnClearFilter = () => handlerOnClearFilters(name);

  return (
    <div
      className="
        m-1
        py-1
        px-3
        flex
        w-9rem
        flex-row
        items-center
        cursor-pointer
        bg-primary-green
        mobile:w-7.5rem
        mobile-sm:w-7.5rem
      "
    >
      <div
        onClick={() => handlerOnOpenDropdown(name)}
        className="
          flex
          flex-row
          items-center
          justify-center
          space-x-2
          w-full
          text-sm
          capitalize
          font-medium
          text-white
          mobile:text-xs
          mobile-sm:text-xs
        "
      >
        {iconSrc ? (
          <img className="h-5 invert" src={iconSrc} alt={name} />
        ) : (
          <>
            <span>{name}</span>
            <span className="text-xs">
              {quantity ? ` (${quantity.length})` : ""}
            </span>
          </>
        )}
      </div>
      <div role="button" className="ml-2 w-4" onClick={handlerOnClearFilter}>
        <CloseMini size={17} />
      </div>
    </div>
  );
}

CategoryPill.propTypes = {
  name: PropTypes.string,
  iconSrc: PropTypes.string,
  quantity: PropTypes.array,
  handlerOnOpenDropdown: PropTypes.func,
  handlerOnClearFilters: PropTypes.func
};

export default CategoryPill;
