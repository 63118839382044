import PropTypes from "prop-types";

import { Checkbox } from "../../inputs";
import { Colors } from "../../utils";

function CheckboxSelector({ data }) {
  return (
    <div className="px-1 py-3 flex flex-col">
      {data.map((option) => (
        <Checkbox
          key={option.id}
          label={option.label}
          color={Colors.GREEN}
          value={option.active}
          onChange={option.handler}
          defaultChecked={option.active}
          id={`filter-checkboxes-${option.id}`}
        />
      ))}
    </div>
  );
}

CheckboxSelector.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CheckboxSelector;
