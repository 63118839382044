import PropTypes from "prop-types";

import CollegeSearch from "./CollegeSearch";
import bannerSearch from "../../../../assets/images/search-banner.png";

import { Tooltip } from "../../../../core";
import { IconButton } from "../../../../core/buttons";
import {
  FilterType,
  FilterMajor,
  FilterLocation
} from "../../../../core/icons";

function SearchDashboard({
  chosenSchools,
  typeModalHandler,
  majorModalHandler,
  locationModalHandler,
  showOnboardingTooltip,
  handlerOnSubmitFilters,
  markOnboardingSearchDone
}) {
  
  return (
    <>
      <div
        className={`
          flex
          h-full
          flex-1
          flex-col
          tablet:mt-32
        `}
      >
        <div
          className="
            flex
            py-12
            px-20
            h-1/3
            flex-col
            text-white
            justify-center
            bg-primary-green
            mobile:px-6
            mobile:h-1/2
            tablet:px-6
            tablet:h-1/2
            mobile-sm:px-6
            mobile-sm:h-1/2
            tablet-sm:px-6
            tablet-sm:h-1/2
          "
        >
          <div id="school-search">
            <p className="text-3xl font-extrabold text-white">School Search</p>
            <span
              className="
              text-lg
              font-bold
              mobile:text-base
              mobile-sm:text-base
              tablet-sm:text-base
            "
            >
              Have a school in mind? Search below:
            </span>
          </div>
          <div className="w-full my-8">
            <CollegeSearch
              onSubmit={handlerOnSubmitFilters}
              collegesSelected={chosenSchools.map(
                (school) => school.school.ipedsId
              )}
            />
          </div>
        </div>
        <div
          className="
            p-12
            flex
            h-1/3
            flex-col
            bg-white
            justify-center
            mobile:px-6
            mobile:h-1/2
            tablet:px-6
            tablet:h-1/2
            mobile-sm:px-6
            mobile-sm:h-1/2
            tablet-sm:px-6
            tablet-sm:h-1/2
          "
        >
          <div className="flex flex-col text-center">
            <div
              className="
                flex
                w-1/2
                relative
                bottom-48
                justify-center
                place-self-end
                mobile:w-full
                mobile-sm:w-full
                tablet-sm:w-full
              "
            >
              <Tooltip
                tooltipCloseButton={true}
                open={showOnboardingTooltip}
                tooltipHandleOnClose={markOnboardingSearchDone}
                text="First step is to add a school! You can either search by school name or by the factor that is most important to you!"
              />
            </div>
            <p
              className="
                text-3xl
                font-extrabold
                mobile-sm:hidden
                tablet-sm:hidden
              "
            >
              Not Sure Where to Start?
            </p>
            <p
              className="
                my-4
                text-lg
                font-bold
                mobile:mt-10
                mobile-sm:mt-10
                tablet-sm:mt-10
                mobile:text-2xl
                mobile-sm:text-2xl
                tablet-sm:text-2xl
              "
            >
              Start your list with what is most important to you
            </p>
          </div>
          <div
            className="
              mt-4
              flex
              desktop:flex-row
              mobile:flex-col
              space-x-2
              mobile:space-y-4
              mobile:space-x-0
              items-center
              justify-center
            "
          >
            <IconButton
              label="Location"
              onClick={locationModalHandler}
              className="
                w-36
                h-36
                bg-primary-green
                border-primary-green
                text-white
                hover:text-primary-green
                active:text-primary-green
                hover:bg-white
                active:bg-white
                hover:border-black
                active:border-black
                mobile:w-44
                mobile:h-44
                mobile-sm:w-44
                mobile-sm:h-44
              "
              icon={<FilterLocation color="fill-current" size={60} />}
            />
            <IconButton
              label="Major"
              onClick={majorModalHandler}
              className="
              w-36
              h-36
              bg-primary-green
              border-primary-green
              text-white
              hover:text-primary-green
              active:text-primary-green
              hover:bg-white
              active:bg-white
              hover:border-black
              active:border-black
              mobile:w-44
              mobile:h-44
              mobile-sm:w-44
              mobile-sm:h-44
              "
              icon={<FilterMajor color="fill-current" size={60} />}
            />
            <IconButton
              label="Type"
              onClick={typeModalHandler}
              className="
              w-36
              h-36
              bg-primary-green
              border-primary-green
              text-white
              hover:text-primary-green
              active:text-primary-green
              hover:bg-white
              active:bg-white
              hover:border-black
              active:border-black
              mobile:w-44
              mobile:h-44
              mobile-sm:w-44
              mobile-sm:h-44
              "
              icon={<FilterType color="stroke-current" size={60} />}
            />
          </div>
        </div>
        <div
          className="
            h-1/3
            flex
            flex-row
            mobile:hidden
            mobile-sm:hidden
            tablet-sm:hidden
          "
        >
          <img
            src={bannerSearch}
            alt="Banner search"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </>
  );
}

SearchDashboard.propTypes = {
  typeModalHandler: PropTypes.func,
  majorModalHandler: PropTypes.func,
  locationModalHandler: PropTypes.func,
  showOnboardingTooltip: PropTypes.bool,
  handlerOnSubmitFilters: PropTypes.func,
  handlerOnFinishOnboarding: PropTypes.func,
  chosenSchools: PropTypes.arrayOf(PropTypes.shape())
};

export default SearchDashboard;
