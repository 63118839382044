import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { LinkCustom } from "../../../../core";
import { Button } from "../../../../core/buttons";
import {
  Enrollment,
  CollegeType,
  decidedRoute,
  getEnrollmentIcon,
} from "../../../../core/utils";

import { saveState, Items } from "../../../../redux/storePersist";

function Infographic({ student, isDesktop }) {
  const navigate = useNavigate();

  const getAdvancedProgress = useCallback(() => {
    let progress = "";
    const firstChosenSchool = student.chosenSchools[0] || {};
    const chosenSchoolsYearType =
      student?.chosenSchoolsYearType ||
      firstChosenSchool?.school?.programLength ||
      CollegeType.FOUR_YEARS;

    student.chosenSchools.forEach((school) => {
      if (school.school.programLength === chosenSchoolsYearType) {
        if (school.enrollmentStatus > progress)
          progress = school.enrollmentStatus;
      }
    });

    return { progress, chosenSchoolsYearType };
  }, [student]);

  const getInfographicContent = () => {
    const Container = ({ left, right, children }) => {
      return (
        <div
          id="infographic-container"
          className={`
            flex
            w-1/2
            flex-col
            items-center
            justify-center
            ${left ? "pl-3 pr-6" : ""}
            ${right ? "pl-8 pr-5" : ""}
          `}
        >
          {children}
        </div>
      );
    };
    const { progress, chosenSchoolsYearType } = getAdvancedProgress();

    const handlerOnClickInterestedAppliedAcceptd = () => {
      if (isDesktop) {
        navigate("/college/compare");
        saveState({ showUploadLetterModal: true }, Items.APP_STATE);
      } else {
        const school = student.chosenSchools.find(
          (school) => school.school.programLength === chosenSchoolsYearType,
        );
        navigate(`/college/id/${school.school.ipedsId}`);
      }
    };

    const handlerOnClickUploadedLetter = () => {
      if (isDesktop) navigate("/college/compare");
      else {
        const school = student.chosenSchools
          .filter(
            (school) => school.school.programLength === chosenSchoolsYearType,
          )
          .find((school) =>
            [
              Enrollment.ENROLLMENT_1_INTERESTED,
              Enrollment.ENROLLMENT_2_APPLIED,
              Enrollment.ENROLLMENT_3_ACCEPTED,
            ].includes(school.enrollmentStatus),
          );

        if (school) navigate(`/college/id/${school.school.ipedsId}`);
        else {
          const school = student.chosenSchools.find(
            (school) => school.school.programLength === chosenSchoolsYearType,
          );
          navigate(`/college/id/${school.school.ipedsId}`);
        }
      }
    };

    switch (progress) {
      case Enrollment.ENROLLMENT_1_INTERESTED:
      case Enrollment.ENROLLMENT_2_APPLIED:
      case Enrollment.ENROLLMENT_3_ACCEPTED:
        return (
          <>
            <Container left={true}>
              <div
                className="
                  text-8xl
                  font-bold
                  text-primary-green
                  tablet:text-6xl
                  mobile:text-6xl
                  mobile-sm:text-6xl
                "
              >
                69%
              </div>
              <div
                className="
                  mt-4
                  font-bold
                  tablet:text-sm
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                of students list cost as their biggest concern about college.
              </div>
            </Container>
            <Container right={true}>
              <div
                className="
                  font-bold
                  tablet:text-sm
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                Upload your financial aid award letters to get personalized
                affordability info!
              </div>
              <Button
                onClick={handlerOnClickInterestedAppliedAcceptd}
                className="
                  mt-4
                  w-full
                  text-white
                  bg-primary-green
                  border-primary-green
                  hover:bg-white
                  hover:text-primary-green
                  tablet:text-sm
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                <span className="font-bold normal-case">Upload a Letter</span>
              </Button>
            </Container>
          </>
        );
      case Enrollment.ENROLLMENT_4_UPLOADED_LETTER:
        return (
          <>
            <Container left={true}>
              <div
                className="
                  text-8xl
                  font-bold
                  text-primary-green
                  tablet:text-6xl
                  mobile:text-6xl
                  mobile-sm:text-6xl
                "
              >
                42%
              </div>
              <div
                className="
                  mt-4
                  font-bold
                  tablet:text-sm
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                of students who don't finish their degree say cost is the main
                reason.
              </div>
            </Container>
            <Container right={true}>
              <div
                className="
                  font-bold
                  tablet:text-sm
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                Compare your financial aid letters in DecidED to make a choice
                that's affordable for you.
              </div>
              <Button
                onClick={handlerOnClickUploadedLetter}
                className="
                  mt-4
                  w-full
                  text-white
                  bg-primary-green
                  border-primary-green
                  hover:bg-white
                  hover:text-primary-green
                  tablet:text-sm
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                <span className="font-bold normal-case">Compare Schools</span>
              </Button>
            </Container>
          </>
        );
      case Enrollment.ENROLLMENT_5_ENROLLED:
        return (
          <>
            <Container left={true}>
              {getEnrollmentIcon({
                size: 100,
                enrollment: progress,
                color: "fill-primary-green",
              })}
              <div
                className="
                  mt-4
                  font-bold
                  tablet:text-sm
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                You did it! You've made a decision and enrolled in college!
              </div>
            </Container>
            <Container right={true}>
              <div
                className="
                  font-bold
                  tablet:text-sm
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                Take a moment to celebrate, then look at our guides for the next
                steps on budgeting and paying for college.
              </div>
              <LinkCustom
                target="_blank"
                id="header-button-help"
                href={decidedRoute("/guides")}
                className="
                  mt-4
                  py-2
                  px-5
                  w-full
                  border
                  text-center
                  text-white
                  font-extrabold
                  bg-primary-green
                  border-primary-green
                  hover:bg-white
                  hover:text-primary-green
                  tablet:text-sm
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                <span className="font-bold normal-case">Read our Guides</span>
              </LinkCustom>
            </Container>
          </>
        );

      default:
        return (
          <>
            <Container left={true}>
              <div
                className="
                  text-7xl
                  font-bold
                  text-primary-green
                  tablet:text-5xl
                  mobile:text-5xl
                  mobile-sm:text-5xl
                "
              >
                2,785
              </div>
              <div
                className="
                  mt-4
                  font-bold
                  tablet:text-sm
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                colleges and universities in DecidED - find your affordable fit!
              </div>
            </Container>
            <Container right={true}>
              <div
                className="
                  font-bold
                  tablet:text-sm
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                Comparing schools side by side helps you see what you can afford
                and other fit factors. Start adding to your list!
              </div>
              <Button
                onClick={() => navigate("/college/search")}
                className="
                  mt-4
                  w-full
                  text-white
                  bg-primary-green
                  border-primary-green
                  hover:bg-white
                  hover:text-primary-green
                  tablet:text-sm
                  mobile:text-sm
                  mobile-sm:text-sm
                "
              >
                <span className="font-bold">Search Schools</span>
              </Button>
            </Container>
          </>
        );
    }
  };

  return (
    <div className="w-full h-full bg-white">
      <div className="p-4 flex h-full flex-1 flex-col">
        <div className="flex flex-1 flex-row divide-x divide-primary-green">
          {getInfographicContent()}
        </div>
      </div>
    </div>
  );
}

Infographic.propTypes = {
  isDesktop: PropTypes.bool,
  student: PropTypes.shape(),
};

export default Infographic;
