import PropTypes from "prop-types";

import { useIsDesktopMQL } from "../../hooks";
import { Button } from "../../buttons";

function ButtonSelector({ data }) {
  const isDesktop = useIsDesktopMQL();

  return (
    <div className="px-1 py-3 flex flex-1 flex-row flex-wrap">
      {data.map((option) => (
        <Button
          key={option.id}
          onClick={option.handler}
          id={`filter-buttons-${option.id}`}
          className={`
            my-1
            mx-2
            border
            text-sm
            rounded-lg
            font-semibold
            mobile:text-xs
            mobile-sm:text-xs
            tablet-sm:text-xs
            focus:outline-none
            border-primary-green
            ${
              isDesktop
                ? option.active
                  ? "text-white bg-primary-green hover:border-white"
                  : "bg-white text-primary-green hover:text-white hover:bg-primary-green"
                : option.active
                ? "text-white bg-primary-green"
                : "bg-white text-primary-green"
            }
          `}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
}

ButtonSelector.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ButtonSelector;
