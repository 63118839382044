import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Layout from "../../Common/Layout";
import LayoutCollege from "../LayoutCollege";
import CollegeYearButtons from "../Common/CollegeYearButtons";

import MyPathBody from "./MyPathBody";
import MyPathHeader from "./MyPathHeader";

import Api from "../../../services/Api";
import {
  useStudent,
  useGetShowUploadTutorial,
} from "../../../redux/Student/hooks";
import { ModalTypes } from "../../Common/utils";
import { GetIpedsId, useSpinner } from "../../Common/Hooks";
import { PageViewDataLayer } from "../../../utils/DataLayers";
import { CollegeType } from "../../../core/utils";

import { updateChosenSchoolYearType } from "../../../redux/Student/actions";

const getCollegesData = (colleges, status) => {
  const localColleges = colleges || true;

  return localColleges?.length > 0
    ? localColleges.map((college) => ({
        ...college,
        enrollmentStatusLabel: status.find(
          (status) => status.id === college.enrollmentStatus,
        )?.name,
      }))
    : localColleges;
};

function MyPath({ student, openModal, modalResponse }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ipedsId = GetIpedsId();
  const { schemaTypes } = useStudent();
  const showUploadTutorial = useGetShowUploadTutorial();

  const [status, setStatus] = useState([]);
  const [doFetch, setDoFetch] = useState(false);
  const [colleges, setColleges] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [isLoading, setIsLoanding] = useState(true);
  const [allColleges, setAllColleges] = useState(null);
  const [getDistances, setGetDistances] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filters, setFilters] = useState({ name: "", status: [] });
  const [chosenSchoolsYearType, setChosenSchoolsYearType] = useState("");

  useEffect(() => {
    if (student) {
      PageViewDataLayer({
        ipeds: ipedsId,
        screenName: "My Path",
        userId: student.userId,
        organizationId: student.organizations.map((org) => org.organizationId),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchApi = useSpinner(async ({ filter }) => {
    try {
      return await Api.fetchStudentChosenSchools({
        filter,
        orderBy: { enrollmentStatus: "DESC" },
      });
    } catch (error) {
      console.log(error);
    }

    return [];
  });

  const getChosenSchoolList = useCallback(() => {
    if (allColleges) {
      if (typeof allColleges === "boolean") return [];

      const getSchoolsYearType = (firstSchool) => {
        const firstChosenSchool = allColleges[0] || {};

        return firstSchool
          ? firstChosenSchool?.programLength || CollegeType.FOUR_YEARS
          : student?.chosenSchoolsYearType ||
              firstChosenSchool?.programLength ||
              CollegeType.FOUR_YEARS;
      };

      const getCollegesFiltered = (firstSchool) => {
        const chosenSchoolsYearType = getSchoolsYearType(firstSchool);
        setChosenSchoolsYearType(chosenSchoolsYearType);
        dispatch(updateChosenSchoolYearType({ chosenSchoolsYearType }));

        return allColleges.filter(
          (college) => college.programLength === chosenSchoolsYearType,
        );
      };

      let collegesFiltered = getCollegesFiltered();
      if (collegesFiltered.length === 0)
        collegesFiltered = getCollegesFiltered(true);

      const favorite =
        collegesFiltered.find((college) => college.isFavorite) || null;

      if (favorite) {
        collegesFiltered = collegesFiltered.filter(
          (college) => !college.isFavorite,
        );
      }

      if (!firstLoad) setFirstLoad(true);
      return favorite ? [favorite, ...collegesFiltered] : collegesFiltered;
    }

    return null;
  }, [dispatch, firstLoad, allColleges, student?.chosenSchoolsYearType]);

  useEffect(() => {
    if (colleges?.length > 0) {
      setGetDistances(false);
      setColleges((colleges) =>
        colleges.map((college) => {
          const newCollege = student.chosenSchools.find(
            (school) => school.school.ipedsId === college.ipedsId,
          );

          return {
            ...college,
            isNew: newCollege.isNew,
            sortOrder: newCollege.sortOrder,
            isFavorite: newCollege.isFavorite,
            enrollmentStatus: newCollege.enrollmentStatus,
            affordability:
              newCollege?.award?.postAwardSchoolCosts?.affordability || null,
            awardStatus: newCollege?.award?.status,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student.chosenSchools]);

  useEffect(() => {
    if (!student.chosenSchoolsYearType) {
      const firstChosenSchool = student.chosenSchools[0] || {};
      const chosenSchoolsYearType =
        firstChosenSchool?.school?.programLength || CollegeType.FOUR_YEARS;
      dispatch(updateChosenSchoolYearType({ chosenSchoolsYearType }));
    } else setDoFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student.chosenSchoolsYearType]);

  useEffect(() => {
    const fetchStatus = async () => {
      const enrollmentStatus =
        schemaTypes?.find((type) => type.name === "_EnrollmentStatus")
          ?.enumValues || [];
      setStatus(
        enrollmentStatus.map((status) => ({
          id: status.name,
          name: status.description,
        })),
      );
    };

    fetchStatus();
  }, [schemaTypes]);

  useEffect(() => {
    const fetchColleges = async () => {
      setIsLoanding(true);

      const allColleges = await fetchApi({
        filter: { schoolName: filters.name, enrollmentStatus: filters.status },
      });

      const allCollegesProcessed = allColleges.map((college) => ({
        ...college.school,
        isNew: college.isNew,
        sortOrder: college.sortOrder,
        isFavorite: college.isFavorite,
        enrollmentStatus: college.enrollmentStatus,
        graduationRate: `${Math.round(college.schoolCosts.graduationRate)}%`,
        affordability:
          college?.award?.postAwardSchoolCosts?.affordability || null,
      }));

      setAllColleges(getCollegesData(allCollegesProcessed, status));
      setIsLoanding(false);
      setDoFetch(true);
    };

    if (filters.name !== "" || filters.status.length > 0)
      setFiltersApplied(true);
    else setFiltersApplied(false);

    if (status.length > 0) fetchColleges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, filters]);

  useEffect(() => {
    if (doFetch) {
      setGetDistances(true);
      setColleges(getChosenSchoolList());
      setDoFetch(false);
    }
  }, [doFetch, getChosenSchoolList]);

  useEffect(() => {
    if (!student.onboardingSignUp) openModal({ type: ModalTypes.ALL_SET });
    if (!student.gradeYear)
      openModal({
        type: ModalTypes.GRADE_YEAR,
        data: { student },
      });
  }, [
    navigate,
    openModal,
    student,
    student.gradeYear,
    student.onboardingSignUp,
  ]);

  const handlerOnSubmitFilters = ({ type, value }) => {
    setColleges(null);
    if (type === "name")
      setFilters((filtersStatus) => ({ ...filtersStatus, [type]: value }));
    else {
      setFilters((filtersStatus) =>
        filtersStatus[type].includes(value)
          ? {
              ...filtersStatus,
              [type]: filtersStatus[type].filter((status) => status !== value),
            }
          : { ...filtersStatus, [type]: [...filtersStatus[type], value] },
      );
    }
  };

  return (
    <Layout
      student={student}
      openModal={openModal}
      modalResponse={modalResponse}
    >
      <LayoutCollege>
        <div
          id="my-path-page"
          className="
            min-h-screen-footer-md
            mobile:min-h-screen-footer
            mobile-sm:min-h-screen-footer
            tablet-sm:min-h-screen-footer
          "
        >
          <div className="pr-4 flex flex-row justify-end">
            <CollegeYearButtons />
          </div>
          <MyPathHeader
            status={status}
            filters={filters}
            student={student}
            firstLoad={firstLoad}
            collegesCount={colleges?.length}
            handlerOnSubmitFilters={handlerOnSubmitFilters}
          />
          <MyPathBody
            student={student}
            colleges={colleges}
            isLoading={isLoading}
            openModal={openModal}
            getDistances={getDistances}
            filtersApplied={filtersApplied}
            showUploadTutorial={showUploadTutorial}
            id={`colleges-${chosenSchoolsYearType
              .split("_")
              .join("-")
              .toLowerCase()}-container`}
          />
        </div>
      </LayoutCollege>
    </Layout>
  );
}

MyPath.propTypes = {
  openModal: PropTypes.func,
  student: PropTypes.shape(),
  modalResponse: PropTypes.string,
};

export default MyPath;
