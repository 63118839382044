import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import CollegeDisplay from "./CollegeDisplay";
import AffordabilityInfo from "./AffordabilityInfo";
import AffordabilityChart from "./AffordabilityChart";
import Api from "../../../../services/Api";

import { Panel } from "../../../../core/compose";
import { getTheme } from "../../../../core/utils";

import { ReuploadAwardAffordabilityBox } from "../../../../utils/DataLayers";
import { fetchStudent } from "../../../../redux/Student/actions";

function AffordabilityBox({
  student,
  infoCard,
  isDesktop,
  openModal,
  chosenSchool,
  showUploadTutorial
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!chosenSchool) return null;
  const { onboardingAutomaticAidEstimation } = student;
  const { award, school, enrollmentStatus } = chosenSchool || {};
  const affordability = award?.postAwardSchoolCosts?.affordability;
  const theme = getTheme({ affordability });

  const onClickViewAwardLetter = ({ isManualReview }) => {
    navigate(
      `/college/id/${school?.ipedsId}/view-award-letter`,
      isManualReview ? isManualReview : false
    );
  };

  const onClickReuploadAwardLetter = () => {
    ReuploadAwardAffordabilityBox({
      affordability_state: affordability,
      ipeds: school?.ipedsId
    });
    navigate(
      `/college/add-your-money/${school?.ipedsId}${
        !showUploadTutorial ? "/upload-images" : ""
      }`
    );
  };

  const onClickOnboardingTooltip = async () => {
    if (!onboardingAutomaticAidEstimation) {
      await Api.updateStudentFlags({
        onboardingAutomaticAidEstimation: true
      });
      dispatch(fetchStudent({}));
    } else return null;
  };

  const addYourMoneyPath = `/college/add-your-money/${school?.ipedsId}${
    !showUploadTutorial ? "/upload-images" : ""
  }`;

  return (
    <>
      {!infoCard ? (
        <Panel
          className="
            tablet:flex
            tablet:flex-1
            tablet:flex-row
            laptop:flex
            laptop:flex-1
            laptop:flex-row
            desktop:flex
            desktop:flex-1
            desktop:flex-row
          "
        >
          <>
            <div className="flex flex-1 flex-col">
              {isDesktop && (
                <div
                  className="
                    py-4
                    mt-4
                    mx-4
                    shadow-lg
                    bg-white
                    mobile:px-4
                    mobile-sm:px-4
                    tablet-sm:px-4
                  "
                >
                  <CollegeDisplay
                    school={school}
                    color={theme.color}
                    openModal={openModal}
                    chosenSchool={chosenSchool}
                    enrollmentStatus={enrollmentStatus}
                    addYourMoneyPath={addYourMoneyPath}
                  />
                </div>
              )}
              <div
                className="
                  flex
                  flex-1
                  flex-col
                  tablet:flex-row
                  laptop:flex-row
                  desktop:flex-row
                "
              >
                <div
                  className="
                    w-1/2
                    flex
                    flex-1
                    flex-col
                    mobile:w-full
                    mobile-sm:w-full
                    tablet-sm:w-full
                  "
                >
                  <AffordabilityChart
                    theme={theme}
                    student={student}
                    isDesktop={isDesktop}
                    openModal={openModal}
                    chosenSchool={chosenSchool}
                    addYourMoneyPath={addYourMoneyPath}
                    onClickViewAwardLetter={onClickViewAwardLetter}
                    onClickOnboardingTooltip={onClickOnboardingTooltip}
                    onClickReuploadAwardLetter={onClickReuploadAwardLetter}
                  />
                </div>
                <div
                  className="
                    w-1/2
                    flex
                    flex-1
                    flex-col
                    mobile:hidden
                    mobile-sm:hidden
                    tablet-sm:hidden
                  "
                >
                  <AffordabilityInfo
                    theme={theme}
                    show={isDesktop}
                    chosenSchool={chosenSchool}
                    addYourMoneyPath={addYourMoneyPath}
                  />
                </div>
              </div>
            </div>
          </>
        </Panel>
      ) : (
        <Panel
          className="
            py-4
            mt-4
            mx-4
            shadow-lg
            bg-white
            mobile:px-4
            mobile-sm:px-4
            tablet-sm:px-4
            tablet:flex
            tablet:flex-1
            tablet:flex-row
            laptop:flex
            laptop:flex-1
            laptop:flex-row
            desktop:flex
            desktop:flex-1
            desktop:flex-row
          "
        >
          <AffordabilityInfo
            show={true}
            theme={theme}
            infoCard={infoCard}
            chosenSchool={chosenSchool}
            addYourMoneyPath={addYourMoneyPath}
          />
        </Panel>
      )}
    </>
  );
}

export default AffordabilityBox;
