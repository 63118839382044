import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";

import { Button } from "../../../core/buttons";
import { PillList } from "../../../core/compose";
import { SearchTextModal } from "../../../core/inputs";

import { Colors } from "../../../core/utils";
import { useIsDesktopMQL } from "../../../core/hooks";

import { useMajorSearch } from "../../Common/Hooks";

function getDataMajors(data, selected) {
  if (!data) return null;

  return data
    .filter((item) => !selected.includes(item.code))
    .map((item) => ({
      id: item.code,
      name: item.name,
    }));
}

function FilterByMajor({ isModal, filters, onSubmit }) {
  const divHeightRef = useRef(null);
  const isDesktop = useIsDesktopMQL();

  const [searchMajor, setSearchMajor] = useState("");
  const [localFilters, setLocalFilters] = useState([]);

  const majorSearch = useMajorSearch({ name: searchMajor });

  useEffect(() => setLocalFilters(filters), [filters]);

  const handlerOnClear = () => {
    setSearchMajor("");
    setLocalFilters([]);
  };

  const handlerOnRemove = (item) => {
    setLocalFilters((stateLocalFilters) =>
      stateLocalFilters.filter((filter) => filter.val !== item.val)
    );
  };

  const handlerOnSelect = (major) => {
    setSearchMajor("");
    setLocalFilters((stateLocalFilters) => [
      ...stateLocalFilters,
      { val: major.id, label: major.name },
    ]);
  };

  const handlerOnSearchDebounce = useMemo(
    () => debounce(async (search) => setSearchMajor(search), 500),
    []
  );

  const handlerOnSearch = useCallback(handlerOnSearchDebounce, [
    handlerOnSearchDebounce,
  ]);

  const calculateHeightPills = () => {
    const refHieght = Math.ceil(
      (divHeightRef.current?.clientHeight || 0) * 0.4
    );
    const dinamicHeight =
      Math.ceil(localFilters.length / (isDesktop ? 3 : 2)) * 40;

    return refHieght > dinamicHeight ? dinamicHeight : refHieght;
  };

  return (
    <div
      ref={divHeightRef}
      className="
        pb-2
        flex
        flex-1
        flex-col
        space-y-5
        mobile:pb-0
        mobile-sm:pb-0
        tablet-sm:pb-0
        overflow-hidden
        mobile:space-y-1
        mobile-sm:space-y-1
        tablet-sm:space-y-1
      "
    >
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="flex flex-row justify-between">
          <p
            className="
              font-bold
              mobile:text-sm
              mobile-sm:text-sm
              tablet-sm:text-sm
            "
          >
            Search for majors you would like to add
          </p>
          <p
            onClick={handlerOnClear}
            className="
              text-sm
              font-bold
              cursor-pointer
              text-primary-green
            "
          >
            Clear
          </p>
        </div>
        <div
          className="pt-1 overflow-auto"
          style={{
            height: `${calculateHeightPills()}px`,
            minHeight: `${calculateHeightPills()}px`,
          }}
        >
          <PillList
            data={localFilters}
            color={Colors.GREEN}
            onRemovePill={handlerOnRemove}
          />
        </div>
        <div className="pt-2 overflow-hidden">
          <SearchTextModal
            value={searchMajor}
            placeholder="Majors"
            searchEndpoint={handlerOnSearch}
            onSelectResult={handlerOnSelect}
            isLoading={majorSearch.isLoading}
            searchResults={getDataMajors(
              majorSearch.result,
              localFilters.map((filter) => filter.val)
            )}
          />
        </div>
      </div>
      <div
        className={`
          flex
          flex-row
          ${isModal ? "items-end justify-end" : "justify-center"}
        `}
      >
        <Button
          onClick={() => {
            const filters =
              localFilters.length > 0 ? { selected: localFilters } : {};
            onSubmit({ type: "major", filters });
          }}
          className={`
            py-2
            text-md
            text-white
            bg-primary-green
            border-primary-green
            hover:bg-white
            active:bg-white
            hover:text-primary-green 
            active:text-primary-green
            mobile:text-sm
            mobile-sm:text-sm
            ${
              isModal
                ? "w-1/3 mobile:w-1/2 mobile-sm:w-1/2"
                : "w-1/2 mobile:w-full mobile-sm:w-full"
            }
          `}
        >
          {isModal ? "continue" : "show updated results"}
        </Button>
      </div>
    </div>
  );
}

FilterByMajor.propTypes = {
  isModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.shape()),
};

export default FilterByMajor;
