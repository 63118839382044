import { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";

import CollegeGuide from "../../Common/CollegeGuide";

import { ShortArrow } from "../../../../core/icons";

import { Enrollment, CollegeType } from "../../../../core/utils";

import { useStudent } from "../../../../redux/Student/hooks";

function Guides({ title, student, titleClassName }) {
  const scroll = useRef(null);

  const { guides } = useStudent();

  const [localGuides, setLocalGuides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getAdvancedProgress = useCallback(() => {
    let progress = "";
    const firstChosenSchool = student.chosenSchools[0] || {};
    const chosenSchoolsYearType =
      student?.chosenSchoolsYearType ||
      firstChosenSchool?.school?.programLength ||
      CollegeType.FOUR_YEARS;

    student.chosenSchools.forEach((school) => {
      if (school.school.programLength === chosenSchoolsYearType) {
        if (school.enrollmentStatus > progress)
          progress = school.enrollmentStatus;
      }
    });

    return progress;
  }, [student]);

  useEffect(() => {
    if (guides) {
      const progress =
        getAdvancedProgress() || Enrollment.ENROLLMENT_1_INTERESTED;
      const localGuides = guides.filter((guide) =>
        guide.enrollmentStatuses.includes(progress)
      );
      setLocalGuides(
        localGuides
          .map((localGuide) => ({
            url: localGuide.url,
            image: localGuide.image,
            color: localGuide.colorTheme,
            category: localGuide.category,
            title: <h2>{localGuide.title}</h2>,
            children: <h3>{localGuide.subtitle}</h3>
          }))
          .slice(0, 6)
      );
    }
  }, [guides, getAdvancedProgress]);

  useEffect(() => {
    if (scroll.current) {
      const scrollOffset = currentIndex * 256;
      scroll.current.scrollTo({ left: scrollOffset, behavior: "smooth" });
    }
  }, [currentIndex]);

  const handlerNext = () =>
    setCurrentIndex((index) => {
      return index < localGuides.length - 1 ? index + 1 : index;
    });

  const handlerPrevious = () =>
    setCurrentIndex((index) => {
      return index !== 0 ? index - 1 : index;
    });

  const handlerNavigateTo = (index) => setCurrentIndex(index);

  if (localGuides.length === 0) return null;

  return (
    <div className="w-full bg-white">
      <div className="p-4 flex flex-1 flex-col">
        <div
          id="guides-container"
          className="flex flex-1 flex-row items-center"
        >
          <p className={`font-semibold ${titleClassName}`}>{title}</p>
        </div>
        <div className="flex flex-1 flex-row">
          <div
            tabIndex="0"
            role="button"
            onClick={handlerPrevious}
            className="flex items-center justify-center outline-none"
          >
            <ShortArrow size={20} />
          </div>
          <div
            ref={scroll}
            className="p-1 flex flex-1 flex-row overflow-x-scroll"
          >
            {localGuides.map((guide, index) => (
              <CollegeGuide
                key={index}
                guide={guide}
                hasDescription={true}
                childrenClassName="text-xs"
                categoryClassName="py-2 text-xs"
                titleClassName="
                  my-1
                  text-base
                  font-extrabold
                "
                className="
                  mx-2
                  flex
                  flex-1
                  flex-col
                  min-w-13rem
                "
              />
            ))}
          </div>
          <div
            tabIndex="0"
            role="button"
            onClick={handlerNext}
            className="
              flex
              transform
              rotate-180
              items-center
              outline-none
              justify-center
            "
          >
            <ShortArrow size={20} />
          </div>
        </div>
        <div
          id="guides-content"
          className="mt-2 flex flex-1 flex-row items-center justify-center"
        >
          {localGuides.map((_, index) => (
            <div
              key={index}
              role="button"
              onClick={() => handlerNavigateTo(index)}
              className={`
                w-4
                h-4
                mx-2
                rounded-full
                ${
                  index === currentIndex
                    ? "bg-primary-green"
                    : "bg-secondary-green"
                }
              `}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}

Guides.propTypes = {
  title: PropTypes.string,
  student: PropTypes.shape(),
  titleClassName: PropTypes.string
};

export default Guides;
