import PropTypes from "prop-types";

import { Loader } from "../../";
import { Close } from "../../icons";

function GroupsListModal({ data, onClick, isLoading }) {
  return (
    <>
      {data.map((item, index) => (
        <div key={item.id}>
          <div
            className={`
              py-1
              px-5
              flex
              flex-1
              flex-row
              mobile:px-2
              mobile-sm:px-2
              tablet-sm:px-3
              ${index % 2 === 0 && "bg-light-gray"}
            `}
          >
            <span className="text-sm truncate">
              <span className="font-semibold">{item.name}</span>
              {" - "}
              <span className="text-xs font-semibold text-gray-500">
                {item.code}
              </span>
            </span>
            <div
              role="button"
              onClick={() => onClick(item)}
              className="ml-auto place-self-center"
            >
              <Close size={16} />
            </div>
          </div>
        </div>
      ))}
      {data.length === 0 && !isLoading && (
        <div
          className="
            flex
            flex-1
            text-sm
            flex-col
            text-center
            font-semibold
          "
        >
          <p className="my-auto">Search a group to add in the search bar</p>
        </div>
      )}
      {isLoading && (
        <div className="py-8 mobile:py-4 mobile-sm:py-4 tablet-sm:py-4">
          <Loader show={isLoading} />
        </div>
      )}
    </>
  );
}

GroupsListModal.propTypes = {
  data: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default GroupsListModal;
