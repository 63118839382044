import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { FloatBox } from "../../../../core";
import { Colors, getEnrollmentIcon } from "../../../../core/utils";

function FilterResults({
  open,
  toggle,
  status,
  filters,
  containerRef,
  handleOnSubmit,
}) {
  const [localStatus, setLocalStatus] = useState([]);

  useEffect(() => {
    const statusFilter = filters?.status || [];
    setLocalStatus(
      status.map((status) => {
        if (statusFilter.includes(status.id))
          return { ...status, active: true };

        return status;
      }),
    );
  }, [status, filters]);

  if (!open) return null;

  const handleOutsideClick = () => {
    toggle();
  };

  const handlerOnSubmit = (value) => {
    handleOnSubmit(value);
    handleOutsideClick();
  };

  return (
    <FloatBox
      open={open}
      width={250}
      color={Colors.GREEN}
      containerRef={containerRef}
      handleOnClickOutside={handleOutsideClick}
      className="-mt-1 top-100 bg-white"
    >
      {localStatus.map((status) => (
        <div
          role="button"
          key={status.id}
          id={`my-path-filter-${status.name}-button`}
          onClick={!status.disabled ? () => handlerOnSubmit(status.id) : null}
          className={`
            flex
            flex-1
            flex-row
            py-2
            px-3
            space-x-2
            items-center
            text-primary-green
            hover:bg-secondary-green
            ${status.active && "text-alternative-green"}
            ${status.disabled && "opacity-40 cursor-not-allowed"}
          `}
        >
          <div className="w-5">
            {getEnrollmentIcon({
              size: 20,
              color: "fill-current",
              enrollment: status.id,
            })}
          </div>
          <div className="text-sm font-bold capitalize">{status.name}</div>
        </div>
      ))}
    </FloatBox>
  );
}

FilterResults.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  filters: PropTypes.shape(),
  containerRef: PropTypes.any,
  handleOnSubmit: PropTypes.func,
  status: PropTypes.arrayOf(PropTypes.shape()),
};

export default FilterResults;
