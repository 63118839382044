import PropTypes from "prop-types";

import Header, { SimpleHeader } from "./Header";
import Footer from "./Footer";

function Layout({
  student,
  children,
  openModal,
  logoWhite,
  showHeader = true,
  showFooter = true,
  headerShadow,
  modalResponse,
  headerBGColor
}) {
  return (
    <main>
      {showHeader && (
        <>
          {student ? (
            <Header
              student={student}
              openModal={openModal}
              logoWhite={logoWhite}
              headerShadow={headerShadow}
              headerBGColor={headerBGColor}
              modalResponse={modalResponse}
            />
          ) : (
            <SimpleHeader logoWhite={logoWhite} headerBGColor={headerBGColor} />
          )}
        </>
      )}
      {children}
      {showFooter && <Footer />}
    </main>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
  logoWhite: PropTypes.bool,
  openModal: PropTypes.func,
  student: PropTypes.shape(),
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  headerShadow: PropTypes.bool,
  headerBGColor: PropTypes.string,
  modalResponse: PropTypes.string
};

export default Layout;
