import { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { Form } from "../../../core";
import { Button } from "../../../core/buttons";
import { Colors, retryTimeout } from "../../../core/utils";

import { useSpinner } from "../../Common/Hooks";
import { ErrorReport, errorReportDisplay } from "./utils";

function ReportErrorForm({ onSubmit }) {
  const formID = "report-an-error-form";

  const [somethingWentWrong, setSomethingWentWrong] = useState(false);

  const onSubmitWithSpinner = retryTimeout(
    useSpinner(async (form) => {
      setSomethingWentWrong(false);
      try {
        await onSubmit(form);
      } catch (error) {
        setSomethingWentWrong(true);
      }
    })
  );

  const { handleSubmit, formState, control } = useForm({
    mode: "onChange",
    defaultValues: { error: null, description: "" }
  });

  const fetchErrors = (search, callback) => {
    const options = [
      {
        value: ErrorReport.VALUE_ERROR,
        label: errorReportDisplay(ErrorReport.VALUE_ERROR)
      },
      {
        value: ErrorReport.COST_ERROR,
        label: errorReportDisplay(ErrorReport.COST_ERROR)
      },
      {
        value: ErrorReport.MISSING_AWARD,
        label: errorReportDisplay(ErrorReport.MISSING_AWARD)
      },
      {
        value: ErrorReport.SOMETHING_ELSE,
        label: errorReportDisplay(ErrorReport.SOMETHING_ELSE)
      }
    ];

    callback(() =>
      options.filter((option) =>
        option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  return (
    <Form id={formID} onSubmit={handleSubmit(onSubmitWithSpinner)}>
      <Form.Select
        name="error"
        label="Error"
        required={true}
        control={control}
        color={Colors.GREEN}
        loadOptions={fetchErrors}
        id="error-select-input"
        rules={{
          required: {
            value: true,
            message: "This field is required."
          }
        }}
      />
      <Form.ErrorMessage errors={formState.errors} name="error" />

      <br />

      <Form.Textarea
        rows={15}
        required={true}
        control={control}
        name="description"
        color={Colors.GREEN}
        id="error-textarea-input"
        errors={formState.errors}
        label="fill us in some more about what's happening..."
        rules={{
          required: {
            value: true,
            message: "Description is required."
          }
        }}
      />
      <Form.ErrorMessage errors={formState.errors} name="description" />

      <div className="mt-1">
        {somethingWentWrong && (
          <p className="text-sm font-semibold text-primary-red">
            Something went wrong, please try again later!
          </p>
        )}
      </div>

      <Button
        type="submit"
        form={formID}
        value="submit"
        id="error-send-report-button"
        disabled={!formState.isValid}
        className="
          mt-6
          text-white
          bg-primary-green
          border-primary-green
          hover:bg-white
          active:bg-white
          hover:text-primary-green
          active:text-primary-green
        "
      >
        send error report
      </Button>
    </Form>
  );
}

ReportErrorForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default ReportErrorForm;
