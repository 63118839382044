import PropTypes from "prop-types";

function CollegeCosts({ size = 25, color = "fill-black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.254 25.0019L30.3872 18.4553L32.9193 17.5201L19.9924 11.7751L7.06543 16.7185L12.1296 19.1233L11.7298 26.4715L19.1928 26.2043L27.0556 27.4067L27.3221 19.6577L28.1217 19.3905L27.9884 25.0019H30.254ZM19.9924 14.18L26.9223 17.2529L19.7258 19.9249L13.0625 16.8521L19.9924 14.18ZM24.9233 24.6011L19.4593 23.7994L14.2619 23.933L14.5284 20.0585L19.5926 22.4634L25.0565 20.4594L24.9233 24.6011Z"
        fill={color}
      />
      <path
        d="M31.7199 9.63745C31.0536 9.63745 30.787 9.50385 30.787 8.70223H28.5215C28.5215 10.3055 29.3211 11.3743 30.5205 11.7751V13.2447H32.786V11.9087C33.9855 11.5079 34.7851 10.5727 34.7851 9.23664C34.7851 6.83178 32.9193 6.29737 31.8532 5.89656C30.787 5.49575 30.787 5.36215 30.787 5.09494C30.787 4.69413 30.9203 4.29332 31.7199 4.29332C31.9864 4.29332 32.5195 4.29332 32.5195 5.09494H33.7189H34.9183C34.9183 4.02612 34.3853 2.69008 32.9193 2.15567V0.686035H30.6538V2.15567C29.4544 2.55648 28.6548 3.62531 28.6548 4.96134C28.6548 6.96539 30.254 7.6334 31.3201 7.90061C32.5195 8.30142 32.6528 8.43502 32.6528 9.10304C32.5195 9.37025 32.5195 9.63745 31.7199 9.63745Z"
        fill={color}
      />
      <path
        d="M6.13254 27.9412C6.39907 27.9412 6.93214 27.9412 6.93214 28.7428H8.13155H9.33096C9.33096 27.674 8.79789 26.338 7.33194 25.8036V24.4675H5.0664V25.9372C3.86699 26.338 3.06738 27.4068 3.06738 28.7428C3.06738 30.7469 4.66659 31.4149 5.73273 31.6821C6.93214 32.0829 7.06541 32.2165 7.06541 32.8845C7.06541 33.1517 7.06541 33.4189 6.2658 33.4189C5.59947 33.4189 5.33293 33.2853 5.33293 32.4837H3.06738C3.06738 34.087 3.86699 35.1558 5.0664 35.5566V37.0262H7.33194V35.6902C8.53135 35.2894 9.33096 34.3542 9.33096 33.0181C9.33096 30.6133 7.46521 30.0789 6.39907 29.6781C5.33293 29.2772 5.33293 29.1436 5.33293 28.8764C5.19966 28.342 5.33293 27.9412 6.13254 27.9412Z"
        fill={color}
      />
      <path
        d="M3.33377 23.7995C2.93397 22.4635 2.8007 20.9938 2.8007 19.5242C2.8007 10.3056 10.2637 2.6902 19.5924 2.6902C21.858 2.6902 23.9902 3.09101 25.9893 4.02623L26.9221 1.88858C24.5233 0.953358 22.1245 0.418945 19.5924 0.418945C9.06428 0.418945 0.535156 8.96955 0.535156 19.5242C0.535156 21.1274 0.801691 22.7307 1.20149 24.3339L3.33377 23.7995Z"
        fill={color}
      />
      <path
        d="M35.3175 13.5121C36.1171 15.3825 36.3836 17.5202 36.3836 19.5242C36.3836 28.7428 28.9207 36.3582 19.5919 36.3582C16.7933 36.3582 13.9947 35.6902 11.4626 34.2206L10.3965 36.2246C13.1951 37.8279 16.3935 38.6295 19.5919 38.6295C30.1201 38.6295 38.6492 30.0789 38.6492 19.5242C38.6492 17.1194 38.2494 14.8481 37.4498 12.7104L35.3175 13.5121Z"
        fill={color}
      />
    </svg>
  );
}

CollegeCosts.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default CollegeCosts;
