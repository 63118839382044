import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import PropTypes from "prop-types";

import NotFound from "../NotFound";

import { Items, loadState, saveState } from "../../redux/storePersist";

import { SessionStart } from "../../utils/DataLayers";

function CustomSwitch({ children }) {
  useEffect(() => {
    const { date, firstCall, isLoggedIn, validationTime } = loadState(
      Items.GTM_STATE
    );

    const currentDate = new Date();
    const loadedDate = date ? new Date(date) : new Date();

    const invalidDate = Math.abs(currentDate - loadedDate) > validationTime;

    if (!firstCall || (invalidDate && !isLoggedIn)) {
      SessionStart();
      saveState(
        {
          firstCall: true,
          isLoggedIn: false,
          validationTime: 5 * 60 * 1000,
          date: new Date().toISOString()
        },
        Items.GTM_STATE
      );
    }
  });

  return (
    <Routes>
      {children}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

CustomSwitch.propTypes = {
  children: PropTypes.any
};

export default CustomSwitch;
