import PropTypes from "prop-types";

import Item from "./Item";
import Category from "./Category";

function BoxAmounts({ grants, totalAward }) {
  return (
    <>
      <div className="bg-white border border-black">
        <div className="divide-y divide-black">
          <Category name="Grants & Scholarships" />
          {grants.map((aid) => (
            <div key={`${aid.id}-${aid.name}`}>
              <Item name={aid.name} value={aid.value} />
            </div>
          ))}
        </div>
      </div>
      <div
        className="
          flex
          pt-4
          pl-4
          pr-10
          flex-1
          flex-row
          text-2xl
          font-bold
          mobile:pr-5
          mobile:text-xl
          mobile-sm:pr-5
          mobile-sm:text-xl
          tablet-sm:pr-5
          tablet-sm:text-xl
        "
      >
        <p className="w-full capitalize">grand total:</p>
        <p className="justify-end">
          {`$${(totalAward || 0).toLocaleString("en-US")}`}
        </p>
      </div>
    </>
  );
}

BoxAmounts.propTypes = {
  grants: PropTypes.array,
  totalAward: PropTypes.number,
};

export default BoxAmounts;
