import { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { Form } from "../../core";
import { Button } from "../../core/buttons";

import { useSpinner } from "../Common/Hooks";

import {
  Colors,
  Ethnicity,
  retryTimeout,
  ethnicityDisplay,
  GradeYearTypes,
  getGradeYearLabel
} from "../../core/utils";

export function validateSATACT(value) {
  const reg = new RegExp("^[0-9]+$");
  if (value) {
    if (reg.test(value)) {
      if (value < 0 || (value > 36 && value < 400) || value > 1600) {
        return "ACT must be between 0-36. SAT must be between 400 - 1600.";
      }
    } else return "ACT/SAT must be a number.";
  }
  return true;
}

export function isAct(value) {
  return value >= 0 && value <= 36;
}

function ProfileInfoForm({
  id,
  isOpen,
  defaultValues = {},
  onSubmit = () => {}
}) {
  const zip = defaultValues.zip
    ? String(Number(defaultValues.zip)).padStart(5, "0")
    : "";

  const ethnicity = {
    value: defaultValues.ethnicity,
    label: ethnicityDisplay(defaultValues.ethnicity) || "Prefer not to disclose"
  };

  const gradeYear = {
    value: defaultValues.gradeYear,
    label: getGradeYearLabel(defaultValues.gradeYear) || ""
  };

  const {
    reset,
    setError,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      gradeYear,
      zip,
      ethnicity,
      gpa:
        defaultValues.gpa !== null && defaultValues.gpa >= 0
          ? `${defaultValues.gpa}`
          : "",
      satact:
        defaultValues.satActScore !== null && defaultValues.satActScore >= 0
          ? `${defaultValues.satActScore}`
          : ""
    }
  });

  useEffect(() => {
    reset({
      gradeYear,
      zip,
      ethnicity,
      gpa:
        defaultValues.gpa !== null && defaultValues.gpa >= 0
          ? `${defaultValues.gpa}`
          : "",
      satact:
        defaultValues.satActScore !== null && defaultValues.satActScore >= 0
          ? `${defaultValues.satActScore}`
          : ""
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues.openEditProfileInfo]);

  const filterOptions = (options, search, callback) => {
    callback(() =>
      options.filter((option) =>
        option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  const fetchEthnicityOptions = (search, callback) => {
    const options = [
      { value: null, label: "Prefer not to disclose" },
      ...Object.values(Ethnicity).map((eth) => ({
        value: eth,
        label: ethnicityDisplay(eth)
      }))
    ];
    filterOptions(options, search, callback);
  };

  const fetchGradeYearTypes = (search, callback) => {
    filterOptions(GradeYearTypes, search, callback);
  };

  const onSubmitWithSpinner = retryTimeout(
    useSpinner(async (data) => {
      const result = await onSubmit({
        ...data,
        ethnicity: data.ethnicity.value,
        gradeYear: data.gradeYear.value
      });
      if (result?.type === "ZipCodeNotFound") {
        setError("zip", { message: "Please enter a valid US zip code" });
      }
    })
  );

  return (
    <Form id={id} onSubmit={handleSubmit(onSubmitWithSpinner)}>
      <Form.Select
        errors={errors}
        name="gradeYear"
        control={control}
        color={Colors.GREEN}
        label="Grade"
        defaultValue={gradeYear}
        tabIndex={!isOpen ? -1 : null}
        loadOptions={fetchGradeYearTypes}
      />
      <Form.ErrorMessage errors={errors} name="gradeYear" />

      <Form.Input
        name="gpa"
        label="gpa"
        errors={errors}
        labelUpper={true}
        control={control}
        color={Colors.GREEN}
        tabIndex={!isOpen ? -1 : null}
        rules={{
          required: {
            value: true,
            message: "GPA is required"
          },
          pattern: {
            value: /^0*[0-4](\.[0-9]+)?$/,
            message: "GPA must be between 0 and 4"
          }
        }}
      />
      <Form.ErrorMessage errors={errors} name="gpa" />

      <Form.Input
        name="satact"
        label="sat/act"
        errors={errors}
        labelUpper={true}
        control={control}
        color={Colors.GREEN}
        tabIndex={!isOpen ? -1 : null}
        rules={{
          validate: {
            validateSATACT
          }
        }}
      />
      <span className="text-sm font-semibold">
        Enter 0 if you are not planning to take the ACT or SAT.
      </span>
      <Form.ErrorMessage errors={errors} name="satact" />

      <Form.Input
        name="zip"
        maxLength="5"
        errors={errors}
        label="zip code"
        control={control}
        color={Colors.GREEN}
        tabIndex={!isOpen ? -1 : null}
        rules={{
          pattern: {
            value: /^[0-9]{5}$/,
            message: "Zip Code must be a 5 digit number."
          }
        }}
      />
      <Form.ErrorMessage errors={errors} name="zip" />

      <Form.Select
        errors={errors}
        name="ethnicity"
        control={control}
        color={Colors.GREEN}
        label="Race/Ethnicity"
        defaultValue={ethnicity}
        tabIndex={!isOpen ? -1 : null}
        loadOptions={fetchEthnicityOptions}
      />
      <Form.ErrorMessage errors={errors} name="ethnicity" />

      <Button
        form={id}
        type="submit"
        tabIndex={!isOpen ? -1 : null}
        id="button-save-edit-profile-info"
        className="
          mt-4
          w-full
          text-white
          bg-primary-green
          border-primary-green
          hover:bg-white
          active:bg-white
          hover:text-primary-green
          active:text-primary-green
        "
      >
        save
      </Button>
    </Form>
  );
}

ProfileInfoForm.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.shape()
};

export default ProfileInfoForm;
