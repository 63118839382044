import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Close, Question } from "../../core/icons";

import { updateStudent } from "../../redux/Student/actions";

function FirstPostAwardModal({ onClose }) {
  const dispatch = useDispatch();

  const markOnboardingFirstLetterDone = () => {
    dispatch(updateStudent({ onboardingLetter: true }));
    onClose();
  };

  return (
    <div
      className="
        py-2
        flex
        pb-24
        flex-1
        flex-col
        relative
        justify-center
      "
    >
      <div
        onClick={() => markOnboardingFirstLetterDone()}
        className="m-4 flex flex-row self-end cursor-pointer"
      >
        <Close
          size={25}
          color="stroke-white"
          className="ml-auto my-auto place-self-center"
        />
      </div>
      <div
        className="
          z-10
          flex
          mx-10
          flex-1
          text-xl
          flex-col
          font-bold
          text-center
          text-white
          justify-center
          mobile:mx-5
        "
      >
        <p className="normal-case">Nice!</p>
        <p className="capitalize">You added your first award letter!</p>
        <div className="my-5 flex flex-row justify-center">
          <Question size={35} />
        </div>
        <p className="tablet:mx-20 laptop:mx-20 desktop:mx-20">
          You will notice that some things will look a little different here. We
          will be here to guide you through these calculations.
        </p>
      </div>
    </div>
  );
}

FirstPostAwardModal.propTypes = {
  onClose: PropTypes.func,
};

export default FirstPostAwardModal;
