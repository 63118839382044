import PropTypes from "prop-types";

import Guides from "./Guides";
import ProgressBar from "./ProgressBar";
import Infographic from "./Infographic";
import CollegesFilter from "./CollegesFilter";

import { useIsDesktopMQL } from "../../../../core/hooks";

function MyPathHeader({
  status,
  filters,
  student,
  firstLoad,
  collegesCount,
  handlerOnSubmitFilters,
}) {
  const isDesktop = useIsDesktopMQL();
  return (
    <div className="space-y-4">
      {firstLoad && (
        <>
          <ProgressBar
            status={status}
            student={student}
            isDesktop={isDesktop}
          />
          {isDesktop ? (
            <div className="flex flex-1 flex-row space-x-4">
              <div className="w-1/2">
                <Infographic student={student} isDesktop={isDesktop} />
              </div>
              <div className="w-1/2">
                <Guides
                  student={student}
                  titleClassName="text-base"
                  title="Guides that support you in your journey"
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col space-y-4">
              <div className="w-full">
                <Guides
                  student={student}
                  titleClassName="text-2xl"
                  title="Suggested Guides for You"
                />
              </div>
              <div className="w-full">
                <Infographic student={student} isDesktop={isDesktop} />
              </div>
            </div>
          )}
          <CollegesFilter
            status={status}
            filters={filters}
            isDesktop={isDesktop}
            collegesCount={collegesCount || 0}
            handlerOnSubmitFilters={handlerOnSubmitFilters}
          />
        </>
      )}
    </div>
  );
}

MyPathHeader.propTypes = {
  firstLoad: PropTypes.bool,
  filters: PropTypes.shape(),
  student: PropTypes.shape(),
  collegesCount: PropTypes.number,
  handlerOnSubmitFilters: PropTypes.func,
  status: PropTypes.arrayOf(PropTypes.shape()),
};

export default MyPathHeader;
