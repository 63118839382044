import PropTypes from "prop-types";

import { Button } from "../../../../core/buttons";

function Item({ icon, title, number, buttonText, description, buttonOnClick }) {
  return (
    <div
      className="
        ml-4
        flex
        flex-col
        mobile:my-2
        mobile:flex-row
        mobile:items-stretch
        mobile-sm:my-2
        mobile-sm:flex-row
        mobile-sm:items-stretch
        tablet-sm:my-2
        tablet-sm:flex-row
        tablet-sm:items-stretch
      "
    >
      <div className="px-1 flex flex-1 flex-row items-center justify-between">
        <div className="border border-black bg-primary-green">
          <div
            className="
              my-2
              mx-5
              text-2xl
              font-bold
              text-white
              mobile:my-0
              mobile:mx-3
              mobile-sm:my-0
              mobile-sm:mx-3
              tablet-sm:my-0
              tablet-sm:mx-3
            "
          >
            <span>{number}</span>
          </div>
        </div>
        <div
          className="
            mt-3
            place-self-end
            mobile:mr-5
            mobile-sm:mr-5
            tablet-sm:place-self-center
          "
        >
          {icon}
        </div>
      </div>
      <div className="flex flex-1 mt-10 flex-row mobile:mt-4 tablet:mt-4">
        <div>
          <h2
            className="
              text-3xl
              font-bold
              text-center
              leading-none
              mobile:text-left
              mobile:text-base
              tablet-sm:text-base
              tablet-sm:text-left
              text-primary-green
            "
          >
            {title}
          </h2>
          <p
            className="
              mt-2
              text-base
              text-center
              mobile:hidden
              mobile-sm:hidden
              tablet-sm:hidden
            "
          >
            {description}
          </p>
          {buttonText && buttonOnClick && (
            <Button
              onClick={buttonOnClick}
              className="
                mt-10
                w-full
                normal-case
                text-white
                bg-primary-green
                border-primary-green
                mobile:mx-0
                mobile-sm:mx-0
                tablet-sm:mx-0
                mobile:hidden
                mobile-sm:hidden
                tablet-sm:hidden
                hover:bg-white
                active:bg-white
                hover:text-primary-green
                active:text-primary-green
              "
            >
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

Item.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  number: PropTypes.number,
  buttonText: PropTypes.string,
  description: PropTypes.string,
  buttonOnClick: PropTypes.func,
};

export default Item;
