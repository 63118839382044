import { useState } from "react";

import GoogleMapView from "./GoogleMap";
import CollegeSelector from "./CollegeSelector";
import AffordabilityBox from "./AffordabilityBox";
import CollegeMajorsBox from "./CollegeMajorsBox";
import CollegeGuidesBox from "./CollegeGuidesBox";
import CollegeEarningsBox from "./CollegeEarningsBox";
import CollegeDiversityBox from "./CollegeDiversityBox";
import CollegeGraduationBox from "./CollegeGraduationBox";
import CollegeCampusEnvironment from "./CollegeCampusEnviroment";

import { DeleteSchoolModal } from "../../Modals";
import InputTooltip from "../Common/InputTooltip";
import DeleteButton from "../Common/DeleteButton";
import CollegeYearButtons from "../Common/CollegeYearButtons";
import { InputTooltipType } from "../Common/InputTooltip/utils";

function DetailMobile({
  student,
  openModal,
  selectedSchool,
  showZipTooltip,
  showUploadTutorial,
  showEthnicityTooltip,
  handlerShowZipTooltip,
  handlerShowEthnicityTooltip
}) {
  const [deleteSchool, setDeleteSchool] = useState(false);

  const deleteSchoolConfirmToggle = () =>
    setDeleteSchool((prevState) => !prevState);

  const commonProps = {
    student,
    isDesktop: false,
    chosenSchool: selectedSchool
  };

  return (
    <div className="bg-grey flex flex-1 flex-col">
      <div className="px-4 flex flex-row justify-end">
        <CollegeYearButtons redirect={true} />
      </div>
      <CollegeSelector
        student={student}
        selectedSchool={selectedSchool}
        colleges={student?.chosenSchools?.map((x) => ({
          ...x.school,
          isNew: x.isNew,
          sortOrder: x.sortOrder,
          isFavorite: x.isFavorite
        }))}
      />
      <p className="text-3xl font-semibold px-4 pt-2 tracking-tighter">
        {selectedSchool?.school.name || ""}
      </p>
      <div className="laptop:max-w-25rem desktop:max-w-25rem">
        <AffordabilityBox
          {...commonProps}
          openModal={openModal}
          showUploadTutorial={showUploadTutorial}
        />
        <AffordabilityBox
          {...commonProps}
          infoCard={true}
          openModal={openModal}
          showUploadTutorial={showUploadTutorial}
        />
        <CollegeMajorsBox chosenSchool={selectedSchool} />
        <CollegeGraduationBox
          chosenSchool={selectedSchool}
          studentEthnicity={student.ethnicity}
        />
        <CollegeEarningsBox
          {...commonProps}
          tooltipClassName="-left-10 bottom-20 mobile-sm:-left-20"
        />
        <div className="flex flex-col relative items-center justify-center">
          <InputTooltip
            value={student.ethnicity}
            open={showEthnicityTooltip}
            chosenSchool={selectedSchool}
            type={InputTooltipType.ETHNICITY}
            handlerOnClose={() => handlerShowEthnicityTooltip(false)}
          />
          <div className="block w-full">
            <CollegeDiversityBox
              {...commonProps}
              ethnicity={student.ethnicity}
              handlerOnClickTooltip={() => handlerShowEthnicityTooltip(true)}
            />
          </div>
        </div>
        <div className="flex flex-col relative items-center justify-center">
          <InputTooltip
            value={student.zip}
            open={showZipTooltip}
            chosenSchool={selectedSchool}
            type={InputTooltipType.ZIP_CODE}
            handlerOnClose={() => handlerShowZipTooltip(false)}
          />
          <div className="block w-full">
            <CollegeCampusEnvironment
              {...commonProps}
              handlerOnClickTooltip={() => handlerShowZipTooltip(true)}
            />
            <GoogleMapView
              className="mx-4 shadow-lg"
              studentLatitude={student.lat}
              studentLongitude={student.lon}
              schoolName={selectedSchool.school.name}
              schoolLatitude={selectedSchool.school.latitude}
              schoolLongitude={selectedSchool.school.longitude}
            />
          </div>
        </div>
        <CollegeGuidesBox isDesktop={false} selectedSchool={selectedSchool} />
        <DeleteButton
          chosenSchool={selectedSchool}
          onClick={deleteSchoolConfirmToggle}
        />
        {deleteSchool && (
          <DeleteSchoolModal
            open={deleteSchool}
            chosenSchool={selectedSchool}
            deleteSchoolCancelHandler={deleteSchoolConfirmToggle}
            deleteSchoolConfirmHandler={deleteSchoolConfirmToggle}
          />
        )}
      </div>
    </div>
  );
}

export default DetailMobile;
