import DefaultScreen from "../Common/DefaultScreen";

function NotFound() {
  return (
    <div className="h-screen w-full">
      <DefaultScreen title="Page Not Found!">
        <div
          className="
            flex
            text-xl
            flex-row
            font-bold
            items-center
            justify-center
            text-primary-green
            hover:underline
            hover:text-alternative-green
          "
        >
          <a href="/">Go Home</a>
        </div>
      </DefaultScreen>
    </div>
  );
}

export default NotFound;
