import PropTypes from "prop-types";

import ChartDesktop from "../../../../../core/charts/AffordableChart/Desktop";

import { getChartComponent } from "./AffordabilityChartConfig";

function AffordabilityChartDesktop(props) {
  const { chartData, chosenSchool, affordability, isDesktop } = props;
  if (!isDesktop) return null;

  const chartConfig = getChartComponent(chosenSchool.schoolStatus, props);

  return (
    <div
      className="
        p-4
        mx-4
        flex
        flex-1
        flex-col
        shadow-lg
        bg-white
      "
    >
      <div className="my-auto">
        <div className="-mt-6 -mb-10 px-16 tablet:-mb-6">
          <ChartDesktop data={chartData} affordability={affordability} />
        </div>
        {chartConfig}
      </div>
    </div>
  );
}

AffordabilityChartDesktop.propTypes = {
  theme: PropTypes.shape(),
  award: PropTypes.shape(),
  affordability: PropTypes.string,
  addYourMoneyPath: PropTypes.string,
  schoolStatus: PropTypes.string,
  onClickViewAwardLetter: PropTypes.func,
  onClickReuploadAwardLetter: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape())
};

export default AffordabilityChartDesktop;
