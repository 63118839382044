import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";

import Layout from "../Common/Layout";
import LayoutDesktop, {
  LayoutLeftContent,
  LayoutRightContent,
} from "../Common/LayoutDesktop";

import { Button, LoadingButton } from "../../core/buttons";

import Api from "../../services/Api";
import { useStudent } from "../../redux/Student/hooks";
import { fetchStudent } from "../../redux/Student/actions";

function Invitation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { invitationId } = useParams();

  const student = useStudent();

  const {
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const [error, setError] = useState();
  const [profile, setProfile] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [invitation, setInvitation] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [userExists, setUserExists] = useState(false);
  const [errorMutation, setErrorMutation] = useState();
  const [isLoadingMutation, setIsLoadingMutation] = useState(false);

  const skipOnClick = () => navigate("/");

  const acceptOnClick = async () => {
    setIsLoadingMutation(true);
    try {
      await Api.acceptInvitation({ invitationId });
      await dispatch(fetchStudent({}));
      navigate("/");
    } catch (error) {
      setErrorMutation(error);
    } finally {
      setIsLoadingMutation(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        if (isAuthenticated) {
          const claims = await getIdTokenClaims();
          setProfile(claims);
          if (!student.isLoggedIn) {
            const getToken = async () => {
              await getAccessTokenSilently();
              const claims = await getIdTokenClaims();
              return claims.__raw;
            };
            Api.configure({
              token: getToken,
            });

            const exists = await Api.checkUserExists();
            setUserExists(exists);
          } else {
            setUserExists(true);
          }
        }
        setIsLoading(false);
      } catch (e) {
        setError(e);
      }
    })();
  }, [
    isAuthenticated,
    student.isLoggedIn,
    getIdTokenClaims,
    getAccessTokenSilently,
  ]);

  useEffect(() => {
    const fetchInvitation = async () => {
      if (invitationId) {
        try {
          const invitation = await Api.fetchInvitation({ invitationId });

          if (isAuthenticated) {
            if (!userExists || !profile.email_verified) {
              loginWithRedirect();
              return;
            }

            if (invitation.status === "ACCEPTED") {
              setAccepted(true);
            }
            setInvitation(invitation);
            setIsReady(true);
          } else {
            if (invitation.expired) {
              setError({ errors: { message: "Invitation has expired!" } });
              setIsReady(true);
            } else {
              if (invitation.recipient_is_registered) await loginWithRedirect();
              else
                await loginWithRedirect({
                  authorizationParams: { screen_hint: "signup" },
                });
            }
          }
        } catch (error) {
          setError(error);
          setIsReady(true);
        }
      }
    };

    if (!isLoading) fetchInvitation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (!isReady)
    return <div className="w-full font-bold text-center">Loading...</div>;
  else
    return (
      <Layout logoWhite={true} headerBGColor="text-white bg-primary-green">
        <LayoutDesktop className="bg-primary-green min-h-screen-footer-md">
          <LayoutLeftContent>
            <p
              className="
                text-3xl
                font-semibold
                laptop:text-4xl
                desktop:text-5xl
              "
            >
              Join us in DecidED
            </p>
          </LayoutLeftContent>
          <LayoutRightContent>
            <div
              className="
                py-4
                px-6
                flex
                border
                flex-col
                shadow-lg
                bg-white
                items-center
                border-black
              "
            >
              <div className="flex flex-1 flex-col">
                <div className="my-12">
                  <div className="text-4xl font-extrabold text-primary-green">
                    {error ? error?.errors?.message : invitation?.subject}
                  </div>
                  <div className="mb-4 my-12 text-2xl text-black">
                    {invitation?.body}
                  </div>
                </div>

                {errorMutation && (
                  <p className="pb-2 text-primary-red">
                    {errorMutation.errors[0].message}
                  </p>
                )}

                <div
                  className={`
                    flex
                    flex-1
                    flex-row
                    space-x-4
                    ${(error || accepted) && "hidden"}
                  `}
                >
                  <div className="w-1/2">
                    <Button
                      onClick={() => skipOnClick()}
                      className="
                        mb-5
                        w-full
                        bg-white
                        text-primary-green
                        border-primary-green
                        hover:text-white
                        active:text-white
                        hover:bg-primary-green
                        active:bg-primary-green
                      "
                    >
                      Skip
                    </Button>
                  </div>
                  <div className="w-1/2">
                    <LoadingButton
                      isLoading={isLoadingMutation}
                      onClick={() => acceptOnClick()}
                      className="
                        mb-5
                        w-full
                        text-white
                        bg-primary-green
                        border-primary-green
                        hover:bg-white
                        active:bg-white
                        hover:text-primary-green
                        active:text-primary-green
                      "
                    >
                      Accept
                    </LoadingButton>
                  </div>
                </div>
                <div
                  className={`
                    flex
                    flex-1
                    flex-row
                    ${!accepted && !error && "hidden"}
                  `}
                >
                  <div className="w-full">
                    <Button
                      onClick={() => skipOnClick()}
                      className="
                        mb-5
                        w-full
                        text-white
                        bg-primary-green
                        border-primary-green
                        hover:bg-white
                        active:bg-white
                        hover:text-primary-green
                        active:text-primary-green
                      "
                    >
                      Return
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </LayoutRightContent>
        </LayoutDesktop>
      </Layout>
    );
}

export default Invitation;
