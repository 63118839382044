import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useLogin } from "../../utils/auth";
import { useStudent } from "../../redux/Student/hooks";
import { fetchGuides, fetchStudent } from "../../redux/Student/actions";

function PrivateRoute({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const student = useStudent();
  const { isLoading, userExists, error } = useLogin();

  const location = useLocation();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      if (!isLoading && !error) {
        if (userExists) {
          if (!student.isLoggedIn) {
            await dispatch(fetchStudent({}));
            await dispatch(fetchGuides());
          }
          setReady(true);
        } else {
          const qs = new URLSearchParams(location.search.slice(1));
          let redirectUrl = "/create-account";
          if (qs.get("next")) {
            redirectUrl += `?next=${encodeURIComponent(qs.get("next"))}`;
          }
          navigate(redirectUrl);
        }
      }
    })();
  }, [
    isLoading,
    userExists,
    student.isLoggedIn,
    error,
    dispatch,
    navigate,
    location
  ]);

  if (ready) {
    return children;
  } else {
    // FIXME: Display something
    return null;
  }
}

PrivateRoute.propTypes = {
  children: PropTypes.any
};

export default PrivateRoute;
