import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../core/buttons";
import AddAwardAffordabilityBox from "../../../utils/DataLayers/AddAwardAffordabilityBox";

import { GetIpedsId } from "../../Common/Hooks";

function AddYourMoneyButton({ path }) {
  const navigate = useNavigate();
  const ipedsId = GetIpedsId();

  const handlerOnClick = () => {
    AddAwardAffordabilityBox({ ipeds: ipedsId });
    navigate(path);
  };

  return (
    <Button
      className="
        mt-4
        w-full
        text-xsm
        text-white
        font-extrabold
        bg-primary-blue
        border-primary-blue
        hover:bg-white
        active:bg-white
        hover:text-primary-blue 
        active:text-primary-blue
      "
      onClick={handlerOnClick}
    >
      add your award letter
    </Button>
  );
}

AddYourMoneyButton.propTypes = {
  path: PropTypes.string,
};

export default AddYourMoneyButton;
