import PropTypes from "prop-types";

import Item from "./Item";
import Header from "../../Common/Header";
import Layout from "../../../Common/Layout";
import LayoutCollege from "../../LayoutCollege";

import { Link } from "../../../../core";
import { Button } from "../../../../core/buttons";
import { Wallet, Letter, College, ShortArrow } from "../../../../core/icons";

import { Colors, decidedRoute } from "../../../../core/utils";

function DontHaveAward({
  onBack,
  student,
  openModal,
  onBackToHome,
  modalResponse,
}) {
  return (
    <Layout
      student={student}
      openModal={openModal}
      modalResponse={modalResponse}
    >
      <LayoutCollege>
        <div
          className="
            min-h-screen-footer-md
            mobile:min-h-screen-footer
            mobile-sm:min-h-screen-footer
            tablet-sm:min-h-screen-footer
          "
        >
          <div className="p-4 mobile:hidden tablet-sm:hidden">
            <Header title="Find your Financial Aid Award" onBack={onBack} />
          </div>
          <div
            className="
              p-8
              flex
              flex-1
              flex-col
              bg-white
              mobile:px-4
              tablet-sm:px-4
              overflow-scroll
            "
          >
            <div className="tablet:hidden laptop:hidden desktop:hidden">
              <Header title="" onBack={onBack} />

              <div className="mt-4 mb-8 flex flex-row items-center">
                <span className="ml-4 text-3xl font-black">
                  Find your Financial Aid Award
                </span>
              </div>
            </div>

            <p
              className="
                mb-4
                text-xl
                font-bold
                text-primary-green
                mobile:mx-4
                mobile-sm:mx-4
                tablet-sm:mx-4
              "
            >
              Don't have your award yet? A few tips on what to do next:
            </p>

            <div
              className="
                my-8
                flex
                flex-1
                flex-row
                mobile:flex-col
                tablet-sm:flex-col
                mobile:space-y-6
                tablet-sm:space-y-6
              "
            >
              <Item
                icon={
                  <Letter
                    size={50}
                    iconColor="fill-primary-green"
                    fillColor="fill-secondary-green"
                  />
                }
                text={
                  <div>
                    <p>
                      Colleges usually send your financial aid award after your
                      acceptance letter. This is important, so keep checking to
                      see if you've received it.
                    </p>
                    <Link
                      icon={true}
                      target="_blank"
                      internal={false}
                      color={Colors.GREEN}
                      textClassName="text-sm text-left font-bold"
                      href={decidedRoute(
                        "/guide/find-your-financial-aid-award/"
                      )}
                      text="Read the DecidED Guide for more details on how to Find your Financial Aid Award"
                      customIcon={
                        <div className="transform place-self-end rotate-180">
                          <ShortArrow size={16} color="stroke-current" />
                        </div>
                      }
                    />
                  </div>
                }
              />
              <Item
                icon={
                  <Wallet
                    size={50}
                    iconColor="fill-primary-green"
                    fillColor="fill-secondary-green"
                  />
                }
                text={
                  <div>
                    <p>
                      To get your award, you may have to verify your family's
                      finances. Check for an email telling you to submit
                      additional documents or that you are selected for
                      verification. If so, complete the necessary steps.
                    </p>
                    <Link
                      icon={true}
                      target="_blank"
                      internal={false}
                      color={Colors.GREEN}
                      textClassName="text-sm text-left font-bold"
                      text="How to verify your family's financial information"
                      href={decidedRoute(
                        "/guide/what-you-need-to-know-about-verification/"
                      )}
                      customIcon={
                        <div className="transform place-self-end rotate-180">
                          <ShortArrow size={16} color="stroke-current" />
                        </div>
                      }
                    />
                  </div>
                }
              />
              <Item
                icon={<College size={50} fillColor="fill-primary-green" />}
                text={
                  <p>
                    If you think you should have gotten your award but haven't,
                    call the college's Financial Aid Office.
                  </p>
                }
              />
            </div>
            <div
              className="
                flex
                flex-1
                flex-row
                tablet:space-x-2
                laptop:space-x-8
                desktop:space-x-8
              "
            >
              <div
                className="
                  w-1/2
                  mobile:hidden
                  mobile-sm:hidden
                  tablet-sm:hidden
                "
              ></div>
              <div
                className="
                  w-1/2
                  mt-auto
                  mobile:w-full
                  mobile-sm:w-full
                  tablet-sm:w-full
              "
              >
                <Button
                  onClick={onBackToHome}
                  className="
                    w-full
                    normal-case
                    text-white
                    bg-primary-green
                    border-primary-green
                    hover:bg-white
                    active:bg-white
                    hover:text-primary-green
                    active:text-primary-green
                  "
                >
                  Back to Home
                </Button>
              </div>
            </div>
          </div>
        </div>
      </LayoutCollege>
    </Layout>
  );
}

DontHaveAward.propTypes = {
  onBack: PropTypes.func,
  openModal: PropTypes.func,
  student: PropTypes.shape(),
  onBackToHome: PropTypes.func,
  modalResponse: PropTypes.string,
};

export default DontHaveAward;
