import TagManager from "react-gtm-module";

function Tooltip({ text, ipeds }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "tool_tip",
      text: text.text,
      ipeds: ipeds,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

export default Tooltip;
