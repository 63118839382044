const RESET = "NOTIFICATIONS/RESET";
const SET_PAGE = "NOTIFICATIONS/SET_PAGE";
const SET_ERROR = "NOTIFICATIONS/SET_ERROR";
const SET_IS_LOADING = "NOTIFICATIONS/SET_IS_LOADING";

const Types = {
  RESET,
  SET_PAGE,
  SET_ERROR,
  SET_IS_LOADING,
};

export default Types;
