import PropTypes from "prop-types";

import ButtonSelector from "./ButtonSelector";
import CheckboxSelector from "./CheckboxSelector";

function MultiSelector({ data, selectorType }) {
  switch (selectorType) {
    case "Button":
      return <ButtonSelector data={data} />;
    case "CheckBox":
      return <CheckboxSelector data={data} />;
    default:
      return null;
  }
}

MultiSelector.propTypes = {
  selectorType: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default MultiSelector;
