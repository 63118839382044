import React, { useState, useEffect } from "react";
import { ScrollToTop } from "../../../core/icons";

function ScrollToTopButton() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;

      if (currentPosition > 300) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      className={`fixed bottom-20 right-6 z-50 bg-gray-300 px-5 py-3 rounded-full flex flex-col items-center justify-center transition-all ${
        visible ? "opacity-70" : "opacity-0"
      }`}
    >
      <ScrollToTop size={27} color="text-black" />
      <span className="text-black uppercase font-bold text-xs truncate">
        Scroll
      </span>
      <span className="text-black uppercase font-bold text-xs truncate">
        to top
      </span>
    </button>
  );
}

export default ScrollToTopButton;
