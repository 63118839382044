import PropTypes from "prop-types";

import FirstYearButtons from "../../Common/FirstYearButtons";

import { FirstYearBreakdownCard as CommonFirstYearBreakdownCard } from "../../../../core/compose/cards";

import { AwardStatus, getTheme, getColors } from "../../../../core/utils";

import Api from "../../../../services/Api";

function FirstYearBreakdownCard({
  id,
  open,
  chosenSchool,
  studentState,
  externalToggle,
  callbackToggle,
  showUploadTutorial,
}) {
  const { award, school, pairedId } = chosenSchool;

  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability,
  });
  const colors = getColors(theme.color);

  const successfulAward = award?.status === AwardStatus.READY;

  const addYourMoneyPath = `/college/add-your-money/${school.ipedsId}${
    !showUploadTutorial ? "/upload-images" : ""
  }`;
  const reportAnErrorPath = `/college/report-an-error/${school.ipedsId}`;

  const handleUpdateAward = (isIntersecting) => {
    if (isIntersecting)
      setTimeout(() => {
        Api.readAwardUpdate({ chosenSchoolId: pairedId });
      }, 2000);
  };

  const firstYearButtonsComponent = (
    <FirstYearButtons
      colors={colors}
      successfulAward={successfulAward}
      addYourMoneyPath={addYourMoneyPath}
      reportAnErrorPath={reportAnErrorPath}
    />
  );

  return (
    <CommonFirstYearBreakdownCard
      id={id}
      open={open}
      colors={colors}
      chosenSchool={chosenSchool}
      studentState={studentState}
      externalToggle={externalToggle}
      callbackToggle={callbackToggle}
      handleUpdateAward={handleUpdateAward}
      firstYearButtonsComponent={firstYearButtonsComponent}
    />
  );
}

FirstYearBreakdownCard.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  studentState: PropTypes.string,
  externalToggle: PropTypes.bool,
  callbackToggle: PropTypes.func,
  chosenSchool: PropTypes.shape(),
  showUploadTutorial: PropTypes.bool,
};

export default FirstYearBreakdownCard;
