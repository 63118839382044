import PropTypes from "prop-types";

function Category({ name }) {
  return (
    <div
      className="
        py-4
        px-10
        w-full
        text-white
        mobile:px-5
        mobile-sm:px-5
        tablet-sm:px-5
        bg-primary-green
      "
    >
      <p className="font-semibold">{name}</p>
    </div>
  );
}

Category.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Category;
