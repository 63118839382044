import PropTypes from "prop-types";
import { useState } from "react";

import {
  Loans,
  CollegeCosts,
  FinancialAid,
  PayingForCollege,
  ComparingColleges
} from "../../../core/icons/guides";

import { LinkCustom } from "../../../core";
import { GetIpedsId } from "../../Common/Hooks";
import { AffordabilityBoxGuide } from "../../../utils/DataLayers";

import FindFinAwardImage from "../../../assets/images/find-your-financial-award.png";

function Guide({
  href,
  title,
  image,
  category,
  children,
  hasTitle,
  className,
  hasButton,
  buttonText,
  buttonOnClick,
  hasDescription,
  titleClassName,
  childrenClassName
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleImageHover = () => {
    setIsHovered(true);
  };

  const handleImageLeave = () => {
    setIsHovered(false);
  };

  if (!hasButton)
    return (
      <LinkCustom
        href={href}
        target="_blank"
        onClick={buttonOnClick}
        className={`
          flex
          w-full
          h-full
          flex-col
          ${className} 
          ${(hasTitle || hasDescription) && "shadow-lg"}
          ${isHovered && "shadow-lg shadow-primary-green"}
        `}
      >
        <div className="w-full relative">
          <div
            className="
              py-1
              px-4
              mx-6
              z-10
              top-0
              left-0
              absolute
              text-white
              bg-primary-green
            "
          >
            {category}
          </div>
        </div>
        <div
          className="flex flex-row bg-secondary-green"
          onMouseEnter={handleImageHover}
          onMouseLeave={handleImageLeave}
        >
          {image ? (
            <img
              src={image}
              height={110}
              className="w-full"
              alt="guide-thumbnail"
            />
          ) : (
            <div
              style={{ height: 110 }}
              className="w-full bg-secondary-green"
            ></div>
          )}
        </div>
        <div
          className={`
            flex
            w-full
            flex-1
            flex-row
            ${hasTitle || hasDescription ? "p-4 bg-white" : ""}
          `}
          onMouseEnter={handleImageHover}
          onMouseLeave={handleImageLeave}
        >
          <div className="flex w-full flex-col justify-between">
            <div
              className="
                flex
                flex-1
                flex-col
                self-start
                justify-center
                place-self-start
              "
            >
              {hasTitle && (
                <div
                  className={`leading-none text-primary-green ${titleClassName}`}
                >
                  {title}
                </div>
              )}
              {hasDescription && (
                <div className={`font-bold ${childrenClassName}`}>
                  {children}
                </div>
              )}
            </div>
          </div>
        </div>
      </LinkCustom>
    );

  return (
    <div
      className={`
        flex
        w-full
        h-full
        flex-col
        ${className} 
        ${(hasTitle || hasDescription) && "shadow-lg"}
        ${isHovered && "shadow-lg shadow-primary-green"}
      `}
    >
      <div className="w-full relative">
        <div
          className="
            py-1
            px-4
            mx-6
            z-10
            top-0
            left-0
            absolute
            text-white
            bg-primary-green
          "
        >
          {category}
        </div>
      </div>
      <a href={href} target="_blank" rel="noopener noreferrer">
        <div
          className="flex flex-row bg-secondary-green cursor-pointer"
          onMouseEnter={handleImageHover}
          onMouseLeave={handleImageLeave}
        >
          <img
            src={image ? image : FindFinAwardImage}
            height={110}
            className="w-full"
            alt="guide-thumbnail"
          />
        </div>
      </a>
      <div
        className={`
          flex
          w-full
          flex-row
          ${hasTitle || hasDescription ? "px-4 bg-white" : ""}
        `}
      >
        <div
          className="flex w-full flex-col justify-between"
          onMouseEnter={handleImageHover}
          onMouseLeave={handleImageLeave}
        >
          <div
            className="
              flex
              flex-1
              flex-col
              self-start
              justify-center
              place-self-start
            "
          >
            {hasTitle && (
              <div
                className={`leading-none text-primary-green ${titleClassName}`}
              >
                {title}
              </div>
            )}
            {hasDescription && (
              <div
                className={`
                  font-bold
                  ${childrenClassName}
                  ${!hasButton && "pb-2"}
                `}
              >
                {children}
              </div>
            )}
          </div>
          {hasButton && (
            <div
              className={`
              py-2
              px-5
              w-full
              text-sm
              font-extrabold
              bg-primary-green
              border-primary-green
              ${isHovered && "bg-white border-primary-green"}
              `}
            >
              <LinkCustom href={href} target="_blank" onClick={buttonOnClick}>
                <p
                  className={`text-center ${
                    isHovered ? "text-primary-green" : "text-white"
                  }`}
                >
                  {buttonText || "Read More"}
                </p>
              </LinkCustom>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Guide.propTypes = {
  title: PropTypes.node,
  href: PropTypes.string,
  image: PropTypes.string,
  category: PropTypes.any,
  children: PropTypes.any,
  hasTitle: PropTypes.bool,
  hasButton: PropTypes.bool,
  className: PropTypes.string,
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
  hasDescription: PropTypes.bool,
  titleClassName: PropTypes.string,
  childrenClassName: PropTypes.string
};

function CollegeGuide({
  guide,
  className,
  affordability,
  titleClassName,
  childrenClassName,
  hasTitle = true,
  hasButton = false,
  hasDescription = false
}) {
  const { url, title, image, category, children, buttonText } = guide;
  const ipedsId = GetIpedsId();

  const guideHandleClick = () => {
    AffordabilityBoxGuide({
      ipeds: ipedsId,
      affordability_state: affordability
    });
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case "COLLEGE_COSTS":
        return <CollegeCosts size={25} color="white" />;
      case "COMPARING_COLLEGES":
        return <ComparingColleges size={25} color="white" />;
      case "FINANCIAL_AID":
        return <FinancialAid size={25} color="white" />;
      case "PAYING_FOR_COLLEGES":
        return <PayingForCollege size={25} color="white" />;
      default:
        return <Loans size={25} color="white" />;
    }
  };

  return (
    <Guide
      href={url}
      title={title}
      image={image}
      hasTitle={hasTitle}
      hasButton={hasButton}
      className={className}
      buttonText={buttonText}
      titleClassName={titleClassName}
      hasDescription={hasDescription}
      category={getCategoryIcon(category)}
      childrenClassName={childrenClassName}
      buttonOnClick={() => guideHandleClick()}
    >
      {children}
    </Guide>
  );
}

CollegeGuide.propTypes = {
  guide: PropTypes.shape(),
  hasTitle: PropTypes.bool,
  hasButton: PropTypes.bool,
  buttonText: PropTypes.any,
  className: PropTypes.string,
  hasDescription: PropTypes.bool,
  titleClassName: PropTypes.string,
  childrenClassName: PropTypes.string
};

export default CollegeGuide;
